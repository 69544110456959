import { ChangeEvent, FC, useEffect, useState } from "react";
import { InvoiceDetailsModal } from "./InvoiceDetails";
import { Modal } from "bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import Authorize from "../../modules/auth/components/Authorize";

interface Data {
  customerName: string;
  invoiceNumber: string;
  invoiceDate: string;
  subTotal: number;
  total: number;
  invoiceGuid: string;
  invStatus: number;
  customerEmailId: string;
}

interface TableProps {
  isActive: number;
  setNumOfInvoice: (num: number) => void;
  setEmpStatus: (status: string) => void;
  setActive: (num: number) => void;
}

export const ApiData = {
  api: (): void => {},
};

export const TableComponent: FC<TableProps> = ({
  isActive,
  setNumOfInvoice,
  setEmpStatus,
  setActive,
}) => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");

  const [tabledata, setTableData] = useState<Data[]>([]);
  const [filteredData, setFilteredData] = useState<Data[]>(tabledata);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [selectedData, setSelectedData] = useState<Data | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTab, setSelectedTab] = useState<string>("All");

  const tooltipresend = <Tooltip id="tooltip-add-money">Resend</Tooltip>;
  const tooltipcancell = <Tooltip id="tooltip-add-money">Cancel</Tooltip>;

  const navigate = useNavigate();
  let editEmail = "";
  console.log("Table");

  const handleCancelClick = async (index: number) => {
    try {
      const response = await Swal.fire({
        icon: "warning",
        title: "Confirmation",
        text: "Are you sure you want to cancel this invoice?",
        showCancelButton: true,
        confirmButtonColor: "#007bff",
        confirmButtonText: "Yes",
      });

      if (response.isConfirmed) {
        const clickedData = filteredData[index].invoiceGuid;
        const url = `${API_URL}/invoice/cancel`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
          invoiceGuid: clickedData,
        });

        const options = {
          method: "POST",
          headers,
          body,
        };

        const apiResponse = await enhancedFetch(url, options);
        const data = await apiResponse.json();

        if (data.status.statusCode === 0) {
          getInvoice();
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: `Invoice Cancelled`,
            confirmButtonColor: "#007bff",
            confirmButtonText: "OK",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendClick = async (index: number) => {
    try {
      const response = await Swal.fire({
        icon: "info",
        title: "Confirmation",
        input: "email",
        inputLabel: `Your Invoice with number ${filteredData[index].invoiceNumber} will be resent to`,
        inputValue: `${filteredData[index].customerEmailId || ""}`,
        showCancelButton: true,
        confirmButtonColor: "#007bff",
        confirmButtonText: "Resend Invoice",
        cancelButtonText: "Cancel",
        allowEscapeKey: true,
        allowEnterKey: true,
        inputAttributes: {
          autocapitalize: "off",
          autocorrect: "off",
        },
        preConfirm: () => {
          const emailInputValue = (
            Swal?.getPopup()?.querySelector("#emailInput") as HTMLInputElement
          )?.value;
          return emailInputValue;
        },
      });

      if (response.isConfirmed) {
        console.log("hi");

        editEmail = response?.value;
        const clickedData = filteredData[index].invoiceGuid;
        const url = `${API_URL}/invoice/sendInvoice`;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        const body = JSON.stringify({
          invoiceGuid: clickedData,
          customerEmailId: response?.value,
        });

        const options = {
          method: "POST",
          headers,
          body,
        };

        const apiResponse = await enhancedFetch(url, options);
        const data = await apiResponse.json();

        if (data.status.statusCode === 0) {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            text: `Your Invoice with number ${filteredData[index].invoiceNumber} has been resent to ${editEmail}`,
            confirmButtonColor: "#007bff",
            confirmButtonText: "OK",
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              getInvoice();
            }
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getInvoice = async () => {
    try {
      const url = `${API_URL}/invoice/view`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0 && data?.results?.length > 0) {
        setTableData(data?.results);
        setNumOfInvoice(data?.results?.length);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getInvoice();
  }, []);

  ApiData.api = getInvoice;

  const getStatusClass = (paymentStatus: string): string => {
    switch (paymentStatus) {
      case "Paid":
        return "text-success";
      case "Issued":
        return "text-warning";
      case "Cancelled":
        return "text-danger";
      default:
        return "";
    }
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);

    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handleDetailsClick = (index: number) => {
    const modalElement = document.getElementById("details_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
    const clickedData: Data = filteredData[index];
    setSelectedData(() => clickedData);
  };

  useEffect(() => {
    // Filter data based on both selected tab and search query
    const filteredData = tabledata?.filter((data) => {
      // Filter based on selected tab
      let isTabMatched = false;
      switch (selectedTab) {
        case "All":
          isTabMatched = true; // Always match for "All" tab
          break;
        case "Paid":
          isTabMatched = data.invStatus === 2;
          break;
        case "Unpaid":
          isTabMatched = data.invStatus === 1;
          break;
        case "Cancelled":
          isTabMatched = data.invStatus === 4;
          break;
        default:
          isTabMatched = true; // Default to true for unknown tabs
      }

      // Filter based on search query
      const searchValue = searchTerm?.toLowerCase();
      const isSearchMatched =
        data.customerName.toLowerCase().includes(searchValue) ||
        data.invoiceDate.toLowerCase().includes(searchValue) ||
        data.subTotal.toString().toLowerCase().includes(searchValue) ||
        data.invoiceNumber.toLowerCase().includes(searchValue);

      // Return true if both conditions are matched
      return isTabMatched && isSearchMatched;
    });

    // Update filtered data
    setFilteredData(filteredData);
  }, [searchTerm, tabledata, selectedTab]);

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    setCurrentPage(1);

    // Update filteredTransactions based on the selected tab
    if (tab === "All") {
      setFilteredData(tabledata);
    } else if (tab === "Paid") {
      const filteredData = tabledata.filter((data) =>
        tab === "Paid" ? data.invStatus === 2 : ""
      );
      setFilteredData(filteredData);
    } else if (tab === "Unpaid") {
      const filteredData = tabledata.filter((data) =>
        tab === "Unpaid" ? data.invStatus === 1 : ""
      );
      setFilteredData(filteredData);
    } else if (tab === "Cancelled") {
      const filteredData = tabledata.filter((data) =>
        tab === "Cancelled" ? data.invStatus === 4 : ""
      );
      setFilteredData(filteredData);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredData?.length / itemsPerPage);
    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };
  return (
    <>
      <div className="py-2 card p-5">
        <div className="d-flex justify-content-between align-items-center ">
          <div className="">
            <ul className="nav nav-tabs nav-line-tabs ms-4 fs-6 d-flex flex-nowrap">
              <li className="nav-item" key={1}>
                <a
                  className="nav-link active text-active-gray-800"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_1"
                  onClick={() => handleTabClick("All")}
                >
                  All
                </a>
              </li>
              <li className="nav-item" key={2}>
                <a
                  className="nav-link text-active-gray-800"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_2"
                  onClick={() => handleTabClick("Paid")}
                >
                  Paid
                </a>
              </li>
              <li className="nav-item" key={3}>
                <a
                  className="nav-link text-nowrap text-active-gray-800"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_3"
                  onClick={() => handleTabClick("Unpaid")}
                >
                  Unpaid
                </a>
              </li>
              <li className="nav-item" key={4}>
                <a
                  className="nav-link text-nowrap text-active-gray-800"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_4"
                  onClick={() => handleTabClick("Cancelled")}
                >
                  Cancelled
                </a>
              </li>
            </ul>
          </div>

          <div className="d-flex flex-1 align-items-center p-3 gap-10">
            <div className="d-flex align-items-center  position-relative my-1">
              <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
              <input
                type="text"
                data-kt-docs-table-filter="search"
                className="form-control p-3 border-secondary form-control-solid w-250px ps-15"
                placeholder="Search invoice"
                defaultValue={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
            <Authorize hasAnyPermission={["MENU_INVOICE|ADD"]}>
              <button
                className="btn  fs-7 p-3"
                onClick={() => navigate("/invoice/create-invoice")}
              >
                Create Invoice
              </button>
            </Authorize>
          </div>
        </div>

        <div className="tab-content " id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="kt_tab_pane_1"
            role="tabpanel"
          >
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Customer Name</th>
                      <th className="min-w-100px p-5">Invoice No</th>
                      <th className="min-w-100px p-5">Transaction Date</th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Amount Paid
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Payment Status
                      </th>
                      <th className="min-w-100px p-5">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData.length > 0 ? (
                      currentData.map((item, index) => (
                        <tr
                          key={index}
                          onMouseOver={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "#f1f1f2";
                            // target.style.transform =
                            //   "translate3d(6px, -6px, 0)";
                            // target.style.boxShadow =
                            //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                          }}
                          // onClick={() => handleDetailsClick(index)}
                          onMouseOut={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "white";
                            // target.style.transform = "none";
                            // target.style.boxShadow = "none";
                          }}
                        >
                          <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                            {item.customerName}
                          </td>
                          <td
                            className="p-5 text-primary text-hover-dark text-decoration-underline cursor-pointer "
                            onClick={() => handleDetailsClick(index)}
                          >
                            {item.invoiceNumber}
                          </td>
                          <td className="p-5 text-gray-600">
                            {new Date(item.invoiceDate).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )}
                          </td>
                          <td className="p-5 text-gray-600">
                            {baseCurrency}{" "}
                            {item.total.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td
                            className={`p-5 ${
                              item.invStatus === 1
                                ? "text-primary"
                                : item.invStatus === 2
                                ? "text-success"
                                : item.invStatus === 3
                                ? "text-deleted"
                                : item.invStatus === 4
                                ? "text-danger"
                                : "default-style"
                            }`} // Add a default style class
                          >
                            {`${
                              item.invStatus === 1
                                ? "unpaid"
                                : item.invStatus === 2
                                ? "paid"
                                : item.invStatus === 3
                                ? "deleted"
                                : item.invStatus === 4
                                ? "cancelled"
                                : ""
                            }`}
                          </td>
                          <td className="p-5 text-gray-600 p-2">
                            {item.invStatus === 1 && (
                              <div className="d-flex gap-10">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltipresend}
                                >
                                  <i
                                    className="bi bi-send fs-3 cursor-pointer"
                                    style={{ color: "#0000ff" }}
                                    onClick={() => handleResendClick(index)}
                                  ></i>
                                </OverlayTrigger>
                                <Authorize
                                  hasAnyPermission={["MENU_INVOICE|DELETE"]}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipcancell}
                                  >
                                    <i
                                      className="bi bi-x-circle fs-3 cursor-pointer"
                                      style={{ color: "#FF0000" }}
                                      onClick={() => {
                                        handleCancelClick(index);
                                      }}
                                    ></i>
                                  </OverlayTrigger>
                                </Authorize>
                              </div>
                            )}

                            {item.invStatus === 2 && (
                              <div className="d-flex gap-10">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltipresend}
                                >
                                  <i
                                    className="bi bi-send fs-3 cursor-pointer"
                                    style={{ color: "#0000ff" }}
                                    onClick={() => handleResendClick(index)}
                                  ></i>
                                </OverlayTrigger>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* <InvoiceModal data={details}/> */}
            </>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Customer Name</th>
                      <th className="min-w-100px p-5">Invoice No</th>
                      <th className="min-w-100px p-5">Transaction Date</th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Amount Paid
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Payment Status
                      </th>
                      <th className="min-w-100px p-5">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData?.length > 0 ? (
                      currentData?.map((item, index) => {
                        if (item.invStatus === 2) {
                          return (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                                // target.style.transform =
                                //   "translate3d(6px, -6px, 0)";
                                // target.style.boxShadow =
                                //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                                // target.style.transform = "none";
                                // target.style.boxShadow = "none";
                              }}
                            >
                              <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                {item.customerName}
                              </td>
                              <td
                                className="p-5 text-primary text-hover-dark text-decoration-underline cursor-pointer "
                                onClick={() => handleDetailsClick(index)}
                              >
                                {item.invoiceNumber}
                              </td>
                              <td className="p-5 text-gray-600">
                                {new Date(item.invoiceDate).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                )}
                              </td>
                              <td className="p-5 text-gray-600">
                                {baseCurrency}{" "}
                                {item.total.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td className="text-success">paid</td>
                              <td className="p-5 text-gray-600 p-2">
                                <div className="d-flex gap-10">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipresend}
                                  >
                                    <i
                                      className="bi bi-send fs-3 cursor-pointer"
                                      style={{ color: "#0000ff" }}
                                      onClick={() => handleResendClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                  {/* <i
                                  className="bi bi-x-circle fs-3"
                                  style={{ color: "#FF0000" }}
                                  onClick={() => {
                                    handleCancelClick(index);
                                  }}
                                ></i> */}
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Customer Name</th>
                      <th className="min-w-100px p-5">Invoice No</th>
                      <th className="min-w-100px p-5">Transaction Date</th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Amount Paid
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Payment Status
                      </th>
                      <th className="min-w-100px p-5">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData?.length > 0 ? (
                      currentData?.map((item, index) => {
                        if (item.invStatus === 1) {
                          return (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                                // target.style.transform =
                                //   "translate3d(6px, -6px, 0)";
                                // target.style.boxShadow =
                                //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                                // target.style.transform = "none";
                                // target.style.boxShadow = "none";
                              }}
                            >
                              <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                {item.customerName}
                              </td>
                              <td
                                className="p-5 text-primary text-hover-dark text-decoration-underline cursor-pointer "
                                onClick={() => handleDetailsClick(index)}
                              >
                                {item.invoiceNumber}
                              </td>
                              <td className="p-5 text-gray-600">
                                {new Date(item.invoiceDate).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                )}
                              </td>
                              <td className="p-5 text-gray-600">
                                {baseCurrency}{" "}
                                {item.total.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td className="text-primary">unpaid</td>
                              <td className="p-5 text-gray-600 p-2">
                                <div className="d-flex gap-10">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltipresend}
                                  >
                                    <i
                                      className="bi bi-send fs-3 cursor-pointer"
                                      style={{ color: "#0000ff" }}
                                      onClick={() => handleResendClick(index)}
                                    ></i>
                                  </OverlayTrigger>
                                  <Authorize
                                    hasAnyPermission={["MENU_INVOICE|DELETE"]}
                                  >
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={tooltipcancell}
                                    >
                                      <i
                                        className="bi bi-x-circle fs-3 cursor-pointer"
                                        style={{ color: "#FF0000" }}
                                        onClick={() => {
                                          handleCancelClick(index);
                                        }}
                                      ></i>
                                    </OverlayTrigger>
                                  </Authorize>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* <InvoiceModal data={details}/> */}
            </>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Customer Name</th>
                      <th className="min-w-100px p-5">Invoice No</th>
                      <th className="min-w-100px p-5">Transaction Date</th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Amount Paid
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Payment Status
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData.length > 0 ? (
                      currentData?.map((item, index) => {
                        if (item.invStatus === 4) {
                          return (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                                // target.style.transform =
                                //   "translate3d(6px, -6px, 0)";
                                // target.style.boxShadow =
                                //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                                // target.style.transform = "none";
                                // target.style.boxShadow = "none";
                              }}
                            >
                              <td className="p-5 text-gray-600 text-hover-dark fw-hover-bold fs-6">
                                {item.customerName}
                              </td>
                              <td
                                className="p-5 text-primary text-hover-dark text-decoration-underline cursor-pointer "
                                onClick={() => handleDetailsClick(index)}
                              >
                                {item.invoiceNumber}
                              </td>
                              <td className="p-5 text-gray-600">
                                {new Date(item.invoiceDate).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                )}
                              </td>
                              <td className="p-5 text-gray-600">
                                {baseCurrency}{" "}
                                {item.total.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td className="text-danger">cancelled</td>
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* <InvoiceModal data={details}/> */}
            </>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div className="p-5">
            <ul className="pagination">
              <li
                className={`page-item double-arrow ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={0}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(1)}
                >
                  <i className="previous"></i>
                  <i className="previous"></i>
                </a>
              </li>
              <li
                className={`page-item previous ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                key={1}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <i className="previous"></i>
                </a>
              </li>
              {renderPageNumbers()}
              <li
                className={`page-item next ${
                  currentPage === Math.ceil(filteredData?.length / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={2}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <i className="next"></i>
                </a>
              </li>
              <li
                className={`page-item double-arrow ${
                  currentPage === Math.ceil(filteredData?.length / itemsPerPage)
                    ? "disabled"
                    : ""
                }`}
                key={3}
              >
                <a
                  href="#"
                  className="page-link"
                  onClick={() =>
                    handlePageChange(
                      Math.ceil(filteredData?.length / itemsPerPage)
                    )
                  }
                >
                  <i className="next"></i>
                  <i className="next"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="p-5 d-flex align-items-center">
            <label htmlFor="itemsPerPage" style={{ fontWeight: "bold" }}>
              Total: {filteredData?.length}&nbsp;&nbsp;
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
      </div>
      <InvoiceDetailsModal data={selectedData} />
    </>
  );
};
