export const settingData = {
  invoicePrefix: "",
  invoiceSequencePattern: "",
  termsAndConditions: "",
  dueDatePeriod: 0,
  reminderFrequency: 0,
  tax: 0,

  documents: [
    {
      documentExtention: "",
      documentName: "logo",
      documentTypes: [1],
      documentUploadedByType: 1,
      documentStatus: 4,
      fileUploadType: 1,
      documentData: "",
    },
    {
      documentExtention: "",
      documentName: "signature",
      documentTypes: [9],
      documentUploadedByType: 1,
      documentStatus: 4,
      fileUploadType: 1,
      documentData: "",
    },
  ],
};
