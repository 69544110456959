/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";
import { defaultCreateAppData, ICreateAppData } from "./PayrollAppModel";
import { PayrollStepperModal } from "./PayrollComponent";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { SteppperModal2 } from "./PayrollStepperModal2";
import { SteppperModal3 } from "./Payroll";
import { SteppperModal4 } from "./PayrollStepperModal4";
import { KTIcon } from "../../../../_metronic/helpers";
import { employeeData } from "./Data";
import { TaxData } from "./Data";
import { parsePhoneNumber } from "react-phone-number-input";
import { taxResults } from "./Data";
import { EmployeeDetails } from "./Data";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
import { Api } from "../PayrollDashboard";
import { isPhoneValid } from "../../../modules/auth/components/Login";

type Props = {
  show: boolean;
  handleClose: () => void;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

export const PayrollModal = ({ show, handleClose }: Props) => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [data, setData] = useState<ICreateAppData>(defaultCreateAppData);
  const [hasError, setHasError] = useState(false);
  const [employeeCode, setEmployeeCode] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [status, setStatus] = useState(false);

  type TaxResultsType = typeof taxResults;
  const [result, setResults] = useState<TaxResultsType>({} as TaxResultsType);

  TaxData.employeeCode = employeeCode;

  EmployeeDetails.empCode = employeeCode;
  EmployeeDetails.empName = employeeName;

  taxResults.basicMonthlySalary = result?.basicMonthlySalary;
  taxResults.basicPensionDeduction = result?.basicPensionDeduction;
  taxResults.travelAllowance = result?.travelAllowance;
  taxResults.medicalDependants = result?.medicalDependants;
  taxResults.otherAllowances = result?.otherAllowances;
  taxResults.annualBonus = result?.annualBonus;
  taxResults.incomeSummary.basicSalary = result?.incomeSummary?.basicSalary;
  taxResults.incomeSummary.travelAllowance =
    result?.incomeSummary?.travelAllowance;
  taxResults.incomeSummary.otherAllowances =
    result?.incomeSummary?.otherAllowances;
  taxResults.incomeSummary.bonus = result?.incomeSummary?.bonus;
  taxResults.incomeSummary.totalTaxableIncome =
    result?.incomeSummary?.totalTaxableIncome;
  taxResults.incomeSummary.pension = result?.incomeSummary?.pension;
  taxResults.incomeSummary.taxableIncome = result?.incomeSummary?.taxableIncome;
  taxResults.incomeSummary.totalIncomeTax =
    result?.incomeSummary?.totalIncomeTax;
  taxResults.incomeSummary.taxRebate = result?.incomeSummary?.taxRebate;
  taxResults.incomeSummary.medicalTaxCredits =
    result?.incomeSummary?.medicalTaxCredits;
  taxResults.incomeSummary.annualTaxPayable =
    result?.incomeSummary?.annualTaxPayable;
  taxResults.incomeSummary.basicSalaryMonthly =
    result?.incomeSummary?.basicSalaryMonthly;
  taxResults.incomeSummary.travelAllowanceMonthly =
    result?.incomeSummary?.travelAllowanceMonthly;
  taxResults.incomeSummary.otherAllowancesMonthly =
    result?.incomeSummary?.otherAllowancesMonthly;
  taxResults.incomeSummary.grossPayMonthly =
    result?.incomeSummary?.grossPayMonthly;
  taxResults.incomeSummary.totalDeductionsMonthly =
    result?.incomeSummary?.totalDeductionsMonthly;
  taxResults.incomeSummary.incomeTaxMonthly =
    result?.incomeSummary?.incomeTaxMonthly;
  taxResults.incomeSummary.uifMonthly = result?.incomeSummary?.uifMonthly;
  taxResults.incomeSummary.pensionMonthly =
    result?.incomeSummary?.pensionMonthly;
  taxResults.incomeSummary.totalDeductionsMonthly =
    result?.incomeSummary?.totalDeductionsMonthly;
  taxResults.incomeSummary.netPayMonthly = result?.incomeSummary?.netPayMonthly;
  taxResults.incomeSummary.bonus = result?.incomeSummary?.bonus;
  taxResults.incomeSummary.uifMonthly = result?.incomeSummary?.uifMonthly;
  taxResults.incomeSummary.bonus = result?.incomeSummary?.bonus;

  const phoneNumber = data?.empPhone.phone;
  const phoneNumberParsed = parsePhoneNumber(phoneNumber);

  const emergencyNumber = data?.emeContact.emeContact;
  const emePhoneNumberParsed = parsePhoneNumber(emergencyNumber);

  employeeData.firstName = data.appBasic.empName;
  employeeData.dob = data.empDate.date;
  employeeData.email = data.empEmail.email;
  employeeData.isdCode = Number(phoneNumberParsed?.countryCallingCode);
  employeeData.phoneNumber = Number(phoneNumberParsed?.nationalNumber);
  employeeData.address = data.empAddress.address;
  employeeData.documents[1].documentData = data.empAddressProof.addressProof;
  employeeData.bloodGroup = data.empBloodGroup.bloodGroup;
  employeeData.documents[0].documentData = data.idProof.idProof;
  employeeData.emergencyContactIsdCode = Number(
    emePhoneNumberParsed?.countryCallingCode
  );
  employeeData.emergencyContactNo = Number(
    emePhoneNumberParsed?.nationalNumber
  );
  employeeData.joiningDate = data.doj.doj;
  employeeData.designation = data.designation.designation;
  employeeData.department = data.department.department;
  employeeData.location = data.location.location;
  employeeData.bankAccountNumber = data.accNumber.accNumber;
  employeeData.bankName = data.accName.accName;
  employeeData.bankCode = data.accIdentifier.accIdetifier;
  const employeeDataa = async () => {
    try {
      const url = `${API_URL}/employee/create`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        ...employeeData,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      setEmployeeCode(data.message.employeeCode);
      setEmployeeName(data.message.firstName);
    } catch (error) {
      console.log(error);
    }
  };

  const TaxDataa = async () => {
    try {
      const url = `${API_URL}/employee/managePayrollStructure`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        ...TaxData,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      setResults(data.message);
    } catch (error) {
      console.log(error);
    }
  };

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const updateData = (fieldsToUpdate: Partial<ICreateAppData>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const checkAppBasic = (): boolean => {
    if (!data.appBasic.empName) {
      return false;
    }
    if (!data.empDate.date) {
      return false;
    }
    if (
      !data.empEmail.email ||
      !/^[a-z0-9]+[a-z0-9._%+-]*@[a-z0-9.-]+\.[a-z]{2,}$/.test(data.empEmail.email)
    ) {
      return false;
    }
    
    if (!data.empPhone.phone || !isPhoneValid(data.empPhone.phone)) {
      return false;
    }
    if (!data.empAddressProof.addressProof) {
      return false;
    }
    if (!data.idProof.idProof) {
      return false;
    }
    if (!data.empAddress.address) {
      return false;
    }
    if (!data.empBloodGroup.bloodGroup) {
      return false;
    }
    if (!data.emeContact.emeContact || !isPhoneValid(data.emeContact.emeContact)) {
      return false;
    }
    if (!data.doj.doj) {
      return false;
    }

    return true;
  };

  const checkAppData = (): boolean => {
    // if (!data.employeeID.employeeID) {
    //     return false
    // }
    if (!data.designation.designation) {
      return false;
    }
    if (!data.department.department) {
      return false;
    }
    if (!data.location.location) {
      return false;
    }
    if (!data.accNumber.accNumber || data.accNumber.accNumber.length < 10) {
      return false;
    }
    
    if (!data.accName.accName) {
      return false;
    }
    if (!data.accIdentifier.accIdetifier) {
      return false;
    }
    return true;
  };

 

  const checkPayroll = (): boolean => {
    if (
      data.monthlySalary.monthlySalary &&
      data.monthlySalary.monthlySalary === "0"
    ) {
      return false;
    }
    if (!data.pensionDeductions.pensionDeductions) {
      return false;
    }
    if (!data.travelAllowance.travelAllowance) {
      return false;
    }
    if (!data.medicalDependents.medicalDependents) {
      return false;
    }
    if (!data.otherAllowance.otherAllowance) {
      return false;
    }
    // if (!data.age.age) {
    //     return false
    // }
    if (!data.annualBonus.annualBonus) {
      return false;
    }
    return true;
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();
  };

  const nextStep = () => {
    setHasError(false);
    if (!stepper.current) {
      return;
    }

    if (stepper.current.getCurrentStepIndex() === 1) {
      if (!checkAppBasic()) {
        setHasError(true);
        return;
      }
    }
    if (stepper.current.getCurrentStepIndex() === 2) {
      if (!checkAppData()) {
        setHasError(true);
        return;
      }
      employeeDataa();
    }
    if (stepper.current.getCurrentStepIndex() === 3) {
      if (!checkPayroll()) {
        setHasError(true);
        return;
      }
      TaxDataa();
    }

    stepper.current.goNext();
  };



  const submit = () => {
    Api.api();
    reset();
    handleClose();
  };

  const reset = () => {
    updateData(defaultCreateAppData);
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={()=>{
        handleClose()
        reset()
      }}
      onEntered={loadStepper}

    >
      <div className="modal-header">
        <h2>Add Employee</h2>
        {/* begin::Close */}
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
          onClick={() => {
            reset();
            handleClose();
          }}
        >
           <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
        </div>
        {/* end::Close */}
      </div>

      <div className="modal-body py-lg-10 px-lg-10 mb-10">
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
          id="kt_modal_create_app_stepper"
        >
          {/* begin::Aside*/}
          <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
            {/* begin::Nav*/}
            <div className="stepper-nav ps-lg-10">
              {/* begin::Step 1*/}
              <div
                className="stepper-item current"
                data-kt-stepper-element="nav"
              >
                {/* begin::Wrapper*/}
                <div className="stepper-wrapper">
                  {/* begin::Icon*/}
                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className="stepper-label">
                    <h3 className="stepper-title">Enter employee data </h3>

                    {/* <div className='stepper-desc'></div> */}
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className="stepper-line h-40px"></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className="stepper-item" data-kt-stepper-element="nav">
                {/* begin::Wrapper*/}
                <div className="stepper-wrapper">
                  {/* begin::Icon*/}
                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">2</span>
                  </div>
                  {/* begin::Icon*/}

                  {/* begin::Label*/}
                  <div className="stepper-label">
                    <h3 className="stepper-title">Enter Company data </h3>

                    {/* <div className='stepper-desc'>Define your app framework</div> */}
                  </div>
                  {/* begin::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className="stepper-line h-40px"></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              <div className="stepper-item" data-kt-stepper-element="nav">
                {/* begin::Wrapper*/}
                <div className="stepper-wrapper">
                  {/* begin::Icon*/}
                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className="stepper-label">
                    <h3 className="stepper-title">Enter Payroll data </h3>

                    {/* <div className='stepper-desc'>Select the app database type</div> */}
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className="stepper-line h-40px"></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 3*/}

              {/* begin::Step 4*/}
              <div className="stepper-item" data-kt-stepper-element="nav">
                {/* begin::Wrapper*/}
                <div className="stepper-wrapper">
                  {/* begin::Icon*/}
                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">4</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className="stepper-label">
                    <h3 className="stepper-title">Confirmation</h3>

                    {/* <div className='stepper-desc'>Provide storage details</div> */}
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                {/* <div className='stepper-line h-40px'></div> */}
                {/* end::Line*/}
              </div>
              {/* end::Step 4*/}

              {/* begin::Step 5*/}
              {/* <div className='stepper-item' data-kt-stepper-element='nav'> */}
              {/* begin::Wrapper*/}
              {/* <div className='stepper-wrapper'> */}
              {/* begin::Icon*/}
              {/* <div className='stepper-icon w-40px h-40px'> */}
              {/* <i className='stepper-check fas fa-check'></i> */}
              {/* <span className='stepper-number'>5</span> */}
              {/* </div> */}
              {/* end::Icon*/}

              {/* begin::Label*/}
              {/* <div className='stepper-label'> */}
              {/* <h3 className='stepper-title'></h3> */}

              {/* <div className='stepper-desc'>Review and Submit</div> */}
              {/* </div> */}
              {/* end::Label*/}
              {/* </div> */}
              {/* end::Wrapper*/}
              {/* </div> */}
              {/* end::Step 5*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className="flex-row-fluid py-lg-5 px-lg-15">
            {/*begin::Form */}
            <form noValidate id="kt_modal_create_app_form">
              <PayrollStepperModal
                data={data}
                updateData={updateData}
                hasError={hasError}
              />
              <SteppperModal2
                data={data}
                updateData={updateData}
                hasError={hasError}
              />
              <SteppperModal3
                data={data}
                updateData={updateData}
                hasError={hasError}
              />
              <SteppperModal4
                data={data}
                updateData={updateData}
                hasError={hasError}
              />

              {/*begin::Actions */}
              <div className="d-flex flex-stack pt-10">
                <div className="me-2">
                  <button
                    type="button"
                    className="btn btn-lg btn-light-primary me-3"
                    data-kt-stepper-action="previous"
                    onClick={prevStep}
                  >
                    <KTIcon iconName="arrow-left" className="fs-3 me-1" />{" "}
                    Previous
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-lg btn"
                    data-kt-stepper-action="submit"
                    onClick={submit}
                  >
                    Finish{" "}
                    <KTIcon iconName="arrow-right" className="fs-3 ms-2 me-0" />
                  </button>

                  <button
                    type="button"
                    className="btn btn-lg btn"
                    data-kt-stepper-action="next"
                    onClick={nextStep}
                  >
                    Next{" "}
                    <KTIcon iconName="arrow-right" className="fs-3 ms-1 me-0" />
                  </button>
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  );
};
