/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useRef } from "react";
import { StepProps } from "../../../../app/pages/components/Modal/AppModel";
import { sameBank, wallet } from "./Data";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { PhoneInput } from "react-international-phone";
import { currencyData, popularCurrencies } from "./currencyData";
import Select from "react-dropdown-select";
import { string } from "yup";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
import { Bank } from "../../Payee/PayrollStepper/PayrollStepperModal2";

export const SteppperModal2 = ({ data, updateData, hasError }: StepProps) => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const [bank, setBank] = useState([]);
  const [showFund, setShowFund] = useState("");
  const [accError, setAccError] = useState(false);
  const [phone, setPhone] = useState("");
  const [displayList, setDisplayList] = useState(popularCurrencies);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(-1);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchCurrencyQuery, setCurrencySearchQuery] = useState("");
  const [filteredTransactions, setFilteredTransactions] =
    useState(currencyData);
  const [filteredCurrencyTxn, setFilteredCurrencyTxn] = useState(currencyData);
  const [previousValue, setPreviousValue] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownCurrencyRef = useRef<HTMLDivElement>(null);
  const phoneInputRef = useRef<HTMLInputElement>(null);

  sameBank.payeeFirstName = data?.firstname.firstname || "";
  sameBank.payeeLastName = data?.lastname.lastname || "";
  sameBank.payeeSubType = "INTRA";
  sameBank.payeeAccountNumber = data?.accNumber.accNumber || "";
  sameBank.currency = data?.currency.currency || "";
  sameBank.payeeAlias = data.nickname.nickname || "";
  sameBank.payeeLimit = data.payeelimit.payeelimit || "";
  sameBank.payeeType = "BANK";

  wallet.payeeFirstName = data?.walletFirstName.walletfirstname || "";
  wallet.payeeLastName = data?.walletLastName.walletlastname || "";
  wallet.currency = data?.walletCurrency.walletCurrency || "";
  wallet.payeeAlias = data.walletNickName.walletNickName || "";
  wallet.payeeLimit = data.walletPayeeLimit.walletPayeeLimit || "";
  wallet.payeeType = "WALLET";
  wallet.payeeMobile = data.walletPhone.walletPhone;

  let payeeType = 0;
  if (data.appBasic.appType === "Same Bank") {
    payeeType = 1;
  }

  const today = dayjs();
  const minDate = today.subtract(18, "year").toDate();

  const [phoneNumber, setPhoneNumber] = useState("");

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): string => {
    const value = event.target.value;

    // Validate input using regular expression
    if (/^[0-9]*$/.test(value)) {
      setPhoneNumber(value);
      return value;
    }
    return phoneNumber;
  };

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): string => {
    const value = event.target.value;

    // If the value is empty, set the previous value to an empty string
    if (value === "") {
      setPreviousValue("");
      return ""; // Return an empty string
    }

    // Check if the value is a valid number with up to 2 decimal places and a single decimal point
    if (/^\d{1,12}(\.\d{0,2})?$/.test(value)) {
      // Assuming `updateData` is your function to update the state
      setPreviousValue(value);
      return value;
    }
    return previousValue;
  };

  const handleDropdownClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      // Click occurred outside the dropdown, close it
      setShowDropdown(false);
    }

    if (
      dropdownCurrencyRef.current &&
      !dropdownCurrencyRef.current.contains(event.target as Node)
    ) {
      // Click occurred outside the dropdown, close it
      setShowCurrencyDropdown(false);
    }
  };

  useEffect(() => {
    getBank();
    // Attach the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCurrencyDropdownClick = () => {
    setShowCurrencyDropdown(!showCurrencyDropdown);
  };

  useEffect(() => {
    setFilteredTransactions(
      currencyData?.filter((data) =>
        data.name.toLowerCase().includes(searchQuery.toLowerCase().trim())
      )
    );
  }, [searchQuery]);

  const handleSearchChange = (value: string) => {
    // setShowDropdown(true);
    setSearchQuery(value);
  };

  useEffect(() => {
    setFilteredCurrencyTxn(
      currencyData?.filter((data) =>
        data.name
          .toLowerCase()
          .includes(searchCurrencyQuery.toLowerCase().trim())
      )
    );
  }, [searchCurrencyQuery]);

  const handleCurrencySearchChange = (value: string) => {
    // setShowCurrencyDropdown(true);
    setCurrencySearchQuery(value);
  };

  const handleMouseOver = (index: number) => {
    setHoveredItem(index);
  };

  const handleMouseOut = () => {
    setHoveredItem(-1);
  };

  // Function to move the existing country to the first position or add a new country
  const updateCurrencyArray = (selectedCountry: string) => {
    // Create a copy of popularCurrencies
    const updatedCurrencies = [...popularCurrencies];

    // Find the index of the selected country in the copied array
    const existingIndex = updatedCurrencies.findIndex(
      (item) => item.isoCode === selectedCountry
    );

    // If the selected country is already in the array, move it to the first position
    if (existingIndex !== -1) {
      const existingCountry = updatedCurrencies[existingIndex];
      updatedCurrencies.splice(existingIndex, 1);
      updatedCurrencies.unshift(existingCountry);
    } else {
      // Add the newly selected country to the first position
      const newCountryObject = currencyData.find(
        (item) => item.isoCode === selectedCountry
      );
      if (newCountryObject) {
        // Ensure that the original 3 objects always remain in the array
        const originalItems = updatedCurrencies.slice(0, 3);
        const newlyAddedItem = updatedCurrencies.find(
          (item) => !originalItems.includes(item)
        );

        if (newlyAddedItem) {
          const indexOfNewlyAddedItem =
            updatedCurrencies.indexOf(newlyAddedItem);
          updatedCurrencies.splice(indexOfNewlyAddedItem, 1);
        }
        updatedCurrencies.unshift(newCountryObject);
      }
    }

    // Ensure that the length of updatedCurrencies is always 4
    if (updatedCurrencies.length > 4) {
      updatedCurrencies.pop();
    }

    setDisplayList(updatedCurrencies);
  };

  // useEffect(() => {
  //   updateCurrencyArray(data.intCountry.intCountry);
  //   console.log(data.intCountry.intCountry);
  // }, [data.intCountry.intCountry]);

  const getBank = async () => {
    try {
      const url = `${API_URL}/invSetting/getBanks`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setBank(data.results);
    } catch (error) {
      console.log(error);
    }
  };
  const [phoneState, setPhoneState] = useState({
    countryCode: data?.country?.country || "",
    phoneNumber: data?.phone?.phone || "",
  });
  return (
    <div
      className="pb-5 card shadow-sm p-10 "
      data-kt-stepper-element="content"
      style={{ height: "370px", overflow: "auto" }}
    >
      <div className="w-100">
        {data.appBasic.appType === "Same Bank" && (
          <>
            <div className="mb-10">
              <div className="mb-10 d-flex flex-row justify-content-between">
                <div className="form-check form-check-custom form-check-solid form-check-sm">
                  <input
                    className="form-check-input"
                    name="accTypee"
                    type="radio"
                    value="Business"
                    id="flexRadioSm"
                    checked={data.accType.acctype === "BUSINESS"}
                  />
                  <label className="form-check-label" htmlFor="flexRadioSm">
                    Business
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid form-check-sm">
                  <input
                    className="form-check-input"
                    name="accTypee"
                    type="radio"
                    value="Individual"
                    id="flexRadioSm"
                    checked={data.accType.acctype === "RETAIL"}
                  />
                  <label className="form-check-label" htmlFor="flexRadioSm">
                    Individual
                  </label>
                </div>
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter customer phone number
                </label>
                <div className="mb-5">
                  {data.cusId.cusId === "" ? (
                    <PhoneInput
                      value={`+${data?.country?.country || ""}-${
                        data?.phone?.phone || ""
                      }`}
                      inputStyle={{ padding: "21px" }}
                      countrySelectorStyleProps={{
                        buttonStyle: {
                          padding: "21px 5px",
                        },
                      }}
                      forceDialCode={true}
                      inputClassName="form-control"
                      defaultCountry="in"
                      onChange={(phone) => {
                        const countryCodeRegex = /^\+(\d{1,3})\s?/;
                        const phoneWithoutCountryCode = phone
                          .replace(countryCodeRegex, "")
                          .replace(/[\s+\-]/g, "");
                        const extractedCountryCode =
                          (phone.match(countryCodeRegex) || [])[1] || "";

                        updateData({
                          phone: {
                            phone: phoneWithoutCountryCode,
                          },
                          country: {
                            country: extractedCountryCode,
                          },
                        });
                      }}
                      disableDialCodePrefill={false}
                      disabled={data.cusId.cusId === ""}
                    />
                  ) : (
                    <PhoneInput
                      inputStyle={{ padding: "21px" }}
                      countrySelectorStyleProps={{
                        buttonStyle: {
                          padding: "21px 5px",
                        },
                      }}
                      forceDialCode={true}
                      inputClassName="form-control"
                      defaultCountry="in"
                      onChange={(phone) => {
                        const countryCodeRegex = /^\+(\d{1,3})\s?/;
                        const phoneWithoutCountryCode = phone
                          .replace(countryCodeRegex, "")
                          .replace(/[\s+\-]/g, "");
                        const extractedCountryCode =
                          (phone.match(countryCodeRegex) || [])[1] || "";

                        updateData({
                          phone: {
                            phone: phoneWithoutCountryCode,
                          },
                          country: {
                            country: extractedCountryCode,
                          },
                        });
                      }}
                      disableDialCodePrefill={false}
                    />
                  )}
                  {/* <PhoneInput
                    value={`+${data?.country?.country || ""}-${
                      data?.phone?.phone || ""
                    }`}
                    inputStyle={{ padding: "21px" }}
                    countrySelectorStyleProps={{
                      buttonStyle: {
                        padding: "21px 5px",
                      },
                    }}
                    forceDialCode={true}
                    inputClassName="form-control"
                    defaultCountry="in"
                    onChange={(phone) => {
                      const countryCodeRegex = /^\+(\d{1,3})\s?/;
                      const phoneWithoutCountryCode = phone
                        .replace(countryCodeRegex, "")
                        .replace(/[\s+\-]/g, "");
                      const extractedCountryCode =
                        (phone.match(countryCodeRegex) || [])[1] || "";

                      updateData({
                        phone: {
                          phone: phoneWithoutCountryCode,
                        },
                        country: {
                          country: extractedCountryCode,
                        },
                      });
                    }}
                    disableDialCodePrefill={false}
                    disabled={data.cusId.cusId === ""}
                  /> */}
                </div>
                {!data.phone.phone && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Phone Number is Required
                    </div>
                  </div>
                )}
                {data.phone.phone.length < 8 && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Phone Number should be between 8 to 12 characters
                    </div>
                  </div>
                )}
              </div>
              {data.accType.acctype === "BUSINESS" && (
                <div className="mb-10">
                  <label
                    className="form-label p-0 m-0"
                    htmlFor="selectedMinute"
                  >
                    Enter Company Name
                  </label>
                  <input
                    type="text"
                    maxLength={50}
                    className="form-control"
                    placeholder="Company Name"
                    value={data.companyName.companyName}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(
                        /[^A-Za-z0-9 ]/g,
                        ""
                      );
                      updateData({
                        companyName: {
                          companyName: newValue,
                        },
                      });
                    }}
                  />
                  {!data.companyName.companyName && hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        Company Name is Required
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First name"
                  value={data.firstname.firstname}
                  // onChange={(e) =>
                  //   updateData({
                  //     firstname: {
                  //       firstname: e.target.value,
                  //     },
                  //   })
                  // }
                  readOnly
                />
                {!data.firstname.firstname && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      First Name is Required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last name"
                  value={data.lastname.lastname}
                  // onChange={(e) =>
                  //   updateData({
                  //     lastname: {
                  //       lastname: e.target.value,
                  //     },
                  //   })
                  // }
                  readOnly
                />
                {/* {!data.lastname.lastname && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Last Name is Required
                    </div>
                  </div>
                )} */}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Currency
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Currency"
                  value={data.currency.currency}
                  // onChange={(e) =>
                  //   updateData({
                  //     currency: {
                  //       currency: e.target.value,
                  //     },
                  //   })
                  // }
                  readOnly
                />
                {!data.currency.currency && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Currency is Required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter Account Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Account Number"
                  value={data.accNumber.accNumber}
                  // onChange={(e) =>
                  //   updateData({
                  //     accNumber: {
                  //       accNumber: e.target.value,
                  //     },
                  //   })
                  // }
                  readOnly
                />
                {!data.accNumber.accNumber && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Account Number is Required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter Nickname
                </label>
                <input
                  type="text"
                  maxLength={50}
                  className="form-control"
                  placeholder="Nickname"
                  value={data.nickname.nickname}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(
                      /[^A-Za-z0-9 ]/g,
                      ""
                    );
                    updateData({
                      nickname: {
                        nickname: newValue,
                      },
                    });
                  }}
                />
                {!data.nickname.nickname && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Nick Name is Required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter Payee Limit
                </label>
                <span
                  className="fs-6 fw-bold text-gray-700"
                  style={{
                    position: "absolute",
                    padding: "12px",
                    right: "350px",
                    marginTop: "20px",
                  }}
                >
                  {data.currency.currency}
                </span>
                <input
                  type="text"
                  className="form-control"
                  style={{ paddingLeft: "60px" }}
                  value={data.payeelimit.payeelimit}
                  onChange={(e) =>
                    updateData({
                      payeelimit: {
                        payeelimit: handleAmountChange(e),
                      },
                    })
                  }
                />
                {!data.payeelimit.payeelimit && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Payee Limit is Required
                    </div>
                  </div>
                )}
                {data.payeelimit.payeelimit &&
                  +data.payeelimit.payeelimit === 0 &&
                  hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        Payee Limit is should be greater than 0
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </>
        )}
        {data.appBasic.appType === "Different Bank" && (
          <>
            <div
              className="mb-10"
              // style={{ height: "370px",  }}
            >
              <div>
                <div className="mb-10 d-flex flex-row justify-content-between">
                  <div className="form-check form-check-custom form-check-solid form-check-sm">
                    <input
                      className="form-check-input"
                      name="intaccType"
                      type="radio"
                      value="Business"
                      id="flexRadioSm"
                      checked={data.diffAccType.diffaccType === "BUSINESS"}
                    />
                    <label className="form-check-label" htmlFor="flexRadioSm">
                      Business
                    </label>
                  </div>
                  <div className="form-check form-check-custom form-check-solid form-check-sm">
                    <input
                      className="form-check-input"
                      name="intaccType"
                      type="radio"
                      value="Individual"
                      id="flexRadioSm"
                      checked={data.diffAccType.diffaccType === "RETAIL"}
                    />
                    <label className="form-check-label" htmlFor="flexRadioSm">
                      Individual
                    </label>
                  </div>
                </div>
              </div>
              {data.diffAccType.diffaccType === "BUSINESS" && (
                <div className="mb-10">
                  <label
                    className="form-label p-0 m-0"
                    htmlFor="selectedMinute"
                  >
                    Enter Business name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Business name"
                    maxLength={50}
                    value={data.diffCompanyName.diffcompanyName}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(
                        /[^A-Za-z0-9 ]/g,
                        ""
                      );
                      updateData({
                        diffCompanyName: {
                          diffcompanyName: newValue,
                        },
                      });
                    }}
                  />
                  {!data.diffCompanyName.diffcompanyName && hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        Business Name is required
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  {data.diffAccType.diffaccType === "BUSINESS"
                    ? "Enter Representative's first name"
                    : "Enter first name"}
                </label>
                <input
                  type="text"
                  maxLength={50}
                  className="form-control"
                  placeholder={
                    data.diffAccType.diffaccType === "BUSINESS"
                      ? "Representative's first name"
                      : "First name"
                  }
                  value={data.diffFirstName.difffirstname}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(
                      /[^A-Za-z0-9 ]/g,
                      ""
                    );
                    updateData({
                      diffFirstName: {
                        difffirstname: newValue,
                      },
                    });
                  }}
                />
                {!data.diffFirstName.difffirstname && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      {data.diffAccType.diffaccType === "BUSINESS"
                        ? "Representative's First Name is required"
                        : "First name is required"}
                    </div>
                  </div>
                )}
              </div>

              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  {data.diffAccType.diffaccType === "BUSINESS"
                    ? "Enter Representative's last name"
                    : "Last name"}
                </label>
                <input
                  type="text"
                  maxLength={50}
                  className="form-control"
                  placeholder={
                    data.diffAccType.diffaccType === "BUSINESS"
                      ? "Representative's last name"
                      : "Last name"
                  }
                  value={data.diffLastName.difflastname}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(
                      /[^A-Za-z0-9 ]/g,
                      ""
                    );
                    updateData({
                      diffLastName: {
                        difflastname: newValue,
                      },
                    });
                  }}
                />
                {!data.diffLastName.difflastname && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      {data.diffAccType.diffaccType === "BUSINESS"
                        ? "Representative's Last Name is required"
                        : "Last name is required"}
                    </div>
                  </div>
                )}
              </div>

              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter nickname
                </label>
                <input
                  type="text"
                  maxLength={50}
                  className="form-control"
                  placeholder="Nickname"
                  value={data.diffnickname.diffnickname}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(
                      /[^A-Za-z0-9 ]/g,
                      ""
                    );
                    updateData({
                      diffnickname: {
                        diffnickname: newValue,
                      },
                    });
                  }}
                />
                {!data.diffnickname.diffnickname && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Nick Name is required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Select bank
                </label>
                <select
                  className="form-select text-gray-500"
                  data-control="select2"
                  defaultValue=""
                  value={data.bank.bank}
                  onChange={(e) =>
                    updateData({
                      bank: {
                        bank: e.target.value,
                      },
                    })
                  }
                >
                  <option disabled value="">
                    Select bank
                  </option>

                  {bank &&
                    bank.map((item: Bank, index: number) => (
                      <option key={index} value={item.key}>
                        {item.value}
                      </option>
                    ))}
                </select>
                {!data.bank.bank && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Bank is required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Select currency
                </label>
                <select
                  className="form-select text-gray-500"
                  data-control="select2"
                  defaultValue=""
                  value={data.diffcurrency.diffCurrency}
                  onChange={(e) =>
                    updateData({
                      diffcurrency: {
                        diffCurrency: e.target.value,
                      },
                    })
                  }
                >
                  <option disabled value="">
                    Select currency
                  </option>
                  <option value="USD">USD</option>
                  <option value="ZAR">ZAR</option>
                </select>
                {!data.diffcurrency.diffCurrency && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Currency is required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter Account Number / IBAN
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Account number / IBAN"
                  value={data.diffaccNumber.diffAccNumber}
                  maxLength={34}
                  minLength={10}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(
                      /[^A-Za-z0-9]/g,
                      ""
                    );
                    updateData({
                      diffaccNumber: {
                        diffAccNumber: newValue,
                      },
                    });
                  }}
                />
                {!data.diffaccNumber.diffAccNumber && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      IBAN/Account Number is required
                    </div>
                  </div>
                )}
                {data.diffaccNumber.diffAccNumber &&
                  data.diffaccNumber.diffAccNumber.length < 10 &&
                  hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        IBAN/Account Number should be of length 10 to 20
                      </div>
                    </div>
                  )}
              </div>

              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter Swift / BIC
                </label>
                <input
                  type="text"
                  maxLength={20}
                  className="form-control"
                  placeholder="Swift / BIC"
                  value={data.BIC.BIC}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(
                      /[^A-Za-z0-9]/g,
                      ""
                    );
                    updateData({
                      BIC: {
                        BIC: newValue,
                      },
                    });
                  }}
                />
                {!data.BIC.BIC && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      BIC is required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter payee limit
                </label>
                <span
                  className="fs-6 fw-bold text-gray-700"
                  style={{
                    position: "absolute",
                    padding: "12px",
                    right: "350px",
                    marginTop: "20px",
                  }}
                >
                  {data.diffcurrency.diffCurrency}
                </span>
                <input
                  type="text"
                  className="form-control"
                  style={{ paddingLeft: "60px" }}
                  value={data.diffPayeeLimit.diffPayeeLimit}
                  onChange={(e) =>
                    updateData({
                      diffPayeeLimit: {
                        diffPayeeLimit: handleAmountChange(e),
                      },
                    })
                  }
                  disabled={!data.diffcurrency.diffCurrency}
                />
                {!data.diffPayeeLimit.diffPayeeLimit && hasError && (
                  <div className="fv-plugins-message-container ">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Payee Limit is required
                    </div>
                  </div>
                )}
                {data.diffPayeeLimit.diffPayeeLimit &&
                  +data.diffPayeeLimit.diffPayeeLimit === 0 &&
                  hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        Payee Limit is should be greater than 0
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </>
        )}
        {data.appBasic.appType === "International" && (
          <>
            <div
              className="mb-10"
              // style={{ height: "370px", }}
            >
              <>
                <div className="mb-10 d-flex flex-row justify-content-between">
                  <div className="form-check form-check-custom form-check-solid form-check-sm">
                    <input
                      className="form-check-input"
                      name="intaccType"
                      type="radio"
                      value="Business"
                      id="flexRadioSm"
                      checked={data.intAccType.intaccType === "BUSINESS"}
                    />
                    <label
                      className="form-check-label text-dark"
                      htmlFor="flexRadioSm"
                    >
                      Business
                    </label>
                  </div>
                  <div className="form-check form-check-custom form-check-solid form-check-sm">
                    <input
                      className="form-check-input"
                      name="intaccType"
                      type="radio"
                      value="Individual"
                      id="flexRadioSm"
                      checked={data.intAccType.intaccType === "RETAIL"}
                    />
                    <label
                      className="form-check-label text-dark"
                      htmlFor="flexRadioSm"
                    >
                      Individual
                    </label>
                  </div>
                </div>
                {!data.intAccType.intaccType && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Select Payee Type
                    </div>
                  </div>
                )}
              </>

              {data.intAccType.intaccType === "BUSINESS" && (
                <div className="mb-10">
                  <label
                    className="form-label p-0 m-0"
                    htmlFor="selectedMinute"
                  >
                    {data.intAccType.intaccType === "BUSINESS"
                      ? "Enter business name"
                      : "Enter first name"}
                  </label>
                  <input
                    type="text"
                    maxLength={50}
                    className="form-control"
                    placeholder={
                      data.intAccType.intaccType === "BUSINESS"
                        ? "Business name"
                        : "First name"
                    }
                    value={data.intCompanyName.intcompanyName}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(
                        /[^A-Za-z0-9 ]/g,
                        ""
                      );
                      updateData({
                        intCompanyName: {
                          intcompanyName: newValue,
                        },
                      });
                    }}
                  />
                  {!data.intCompanyName.intcompanyName && hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        Business Name is required
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter nickname
                </label>
                <input
                  type="text"
                  maxLength={50}
                  className="form-control"
                  placeholder="Nickname"
                  value={data.intNickName.intNickName}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(
                      /[^A-Za-z0-9 ]/g,
                      ""
                    );
                    updateData({
                      intNickName: {
                        intNickName: newValue,
                      },
                    });
                  }}
                />
                {!data.intNickName.intNickName && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Nick Name is required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  {data.intAccType.intaccType === "BUSINESS"
                    ? "Enter representative's first name"
                    : "Enter first name"}
                </label>
                <input
                  type="text"
                  maxLength={50}
                  className="form-control"
                  placeholder={
                    data.intAccType.intaccType === "BUSINESS"
                      ? "Representative's first name"
                      : "First name"
                  }
                  value={data.intFirstName.intfirstname}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(
                      /[^A-Za-z0-9 ]/g,
                      ""
                    );
                    updateData({
                      intFirstName: {
                        intfirstname: newValue,
                      },
                    });
                  }}
                />
                {!data.intFirstName.intfirstname && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Representative's First Name is required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  {data.intAccType.intaccType === "BUSINESS"
                    ? "Enter representative's last name"
                    : "Enter last name"}
                </label>
                <input
                  type="text"
                  maxLength={50}
                  className="form-control"
                  placeholder={
                    data.intAccType.intaccType === "BUSINESS"
                      ? "Representative's last name"
                      : "Last name"
                  }
                  value={data.intLastName.intlastname}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(
                      /[^A-Za-z0-9 ]/g,
                      ""
                    );
                    updateData({
                      intLastName: {
                        intlastname: newValue,
                      },
                    });
                  }}
                />
                {!data.intLastName.intlastname && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Representative's Last Name is required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <div
                  className="d-flex flex-column flex-grow-1 mt-6"
                  style={{ width: "100%" }}
                >
                  <label
                    className="form-label p-0 m-0"
                    htmlFor="selectedMinute"
                  >
                    {data.intAccType.intaccType === "BUSINESS"
                      ? "Enter Date of Incorporation"
                      : "Enter DOB"}
                  </label>
                  {data.intAccType.intaccType === "BUSINESS" ? (
                    <input
                      className="form-control"
                      type="date"
                      value={data.intDOB.intDob}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(event) => {
                        updateData({
                          intDOB: {
                            intDob: event.target.value,
                          },
                        });
                      }}
                    />
                  ) : (
                    <input
                      className="form-control"
                      type="date"
                      value={data.intDOB.intDob}
                      max={
                        new Date(
                          new Date().getFullYear() - 15,
                          new Date().getMonth(),
                          new Date().getDate()
                        )
                          .toISOString()
                          .split("T")[0]
                      }
                      min={
                        new Date(
                          new Date().getFullYear() - 100,
                          new Date().getMonth(),
                          new Date().getDate()
                        )
                          .toISOString()
                          .split("T")[0]
                      }
                      onChange={(event) => {
                        const inputDate = event.target.value;
                        updateData({
                          intDOB: {
                            intDob: inputDate,
                          },
                        });
                      }}
                    />
                  )}
                </div>
                {!data.intDOB.intDob && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Date is required
                    </div>
                  </div>
                )}
                {data.intAccType.intaccType === "RETAIL" &&
                  hasError &&
                  (new Date(data.intDOB.intDob) <
                    new Date(
                      new Date().getFullYear() - 100,
                      new Date().getMonth(),
                      new Date().getDate()
                    ) ||
                    new Date(data.intDOB.intDob) >
                      new Date(
                        new Date().getFullYear() - 15,
                        new Date().getMonth(),
                        new Date().getDate()
                      )) && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        Please select a valid date (15 yrs and above)
                      </div>
                    </div>
                  )}
              </div>
              <div className="mb-10">
                <label className="form-label">Enter Phone Number</label>
                <PhoneInput
                  defaultCountry="in"
                  onChange={(phone) => {
                    setPhone(phone);

                    // Extracting country code
                    const countryCode = phone.match(/\+(\d+)/)?.[1] || "";

                    // Extracting phone number without spaces and symbols
                    const phoneDigits = phone.replace(/[\+\-\s]/g, "");

                    // Extracting the remaining digits after the country code
                    const remainingDigits = phoneDigits.slice(
                      countryCode.length
                    );

                    // Updating state
                    updateData({
                      intIsdCode: {
                        intIsdCode: countryCode,
                      },
                      intPhone: {
                        intPhone: remainingDigits,
                      },
                    });

                    if (phoneInputRef.current) {
                      const newPosition = remainingDigits.length;
                      phoneInputRef.current.setSelectionRange(
                        newPosition,
                        newPosition
                      );
                    }
                  }}
                  inputStyle={{ padding: "21px", width: "100%" }}
                  countrySelectorStyleProps={{
                    buttonStyle: {
                      padding: "21px 5px",
                    },
                  }}
                  disableDialCodePrefill={false}
                  forceDialCode={true}
                  value={phone}
                />
                {!data.intPhone.intPhone && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Phone number is required
                    </div>
                  </div>
                )}
                {data.intPhone.intPhone.length < 9 && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Phone number is invalid
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Select ID type
                </label>
                <select
                  className="form-select text-gray-500"
                  data-control="selectid"
                  defaultValue=""
                  value={data.intIdType.intIdType}
                  onChange={(e) =>
                    updateData({
                      intIdType: {
                        intIdType: e.target.value,
                      },
                    })
                  }
                >
                  <option disabled value="">
                    Select ID type
                  </option>

                  {data.intAccType.intaccType === "BUSINESS" && (
                    <>
                      <option value="Trade License">Trade License</option>
                      <option value="Incorporation Certificate">
                        Incorporation Certificate
                      </option>
                      <option value="Tax Certificate">Tax Certificate</option>
                      <option value="Lease Agreement">Lease Agreement</option>
                    </>
                  )}
                  {data.intAccType.intaccType === "RETAIL" && (
                    <>
                      <option value="National Identity">
                        National Identity
                      </option>
                      <option value="Birth Certificate">
                        Birth Certificate
                      </option>
                    </>
                  )}
                </select>
                {!data.intIdType.intIdType && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      ID is required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter ID number
                </label>
                <input
                  type="text"
                  maxLength={20}
                  className="form-control"
                  placeholder="ID number"
                  value={data.intIdNum.intIdNum}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(
                      /[^A-Za-z0-9]/g,
                      ""
                    );
                    updateData({
                      intIdNum: {
                        intIdNum: newValue,
                      },
                    });
                  }}
                />
                {!data.intIdNum.intIdNum && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      ID Number is required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter address
                </label>
                <input
                  type="text"
                  maxLength={100}
                  className="form-control"
                  placeholder="Address"
                  value={data.intAddress.intAddress}
                  onChange={(e) =>
                    updateData({
                      intAddress: {
                        intAddress: e.target.value,
                      },
                    })
                  }
                />
                {!data.intAddress.intAddress && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Address is required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter city
                </label>
                <input
                  type="text"
                  maxLength={30}
                  className="form-control"
                  placeholder="City"
                  value={data.intCity.intCity}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/[^A-Za-z ]/g, "");
                    updateData({
                      intCity: {
                        intCity: newValue,
                      },
                    });
                  }}
                />
                {!data.intCity.intCity && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      City is required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter state
                </label>
                <input
                  type="text"
                  maxLength={30}
                  className="form-control"
                  placeholder="State"
                  value={data.intState.intState}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/[^A-Za-z ]/g, "");
                    updateData({
                      intState: {
                        intState: newValue,
                      },
                    });
                  }}
                />
                {!data.intState.intState && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      State is required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Select Country
                </label>
                <div style={{ position: "relative" }}>
                  <div
                    onClick={() => {
                      setShowDropdown(!showDropdown);
                    }}
                    className="form-select mb-2 py-3 text-gray-500"
                    tabIndex={0}
                  >
                    {data.intCountry.intCountry
                      ? filteredTransactions?.find(
                          (country) =>
                            country?.isoCode === data.intCountry.intCountry
                        )?.name
                      : "Select country"}
                  </div>
                  {showDropdown && (
                    <div
                      ref={dropdownRef}
                      style={{
                        marginTop: "4px",
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        width: "100%",
                        border: "1px solid #ccc",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        zIndex: 1,
                        backgroundColor: "#fff",
                        maxHeight: "200px", // Set your desired maximum height
                        overflowY: "auto",
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select Country"
                        onChange={(e) => {
                          handleSearchChange(e.target.value);
                        }}
                      />
                      {filteredTransactions.map((country, index) => (
                        <div
                          className="px-3 cursor-pointer"
                          key={country.code}
                          style={{
                            backgroundColor:
                              hoveredItem === index ? "#f0f0f0" : "transparent",
                            transition: "background-color 0.3s ease", // Add a smooth transition effect
                          }}
                          onMouseOver={() => handleMouseOver(index)}
                          onMouseOut={handleMouseOut}
                          onClick={(item) => {
                            updateData({
                              intCountry: {
                                intCountry: country.isoCode,
                              },
                            });
                            updateCurrencyArray(country.isoCode);
                            handleSearchChange("");
                            handleDropdownClick();
                          }}
                        >
                          {" "}
                          {`${country.flag} - ${country.isoCode} - ${country.name}`}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {!data.intCountry.intCountry && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Country is required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Select currency
                </label>
                <div style={{ position: "relative" }}>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      setShowCurrencyDropdown(!showCurrencyDropdown);
                    }}
                    className="form-select mb-2 py-3 text-gray-500"
                    tabIndex={0}
                  >
                    {data.IntCurrency.IntCurrency
                      ? data.IntCurrency.IntCurrency
                      : "Select currency"}
                  </div>

                  {showCurrencyDropdown && (
                    <div
                      ref={dropdownCurrencyRef}
                      style={{
                        marginTop: "4px",
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        width: "100%",
                        border: "1px solid #ccc",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        zIndex: 1,
                        backgroundColor: "#fff",
                        maxHeight: "200px", // Set your desired maximum height
                        overflowY: "auto",
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Currency"
                        onChange={(e) => {
                          handleCurrencySearchChange(e.target.value);
                        }}
                      />
                      <p className="m-0 px-4 bg-secondary">
                        Popular Curriences
                      </p>
                      {displayList.map((country, index) => (
                        <div
                          className="px-3 cursor-pointer"
                          key={country.code}
                          style={{
                            backgroundColor:
                              hoveredItem === index ? "#f0f0f0" : "transparent",
                            transition: "background-color 0.3s ease", // Add a smooth transition effect
                          }}
                          // onMouseOver={() => handleMouseOver(index)}
                          // onMouseOut={handleMouseOut}
                          onClick={(item) => {
                            updateData({
                              IntCurrency: {
                                IntCurrency: country.currency,
                              },
                            });
                            handleCurrencySearchChange("");
                            handleCurrencyDropdownClick();
                          }}
                        >
                          {" "}
                          {`${country.flag} - ${country.currency} - ${country.currencyName}`}
                        </div>
                      ))}
                      <p className="m-0 px-4 bg-secondary">All Curriences</p>
                      {filteredCurrencyTxn.map((country, index) => (
                        <div
                          className="px-3 cursor-pointer"
                          key={country.code}
                          style={{
                            backgroundColor:
                              hoveredItem === index ? "#f0f0f0" : "transparent",
                            transition: "background-color 0.3s ease", // Add a smooth transition effect
                          }}
                          onMouseOver={() => handleMouseOver(index)}
                          onMouseOut={handleMouseOut}
                          onClick={(item) => {
                            updateData({
                              IntCurrency: {
                                IntCurrency: country.currency,
                              },
                            });
                            handleCurrencySearchChange("");
                            handleCurrencyDropdownClick();
                          }}
                        >
                          {" "}
                          {`${country.flag} - ${country.currency} - ${country.currencyName}`}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {!data.IntCurrency.IntCurrency && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Currency is required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter Payee Limit
                </label>
                <span
                  className="fs-6 fw-bold text-gray-700"
                  style={{
                    position: "absolute",
                    padding: "12px",
                    right: "350px",
                    marginTop: "20px",
                  }}
                >
                  {data.IntCurrency.IntCurrency}
                </span>
                <input
                  type="text"
                  name="payeeLimit"
                  className="form-control"
                  style={{ paddingLeft: "60px" }}
                  value={data.intPayeeLimit.intPayeeLimit}
                  autoComplete="off"
                  onChange={(e) =>
                    updateData({
                      intPayeeLimit: {
                        intPayeeLimit: handleAmountChange(e),
                      },
                    })
                  }
                  disabled={!data?.IntCurrency?.IntCurrency}
                />
                {!data.intPayeeLimit.intPayeeLimit && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Payee Limit is required
                    </div>
                  </div>
                )}
                {data.intPayeeLimit.intPayeeLimit &&
                  +data.intPayeeLimit.intPayeeLimit === 0 &&
                  hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        Payee Limit is should be greater than 0
                      </div>
                    </div>
                  )}
              </div>
              <div className="mb-10">
                <p>Enter Fund Details</p>
              </div>
              <>
                <div className="d-flex flex-row justify-content-around">
                  <div className="form-check form-check-custom form-check-solid form-check-sm">
                    <input
                      className="form-check-input"
                      name="fundDetails"
                      type="radio"
                      value="1"
                      onChange={(e) => {
                        setShowFund("Bank");
                        updateData({
                          intFundType: {
                            intfundType: +e.target.value,
                          },
                          intAccNumber: {
                            intAccNumber: "",
                          },
                        });
                      }}
                    />
                    <label className="form-check-label">Bank</label>
                  </div>
                  <div className="form-check form-check-custom form-check-solid form-check-sm">
                    <input
                      className="form-check-input"
                      name="fundDetails"
                      type="radio"
                      value="2"
                      onChange={(e) => {
                        setShowFund("Wallet");
                        updateData({
                          intFundType: {
                            intfundType: +e.target.value,
                          },
                        });
                      }}
                    />
                    <label className="form-check-label">Wallet</label>
                  </div>
                </div>
                {!data.intFundType.intfundType && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Select Fund Type
                    </div>
                  </div>
                )}
              </>
              {showFund === "Bank" && (
                <>
                  <div className="my-10 ">
                    <label
                      className="form-label p-0 m-0"
                      htmlFor="selectedMinute"
                    >
                      Enter name of bank
                    </label>
                    <input
                      type="text"
                      maxLength={50}
                      className="form-control"
                      placeholder="Name of Bank"
                      value={data.intBank.intBank}
                      onChange={(e) => {
                        const newValue = e.target.value.replace(
                          /[^A-Za-z0-9 ]/g,
                          ""
                        );
                        updateData({
                          intBank: {
                            intBank: newValue,
                          },
                        });
                      }}
                    />
                    {!data.intBank.intBank && hasError && (
                      <div className="fv-plugins-message-container">
                        <div
                          data-field="appname"
                          data-validator="notEmpty"
                          className="fv-help-block"
                        >
                          Bank is required
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-10">
                    <label
                      className="form-label p-0 m-0"
                      htmlFor="selectedMinute"
                    >
                      Enter A/C number / IBAN
                    </label>
                    <input
                      type="text"
                      name="accountNumber"
                      className="form-control"
                      placeholder="A/C number / IBAN"
                      value={data.intAccNumber.intAccNumber}
                      maxLength={34}
                      minLength={10}
                      onChange={(e) => {
                        const newValue = e.target.value.replace(
                          /[^A-Za-z0-9]/g,
                          ""
                        );
                        updateData({
                          intAccNumber: {
                            intAccNumber: newValue,
                          },
                        });
                      }}
                    />
                    {!data.intAccNumber.intAccNumber && hasError && (
                      <div className="fv-plugins-message-container">
                        <div
                          data-field="appname"
                          data-validator="notEmpty"
                          className="fv-help-block"
                        >
                          IBAN/Account Number is required
                        </div>
                      </div>
                    )}
                    {data.intAccNumber.intAccNumber &&
                      data.intAccNumber.intAccNumber.length < 10 &&
                      hasError && (
                        <div className="fv-plugins-message-container">
                          <div
                            data-field="appname"
                            data-validator="notEmpty"
                            className="fv-help-block"
                          >
                            IBAN/Account Number should be of length 10 to 34
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="mb-10">
                    <label
                      className="form-label p-0 m-0"
                      htmlFor="selectedMinute"
                    >
                      Enter Swift code/BIC
                    </label>
                    <input
                      type="text"
                      maxLength={11}
                      className="form-control"
                      placeholder="Swift code/BIC"
                      value={data.intBIC.intBic}
                      onChange={(e) => {
                        const newValue = e.target.value.replace(
                          /[^A-Za-z0-9]/g,
                          ""
                        );
                        updateData({
                          intBIC: {
                            intBic: newValue,
                          },
                        });
                      }}
                    />
                    {!data.intBIC.intBic && hasError && (
                      <div className="fv-plugins-message-container">
                        <div
                          data-field="appname"
                          data-validator="notEmpty"
                          className="fv-help-block"
                        >
                          Swift code/BIC is required
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
              {showFund === "Wallet" && (
                <>
                  {/* <div className="mb-10">
                    <label
                      className="form-label p-0 m-0"
                      htmlFor="selectedMinute"
                    >
                      Enter Wallet ISD code
                    </label>
                    <input
                      type="text"
                      maxLength={3}
                      className="form-control"
                      placeholder="Wallet ISD code"
                      value={data.intIsdCode.intIsdCode}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        if (/^\d*$/.test(newValue)) {
                          updateData({
                            intIsdCode: {
                              intIsdCode: newValue,
                            },
                          });
                        }
                      }}
                    />
                    {!data.intIsdCode.intIsdCode && hasError && (
                      <div className="fv-plugins-message-container">
                        <div
                          data-field="appname"
                          data-validator="notEmpty"
                          className="fv-help-block"
                        >
                          ISD code is required
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-10">
                    <label
                      className="form-label p-0 m-0"
                      htmlFor="selectedMinute"
                    >
                      Enter Phone Number
                    </label>
                    <input
                      type="text"
                      pattern="[0-9]*"
                      className="form-control"
                      placeholder="Phone Number"
                      value={data.intPhone.intPhone}
                      onChange={(e) =>
                        updateData({
                          intPhone: {
                            intPhone: handlePhoneNumberChange(e),
                          },
                        })
                      }
                    />
                    {!data.intPhone.intPhone && hasError && (
                      <div className="fv-plugins-message-container">
                        <div
                          data-field="appname"
                          data-validator="notEmpty"
                          className="fv-help-block"
                        >
                          Phone Number is required
                        </div>
                      </div>
                    )}
                  </div> */}
                  <div className="my-10">
                    <label
                      className="form-label p-0 m-0"
                      htmlFor="selectedMinute"
                    >
                      Enter Phone Number
                    </label>
                    <PhoneInput
                      //  value={`+${data?.intIsdCode.intIsdCode || ""} ${data?.intPhone.intPhone || ""}`.trim()}
                      inputStyle={{ padding: "21px" }}
                      countrySelectorStyleProps={{
                        buttonStyle: {
                          padding: "21px 5px",
                        },
                      }}
                      forceDialCode={true}
                      inputClassName="form-control"
                      defaultCountry="in"
                      onChange={(phone) => {
                        const countryCodeRegex = /^\+(\d{1,3})\s?/;
                        const phoneWithoutCountryCode = phone
                          .replace(countryCodeRegex, "")
                          .replace(/[\s+\-]/g, "");
                        const extractedCountryCode =
                          (phone.match(countryCodeRegex) || [])[1] || "";

                        updateData({
                          intAccNumber: {
                            intAccNumber:
                              extractedCountryCode +
                              "-" +
                              phoneWithoutCountryCode,
                          },
                        });
                      }}
                      disableDialCodePrefill={false}
                    />
                    {!data.intAccNumber.intAccNumber && hasError && (
                      <div className="fv-plugins-message-container">
                        <div
                          data-field="appname"
                          data-validator="notEmpty"
                          className="fv-help-block"
                        >
                          Wallet Phone Number is required
                        </div>
                      </div>
                    )}
                    {data.intAccNumber.intAccNumber.length > 4 &&
                      data.intAccNumber.intAccNumber.length < 12 &&
                      hasError && (
                        <div className="fv-plugins-message-container">
                          <div
                            data-field="appname"
                            data-validator="notEmpty"
                            className="fv-help-block"
                          >
                            Wallet Phone Number is invalid
                          </div>
                        </div>
                      )}
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {data.appBasic.appType === "Wallet" && (
          <>
            <div
              className="mb-10"
              //  style={{ height: "370px" }}
            >
              <div className="mb-10 d-flex flex-row justify-content-between">
                <div className="form-check form-check-custom form-check-solid form-check-sm">
                  <input
                    className="form-check-input"
                    name="walltAccType"
                    type="radio"
                    value="Business"
                    id="flexRadioSm"
                    checked={data.walletAccType.walletaccType === "BUSINESS"}
                  />
                  <label className="form-check-label" htmlFor="flexRadioSm">
                    Business
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid form-check-sm">
                  <input
                    className="form-check-input"
                    name="walltAccType"
                    type="radio"
                    value="Individual"
                    id="flexRadioSm"
                    checked={data.walletAccType.walletaccType === "RETAIL"}
                  />
                  <label className="form-check-label" htmlFor="flexRadioSm">
                    Individual
                  </label>
                </div>
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter customer phone number
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Customer phone number"
                  value={data.walletPhone.walletPhone}
                  // onChange={(e) => {
                  //   updateData({
                  //     walletPhone: {
                  //       walletPhone: e.target.value,
                  //     },
                  //   });
                  //   setPhone(e.target.value);
                  // }}
                />
                {!data.walletPhone.walletPhone && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Phone Number is Required
                    </div>
                  </div>
                )}
              </div>
              {data.walletAccType.walletaccType === "BUSINESS" && (
                <div className="mb-10">
                  <label
                    className="form-label p-0 m-0"
                    htmlFor="selectedMinute"
                  >
                    Enter Company Name
                  </label>
                  <input
                    type="text"
                    maxLength={20}
                    className="form-control"
                    placeholder="Company Name"
                    value={data.walletCompanyName.walletcompanyName}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(
                        /[^A-Za-z0-9]/g,
                        ""
                      );
                      updateData({
                        walletCompanyName: {
                          walletcompanyName: newValue,
                        },
                      });
                    }}
                  />
                  {!data.walletCompanyName.walletcompanyName && hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        Company Name is Required
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter first name
                </label>
                <input
                  type="text"
                  maxLength={20}
                  className="form-control"
                  placeholder="First name"
                  value={data.walletFirstName.walletfirstname}
                  // onChange={(e) =>{
                  // const newValue = e.target.value.replace(
                  //   /[^A-Za-z0-9]/g,
                  //   ""
                  // );

                  //   updateData({
                  //     walletFirstName: {
                  //       walletfirstname: newValue,
                  //     },
                  //   })}
                  // }
                />
                {!data.walletFirstName.walletfirstname && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      First Name is Required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter last name
                </label>
                <input
                  type="text"
                  maxLength={20}
                  className="form-control"
                  placeholder="Last name"
                  value={data.walletLastName.walletlastname}
                  // onChange={(e) =>{
                  // const newValue = e.target.value.replace(
                  //   /[^A-Za-z0-9]/g,
                  //   ""
                  // );
                  //   updateData({
                  //     walletLastName: {
                  //       walletlastname: newValue,
                  //     },
                  //   })}
                  // }
                />
                {!data.walletLastName.walletlastname && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Last Name is Required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Currency
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Currency"
                  value={data.walletCurrency.walletCurrency}
                  // onChange={(e) =>
                  //   updateData({
                  //     walletCurrency: {
                  //       walletCurrency: e.target.value,
                  //     },
                  //   })
                  // }
                />
                {!data.walletCurrency.walletCurrency && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Currency is Required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter Nickname
                </label>
                <input
                  type="text"
                  maxLength={20}
                  className="form-control"
                  placeholder="Nickname"
                  value={data.walletNickName.walletNickName}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(
                      /[^A-Za-z0-9]/g,
                      ""
                    );
                    updateData({
                      walletNickName: {
                        walletNickName: newValue,
                      },
                    });
                  }}
                />
                {!data.walletNickName.walletNickName && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Nick Name is Required
                    </div>
                  </div>
                )}
              </div>
              <div className="mb-10">
                <label className="form-label p-0 m-0" htmlFor="selectedMinute">
                  Enter payee limit
                </label>
                <span
                  className="fs-6 fw-bold text-gray-700"
                  style={{
                    position: "absolute",
                    padding: "12px",
                    right: "350px",
                    marginTop: "20px",
                  }}
                >
                  {data.walletCurrency.walletCurrency}
                </span>
                <input
                  type="text"
                  className="form-control"
                  style={{ paddingLeft: "60px" }}
                  value={data.walletPayeeLimit.walletPayeeLimit}
                  onChange={(e) =>
                    updateData({
                      walletPayeeLimit: {
                        walletPayeeLimit: handleAmountChange(e),
                      },
                    })
                  }
                />
                {!data.walletPayeeLimit.walletPayeeLimit && hasError && (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="appname"
                      data-validator="notEmpty"
                      className="fv-help-block"
                    >
                      Payee Limit is Required
                    </div>
                  </div>
                )}
                {data.walletPayeeLimit.walletPayeeLimit &&
                  +data.walletPayeeLimit.walletPayeeLimit === 0 &&
                  hasError && (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="appname"
                        data-validator="notEmpty"
                        className="fv-help-block"
                      >
                        Payee Limit is should be greater than 0
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
