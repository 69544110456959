import React from "react";
import { useState, useEffect, useRef } from "react";
import { KTIcon } from "../../../_metronic/helpers";
import { Formik, FormikProps, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal } from "bootstrap";
import { RemittanceMoneyData } from "./TransferMoney";
import PinInput from "react-pin-input";
import { E_OverseasReceiptModal } from "./OverseasModal";
import Swal from "sweetalert2";
import { resetFunction } from "./TransferMoney";
import Swift from "../../../_metronic/assets/Icons/swift_logo.png";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import OTPInput from "react-otp-input";

interface Data {
  currency: string;
  payeeAccountNumber: string;
  payeeType: string;
  payeeFirstName: string;
  payeeId: number;
  isFavourite: boolean;
  payeeAlias: string;
}

interface Bank {
  accountNumber: string;
  currency: string;
  accountType: string;
  balance: number;
}

interface FormValues {
  inputValue: string;
  selectedAccount: string;
  selectedCategory: string;
  remarks: string;
}

interface TransactionData {
  payerCurrency: string;
  txnId: string;
}

export interface ExchangeData {
  fromCurrency: string;
  toCurrency: string;
  exchangeRate: string;
  amountEntered: string;
  totalFees: string;
  remittancePartnerFees: string;
  ourFees: string;
  amountToBeDeducted: string;
}

export interface RemittanceResponse {
  Amount: string;
  "Account Number": string;
  "Beneficiary Amount": string;
  "Beneficiary Account": string;
  Date: string;
  Time: string;
  "Transaction Id": string;
  "Service Charge": string;
  Type: string;
  Status: string;
}

interface RemittanceData {
  serviceCharge: number;
  txnId: string;
  payerAmount: number;
  payerCurrency: string;
  benefAmount: number;
  benefCurrency: string;
}

export function OverseasTransfer({ Data }: { Data: Data | null }) {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  let confirmPinRef = useRef<PinInput | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [accounts, setAccounts] = useState<Bank[]>([]);
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState("Select Account");
  const [selectedAccount, setSelectedAccount] = useState<Bank | null>(null);
  const [password, setPassword] = useState("");
  const [category, setCategory] = useState([]);
  const [buttonText, setButtonText] = useState("Accept and proceed");
  const [firstScreen, setFirstScreen] = useState(true);
  const [secondScreen, setSecondScreen] = useState(false);
  const [conversionRate, setConversionRate] = useState(false);
  const [container, setContainer] = useState(false);
  const [convData, setConvData] = useState<ExchangeData | null>(null);
  const [amount, setAmount] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [remittanceData, setRemittanceData] = useState<RemittanceResponse>({
    Amount: "",
    "Account Number": "",
    "Beneficiary Amount": "",
    "Beneficiary Account": "",
    Date: "",
    Time: "",
    "Transaction Id": "",
    "Service Charge": "",
    Type: "",
    Status: "",
  });

  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [pin, setPin] = useState("");
  const [show, setShow] = useState(true);

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  const handleEReceipt = () => {
    const modalElement = document.getElementById("overseas_receipt");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  function extractTransactionData(data: any): TransactionData {
    return {
      payerCurrency: data?.payerCurrency,
      txnId: data?.txnId,
    };
  }

  const TransferCategory = async () => {
    try {
      const url = `${API_URL}/transaction/getTrfCategory`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setCategory(data.message.trfCategory);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    TransferCategory();
  }, []);

  const getAccounts = async () => {
    try {
      const url = `${API_URL}/transaction/getAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setAccounts(data.results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAccounts();
  }, []);

  const getConversionRate = async () => {
    try {
      const url = `${API_URL}/transaction/getConvertionRate`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        fromCurrency: Data?.currency,
        toCurrency: selectedAccount?.currency
          ? selectedAccount?.currency
          : Data?.currency,
        amountEntered: amount,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setConversionRate(true);
        setConvData(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const doTransfer = async () => {
    try {
      const url = `${API_URL}/transaction/doTransfer`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        ...RemittanceMoneyData,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      handleReset();
      if (data.status.statusCode === 0) {
        getAccounts();
        Swal.fire({
          icon: "success",
          title: "Transfer Successful",
          text: "Payment Successful",
          showCancelButton: true, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "View E-Receipt",
          cancelButtonColor: "#9fa6b2",
          cancelButtonText: "Home",
          allowEscapeKey: true,
          allowEnterKey: true, // Text for the cancel button
        }).then((result) => {
          if (result.isConfirmed) {
            handleEReceipt();
          }
        });
        setRemittanceData(data.message.receipt as RemittanceResponse);
        // handleConfirmationModal();
      }
      if (data.status.statusCode === 1) {
        confirmPinRef.current?.clear();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data?.status?.messageDescription,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
        // setContainer(true);
        // setErrMsg(data.status.messageDescription);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formikRef = React.useRef<FormikProps<FormValues>>(null);
  const handleReset = () => {
    if (formikRef.current) {
      formikRef.current.resetForm();
      confirmPinRef.current?.clear();
      setIsSelected("Select Account");
      setPassword("");
      setAmount(0);
      setSelectedAccount(null);
      setIsActive(false);
      setContainer(false);
      setConversionRate(false);
      setFirstScreen(true);
      setSecondScreen(false);
      setButtonText("Accept and proceed");
      setPin("");
      setOtp(["", "", "", ""]);
      setActiveInput(0);
      setErrMsg("");
      setPassword("");
      RemittanceMoneyData.transactionPIN = "";
    }
  };
  resetFunction.reset = handleReset;

  const handleButtonClick = () => {
    if (buttonText === "Accept and proceed") {
      setFirstScreen(false);
      setSecondScreen(true);
      setConversionRate(false);
      setButtonText("Continue");
    }
    if (buttonText === "Continue") {
      if (
        selectedAccount &&
        Number(convData?.amountToBeDeducted.replace(/,/g, "")) <
        Number(selectedAccount?.balance.toString().replace(/,/g, ""))
      ) {
        setFirstScreen(false);
        setSecondScreen(false);
        setConversionRate(false);
        setContainer(true);
      }
    }
  };

  useEffect(() => {
    // This effect will run when inputValue changes
    const debounceTimeout = setTimeout(() => {
      // Make your API call here using the value in amount or any other necessary state
      if (amount && firstScreen) {
        getConversionRate();
      }
    }, 800); // Adjust the delay time as needed (0.8 second in this case)

    // Cleanup function to clear the timeout when the component unmounts or inputValue changes
    return () => clearTimeout(debounceTimeout);
  }, [amount]);

  useEffect(() => {
    if (selectedAccount && secondScreen) {
      getConversionRate();
    }
  }, [selectedAccount?.currency]);

  const handleDropdownToggle = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <style>
        {`
 .App {
 font-family: sans-serif;
 }
 
 .dropdown {
 margin: 0 auto;
 z-index: 10000;
 width: 180px;
 position: relative;
 border-radius: 50px;
 }
 .dropdown,
 .dropdown * {
 // z-index: 10;
 }
 .dropdown .dropdown-btn {
 cursor: pointer;
 background: white;
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding: 10px;
 background: white;
 border: 1px solid #ddd;
 border-radius: 20px;
 color: #777;
 font-weight: 500;
 }
 .dropdown-content {
 position: absolute;
 background: white; 
 /* top: 110%; */
 left: 0;
 width: 100%;
 box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
 }
 .dropdown-content .item {
 padding: 10px;
 cursor: pointer;
 }
 .dropdown-content .item:hover {
 background: #fcfcfc;
 }
 `}
      </style>

      <div
        id="kt_modal_overseas"
        className="bg-body"
        data-kt-drawer="true"
        data-kt-drawer-name="modal"
        data-kt-drawer-activate="true"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'449px', 'md': '455px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_modal_overseas_transfer"
        data-kt-drawer-close="#kt_modal_close"
      >
        {/* begin::Card */}
        <div className="card shadow-none rounded-0 w-100 p-5">
          {/* begin::Header */}
          <div
            className="card-header d-flex align-items-center justify-content-start gap-5 p-5"
            id="kt_help_header"
          >
            <div
              className="btn btn-icon btn-light "
              id="kt_modal_close"
              style={{ height: "24px", width: "24px" }}
              onClick={() => handleReset()}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            <h5 className="card-title fw-bold text-gray-600 p-0">
              Transfer Money
            </h5>
            <div className="card-toolbar"></div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className="card-body">
            <div className="label">
              <div className="h-1-wrapper d-flex justify-content-center">
                <h1 className="text-wrapper ">{Data?.payeeFirstName} </h1>
              </div>
            </div>
            <div className="label mb-10">
              <div className="div-wrapper d-flex justify-content-center">
                <div className="text-wrapper">
                  Bank | {Data?.payeeAccountNumber}
                </div>
              </div>
            </div>
            <Formik
              innerRef={formikRef}
              initialValues={{
                inputValue: "",
                selectedAccount: "",
                selectedCategory: "",
                remarks: "",
              }}
              validationSchema={Yup.object({
                inputValue: Yup.string().required("Amount is required"),
                selectedAccount: Yup.string().required(
                  "Please choose an account"
                ),
                selectedCategory: Yup.string().required(
                  "Please select a category"
                ),
              })}
              onSubmit={(values, { resetForm }) => {
                RemittanceMoneyData.payeeType = Data?.payeeType || "";
                RemittanceMoneyData.payeeAlias = Data?.payeeAlias || "";
                RemittanceMoneyData.amount =
                  Number(convData?.amountToBeDeducted.replace(/,/g, "")) || 0;
                RemittanceMoneyData.remark = values?.remarks;
                RemittanceMoneyData.trfCategory = [
                  values.selectedCategory as string,
                ];
                RemittanceMoneyData.payerAccountNumber =
                  selectedAccount?.accountNumber || "";
                RemittanceMoneyData.payerCurrency =
                  selectedAccount?.currency || "";
                RemittanceMoneyData.totalFees =
                  convData?.toCurrency + " " + convData?.totalFees || "";
                RemittanceMoneyData.enteredAmount =
                  convData?.fromCurrency + " " + convData?.amountEntered || "";

                handleButtonClick();
                // if (password) doTransfer();
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  {firstScreen && (
                    <>
                      <div
                        className="card shadow-sm border-p d-flex flex-row p-5 mb-5 gap-5 "
                        style={{
                          borderRadius: "20px",
                          border: "1px solid #246BFD",
                        }}
                      >
                        <div className="d-flex flex-column ">
                          <div className="d-flex flex-row align-items-center ">
                            <h1 className="m-0 p-2">{Data?.currency}</h1>
                            <Field
                              className="fs-4 form-control custom-input"
                              type="text"
                              name="inputValue"
                              placeholder="Enter Amount"
                              style={{
                                border: "none",
                                padding: "7px",
                                outline: "none",
                                fontWeight: "700",
                              }}
                              autoFocus
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const inputValue = e.target.value;

                                // Check if the value is '0' or empty, and reject it
                                if (inputValue === "0" || inputValue === "") {
                                  setAmount(0);
                                  setFieldValue("inputValue", "");
                                  return;
                                }

                                // Check if the value is a valid number with up to 2 decimal places and a single decimal point
                                if (/^\d{1,12}(\.\d{0,2})?$/.test(inputValue)) {
                                  setAmount(+inputValue);
                                  setFieldValue("inputValue", inputValue);
                                }
                              }}
                            />

                          </div>

                          <ErrorMessage
                            name="inputValue"
                            component="div"
                            className="text-danger text-nowrap"
                          />
                        </div>
                      </div>
                      {conversionRate && amount !== 0 && (
                        <>
                          <div className="mt-10">
                            <h5>Your Rate</h5>
                            <div className="card bg-gray-300 border p-5 mb-4 mt-5">
                              <div className="d-flex flex-row mb-2 mt-4">
                                <img
                                  src={Swift}
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                  }}
                                />
                                <p className="fw-bold px-2">Swift Transfer</p>
                              </div>
                              <div className="d-flex flex-row justify-content-between">
                                <p className="fw-bold">Estimated Time</p>
                                <p>48-72 hrs</p>
                              </div>
                              <div className="d-flex flex-row justify-content-between ">
                                <p className="fw-bold">Transfer Fees</p>
                                <p>
                                  <a
                                    data-bs-toggle="collapse"
                                    href="#collapseExample"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                  >
                                    <i className="bi bi-info-circle px-2 text-dark"></i>
                                  </a>
                                  {convData?.fromCurrency} {convData?.totalFees}
                                </p>
                              </div>
                              <div
                                className="collapse border border-dashed rounded p-3 border-dark"
                                id="collapseExample"
                              >
                                <div className="d-flex flex-row justify-content-between">
                                  <p className="fw-bold">Remittance Fee</p>
                                  <p>
                                    {convData?.remittancePartnerFees}{" "}
                                    {convData?.toCurrency}
                                  </p>
                                </div>
                                <div className="d-flex flex-row justify-content-between">
                                  <p className="fw-bold mb-0">Our Fee</p>
                                  <p className="mb-0">
                                    {convData?.ourFees} {convData?.toCurrency}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between mt-8 mb-5 px-3">
                              <h5 className="mb-0" style={{ color: "#0000ff" }}>
                                You will pay
                              </h5>
                              <p
                                className="fs-5 m-0"
                                style={{ color: "#0000ff" }}
                              >
                                {convData?.fromCurrency}{" "}
                                {convData?.amountToBeDeducted}
                              </p>
                            </div>
                            <div
                              className="card shadow-sm border-p d-flex flex-row justify-content-between p-3 align-items-center"
                              style={{
                                borderRadius: "10px",
                                border: "1px solid #246BFD",
                              }}
                            >
                              <h5 className="mb-0">Receipent gets</h5>
                              <p className="m-0 fs-5">
                                {" "}
                                {convData?.fromCurrency}{" "}
                                {convData?.amountEntered}
                              </p>
                            </div>
                          </div>
                          <button
                            className="btn rounded"
                            style={{
                              width: "363px",
                              marginTop: "30px",
                            }}
                            onClick={() => {
                              handleButtonClick();
                            }}
                          >
                            {buttonText}
                          </button>
                        </>
                      )}
                    </>
                  )}

                  {secondScreen && (
                    <>
                      <div className="">
                        <div className="App">
                          <div className="">
                            <label className="form-label">Select Account</label>
                            <div
                              onClick={handleDropdownToggle}
                              className="form-select mb-3 d-flex justify-content-between cursor-pointer"
                            >
                              <p className="m-0">
                                {selectedAccount?.accountNumber
                                  ? selectedAccount?.accountNumber
                                  : selected}
                              </p>
                              <p
                                className={`m-0 ${selectedAccount &&
                                    selectedAccount?.balance <
                                    Number(convData?.amountToBeDeducted)
                                    ? "text-danger"
                                    : ""
                                  }`}
                              >
                                {selectedAccount?.currency}{" "}
                                {selectedAccount?.balance?.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </p>
                            </div>
                            <span>
                              {selectedAccount &&
                                Number(
                                  convData?.amountToBeDeducted.replace(/,/g, "")
                                ) >
                                Number(
                                  selectedAccount?.balance
                                    .toString()
                                    .replace(/,/g, "")
                                ) ? (
                                <span className="text-danger">
                                  Low balance, choose a different account
                                </span>
                              ) : (
                                <span>
                                  {/* Render something else if the condition is false */}
                                </span>
                              )}
                            </span>

                            <div
                              className="dropdown-content"
                              ref={dropdownRef}
                              style={{
                                display: isActive ? "block" : "none",
                                marginLeft: "45px",
                                maxWidth: "365px",
                              }}
                            >
                              {accounts?.map((item, key) => (
                                <>
                                  <div
                                    key={key}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (
                                        item?.currency === convData?.toCurrency
                                      ) {
                                        if (
                                          item?.balance >
                                          Number(convData?.amountToBeDeducted)
                                        ) {
                                          setSelectedAccount(item);
                                          setIsSelected(
                                            `${item.accountNumber} ${item.currency}`
                                          );
                                          setIsActive(!isActive);
                                          setFieldValue(
                                            "selectedAccount",
                                            `${item.accountNumber}`
                                          );
                                        }
                                      } else {
                                        setSelectedAccount(item);
                                        setIsSelected(
                                          `${item.accountNumber} ${item.currency}`
                                        );
                                        setIsActive(!isActive);
                                        setFieldValue(
                                          "selectedAccount",
                                          `${item.accountNumber}`
                                        );
                                      }
                                    }}
                                    className="d-flex flex-column item w-100"
                                  >
                                    <div className="d-flex flex-row justify-content-between">
                                      <span style={{ fontWeight: "bold" }}>
                                        {item.accountNumber}
                                      </span>
                                      <span
                                        style={{
                                          marginLeft: "10px",
                                          color:
                                            convData &&
                                              convData.toCurrency ===
                                              item.currency &&
                                              +convData?.amountToBeDeducted >
                                              item.balance
                                              ? "red"
                                              : "#246BFD",
                                        }}
                                        className="text-end"
                                      >
                                        {item.currency}{" "}
                                        {item.balance.toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </span>
                                    </div>
                                    {convData?.toCurrency === item.currency &&
                                      Number(convData?.amountToBeDeducted) >
                                      item.balance && (
                                        <p className="text-danger text-end m-0">
                                          Insufficient balance!
                                        </p>
                                      )}
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="text-danger mb-2">
                          {selectedAccount &&
                            Number(convData?.amountToBeDeducted) >
                            selectedAccount.balance
                            ? "Low balance, choose a different account"
                            : ""}
                        </div>

                        <ErrorMessage
                          name="selectedAccount"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      {selectedAccount && (
                        <div>
                          {selectedAccount &&
                            selectedAccount?.currency === convData?.toCurrency &&
                            selectedAccount?.balance <
                            +convData?.amountToBeDeducted ? (
                            <p className="fw-bold" style={{ color: "red" }}>
                              For this transaction your account needs{" "}
                              {convData?.amountToBeDeducted}{" "}
                              {convData?.toCurrency}{" "}
                              <a
                                data-bs-toggle="collapse"
                                href="#infoCollapse"
                                role="button"
                                aria-expanded="false"
                                aria-controls="infoCollapse"
                              >
                                <i className="bi bi-info-circle ps-1 text-dark"></i>
                              </a>
                            </p>
                          ) : (
                            <p className="fw-bold" style={{ color: "#0000ff" }}>
                              Your account will be debited{" "}
                              {convData?.amountToBeDeducted}{" "}
                              {convData?.toCurrency}{" "}
                              <a
                                data-bs-toggle="collapse"
                                href="#infoCollapse"
                                role="button"
                                aria-expanded="false"
                                aria-controls="infoCollapse"
                              >
                                <i className="bi bi-info-circle px-2 text-dark"></i>
                              </a>
                            </p>
                          )}

                          <div
                            className="collapse border border-dashed rounded p-3 border-dark mb-3"
                            id="infoCollapse"
                          >
                            <div className="d-flex flex-row justify-content-between">
                              <p className="fw-bold  mb-0">Remittance Fee</p>
                              <p className=" mb-1">
                                {convData?.remittancePartnerFees}{" "}
                                {convData?.toCurrency}
                              </p>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                              <p className="fw-bold mb-0">Our Fee</p>
                              <p className="mb-1">
                                {convData?.ourFees} {convData?.toCurrency}
                              </p>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                              <p className="fw-bold mb-0">Total Fee</p>
                              <p className="mb-0">
                                {convData?.totalFees} {convData?.toCurrency}
                              </p>
                            </div>
                          </div>
                          {selectedAccount?.currency !==
                            convData?.fromCurrency &&
                            convData?.toCurrency !== convData?.fromCurrency && (
                              <>
                                <div className="d-flex flex-row justify-content-between">
                                  <p>Exchange Rate </p>
                                  <p>
                                    1 {convData?.fromCurrency} ={" "}
                                    {convData?.exchangeRate}{" "}
                                    {convData?.toCurrency}
                                  </p>
                                </div>
                              </>
                            )}
                          <div
                            className="d-flex flex-row justify-content-between"
                            style={{ color: "#0000ff" }}
                          >
                            <p className="fw-bold">Receipent gets </p>
                            <p>
                              {convData?.amountEntered} {convData?.fromCurrency}
                            </p>
                          </div>
                        </div>
                      )}
                      <div className="mb-7 mt-2">
                        <label className="form-label">Select Category</label>
                        <Field
                          as="select"
                          id="selectedCategory"
                          name="selectedCategory"
                          className="form-select"
                          disabled={
                            selectedAccount &&
                            Number(values.inputValue) > selectedAccount.balance
                          }
                        >
                          <option value="" disabled>
                            Category
                          </option>
                          {category.map((item, key) => (
                            <>
                              <option value={item}>{item}</option>
                            </>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="selectedCategory"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div>
                        <label className="form-label">Remarks</label>
                        <Field
                          type="text"
                          id="remarks"
                          name="remarks"
                          className="form-control"
                          placeholder="remarks"
                          disabled={
                            selectedAccount &&
                            Number(values.inputValue) > selectedAccount.balance
                          }
                          maxLength={100}
                        />
                        <ErrorMessage
                          name="remarks"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn rounded"
                        style={{
                          width: "360px",
                          marginBottom: "25px",
                          position: "fixed",
                          bottom: "0",
                        }}
                      >
                        {buttonText}
                      </button>
                    </>
                  )}

                  {container && (
                    <div className="card shadow p-5">
                      <div className="text-center mt-2 mb-3">
                        <h4>Enter Your PIN</h4>
                      </div>
                      <div className="text-center">
                        <p className="mb-3">Enter your PIN to confirm</p>
                      </div>
                      <div className="d-flex text-center justify-content-center mb-4">
                        <OTPInput
                          // {...field}
                          value={otp.join("")}
                          onChange={(value: string) => {
                            value
                              .split("")
                              .forEach((char, index) =>
                                handleChange(char, index)
                              );
                          }}
                          numInputs={4}
                          renderSeparator={<span>&nbsp;&nbsp;</span>}
                          renderInput={(props, index) => (
                            <input
                              {...props}
                              value={otp[index]}
                              ref={(el) => (inputRefs.current[index] = el)}
                              onChange={(e) => {
                                const pin = handleChange(e.target.value, index);
                                setErrMsg("");
                                setPassword(pin);
                                RemittanceMoneyData.transactionPIN = pin;
                              }}
                              onKeyDown={(e) => {
                                const pin = handleKeyDown(e, index);
                                setErrMsg("");
                                setPassword(pin);
                                RemittanceMoneyData.transactionPIN = pin;
                              }}
                              type={show ? "password" : "text"}
                              autoFocus={true}
                              disabled={index !== activeInput}
                              onFocus={() => setActiveInput(index)}
                              className="text-center rounded mt-3 border border-1 border-dark mx-auto"
                              style={{ height: "50px", width: "50px" }}
                            />
                          )}
                        />
                      </div>
                      <p
                        className="text-center text-decoration-underline cursor-pointer mb-0 mt-2"
                        onClick={() => setShow(!show)}
                      >
                        {show ? "Show PIN" : "Hide PIN"}
                      </p>
                      {errMsg && (
                        <p className="text-danger text-center">{errMsg}</p>
                      )}
                    </div>
                  )}

                  <div className="d-flex justify-content-center">
                    {password.toString().length === 4 && container ? (
                      <button
                        type="submit"
                        className="btn rounded"
                        id="kt_modal_close"
                        aria-label="Close"
                        style={{
                          width: "380px",
                          marginTop: "50px",
                        }}
                        onClick={() => doTransfer()}
                      >
                        Confirm Payment
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          {/* end::Body */}
        </div>
        {/* end::Card */}
      </div>
      <E_OverseasReceiptModal data={remittanceData} />
    </>
  );
}
