/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { NewPassword } from "./components/NewPassword";
import { Welcome } from "./components/Welcome";
import { VerifyEmail } from "./components/VerifyEmail";
import { PasswordConfirmation } from "./components/PassswordConfirmation";
import { AccountDeactivation } from "./components/AccountDeactivation";
import { AuthSelect } from "./components/AuthSelect";
import { OtpScreen } from "./components/Otp";
import DashboardWrapper from "../../pages/dashboard/DashboardWrapper";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import XLogo from "../../../_metronic/assets/Icons/xfin.png";
import OtpScreen1 from "./components/KYC/OtpScreen1";
import Password from "./components/KYC/Password";
import Terms from "./components/KYC/Terms";
import BusinessDetails from "./components/KYC/BusinessDetails";
import Documents from "./components/KYC/Document";
import { InviteApplication } from "./components/InviteApplication";
import { InvitePassword } from "./components/InvitePassword";
import { InviteMPIN } from "./components/InviteMPIN";
import Applicant from "./components/KYC/Applicant";
import PrimaryApplicant from "./components/KYC/PrimaryApplicant";
import SmileId from "./components/KYC/SmileId";
import Signature from "./components/KYC/Signature";
import SecondaryApplicant from "./components/KYC/Secondary";
import Approved from "./components/KYC/Approved";
import ZARAccount from "./components/KYC/ZARAccount";
import USDAccount from "./components/KYC/USDAccount";
import Pin from "./components/KYC/Pin";
import Plans from "./components/KYC/Plans";
import { FormCompletionProvider, useFormCompletion } from "./components/KYC/Stepper";
import Reapply from "./components/KYC/Reapply";
const AuthhLayout = () => {
  useEffect(() => {
    document.body.classList.add("bg-body");
    return () => {
      document.body.classList.remove("bg-body");
    };
  }, []);



  return (
    <div
      className="d-flex flex-column flex-lg-row flex-column-fluid "
      style={{ height: "100vh" }}
    >
      <div
        className="d-flex flex-lg-column-fluid  w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-1 bg-dark"
      // style={{backgroundImage: `url(${toAbsoluteUrl('media/misc/auth-bg.png')})`}}
      >
        {/* begin::Content */}
        <div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
          {/* begin::Logo */}
          {/* <Link to="/" className="mb-12"> */}
            <img alt="Logo" src={XLogo} className="h-50px mb-12" />
          {/* </Link> */}
        
          <h1 className="text-white fs-2 fw-bolder text-center mb-7">
            Digital Banking Platform for African Banks
          </h1>
          {/* end::Title */}

          {/* begin::Text */}

          <p className=" fw-bold me-1">
            <span style={{ color: "white" }}> Reboot </span>{" "}
            <span style={{ color: "#0fea80" }}>banking services </span>{" "}
            <span style={{ color: "white" }}>
              for a world that uses software to run their lives.{" "}
            </span>
          </p>

          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 order-1 order-lg-1">
        {/* begin::Form */}
        <div className="d-flex flex-center flex-column flex-lg-row-fluid">
          {/* begin::Wrapper */}
          <div className="w-lg-500px ps-10 pe-10">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
interface Step {
  path: string;
  label: string;
  name: string;
}

const Stepper: React.FC = () => {
  const location = useLocation();
  const [visitedSteps, setVisitedSteps] = useState<string[]>([]);
  const { completedForms } = useFormCompletion();

  const steps: Step[] = [
    { path: '/auth/registration', label: 'Registration', name: "Enter your mobile number" },
    { path: '/auth/verify', label: 'Verify', name: "Verify with otp" },
    { path: '/auth/password', label: 'Password', name: "Enter your password" },
    { path: '/auth/terms', label: 'Terms', name: "Accept the terms" },
    { path: '/auth/business', label: 'Business', name: "Enter business details" },
    { path: '/auth/documents', label: 'Documents', name: "Upload documents" },
    { path: '/auth/applicant', label: 'Applicant', name: "Select the applicants" },
    { path: '/auth/primary-applicant', label: 'Primary-Applicant', name: "Enter details of primary applicant" },
    { path: '/auth/smileId', label: 'smile', name: "Verify with Smile Id" },
    { path: '/auth/signature', label: 'Signature', name: "Upload signature document" },
    

  ];

  const stepperStyles = {

    item: {
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      textDecoration: 'none',
      padding: '10px 0',
      fontSize: '12px',
    },
    icon: {
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px',
    },
    visitedIcon: {
      backgroundColor: '#00cc66',
      color: 'white',
    },
    notVisitedIcon: {
      backgroundColor: 'grey',
      color: 'white',
    },
    
  };


  return (
    <div>
      {steps.map((step) => {
        const isCompleted = completedForms.join("").includes(step.label.toLowerCase());
        return (
          <NavLink
            key={step.path}
            className="list-group-item list-group-item-action"
            style={{
              ...stepperStyles.item,
              pointerEvents: isCompleted ? 'auto' : 'none',
            }}
            to={step.path}
          >
            <div
              style={{
                ...stepperStyles.icon,
                ...(isCompleted ? stepperStyles.visitedIcon : stepperStyles.notVisitedIcon),
              }}
            >
              {isCompleted ? '✔' : '✔'}
            </div>
            {step.name}
          </NavLink>
        );
      })}
    </div>
  );
};



const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add("bg-body");
    return () => {
      document.body.classList.remove("bg-body");
    };
  }, []);
  const navigate=useNavigate()

  return (

    <div
      className="d-flex flex-column flex-lg-row flex-column-fluid "
      style={{ height: "100vh" }}
    >
       <div className="position-absolute top-0 start-0 p-3">
      <Link to="/" className="mb-12">
        <img alt="Logo" src={XLogo} className="h-50px mt-5 ms-5" />
      </Link>
    </div>
   
      <div
        className="d-flex flex-center flex-lg-column-fluid   w-lg-5 bgi-size-cover bgi-position-center order-2 order-lg-1 bg-dark"
      // style={{backgroundImage: `url(${toAbsoluteUrl('media/misc/auth-bg.png')})`}}
      >
       
        
        <div className="py-15 px-5 px-md-15 w-100 ms-20">
          <p className="text-light fs-2x  fw-bold">Registration Process</p>
          <Stepper />
        </div>
       
      </div>
      <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 order-1 order-lg-1">
        {/* begin::Form */}
        <div className="d-flex flex-start mt-10 ms-10">
            <div>
            {!['registration', 'verify', 'password'].some(keyword => window.location.href.includes(keyword)) && (
            <div className="d-flex flex-row flex-start">
              <div
                className="btn btn btn-light-primary d-flex flex-row"
                style={{
                  padding: "5px",
                  width: "80px",
                  
                
                }}
                onClick={() => navigate(-1)}
              >
                <i className="ki-duotone ki-arrow-left fs-2">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>{" "}
                Back
              </div>
            </div>
          )}
          </div>
         
          </div>
        <div className="d-flex flex-center flex-column flex-lg-row-fluid">
          {/* begin::Wrapper */}
          <div className="w-lg-500px ps-10 pe-10">
         
          
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};


export const AuthPage = () => (
  <Routes>
    <Route element={<AuthhLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />

      <Route path="forgot-password/authselect" element={<AuthSelect />} />
      <Route
        path="forgot-password/authselect/otpScreen"
        element={<OtpScreen />}
      />
      <Route
        path="forgot-password/authselect/otpScreen/newPasswordScreen"
        element={<NewPassword />}
      />
      <Route path="welcome" element={<Welcome />} />
      <Route path="verify-email" element={<VerifyEmail />} />
      <Route path="password-confirmation" element={<PasswordConfirmation />} />
      <Route path="account-deactivation" element={<AccountDeactivation />} />
      <Route index element={<Login />} />
    </Route>


    <Route element={<AuthLayout />}>

      <Route path="registration" element={<Registration />} />
      <Route path="verify" element={<OtpScreen1 />} />
      <Route path="password" element={<Password />} />
      <Route path="terms" element={<Terms />} />
      <Route path="business" element={<BusinessDetails />} />
      <Route path="documents" element={<Documents />} />
      <Route path="applicant" element={<Applicant />} />
      <Route path="primary-applicant" element={<PrimaryApplicant />} />
      <Route path="smileId" element={<SmileId />} />
      <Route path="signature" element={<Signature />} />
      <Route path="secondary" element={<SecondaryApplicant />} />\
      <Route path="approved" element={<Approved />} />
      <Route path="primary-acc" element={<ZARAccount />} />
      <Route path="secondary-acc" element={<USDAccount />} />
      <Route path="pin" element={<Pin />} />
      <Route path="plans" element={<Plans />} />
      <Route path="reapply" element={<Reapply />} />
    </Route>

    <Route path="invite-application" element={<InviteApplication />} />
    <Route path="new-password" element={<InvitePassword />} />
    <Route path="new-mpin" element={<InviteMPIN />} />

  </Routes>
);

export default AuthhLayout;
