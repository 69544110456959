import { ChangeEvent, useEffect, useRef, useState, useCallback } from "react";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import clsx from "clsx";
import { useFormik } from "formik";
import * as Yup from "yup";
import Max from "../../../_metronic/assets/Icons/anime.svg";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { create } from "domain";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import { Modal } from "bootstrap";
import Authorize from "../../modules/auth/components/Authorize";

interface Props {
  onFileUpload: (file: File) => void;
}

interface Attachment {
  documentName: string;
  documentPath: string;
  cloneDocument: boolean;
}

interface Conversation {
  type: string;
  date: string;
  authorType: string;
  authorEmail?: string;
  authorName?: string;
  message?: string;
  messageId: number;
  attachments?: Attachment[];
}

interface Ticket {
  ticketId: string;
  shortID: string;
  createdAt: string;
  status: string;
  priority: number;
  subject: string;
  unreadMessage: boolean;
  lastAdminMessageId: number;
  conversations: Conversation[];
}

export function formatTimestamp(timestamp: string): string {
  const now = new Date();
  const date = new Date(timestamp);

  // If the timestamp is today
  if (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  ) {
    // Format as time (e.g., 10:30 AM)
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  } else {
    // Calculate the difference in days
    const diffTime = Math.abs(now.getTime() - date.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // If it's within 3 days, display as "x day(s) ago"
    if (diffDays <= 3) {
      return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
    } else {
      // Otherwise, display as date (e.g., Apr 04, 2024)
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();
      return `${month} ${day < 10 ? "0" + day : day}, ${year}`;
    }
  }
}

const ServiceTicket = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const navigate = useNavigate();
  const [faq, setFaq] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [filteredTickets, setFilteredTickets] = useState(tickets);

  const getTickets = async () => {
    try {
      const url = `${API_URL}/helpDesk/getAllTickets`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        pageNumber: 1,
        pageSize: 100,
        skipPagination: true,
        searchCriteria: [],
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      setTickets(data.results);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const deleteTicket = async (ticketId: string) => {
    try {
      const url = `${API_URL}/helpDesk/deleteTicket`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        ticketId: ticketId,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.status?.statusCode === 0) {
        getTickets();
        Swal.fire({
          icon: "success",
          text: "Ticket has been deleted.",
          showCancelButton: false,
          confirmButtonColor: "#007bff",
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getTickets();
  }, []);

  useEffect(() => {
    setFilteredTickets(
      tickets?.filter(
        (ticket) =>
          ticket?.status?.toString().includes(searchQuery.toLowerCase()) ||
          ticket?.subject?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          ticket?.ticketId?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [tickets, searchQuery]);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const createFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      bulkFile: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
      .min(2,"Minimum 2 characters required")
      .max(50,"Maximum 50 characters allowed")
        .matches(/^[a-zA-Z0-9 ]+$/, {
          message:
            "Only (letters, numbers, spaces) are allowed ",
        })
        .required("Subject is required"),
      description: Yup.string()
      .min(2,"Minimum 2 characters required")
      .max(100,"Maximum 100 characters allowed")
        .required("Description is required"),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      const formData = new FormData();
      formData.append("channel", "WEB");
      files.forEach((file, index) => {
        formData.append(`files`, file);
      });
      formData.append("subject", values?.name);
      formData.append("message", values?.description);

      const body = JSON.stringify({
        subject: values?.name,
        message: values?.description,
      });

      setSubmitting(true);
      const headersforFile = {
        Authorization: `Bearer ${token}`,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      // Create the POST request options
      const requestOptions = {
        method: "POST",
        headers: files?.length > 0 ? headersforFile : headers,
        body: files?.length > 0 ? formData : body,
      };

      let apiUrlDAta = "";
      if (files?.length > 0) {
        apiUrlDAta = "helpDesk/createTicketWithAttachments";
      } else {
        apiUrlDAta = "helpDesk/createTicket";
      }
      // Send the POST request
      fetch(`${API_URL}/${apiUrlDAta}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result?.status?.statusCode === 0) {
            const myModalEl = document.querySelector(
              "#kt_modal_createee_ticket"
            );
            if (myModalEl) {
              const myModal = Modal.getOrCreateInstance(myModalEl);
              myModal.hide();

              // Remove backdrop
              const backdrop = document.querySelector(".modal-backdrop");
              if (backdrop) {
                backdrop.remove();
              }
            }

            getTickets();
            Swal.fire({
              icon: "success",
              title: "Ticket created",
              text: result?.status?.messageDescription,
              allowEscapeKey: true,
              allowEnterKey: true,
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "Failed",
              text: result?.status?.messageDescription,
              allowEscapeKey: true,
              allowEnterKey: true,
            });
          }
        })
        .catch((error) => console.error("error", error));

      setFiles([]);
      createFormik.resetForm();
      createFormik.setFieldValue("bulkFile", null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    },
  });

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Do something with the uploaded files
    acceptedFiles.forEach((file) => {
      // onFileUpload(file);
    });

    const fileNames = acceptedFiles.map((file) => file.name);
    setSelectedFiles((prevSelectedFiles) => [
      ...prevSelectedFiles,
      ...fileNames,
    ]);
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const dropzoneOptions: DropzoneOptions = {
    accept: {
      "image/*": [".png", ".jpeg", ".jpg"],
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.ms-excel": [".xls"],
    },
    maxFiles: 10,
    maxSize:3000000,
    onDrop,
  };

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(dropzoneOptions);

  const removeFile = (indexToRemove: number) => {
    setSelectedFiles((prevFiles) => {
      // Remove the file at the specified index
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(indexToRemove, 1);
      return updatedFiles;
    });
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(indexToRemove, 1);
      return updatedFiles;
    });
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredTickets?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${
              currentPage === pageNumbers ? "active" : ""
            }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  return (
    <>
      <h4 className="mb-4">Service Ticket</h4>

      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card d-flex flex-column flex-grow-1">
          <>
            <div className="shadow-sm m-5 rounded">
              <div className="py-2 card py-5 px-5">
                <div className="d-flex justify-content-between align-items-center p-3 pt-0">
                  <h4 className="ms mb-0">Recent Tickets</h4>
                  <div className="d-flex align-items-center gap-3">
                    <div className="d-flex align-items-center position-relative my-1">
                      <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-4">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <input
                        type="text"
                        name="searchQuery"
                        // value={searchQuery}
                        className="form-control p-3 border-secondary form-control-solid w-250px ps-15"
                        data-kt-docs-table-filter="search"
                        onChange={handleSearchChange}
                        placeholder="Search Ticket"
                        autoComplete="new-password"
                      />
                    </div>
                    <Authorize hasAnyPermission={["MENU_SR|ADD"]}>
                      <button
                        className="btn fs-5 p-3 px-10"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_createee_ticket"
                      >
                        Create Ticket
                      </button>
                    </Authorize>
                    <div
                      className="modal"
                      id="kt_modal_createee_ticket"
                      tabIndex={-1}
                      aria-hidden="true"
                        
                    >
                      <div className="modal-dialog modal-dialog-centered mw-650px">
                        <div className="modal-content">
                          <div
                            className="modal-header"
                            id="kt_modal_add_user_header"
                          >
                            <h2 className="fw-bold">Create Ticket</h2>

                            <div
                              className="btn btn-icon btn-sm btn-active-icon-primary "
                              data-kt-users-modal-action="close"
                              data-bs-dismiss="modal"
                            >
                              <p
                                className=" fs-2x mb-1"
                                onClick={() => {
                                  createFormik.resetForm();
                                  setSelectedFiles([]);
                                  setFiles([]);
                                  createFormik.setFieldValue("bulkFile", null);
                                  if (fileInputRef.current) {
                                    fileInputRef.current.value = "";
                                  }
                                }}
                              >
                                &times;
                              </p>
                            </div>
                          </div>

                          <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                            <form
                              id="kt_modal_add_user_form"
                              className="form"
                              onSubmit={createFormik.handleSubmit}
                            >
                              <div className="mb-10">
                                <label className="form-label required">
                                  Enter Subject
                                </label>
                                <input
                                  type="text"
                                  {...createFormik.getFieldProps("name")}
                                  name="name"
                                  max={50}
                                  className={clsx(
                                    "form-control mb-3 mb-lg-0",
                                    {
                                      "is-invalid":
                                        createFormik.touched.name &&
                                        createFormik.errors.name,
                                    },
                                    {
                                      "is-valid":
                                        createFormik.touched.name &&
                                        !createFormik.errors.name,
                                    }
                                  )}
                                  onChange={createFormik.handleChange}
                                  placeholder="Subject name"
                                />
                                {createFormik.touched.name &&
                                  createFormik.errors.name && (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block">
                                        <span role="alert">
                                          {createFormik.errors.name}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                              </div>

                              <div className="fv-row mb-10">
                                {/* begin::Label */}
                                <label className="required fw-bold fs-6 mb-2">
                                  Add Description
                                </label>
                                <textarea
                                  placeholder="Description"
                                  {...createFormik.getFieldProps("description")}
                                  className={clsx(
                                    "form-control form-control ",
                                    {
                                      "is-invalid":
                                        createFormik.touched.description &&
                                        createFormik.errors.description,
                                    },
                                    {
                                      "is-valid":
                                        createFormik.touched.description &&
                                        !createFormik.errors.description,
                                    }
                                  )}
                                  rows={5}
                                  style={{ resize: "none" }}
                                  maxLength={100}
                                  name="description"
                                  autoComplete="off"
                                />
                                {/* <p className="text-gray-800 text-end mb-0 fs-8">
                    200 characters
                  </p> */}
                                {/* end::Input */}
                                {createFormik.touched.description &&
                                  createFormik.errors.description && (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block">
                                        <span role="alert">
                                          {createFormik.errors.description}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                              </div>

                              {/* <!--begin::Input group--> */}
                              <div className="fv-row mb-8">
                                <label className="fs-6 fw-semibold mb-2">
                                  Attachments
                                </label>

                                <div
                                  className={`dropzone ${
                                    isDragActive ? "drag-active" : ""
                                  }`}
                                  // id="kt_modal_create_ticket_attachments"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />

                                  {selectedFiles.length > 0 ? (
                                    <div>
                                      <div className="d-flex flex-wrap">
                                        {selectedFiles.map(
                                          (fileName, index) => (
                                            <div
                                              className="d-flex flex-row align-items-center bg-secondary gap-3 px-2 py-1 ms-3 mb-3"
                                              style={{
                                                zIndex: 1,
                                                borderRadius: "5px",
                                                flexBasis:
                                                  "calc(33.33% - 20px)",
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                              }}
                                            >
                                              <p className="m-0">{fileName}</p>
                                              <i
                                                className="bi bi-x m-0 text-dark fs-4"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  removeFile(index);
                                                }}
                                              ></i>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="dz-message needsclick align-items-center">
                                      <i className="ki-duotone ki-file-up fs-3hx text-primary">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                      </i>

                                      <div className="ms-4">
                                        <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                                          Drop files here or click to upload.
                                        </h3>
                                        <span className="fw-semibold fs-7 text-gray-500">
                                          Upload up to 10 files (Maximum upload size 3mb)
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  
                                </div>
                                <p className="fs-7 mt-2">Only .pdf,.xlsx and images are allowed</p>
                              </div>

                              {/* {createFormik.touched.bulkFile &&
                              createFormik.errors.bulkFile && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    <span role="alert">
                                      {createFormik.errors.bulkFile}
                                    </span>
                                  </div>
                                </div>
                              )} */}

                              <div className="text-center pt-15">
                                <button
                                  type="button"
                                  data-bs-dismiss="modal"
                                  className="btn btn-secondary rounded me-3"
                                  style={{
                                    //   width: "180px",
                                    color: "#246bfb",
                                  }}
                                  // onClick={() => handleSelectBillerClose()}
                                >
                                  Cancel
                                </button>
                                <button type="submit" className="btn">
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-content " id="myTabContent">
                  <div className="card p-2">
                    <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                      <thead className="bg-gray-200">
                        <tr className="text-start text-dark-600 fw-bold fs-6 ">
                          <th className="min-w-100px p-5">Ticket ID</th>
                          <th className="min-w-100px p-5">Description</th>
                          <th className="min-w-100px p-5">Status</th>
                          <th className="min-w-100px p-5">Created Time</th>
                          <th className="min-w-100px p-5 text-nowrap">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {filteredTickets && filteredTickets.length > 0 ? (
                          filteredTickets.map((ticket, index) => (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                                // target.style.transform = "none";
                                // target.style.boxShadow = "none";
                              }}
                              onClick={() => {
                                // handleViewClick(item?.txnId);
                              }}
                            >
                              <td
                                className={`p-5 text-${
                                  ticket?.unreadMessage
                                    ? "dark fw-bold"
                                    : "gray-600"
                                } fs-6 cursor-pointer`}
                                onClick={() => {
                                  navigate("/helpdesk/service-ticket/reply", {
                                    state: { data: ticket?.ticketId },
                                  });
                                }}
                              >
                                {ticket.shortID}
                              </td>
                              <td
                                className={`p-5 text-${
                                  ticket?.unreadMessage
                                    ? "dark fw-bold"
                                    : "gray-600"
                                } fs-6 cursor-pointer`}
                                onClick={() => {
                                  navigate("/helpdesk/service-ticket/reply", {
                                    state: { data: ticket?.ticketId },
                                  });
                                }}
                              >
                                {ticket.subject}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {ticket.status === "Open" ? (
                                  <i className="bi bi-circle-fill text-primary fs-9"></i>
                                ) : null}
                              </td>
                              <td
                                className={`p-5 text-${
                                  ticket.status === "open"
                                    ? "success"
                                    : ticket.status === "pending"
                                    ? "warning"
                                    : ticket.status === "closed"
                                    ? "primary"
                                    : ticket.status === "onhold"
                                    ? "dark"
                                    : "primary"
                                }`}
                              >
                                {capitalizeFirstLetter(ticket.status)}
                              </td>
                              <td
                                className={`p-5 text-${
                                  ticket?.unreadMessage
                                    ? "dark fw-bold"
                                    : "gray-600"
                                } fs-6`}
                              >
                                {formatTimestamp(ticket.createdAt)}
                              </td>
                              <td className="p-5 text-gray-600 p-2">
                                <Authorize
                                  hasAnyPermission={["MENU_SR|DELETE"]}
                                >
                                  <i
                                    className="bi bi-trash p-5 text-danger cursor-pointer"
                                    //   id="kt_receipt_modal_toggle"
                                    onClick={() => {
                                      Swal.fire({
                                        icon: "error",
                                        text: "Are you sure you want to delete this ticket?",
                                        showCancelButton: true,
                                        confirmButtonColor: "#007bff",
                                        confirmButtonText: "Yes",
                                        cancelButtonText: "No",
                                        allowEscapeKey: true,
                                        allowEnterKey: true,
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          deleteTicket(ticket?.ticketId);
                                        }
                                      });
                                    }}
                                  ></i>
                                </Authorize>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="p-5">
                    <ul className="pagination">
                      <li
                        className={`page-item double-arrow ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        key={0}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(1)}
                        >
                          <i className="previous"></i>
                          <i className="previous"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item previous ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        key={1}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="previous"></i>
                        </a>
                      </li>
                      {renderPageNumbers()}
                      <li
                        className={`page-item next ${
                          currentPage ===
                          Math.ceil(filteredTickets?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                        key={2}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="next"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item double-arrow ${
                          currentPage ===
                          Math.ceil(filteredTickets?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                        key={3}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() =>
                            handlePageChange(
                              Math.ceil(filteredTickets?.length / itemsPerPage)
                            )
                          }
                        >
                          <i className="next"></i>
                          <i className="next"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="p-5 d-flex align-items-center">
                    <label
                      htmlFor="itemsPerPage"
                      style={{ fontWeight: "bold" }}
                    >
                      Total: {filteredTickets?.length}&nbsp;&nbsp;
                    </label>
                    <select
                      id="itemsPerPage"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                      style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
                    >
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={75}>75</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default ServiceTicket;
