import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-datepicker/dist/react-datepicker.css";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useRef, useState } from "react";
import { findBusinessCategory, getUserDetails, updateUser } from "./Api";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { BusinessFormValues, CategoryModal, Payload } from "./Interface";
import dayjs from "dayjs";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import Swal from "sweetalert2";
import { Modal } from "bootstrap";
import { apiCall } from "../dashboard/DashboardWrapper";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { ImgUpdate } from "../../../_metronic/layout/components/header/Navbar";

export const ApiCalling = {
  api: (): void => {},
};

const PartnerDetails = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const [userDetails, setUserDetails] = useState<BusinessFormValues>();
  const [businessCategory, setBusinessCategory] = useState<CategoryModal[]>();
  const [change, setChange] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const imageModalRef = useRef<HTMLDivElement | null>(null);
  const initialValues = {
    businessName: userDetails?.businessName || "",
    firstName: userDetails?.firstName || "",
    yearOfIncorporation: userDetails?.yearOfIncorporation || "",
    email: userDetails?.email || "",
    country: userDetails?.isdCode?.toString() || "",
    businessCategory: userDetails?.businessCategory || "",
    address: userDetails?.address || "",
    vatNumber: userDetails?.vatNumber || "",
    nidNumber: userDetails?.nidNumber || "",
  };

  const validationSchema = Yup.object({
    businessName: Yup.string().required("Business Name is required"),
    firstName: Yup.string().required("Business Owner Name is required"),
    yearOfIncorporation: Yup.string().required(
      "Incorporation Date is required"
    ),
    country: Yup.string().required("Country is required"),
    businessCategory: Yup.string().required("Company is required"),
    address: Yup.string().required("Business address is required"),
  });

  const onSubmit = (values: Payload) => {
    if (values.yearOfIncorporation) {
      values.yearOfIncorporation = values.yearOfIncorporation;
    }

    const formattedDate = dayjs(values.yearOfIncorporation).toISOString();

    // Your submission logic here...
    const payload: Payload = {
      ...values,
      yearOfIncorporation: formattedDate,
    };
    updateUser(API_URL, token, payload).then((response) => {
      if (response?.statusCode === 0) {
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Your Profile has been updated",
          showCancelButton: false, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    });
  };

  const handleViewClick = async () => {
    const modalElement = document.getElementById("image_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleButtonClick = () => {
    // Trigger the file input click event
    fileInputRef?.current?.click();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      // Handle the selected file
      const selectedFile = e.target.files?.[0];

      if (selectedFile) {
        // Read the selected file as a base64-encoded string
        const fileData = await readFileAsBase64(selectedFile);

        // Call the API to update the profile with the file data
        await updateProfile({
          documentExtention: selectedFile.name.split(".").pop(), // Get the file extension
          documentName: selectedFile.name.split(".")[0],
          documentTypes: [2],
          documentUploadedByType: 1,
          documentStatus: 4,
          fileUploadType: 1,
          documentData: fileData,
        });

        console.log("Selected File:", selectedFile);
      } else {
        console.error("No file selected");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const enhancedFetchData = async () => {
      const data = await getUserDetails(API_URL, token);
      setUserDetails(data);
      const businessCategory = await findBusinessCategory(API_URL, token);
      setBusinessCategory(businessCategory);
    };

    ApiCalling.api = enhancedFetchData;

    enhancedFetchData();
  }, []);

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result as string;
        const startIndex = base64String.indexOf(",") + 1;
        resolve(base64String.substring(startIndex));
      };

      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  };

  const updateProfile = async (profileData: any) => {
    try {
      const url = `${API_URL}/updateProfileImage`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({ documents: [profileData] });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        ApiCalling.api();
        ImgUpdate.api();
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Your Profile Image has been updated",
          showCancelButton: false, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const secondaryDir = [
    {
      firstName: "Akhil",
      lastName: "Reddy",
      dob: "01-01-1999",
      nationality: "Indian",
      email: "akhilreddy@gmail.com",
      isdCode: "91",
      mobileNumber: "6362387373",
      idType: "Passport",
      idNumber: "2838298",
      expDate: "01-07-2025",
    },
    {
      firstName: "Akshay",
      lastName: "Anand",
      dob: "01-01-1999",
      nationality: "Indian",
      email: "akshayanandy@gmail.com",
      isdCode: "91",
      mobileNumber: "6362388383",
      idType: "License",
      idNumber: "7583399",
      expDate: "01-07-2025",
    },
    {
      firstName: "Ruthra",
      lastName: "Moorthy",
      dob: "01-01-1999",
      nationality: "Indian",
      email: "ruthramoorthy@gmail.com",
      isdCode: "91",
      mobileNumber: "6362389393",
      idType: "National ID",
      idNumber: "ID848399",
      expDate: "01-07-2025",
    },
  ];
  return (
    <>
      {" "}
      <h4 className="mb-5">Partner Details</h4>
      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="d-flex flex-column">
          {secondaryDir?.map((dir, index) => (
            <div
              className="card shadow-sm p-5 mb-5 d-flex flex-column"
              key={index}
            >
              <div className="d-flex flex-row gap-20 align-items-center mb-5">
                <div className="d-flex flex-column p-3 gap-2">
                  <div
                    className="card shadow-sm"
                    style={{ maxHeight: "150px", maxWidth: "150px" }}
                  >
                    <div
                      className="image-input image-input-outline overlay overflow-hidden shadow"
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="overlay-wrapper">
                        <img
                          //   src={blank}
                          style={{
                            borderRadius: "10px",
                            objectFit: "fill",
                            height: "150px",
                            width: "150px",
                          }}
                        />
                      </div>
                      <div className="overlay-layer bg-dark bg-opacity-50 align-items-center">
                        <div
                          className="btn btn-icon bg-light border border-dark"
                          style={{ height: "30px", width: "30px" }}
                          //   onClick={() => {
                          //     setImg(dir?.documents?.[0]?.documentPath);
                          //     handleViewClick();
                          //   }}
                        >
                          <i className="bi bi-eye fs-5 text-dark"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card rounded p-3">
                    <div className="fw-bold fs-7">
                      DOB : {dir?.dob?.split("T")[0]}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex flex-row ms-5 mt-5">
                  <div style={{ width: "400px" }}>
                    <div className="fw-bold fs-7">First Name</div>
                    <div className="fs-8">{dir?.firstName}</div>
                  </div>
                  <div style={{ width: "400px" }}>
                    <div className="fw-bold fs-7">Last Name</div>
                    <div className="fs-8">{dir?.lastName}</div>
                  </div>
                  <div style={{ width: "355px" }}>
                    <div className="fw-bold fs-7">Nationality</div>
                    <div className="fs-8">{dir?.nationality}</div>
                  </div>
                </div>
                <br />
                <div className="d-flex flex-row ms-5 mt-5">
                  <div style={{ width: "400px" }}>
                    <div className="d-flex flex-row gap-1">
                      <p className="fw-bold fs-7 m-0">Mobile Number</p>
                      <p
                        className="fs-8 m-0"
                        style={{
                          fontStyle: "italic",
                        }}
                      >
                        (Registered number)
                      </p>
                    </div>
                    <div className="fs-8">
                      {dir?.isdCode}-{dir?.mobileNumber}
                    </div>
                  </div>
                  <div style={{ width: "400px" }}>
                    <div className="d-flex flex-row gap-1">
                      <p className="fw-bold fs-7 m-0">Email ID</p>
                      <p
                        className="fs-8 m-0"
                        style={{
                          fontStyle: "italic",
                        }}
                      >
                        (Registered ID)
                      </p>
                    </div>
                    <div className="fs-8">{dir?.email}</div>
                  </div>
                  <div style={{ width: "355px" }}>
                    <div className="fw-bold fs-7">ID Proof Type</div>
                    <div className="fs-8">
                      {dir?.idType}{" "}
                      <i className="bi bi-pencil-square fs-8 cursor-pointer text-primary"></i>
                    </div>
                  </div>
                </div>
                <br />
                <div className="d-flex flex-row ms-5 mt-5 mb-5">
                  <div style={{ width: "400px" }}>
                    <div className="fw-bold fs-7">ID Number</div>
                    <div className="fs-8">
                      {dir?.idNumber}{" "}
                      <i className="bi bi-pencil-square fs-8 cursor-pointer text-primary"></i>
                    </div>
                  </div>
                  <div style={{ width: "400px" }}>
                    <div className="fw-bold fs-7">Expiry Date</div>
                    <div className="fs-8">
                      {dir?.expDate}{" "}
                      <i className="bi bi-pencil-square fs-8 cursor-pointer text-dark"></i>
                    </div>
                  </div>

                  <div style={{ width: "355px" }}>
                    <div className="fw-bold fs-7">ID Document</div>
                    {/* {dir?.documents.length > 0 && ( */}
                    <div
                      className="fs-8 text-primary fw-bold d-flex align-items-center cursor-pointer"
                      // onClick={() => {
                      //   if (dir?.documents?.[0]?.documentExtention === "pdf") {
                      //     setDoc(dir?.documents?.[0]?.documentPath);
                      //     handleDocViewClick();
                      //   } else {
                      //     setImg(dir?.documents?.[0]?.documentPath);
                      //     handleViewClick();
                      //   }
                      // }}
                    >
                      {/* {dir?.documents?.[0]?.documentName}{" "} */}{" "}
                      <i className="bi bi-pencil-square fs-8 cursor-pointer text-dark"></i>
                    </div>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className="modal"
          tabIndex={-1}
          id="image_modal"
          ref={imageModalRef}
            
        >
          <div
            className="modal-dialog modal-dialog-centered w-400px"
            style={{ margin: "0 auto" }}
          >
            <div className="modal-content p-5">
              <div className="d-flex flex-row justify-content-between">
                <label className="form-label fs-3">Profile Image</label>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                   <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                </div>
              </div>
              <div className="modal-body p-2">
                <div
                  className="image-input image-input-outline"
                  data-kt-image-input="true"
                >
                  <div
                    className="image-input-wrapper w-350px h-250px"
                    style={{
                      backgroundImage: `url(${
                        userDetails?.documents?.find((doc) =>
                          doc.documentTypes.includes(2)
                        )?.documentPath || ""
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PartnerDetails;
