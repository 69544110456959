import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../core/Auth";
import { applicant, password } from "./api";
import { useNavigate } from "react-router-dom";
import { useFormCompletion } from "./Stepper";

const Applicant = () => {
  const [loading, setLoading] = useState(false);
  const { regUser, setRegUser } = useAuth();
  const navigate = useNavigate();
  const { markFormCompleted } = useFormCompletion();
 

  const formik = useFormik({
    initialValues: {
      applicant: regUser?.primaryApplicantType??'',
      directors: regUser?.noOfDirectors?.toString(),
    },
    validationSchema: Yup.object().shape({
      applicant: Yup.string().required("Applicant is required"),
      directors: Yup.string().required("Directors is required"),
    }),
    enableReinitialize:true,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setLoading(true);   // Start loading
      setSubmitting(true); // Indicate form is being submitted
    
      try {
        const data = await applicant(
          values?.applicant,
          values?.directors ?? '',
          regUser?.token ?? ""
        );
    
        if (data?.data?.status?.statusCode === 0) {
          setRegUser(prevState => ({
            ...prevState,
            ...data?.data?.message
          }));
    
          markFormCompleted("applicant");
          navigate("/auth/primary-applicant");
          resetForm();
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);   // Stop loading
        setSubmitting(false); // Indicate form submission is complete
      }
    }
    
  });

  return (
    <div className="d-flex flex-column justify-content-center">
      <p className="fs-2 fw-bold text-center">
        Who is the primary applicant for this account?
      </p>
      <div className="mt-10 p-10">
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <div className="mt-5 ">
            <label className="form-label">Select Category</label>
            <div>
              <select
                className={`form-select ${
                  formik.touched.applicant && formik.errors.applicant
                    ? "is-invalid"
                    : ""
                }`}
                name="applicant"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.applicant}
                aria-label="Select example"
              >
                <option value="">Select</option>
                <option value="Director">Director</option>
                <option value="Freelancer/Proprietor" disabled>
                  Freelancer/Proprietor
                </option>
              </select>
            </div>
            {formik.touched.applicant && formik.errors.applicant && (
              <div className="text-danger mt-1">{formik.errors.applicant}</div>
            )}
          </div>
          <div className="mt-5 ">
            <label className="form-label">
              How many directors do you have ?
            </label>
            <div>
              <select
                className={`form-select ${
                  formik.touched.directors && formik.errors.directors
                    ? "is-invalid"
                    : ""
                }`}
                name="directors"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.directors}
                aria-label="Select example"
                disabled={regUser?.reapply}
              >
                <option value="">Select</option>
                {Array.from({ length: 10 }, (_, index) => (
                  <option key={index + 1} value={(index + 1)}>
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>
            {formik.touched.directors && formik.errors.directors && (
              <div className="text-danger mt-1">{formik.errors.directors}</div>
            )}
          </div>
          <button type="submit" className="btn w-100 mt-20" disabled={formik.isSubmitting || loading}>
            {loading ? (
              <div className="spinner-border text-primary" role="status">
                <span className="" role="status" aria-hidden="true"></span>
              </div>
            ) : (
              <div>Proceed</div>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Applicant;
