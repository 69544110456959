import { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";
import { settingData } from "./settingData";
import Swal from "sweetalert2";
import { enhancedFetch } from "../../../modules/auth/core/_requests";

interface Document {
  documentExtention: string;
  documentName: string;
  documentTypes: number[]; // Assuming it's an array of numbers
  documentUploadedByType: number;
  documentStatus: number;
  fileUploadType: number;
  documentData: string;
}

interface setting {
  invoicePrefix: "";
  invoiceSequencePattern: "";
  termsAndConditions: "";
  dueDatePeriod: "";
  tax: "";

  documents: [
    {
      documentExtention: "";
      documentName: "logo";
      documentTypes: [1];
      documentUploadedByType: 1;
      documentStatus: 4;
      fileUploadType: 1;
      documentData: "";
    },
    {
      documentExtention: "";
      documentName: "signature";
      documentTypes: [9];
      documentUploadedByType: 1;
      documentStatus: 4;
      fileUploadType: 1;
      documentData: "";
    }
  ];
}

interface FormValues {
  prefix: string;
  sequence: string;
  DueDate: string;
  terms: string;
  imageFile: Document | null;
  imageData: string;
  signatureFile: Document | null;
  signatureData: string;
  tax: string;
}

export default function InvoiceSettings() {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const inputRef = useRef<HTMLInputElement | null>(null);
  const signatureRef = useRef<HTMLInputElement | null>(null);
  const formikRef = useRef<FormikProps<FormValues>>(null);

  const [logo, setLogo] = useState("");
  const [signature, setSignature] = useState("");
  const [invSetting, setInvSetting] = useState<setting | null>(null);

  const LoadSettings = async () => {
    try {
      const url = `${API_URL}/invSetting/load`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setInvSetting(data.message);
      setLogo(data.message.documents[0].documentPath);
      setSignature(data.message.documents[1].documentPath);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    LoadSettings();
  }, []);

  const InvoiceSettings = async () => {
    try {
      const url = `${API_URL}/invSetting/manage`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      let body;

      const isInputUpdated =
        inputRef.current?.files?.length && formikRef.current?.values.imageFile;
      const isSignatureUpdated =
        signatureRef.current?.files?.length &&
        formikRef.current?.values.signatureFile;

      const documentsToSend = [];

      if (isInputUpdated) {
        documentsToSend.push({
          ...settingData.documents[0],
          documentData: formikRef.current?.values.imageData,
        });
      }

      if (isSignatureUpdated) {
        documentsToSend.push({
          ...settingData.documents[1],
          documentData: formikRef.current?.values.signatureData,
        });
      }

      if (documentsToSend.length > 0) {
        // Only include 'documents' if there are documents to send
        body = JSON.stringify({
          ...settingData,
          documents: documentsToSend,
        });
      } else {
        // If there are no documents to send, exclude 'documents'
        body = JSON.stringify({ ...settingData, documents: [] });
      }

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      if (data.status.statusCode === 0) {
        LoadSettings();
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Setting Updated!",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="mb-5">
        <h4>Invoice Settings</h4>
      </div>
      <div className="card shadow p-10">
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={
            // invSetting ?

            {
              prefix: invSetting ? invSetting.invoicePrefix : "",
              sequence: invSetting ? invSetting.invoiceSequencePattern : "",
              DueDate: invSetting ? invSetting.dueDatePeriod : "",
              terms: invSetting ? invSetting.termsAndConditions : "",
              imageFile: invSetting ? invSetting.documents[0] : null,
              imageData: "",
              signatureFile: invSetting ? invSetting.documents[1] : null,
              signatureData: "",
              tax: invSetting ? invSetting.tax : "",
            }
            // :
            // {
            //     prefix: '',
            //     sequence: '',
            //     DueDate: '',
            //     terms: '',
            //     imageFile: null,
            //     imageData: '',
            //     signatureFile: null,
            //     signatureData: '',
            //     reminder: '',
            //     tax: ''
            // }
          }
          validationSchema={Yup.object({
            prefix: Yup.string().required("Prefix Required"),
            sequence: Yup.string().required("Sequence Required"),
            terms: Yup.string().required("Terms and Conditions Required"),
            DueDate: Yup.number().required("Due Date Required"),
            tax: Yup.number().required("Tax Percentage Required"),
            // imageFile: Yup.mixed()
            //   .required("Image is required")
            //   .test("fileSize", "File size is too large", (value) => {
            //     if (value instanceof File) {
            //       return value.size <= 5000000;
            //     }
            //     return true;
            //   })
            //   .test("fileType", "Unsupported file format", (value) => {
            //     if (value instanceof File) {
            //       return ["image/jpeg", "image/png"].includes(value.type);
            //     }
            //     return true;
            //   }),
            // signatureFile: Yup.mixed()
            //   .required("Signature is required")
            //   .test("fileSize", "File size is too large", (value) => {
            //     if (value instanceof File) {
            //       return value.size <= 5000000;
            //     }
            //     return true;
            //   })
            //   .test("fileType", "Unsupported file format", (value) => {
            //     if (value instanceof File) {
            //       return ["image/jpeg", "image/png"].includes(value.type);
            //     }
            //     return true;
            //   }),
          })}
          onSubmit={(values, { resetForm }) => {
            settingData.invoicePrefix = values?.prefix;
            settingData.invoiceSequencePattern = values?.sequence;
            settingData.termsAndConditions = values?.terms;
            settingData.dueDatePeriod = Number(values?.DueDate);
            settingData.tax = Number(values?.tax);
            settingData.documents[0].documentExtention =
              settingData.documents[0].documentExtention = (
                values.imageFile as any
              )?.type
                ? (values.imageFile as any).type.split("/")[1]
                : "";
            settingData.documents[0].documentData = values?.imageData;
            settingData.documents[1].documentExtention =
              settingData.documents[1].documentExtention = (
                values.signatureFile as any
              )?.type
                ? (values.signatureFile as any).type.split("/")[1]
                : "";
            settingData.documents[1].documentData = values?.signatureData;

            InvoiceSettings();
            resetForm();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            validateField,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form>
              <div>
                <div className="d-flex flex-row gap-10">
                  <div className="mb-5 ">
                    <label className="form-label">Logo</label>
                    <div
                      className="form-control d-flex align-items-center justify-content-between"
                      style={{
                        border: "1px solid #ced4da",
                        borderRadius: "4px",
                        minHeight: "130px",
                        maxHeight: "130px",
                        minWidth: "126px",
                        maxWidth: "126px",
                      }}
                      // onClick={() => inputRef.current?.click()} // Trigger the file input click event
                    >
                      {values.imageFile ? (
                        <img
                          src={logo}
                          alt="Selected"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <i className="bi bi-folder-fill fs-2"></i>
                      )}
                    </div>
                    <div
                      className="p-2 bg-hover-primary"
                      style={{
                        backgroundColor: "#0000ff",
                        position: "absolute",
                        marginLeft: "100px",
                        marginTop: "-30px",
                        borderRadius: "30%",
                        cursor: "pointer",
                      }}
                      onClick={() => inputRef.current?.click()}
                    >
                      <i className="bi bi-pencil fs-4 text-light"></i>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={inputRef} // Ref to access the input element
                      onChange={(event) => {
                        const selectedFile = event.currentTarget.files?.[0];

                        if (selectedFile) {
                          setFieldValue("imageFile", selectedFile);
                          const reader = new FileReader();
                          reader.onload = async (e) => {
                            const base64Data = (
                              e.target?.result as string
                            )?.split(",")[1];
                            setFieldValue("imageData", base64Data);
                            setLogo(e.target?.result as string);
                          };
                          reader.readAsDataURL(selectedFile);
                        } else {
                          setFieldValue("imageFile", null);
                          setFieldValue("imageData", "");
                        }
                      }}
                    />
                    <ErrorMessage
                      name="imageFile"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="mb-5">
                    <label className="form-label">Signature</label>
                    <div
                      className="form-control d-flex align-items-center justify-content-between"
                      style={{
                        border: "1px solid #ced4da",
                        borderRadius: "4px",
                        minHeight: "130px",
                        maxHeight: "130px",
                        minWidth: "126px",
                        maxWidth: "126px",
                      }}
                    >
                      {values.signatureFile ? (
                        <>
                          <img
                            src={signature}
                            alt="Selected"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                            }}
                          />
                        </>
                      ) : (
                        <i className="bi bi-folder-fill fs-2"></i>
                      )}
                    </div>
                    <div
                      className="p-2 bg-hover-primary"
                      style={{
                        backgroundColor: "#0000ff",
                        position: "absolute",
                        marginLeft: "100px",
                        marginTop: "-30px",
                        borderRadius: "30%",
                        cursor: "pointer",
                      }}
                      onClick={() => signatureRef.current?.click()}
                    >
                      <i className="bi bi-pencil fs-4 text-light"></i>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={signatureRef} // Ref to access the input element
                      onChange={async (event) => {
                        const selectedFile = event.currentTarget.files?.[0];

                        if (selectedFile) {
                          setFieldValue("signatureFile", selectedFile);
                          const reader = new FileReader();
                          reader.onload = async (e) => {
                            const base64Data = (
                              e.target?.result as string
                            )?.split(",")[1];
                            setFieldValue("signatureData", base64Data);
                            setSignature(e.target?.result as string);
                          };
                          reader.readAsDataURL(selectedFile);
                        } else {
                          setFieldValue("signatureFile", null);
                          setFieldValue("signatureData", "");
                        }
                      }}
                    />
                    <ErrorMessage
                      name="signatureFile"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="mb-5 d-flex flex-row justify-content-between gap-5 ">
                  <div className="flex-grow-1">
                    <label className="form-label">Invoice Prefix</label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder="Invoice Prefix"
                      name="prefix"
                      maxLength={5}
                    />
                    <ErrorMessage
                      name="prefix"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="flex-grow-1">
                    <label className="form-label">Starting Sequence</label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder="Starting Sequence"
                      maxLength={8}
                      name="sequence"
                      onChange={(event: any) => {
                        let value = event.target.value;

                        if (
                          /^\d[a-zA-Z]*$/.test(value) ||
                          /^\d+$/.test(value) ||
                          value === ""
                        ) {
                          handleChange(event);
                          validateField("sequence");
                          setFieldValue("sequence", value);
                        }
                      }}
                    />
                    <ErrorMessage
                      name="sequence"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="mb-5">
                  <label className="form-label">Terms and conditions</label>
                  <Field
                    type="text"
                    className="form-control"
                    placeholder="Terms and conditions"
                    name="terms"
                    maxLength={200}
                  />
                  <ErrorMessage
                    name="terms"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="mb-5">
                  <label className="form-label">
                    Due date period range (in days)
                  </label>
                  <Field
                    as="select"
                    className="form-select"
                    aria-label="Due Date"
                    name="DueDate"
                  >
                    <option value="">Due date period range (in days)</option>
                    {Array.from({ length: 31 }, (_, index) => (
                      <option key={index + 1} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="DueDate"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="mb-5">
                  <label className="form-label">Tax (Percentage)</label>
                  <div className="input-group">
                    <Field
                      type="text"
                      className="form-control"
                      placeholder="Tax"
                      name="tax"
                      onChange={(event: any) => {
                        let value = event.target.value;

                        // If the value is empty, set the previous value to an empty string
                        if (value === "") {
                          handleChange(event); // Trigger Formik's change handler
                          validateField("tax");
                          setFieldValue("tax", value);
                        }

                        if (/^\d{1,2}$/.test(value)) {
                          handleChange(event); // Trigger Formik's change handler
                          validateField("tax");
                          setFieldValue("tax", value);
                        }
                      }}
                    />
                    <span className="input-group-text bg-white">%</span>
                  </div>
                  <ErrorMessage
                    name="tax"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="">
                  <button
                    type="submit"
                    className="btn w-100"
                    style={{ borderRadius: "50px" }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
