import { useState, useRef, useEffect } from "react";
import { dataOutcome } from "../bulkPaymentStepper/steps/Step3";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PinInput from "react-pin-input";
import { useNavigate } from "react-router-dom";
import { ApiData } from "../bulkPaymentDashboard";
import clsx from "clsx";
import * as Yup from "yup";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
import { Modal } from "bootstrap";
import {
  ErrorMessage,
  Form,
  Field,
  Formik,
  FormikProps,
  FormikValues,
} from "formik";
import OTPInput from "react-otp-input";
import { User } from "../../TransferMoney/TransferModal";
import { log } from "console";
interface MyData {
  customerName: string;
  notes: string;
  bankAccountNo: string;
  amount: string;
  fee: string;
  type: number;
}

interface selVen {
  customerName: string;
  notes: string;
  bankAccountNo: string;
  amount: string;
  fee: string;
}

export const resData = {
  name: "",
  description: "",
  accountNumber: "",
  jobScheduledTime: "",
  fileUploadId: 0,
  successCount: 0,
  failCount: 0,
  totalCount: 0,
  fileDataList: [],
  transactionPIN: "",
  validationMsgList: [""],
  amount: 0,
  fileUploadResponse: {},
  initiatedTxn: false,
};

export default function CreateBulkPayments() {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const baseCurrency = sessionStorage.getItem("currency");

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const [button, setButton] = useState("Pay Now");

  const [approvers, setApprovers] = useState<User[]>([]);
  const [approverName, setApproverName] = useState("");
  const [approverGuid, setApproverGuid] = useState("");

  const [selectedData, setSelectedData] = useState<selVen[]>([]);
  const [data, setData] = useState<MyData[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [selectedType, setSelectedType] = useState("All");
  const [total, setTotal] = useState(0);
  let pindata: string;

  const navigate = useNavigate();

  const OTPRef = useRef<HTMLDivElement | null>(null);
  const approverRef = useRef<HTMLDivElement | null>(null);
  const formikRef = useRef<FormikProps<FormikValues>>(null);
  const appformikRef = useRef<FormikProps<FormikValues>>(null);
  let ele = useRef<PinInput | null>(null);

  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [show, setShow] = useState(true);

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  useEffect(() => {
    if (OTPRef.current) {
      const modal = new Modal(OTPRef.current);

      OTPRef.current.addEventListener("shown.bs.modal", () => {
        inputRefs.current[activeInput]?.focus();
        ele?.current?.focus();
      });

      OTPRef.current.addEventListener("hidden.bs.modal", handleOTPCloseModal);
      return () => {
        OTPRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleOTPCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const ApproverList = async (amount: string) => {
    try {
      const url = `${API_URL}/team/getApproverList`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        currency: baseCurrency,
        amount: amount,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        setApprovers(data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if(button === "Initiate"){
        if (total) {
          const amount = total.toString();
          ApproverList(amount);
        } else {
          ApproverList(userDet?.userDetails?.zarLimit);
        }
      }
      
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [total]);

  const handleOTPCloseModal = () => {
    setPin("");
    setOtp(["", "", "", ""]);
    setActiveInput(0);
    formikRef.current?.resetForm();
    ele.current?.clear();
    const modalElement = OTPRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  useEffect(() => {
    if (userDet?.userDetails?.authority?.includes("2")) {
      setButton("Initiate");
    }
  }, []);

  const manualUploadValidate = async () => {
    setLoading(true);
    try {
      const url = `${API_URL}/bulk/uploadAndValidateVendorPaymentData`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const fileDataList = selectedData.map((item) => ({
        name: item.customerName,
        remarks: item.notes,
        accountNumber: item.bankAccountNo,
        amount: (Number(item.amount) + Number(item.fee)).toString(),
      }));

      const body = JSON.stringify({
        name: dataOutcome?.name,
        description: dataOutcome?.description,
        accountNumber: dataOutcome?.acc,
        fileDataList: fileDataList,
        // transactionPIN: formikRef?.current?.values?.pin,
        ...(dataOutcome.schedule === "Later" && {
          jobScheduledTime: dataOutcome?.date,
        }),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.status?.statusCode === 0) {
        resData.fileUploadId = data.message.fileUploadId;
        resData.amount = data.message.amount;
        setLoading(false);
        if (button === "Initiate") {
          handleSelectApprover();
        } else {
          handlePayNow();
        }

        // handleOTPCloseModal();
        // const hasJobScheduledTime = !!data?.message?.jobScheduledTime;
        // handleOTPCloseModal();

        //   Swal.fire({
        //     icon: "success",
        //     title: hasJobScheduledTime
        //       ? "Bulk Payment Scheduled!"
        //       : "Transfer Processing!",
        //     text: hasJobScheduledTime
        //       ? "Your payment has been scheduled!"
        //       : "Payment transfer successfully processing!",
        //     showCancelButton: false,
        //     confirmButtonText: "Ok",
        //     cancelButtonText: "Go to dashboard",
        //
        //   });
        //   navigate("/money-movement/bulk-payment");
        //   setTimeout(() => {
        //     ApiData.api();
        //   }, 1000);
        // } else {
        //   Swal.fire({
        //     icon: "warning",
        //     title: "Failed",
        //     text: data?.status?.messageDescription,
        //   });

        
      } else {
        setLoading(false);
        const msg = data.status.messageDescription;
        Swal.fire({
          icon: "warning",
          title: "Error",
          text: msg,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const manualUpload = async () => {
    try {
      const url = `${API_URL}/bulk/processVendorPaymentData`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        fileUploadId: resData.fileUploadId,
        transactionPIN: pin,
        currency: "ZAR",
        amount: resData.amount,
        ...(button === "Initiate" && {
          approverGuid: approverGuid,
          beneficiaryName: "Loan repay",
          approverName: approverName,
          initiatedTxn: true,
        }),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.status?.statusCode === 0) {
        const hasJobScheduledTime = !!data?.message?.jobScheduledTime;
        handleOTPCloseModal();
        if (button === "Initiate") {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            html: "Initiation successful <br> Pending for approval",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        } else {
          Swal.fire({
            icon: "success",
            title: hasJobScheduledTime
              ? "Bulk Payment Scheduled!"
              : "Transfer Processing!",
            text: hasJobScheduledTime
              ? "Your payment has been scheduled!"
              : "Payment transfer successfully processing!",
            showCancelButton: false,
            confirmButtonText: "Ok",
            cancelButtonText: "Go to dashboard",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        }

        navigate("/bulk-payment");
        setTimeout(() => {
          ApiData.api();
        }, 1000);
      } else {
        Swal.fire({
          icon: "warning",
          title: "Failed",
          text: data?.status?.messageDescription,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const viewItem = async () => {
    try {
      const url = `${API_URL}/customer/view`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setData(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    viewItem();
  }, []);

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedType(e.target.value);
    setSelectedData([]);
  };

  useEffect(() => {
    // Filter data by search query
    const filteredBySearch = data?.filter(
      (employee) =>
        employee?.customerName
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        employee?.bankAccountNo
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        employee?.amount?.toString().includes(searchQuery.toLowerCase())
    );
  
    // Filter by type
    const filteredByType =
      selectedType === 'All'
        ? data
        : data.filter((item) => item.type === +selectedType);
  
    // Combine both filters
    const combinedFilteredData = filteredBySearch.filter((item) =>
      filteredByType.includes(item)
    );
  
    // Reset amount and fee for all items in combinedFilteredData and clear selectedData
    setFilteredData(combinedFilteredData.map(item => ({ ...item, amount: '', fee: '' })));
    setSelectedData([]);
  }, [data, searchQuery, selectedType]);

  const handleAmountChange = (index: number, value: string) => {
    const selectedItem = filteredData[index];
  
    // Remove all non-digit and non-decimal characters
    let processedValue = value.replace(/[^\d.]/g, '');
  
    // Ensure only one decimal point
    const parts = processedValue.split('.');
    if (parts.length > 2) {
      processedValue = parts[0] + '.' + parts.slice(1).join('');
    }
  
    // Limit to 12 digits before decimal and 2 after
    const integerPart = parts[0].slice(0, 12);
    const decimalPart = parts[1]?.slice(0, 2) || '';
  
    // Combine integer and decimal parts
    processedValue = integerPart + (decimalPart ? '.' + decimalPart : '');
  
    // Remove leading zeros, but allow a single zero
    processedValue = processedValue.replace(/^0+(?=\d)/, '');
  
    console.log(selectedItem);
    console.log(processedValue);
  
    if (selectedData.some((item) => item.customerName === selectedItem.customerName)) {
      setFilteredData((prevSelectedData) =>
        prevSelectedData.map((item) =>
          item.customerName === selectedItem.customerName
            ? { ...item, amount: processedValue }
            : item
        )
      );
  
      setSelectedData((prevSelectedData) =>
        prevSelectedData.map((item) =>
          item.customerName === selectedItem.customerName
            ? { ...item, amount: processedValue }
            : item
        )
      );
    }
  };

  const handleFeeChange = (index: number, value: string) => {
    const selectedItem = filteredData[index];
  
    // Remove all non-digit and non-decimal characters
    let processedValue = value.replace(/[^\d.]/g, '');
  
    // Ensure only one decimal point
    const parts = processedValue.split('.');
    if (parts.length > 2) {
      processedValue = parts[0] + '.' + parts.slice(1).join('');
    }
  
    // Limit to 12 digits before decimal and 2 after
    const integerPart = parts[0].slice(0, 12);
    const decimalPart = parts[1]?.slice(0, 2) || '';
  
    // Combine integer and decimal parts
    processedValue = integerPart + (decimalPart ? '.' + decimalPart : '');
  
    // Remove leading zeros, but allow a single zero
    processedValue = processedValue.replace(/^0+(?=\d)/, '');
  
    console.log(selectedItem);
    console.log(processedValue);
  
    if (selectedData.some((item) => item.customerName === selectedItem.customerName)) {
      setFilteredData((prevFilteredData) =>
        prevFilteredData.map((item) =>
          item.customerName === selectedItem.customerName
            ? { ...item, fee: processedValue }
            : item
        )
      );
  
      setSelectedData((prevSelectedData) => {
        const updatedData = prevSelectedData.map((item) =>
          item.customerName === selectedItem.customerName
            ? { ...item, fee: processedValue }
            : item
        );
        return updatedData;
      });
    }
  };

  const handleSelectItem = (index: number) => {
    const selectedItem = filteredData[index];
console.log(selectedItem);

    // Check if the item is already in the selectedData array
    const isAlreadySelected = selectedData.some(
      (item) => item.customerName === selectedItem.customerName
    );
    console.log(isAlreadySelected);
    if (isAlreadySelected) {
      // If it's already selected, remove it
      setSelectedData((prevSelectedData) =>
        prevSelectedData.filter(
          (item) => item.customerName !== selectedItem.customerName
        )
      );

      setFilteredData((prevSelectedData) =>
        prevSelectedData.map((item) =>
          item.customerName === selectedItem.customerName
            ? { ...item, amount: "", fee: "" }
            : item
        )
      );
    } else {
      // If it's not selected, add it
      setSelectedData((prevSelectedData) => [
        ...prevSelectedData,
        selectedItem,
      ]);
    }
  };

  const calculateTotal = () => {
    const totalAmt = selectedData.reduce(
      (acc, item) => acc + (item.amount ? Number(item.amount) : 0),
      0
    );

    const totalF = selectedData.reduce(
      (acc, item) => acc + (item.fee ? Number(item.fee) : 0),
      0
    );

    const combinedTotal = totalAmt + totalF;
    setTotal(combinedTotal);
  };

  useEffect(() => {
    calculateTotal();
  }, [selectedData]);

  let isToastVisible = false;

  const OTPModalOpen = () => {
    const modalElement = document.getElementById("OTP_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleSelectApprover = () => {
    const modalElement = document.getElementById("approver_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleApproverCloseModal = () => {
    const modalElement = approverRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const handleApprover = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // setAppErrMsg(false);
    setApproverGuid(e.target.value);
    const appName = approvers.find((app) => app.userGuid === e.target.value);
    if (appName) setApproverName(appName.name);
  };

  const handlePayNow = () => {

    // Check if amount and fee are entered for each selected item
    const isValid = selectedData.every((item) => item.amount && item.fee);

    if (isValid && !isToastVisible) {
      // If valid and no toast is visible, show the pin input modal
      OTPModalOpen();
    } else if (!isValid && !isToastVisible) {
      // If not valid and no toast is visible, show a validation message
      const toast = document.createElement("div");
      toast.classList.add(
        "toast",
        "show",
        "position-absolute",
        "top-0",
        "start-50",
        "translate-middle-x",
        "mt-5"
      );
      toast.setAttribute("role", "alert");
      toast.setAttribute("aria-live", "assertive");
      toast.setAttribute("aria-atomic", "true");
      toast.style.zIndex = "1000"; // Set the z-index value

      toast.innerHTML = `
                <div class="toast-header border-0 bg-danger text-light">
                    <i class="ki-duotone ki-abstract-39 fs-2 text-primary me-3"><span class="path1"></span><span class="path2"></span></i>
                    <strong class="me-auto">Error!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body bg-danger text-light">
                    Please enter amount and fee for all selected fields.
                </div>
            `;

      // Append the toast to the document body
      document.body.appendChild(toast);

      isToastVisible = true;

      // Hide the toast after 3 seconds
      setTimeout(() => {
        toast.remove();
        isToastVisible = false;
      }, 3000);
    }
  };

  const parseNumber = (value:string) => {
    const number = Number(value);
    return isNaN(number) ? 0 : number;
  };

  const isButtonDisabled = () => {
    
    // Check if all amounts and fees are > 0
    const allAmountsAndFeesPositive = selectedData.every(item => {
      const amountNumber = parseNumber(item.amount);
      const feeNumber = parseNumber(item.fee);
      return amountNumber > 0 && feeNumber > 0;
    });

    // Button should be enabled (return false) if `total > 0` and all items have positive amounts and fees
    return !(total > 0 && allAmountsAndFeesPositive);
  };

  return (
    <>
      <style>
        {`
                    .custom-input-style::-webkit-inner-spin-button,
                    .custom-input-style::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    
                    .custom-input-style {
                        -moz-appearance: textfield;
                    }
                    
                    /* Additional style to prevent scroll increase/decrease */
                    .custom-input-style[type=number] {
                        -moz-appearance: textfield;
                    }
                    
                    .custom-input-style[type=number]::-webkit-inner-spin-button,
                    .custom-input-style[type=number]::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    
                `}
      </style>
      <div>
        <h4>Create Bulk Payments</h4>

        {dataOutcome.uploadType === "Manual" && (
          <div className="card p-5">
            <div className="p-5 d-flex align-items-end justify-content-end">
              <div className="d-flex align-items-center  position-relative my-1">
                <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <input
                  value={searchQuery}
                  onChange={handleSearchChange}
                  type="text"
                  data-kt-docs-table-filter="search"
                  className="form-control  p-3 border-secondary form-control-solid w-250px ps-15"
                  placeholder="Search Customer"
                />
              </div>

              <select
                className="form-select form-select-solid border-secondary w-125px mx-5 mb-1"
                data-control="select2"
                onChange={(e) => {
                  handleTypeChange(e);
                }}
              >
                <option value="All">All</option>
                <option value="1">Customer</option>
                <option value="2">Vendor</option>
              </select>

              <button
                className="btn"
                onClick={manualUploadValidate}
                disabled={isButtonDisabled()}
              >
                {!loading && (
                  <span className="indicator-label">
                    {" "}
                    {button} {baseCurrency} {Number(total ? total : 0)}
                  </span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait {baseCurrency} {Number(total ? total : 0)}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>

            <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
              <thead className="bg-gray-200">
                <tr className="text-start text-dark-600 fw-bold fs-6  ">
                  <th className="min-w-100px p-5">Select</th>
                  <th className="min-w-100px p-5">Name</th>
                  <th className="min-w-100px p-5 text-nowrap">
                    Business Category
                  </th>
                  <th className="min-w-100px p-5 text-nowrap">
                    Account Number
                  </th>
                  <th className="min-w-100px p-5 text-nowrap">Amount</th>
                  <th className="min-w-100px p-5 text-nowrap">Fee</th>
                </tr>
              </thead>

              <tbody>
                {filteredData?.map((item, index) => (
                  <tr
                    key={index}
                    onMouseOver={(e) => {
                      const target = e.currentTarget as HTMLElement;
                      target.style.backgroundColor = "#f1f1f2";
                      // target.style.transform =
                      //   "translate3d(6px, -6px, 0)";
                      // target.style.boxShadow =
                      //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                    }}
                    onMouseOut={(e) => {
                      const target = e.currentTarget as HTMLElement;
                      target.style.backgroundColor = "white";
                      // target.style.transform = "none";
                      // target.style.boxShadow = "none";
                    }}
                  >
                    <td className="p-5 text-gray-600">
                      <div className="">
                        <div className="form-check form-check-custom">
                          <input
                            className="form-check-input border border-dark"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            checked={selectedData.some(data => data.customerName === item.customerName)}
                            onClick={() => handleSelectItem(index)}
                          />
                        </div>
                      </div>
                    </td>
                    <td className="p-5 text-gray-600">{item.customerName}</td>
                    <td className="p-5 text-gray-600">
                      {item.type === 1
                        ? "Customer"
                        : item.type === 2
                        ? "Vendor"
                        : "Both"}
                    </td>

                    <td className="p-5 text-gray-600">{item.bankAccountNo}</td>
                    <td className="p-5 text-gray-600">
                      <div className="form-group my-2">
                        <div>
                          {" "}
                          <span
                            className="fs-6 fw-bold text-gray-700"
                            style={{ position: "absolute", padding: "12px" }}
                          >
                            {baseCurrency}
                          </span>
                        </div>
                        <input
                          type="number"
                          onWheel={(e) => e.currentTarget.blur()}
                          inputMode="numeric"
                          className="form-control custom-input-style"
                          style={{ paddingLeft: "42px" }}
                          value={item.amount}
                          onChange={(e) =>
                            handleAmountChange(index, e.target.value)
                          }
                          disabled={
                            !selectedData.some(
                              (data) => data.customerName === item.customerName
                            )
                          }
                        />
                      </div>
                    </td>
                    <td className="p-5 text-gray-600 p-2">
                      <div className="form-group my-2">
                        <div>
                          {" "}
                          <span
                            className="fs-6 fw-bold text-gray-700"
                            style={{ position: "absolute", padding: "12px" }}
                          >
                            {baseCurrency}
                          </span>
                        </div>
                        <input
                          type="number"
                          onWheel={(e) => e.currentTarget.blur()}
                          className="form-control custom-input-style"
                          style={{ paddingLeft: "42px" }}
                          value={item.fee}
                          onChange={(e) =>
                            handleFeeChange(index, e.target.value)
                          }
                          disabled={
                            !selectedData.some(
                              (data) => data.customerName === item.customerName
                            )
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div
        className="modal fade"
        tabIndex={-1}
        id="approver_modal"
        ref={approverRef}
          
      >
        <div
          className="modal-dialog w-400px"
          style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-row justify-content-end">
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                   <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                </div>
              </div>
              <div className="d-flex flex-column align-items-center pb-5">
                <div className="label fs-3 mb-5 fw-bold">Choose Approver</div>

                <Formik
                  innerRef={appformikRef}
                  initialValues={{
                    approver: "",
                  }}
                  validationSchema={Yup.object({
                    approver: Yup.string().required("Select an approver"),
                  })}
                  onSubmit={(values, { resetForm }) => {
                    handleApproverCloseModal();
                    handlePayNow();
                  }}
                >
                  <Form className="w-100 px-5">
                    <div className="mb-5">
                      <Field name="approver">
                        {({
                          field,
                          form,
                        }: {
                          field: any;
                          form: FormikProps<any>;
                        }) => (
                          <>
                            <select
                              {...field}
                              className="form-select"
                              value={field.value}
                              onChange={(e) => {
                                form.setFieldValue("approver", e.target.value);
                                handleApprover(e);
                              }}
                              autoFocus={true}
                            >
                              <option value="" disabled>
                                Select Approver
                              </option>
                              {approvers.map((item, index) => (
                                <option key={index} value={item?.userGuid}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </>
                        )}
                      </Field>

                      <ErrorMessage
                        component="div"
                        name="approver"
                        className="text-danger"
                      />
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                      <button type="submit" className="btn rounded">
                        Continue
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        tabIndex={-1}
        id="OTP_modal"
        ref={OTPRef}
          
      >
        <div
          className="modal-dialog w-400px"
          style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-row justify-content-end">
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center pb-5">
                <div className="label fs-3 fw-bold">Enter Your PIN</div>

                <div className="label p-0">
                  <p className="lorem-ipsum-dolor mb-3 text-nowrap fs-5">
                    Enter your PIN to confirm
                  </p>
                </div>
                <Formik
                  innerRef={formikRef}
                  initialValues={{
                    pin: "",
                  }}
                  validationSchema={Yup.object({
                    pin: Yup.string()
                      .matches(/^[ A-Za-z0-9_@./#&+-]*$/, "Invalid characters")
                      .min(4, "Pin must be 4 digits")
                      .required("Pin is required"),
                  })}
                  onSubmit={(values, { resetForm }) => {
                    manualUpload();
                  }}
                >
                  <Form>
                    <div>
                      <Field name="pin">
                        {({
                          field,
                          meta,
                          form,
                        }: {
                          field: any;
                          meta: any;
                          form: FormikProps<any>;
                        }) => (
                          <div>
                            <OTPInput
                              // {...field}
                              value={otp.join("")}
                              onChange={(value: string) => {
                                value
                                  .split("")
                                  .forEach((char, index) =>
                                    handleChange(char, index)
                                  );
                              }}
                              numInputs={4}
                              renderSeparator={<span>&nbsp;&nbsp;</span>}
                              renderInput={(props, index) => (
                                <input
                                  {...props}
                                  value={otp[index]}
                                  ref={(el) => (inputRefs.current[index] = el)}
                                  onChange={(e) => {
                                    const pin = handleChange(
                                      e.target.value,
                                      index
                                    );
                                    form.setFieldValue("pin", pin);
                                    setErrMsg("");
                                  }}
                                  onKeyDown={(e) => {
                                    const pin = handleKeyDown(e, index);
                                    form.setFieldValue("pin", pin);
                                    setErrMsg("");
                                  }}
                                  type={show ? "password" : "text"}
                                  autoFocus={true}
                                  disabled={index !== activeInput}
                                  onFocus={() => setActiveInput(index)}
                                  className="text-center rounded mt-3 border border-1 border-dark mx-auto"
                                  style={{ height: "50px", width: "50px" }}
                                />
                              )}
                            />
                          </div>
                        )}
                      </Field>
                      <p
                        className="text-center cursor-pointer text-decoration-underline mb-0 mt-2"
                        onClick={() => setShow(!show)}
                      >
                        {show ? "Show PIN" : "Hide PIN"}
                      </p>
                      <ErrorMessage
                        name="pin"
                        component="div"
                        className="text-danger text-center"
                      />
                    </div>
                    <br />
                    {/* {errMsg && (
                      <p className="text-danger text-center">{errMsg}</p>
                    )} */}

                    <div className="d-flex align-items-center justify-content-center">
                      <button
                        type="submit"
                        className="btn  rounded"
                        style={{
                          width: "226px",
                        }}
                      >
                        {button === "Initiate" ? "Initiate" : "Submit"}
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
