import { useState, useEffect, useRef } from "react";
import { Carousel, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Statistics } from "../components/Statistics";
import { Link } from "react-router-dom";
import { Modal } from "bootstrap";
import { TransferModal } from "./Modal/Transfer";
import { EditModal } from "./Modal/Edit";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PinInput from "react-pin-input";

import Service from "../../../_metronic/assets/Icons/Service.svg";
import { useLocation } from "react-router-dom";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import OTPInput from "react-otp-input";
import Authorize from "../../modules/auth/components/Authorize";

export interface Account {
  currency: string;
  iconCode: string;
  goal: string;
  goalLimit: number;
  creditCondition: string;
  creditType: string;
  creditValue: number;
  creditFrequency: string;
  subAccountType: string;
  subAccountName: string;
  subAccountNumber: string;
  subAccountStatus: number;
  createdDate: string;
  balance: string;
  totalCredit: number;
  totalDebit: number;
}

export interface accDetails {
  accountNumber: string;
  referenceId: string;
  currency: string;
  primaryFundId: string;
  accountType: string;
  subAccounts: [
    {
      currency: string;
      iconCode: string;
      goal: string;
      goalLimit: number;
      creditCondition: string;
      creditType: string;
      creditValue: number;
      creditFrequency: string;
      subAccountType: string;
      subAccountName: string;
      subAccountNumber: string;
      subAccountStatus: number;
      balance: number;
      createdDate: string;
      totalCredit: number;
      totalDebit: number;
    }
  ];
}

interface subAccDetails {
  amount: number;
  currency: string;
  txnTypeName: string;
  dateTime: string;
  txnType: string;
}

export const apiCall = {
  submit: () => {},
};

export default function SubAccount() {
  const [showAllIcons, setShowAllIcons] = useState(false);
  const [transactions, setTransactions] = useState<subAccDetails[]>([]);
  const [shouldWrap, setShouldWrap] = useState(false);
  const [account, setAccount] = useState<Account | null>(null);
  const [stmntDuration, setStmntDuration] = useState("1");
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const [pinError, setPinError] = useState(false);
  const [txnPin, setTxnPin] = useState("");

  let ele = useRef<PinInput | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  let pindata: string;

  const location = useLocation();

  const passedData = (
    location.state as { subData: accDetails; AccNumber: Account }
  )?.subData;
  const accData = (
    location.state as { subData: accDetails; AccNumber: Account }
  )?.AccNumber;

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const [authority, setAuthority] = useState(0);

  useEffect(() => {
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      setAuthority(3);
    }
  }, []);

  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const inputRefs2 = useRef<(HTMLInputElement | null)[]>([]);
  const [pin, setPin] = useState("");
  const [action, setAction] = useState("");
  const [show, setShow] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    // Set focus on the active input whenever activeInput changes
    inputRefs.current[activeInput]?.focus();
    inputRefs2.current[activeInput]?.focus();
  }, [activeInput]);

  const handleChange = (value: string, index: number): string => {
    // Only allow positive numbers
    if (/^\d*$/.test(value) && pin.length < 4) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setPin(pin + value);

      if (value && index < otp.length - 1) {
        setActiveInput(index + 1);
      }
      return pin + value;
    }
    return pin;
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number): string => {
    if (e.key === "Backspace") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      } else if (index > 0) {
        setActiveInput(index - 1);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        setPin(pin.substring(0, pin.length - 1));
        return pin.substring(0, pin.length - 1);
      }
    }
    return pin;
  };

  const handlePaybillTxnPinClose = () => {
    setAction("");
    ele.current?.clear();
    setPinError(false);
    setTxnPin("");
    setPin("");
    setOtp(["", "", "", ""]);
    setError("");
    setActiveInput(0);
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);

      modalRef.current.addEventListener("shown.bs.modal", () => {
        ele?.current?.focus();
        inputRefs.current[activeInput]?.focus();
      });

      modalRef.current.addEventListener(
        "hidden.bs.modal",
        handlePaybillTxnPinClose
      );
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handlePaybillTxnPinClose
        );
        modal.dispose();
      };
    }
  }, []);

  const handlePay = () => {
    const modalElement = document.getElementById("txnPin_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleSelect = (selectedIndex: number, e: any) => {
    setSelectedIndex(selectedIndex);
    // You can access the selected index here and perform any desired actions
    // setTransactions(accounts[selectedIndex]?.transactions)
  };

  const handleDateRangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStmntDuration(e.target.value);
  };

  const handleEnterKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      if (pin.length === 4) {
        if (action === "pause") {
          if (account?.subAccountStatus === 10) {
            pauseSubAccount();
          } else {
            activateSubAccount();
          }
        } else {
          closeSubAccount();
        }
      } else {
        setPinError(true);
      }
    }
  };

  useEffect(() => {
    // setTransactions(accounts[selectedIndex]?.transactions);
  }, [handleSelect]);

  const tooltipAddMoney = (
    <Tooltip id="tooltip-add-money">
      {account?.subAccountStatus === 10 ? "Pause" : "Activate"}
    </Tooltip>
  );

  const tooltipArrowRight = (
    <Tooltip id="tooltip-arrow-right">Edit Rules</Tooltip>
  );

  const tooltipCount = <Tooltip id="tooltip-count">Add Money</Tooltip>;

  const tooltipThreeDots = <Tooltip id="tooltip-three-dots">Close</Tooltip>;

  const handlePause = () => {
    if (authority === 3) {
      Swal.fire({
        icon: "warning",

        html: `
    <p style="font-weight: bold; text-align: center; color: red;">
      You do not have access to this feature.
    </p>
  
    <p style="font-weight: bold; text-align: center; color: red;">
      Please contact admin.
    </p>
  `,
        allowEscapeKey: true,
        allowEnterKey: true,
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Pause",
        text: "Are you sure you want to pause this sub account ?",
        showCancelButton: true, // Show the cancel button
        confirmButtonColor: "#007bff", // Set the confirm button color to btn
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowEscapeKey: true,
        allowEnterKey: true,
        //
      }).then((response) => {
        if (response.isConfirmed) {
          handlePay();
          // pauseSubAccount();
        }
      });
    }
  };
  const handleActivate = () => {
    Swal.fire({
      icon: "warning",
      title: "Activate",
      text: "Are you sure you want to activate this sub account ?",
      showCancelButton: true, // Show the cancel button
      confirmButtonColor: "#007bff", // Set the confirm button color to btn
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowEscapeKey: true,
      allowEnterKey: true,
    }).then((response) => {
      if (response.isConfirmed) {
        handlePay();
      }
    });
  };
  const handleEdit = () => {
    const modalElement = document.getElementById("edit_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };
  const handleClose = () => {
    if (authority === 3) {
      Swal.fire({
        icon: "warning",

        html: `
    <p style="font-weight: bold; text-align: center; color: red;">
      You do not have access to this feature.
    </p>
  
    <p style="font-weight: bold; text-align: center; color: red;">
      Please contact admin.
    </p>
  `,
        allowEscapeKey: true,
        allowEnterKey: true,
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Confirmation",
        html: "Are you sure you want to close this Sub Account?<br><br><b>NOTE</b>: Your Sub Account balance will be moved to your main account.",
        showCancelButton: true, // Show the cancel button
        confirmButtonColor: "#007bff", // Set the confirm button color to btn
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowEscapeKey: true,
        allowEnterKey: true,
        //
      }).then((response) => {
        if (response.isConfirmed) {
          handlePay();
        }
      });
    }
  };
  const handleTransfer = () => {
    const modalElement = document.getElementById("transfer_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setShouldWrap(screenWidth < 11 * 96); // Convert inches to pixels (assuming 1 inch = 96 pixels)
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const SubAccountsDetails = async () => {
    try {
      const url = `${API_URL}/subAccount/getSubAccountDashboardData`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        accountNumber: passedData?.accountNumber,
        subAccountName: accData?.subAccountName,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setAccount(data.message);
      setTransactions(data.message.transactions);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    SubAccountsDetails();
  }, [passedData]);

  apiCall.submit = SubAccountsDetails;

  const pauseSubAccount = async () => {
    try {
      const url = `${API_URL}/subAccount/pauseSubAccount`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        accountNumber: passedData?.accountNumber,
        subAccountName: accData?.subAccountName,
        transactionPIN: txnPin,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.status.statusCode === 0) {
        handlePaybillTxnPinClose();
        Swal.fire({
          icon: "success",
          title: "Sub Account Paused !!",
          text: "You can activate it later by choosing the Activate button on dashboard",
          showCancelButton: false, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
        SubAccountsDetails();
      }
      if (data?.status.statusCode === 1) {
        setTxnPin("");
        setPin("");
        setOtp(["", "", "", ""]);
        setActiveInput(0);
        setError(data?.status?.messageDescription);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const activateSubAccount = async () => {
    try {
      const url = `${API_URL}/subAccount/activateSubAccount`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        accountNumber: passedData?.accountNumber,
        subAccountName: accData?.subAccountName,
        transactionPIN: txnPin,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.status.statusCode === 0) {
        handlePaybillTxnPinClose();
        Swal.fire({
          icon: "success",
          title: "Sub Account Activated !!",
          text: "You can pause it later by choosing the pause button on dashboard",
          showCancelButton: false, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
        SubAccountsDetails();
      }
      if (data.status.statusCode === 1) {
        setTxnPin("");
        setPin("");
        setOtp(["", "", "", ""]);
        setActiveInput(0);
        setError(data?.status?.messageDescription);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const closeSubAccount = async () => {
    try {
      const url = `${API_URL}/subAccount/closeSubAccount`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        accountNumber: passedData?.accountNumber,
        subAccountName: accData?.subAccountName,
        transactionPIN: txnPin,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.status.statusCode === 0) {
        handlePaybillTxnPinClose();
        Swal.fire({
          icon: "success",
          title: "Sad To See You Go",
          text: "Your Sub Account had been deleted. You will be redirected to the dashboard",
          showCancelButton: false, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      } else {
        setTxnPin("");
        setPin("");
        setOtp(["", "", "", ""]);
        setActiveInput(0);
        setError(data?.status?.messageDescription);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <h4 className="mb-5">Sub Account</h4>
      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card  d-flex flex-column flex-grow-1">
          <div
            className={`d-flex justify-content-between gap-5 ${
              window.innerWidth < 13 * 96 ? "flex-wrap" : "flex-nowrap"
            } `}
            style={{
              paddingTop: "30px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <div className="card shadow-sm d-flex flex-grow-1 p-3 bg-gray-300 w-800px">
              <div className="card-body p-10" style={{ marginLeft: "20px" }}>
                <div className="d-flex flex-column gap-5 ">
                  <div>
                    <p
                      className="p-0 m-0 text-dark mb-5"
                      style={{ fontWeight: "bold" }}
                    >
                      <span
                        className="text-dark-200"
                        style={{ fontWeight: "normal" }}
                      >
                        {account?.subAccountName} (Goal:{" "}
                        {account?.goal === "OPEN"
                          ? account?.goal
                          : `${account?.currency} ${account?.goalLimit}`}
                        )
                      </span>
                    </p>
                    <h4
                      className="p-0 m-0 text-dark mb-2"
                      style={{ fontSize: "35px" }}
                    >
                      <span style={{ fontSize: "20px", fontWeight: "normal" }}>
                        {account?.currency}
                      </span>{" "}
                      {account?.balance
                        .toString()
                        .split(".")[0]
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      <span
                        style={{
                          fontSize: "15px",
                          position: "absolute",
                          fontWeight: "normal",
                        }}
                      >
                        .
                        {(
                          (account?.balance.toString().split(".")[1] || "00") +
                          "00"
                        ).slice(0, 2)}
                      </span>
                    </h4>
                  </div>
                  <div className="d-flex gap-5">
                    <Authorize hasAnyPermission={["MENU_SUB_ACCOUNTS|EDIT"]}>
                      <OverlayTrigger placement="top" overlay={tooltipAddMoney}>
                        <button
                          className="bg-dark bg-hover-light text-hover-dark"
                          style={{
                            height: "30px",
                            width: "30px",
                            outline: "none",
                            borderRadius: "5px",
                            border: "0px",
                          }}
                          onClick={() => {
                            setAction("pause");
                            if (account?.subAccountStatus === 11) {
                              handleActivate();
                            } else {
                              handlePause();
                            }
                          }}
                        >
                          <span className="svg-icon svg-icon-1">
                            {account?.subAccountStatus === 11 && (
                              <i className="bi bi-play-fill text-secondary text-hover-dark"></i>
                            )}
                            {account?.subAccountStatus === 10 && (
                              <i className="bi bi-pause-fill text-secondary text-hover-dark"></i>
                            )}
                          </span>
                        </button>
                      </OverlayTrigger>
                    </Authorize>
                    <Authorize hasAnyPermission={["MENU_SUB_ACCOUNTS|EDIT"]}>
                      <OverlayTrigger
                        placement="top"
                        overlay={tooltipArrowRight}
                      >
                        <button
                          className="bg-dark bg-hover-light text-hover-dark"
                          style={{
                            ...{
                              height: "30px",
                              width: "30px",
                              outline: "none",
                              borderRadius: "5px",
                              border: "0px",
                            },
                            ...(account?.subAccountStatus === 11
                              ? { opacity: 0.5 }
                              : {}),
                          }}
                          onClick={() => {
                            setAction("edit");
                            handleEdit();
                          }}
                          disabled={account?.subAccountStatus === 11}
                        >
                          <span className="svg-icon svg-icon-1">
                            <i className="bi bi-pen-fill text-secondary text-hover-dark"></i>
                          </span>
                        </button>
                      </OverlayTrigger>
                    </Authorize>
                    <Authorize hasAnyPermission={["MENU_SUB_ACCOUNTS|ADD"]}>
                      <OverlayTrigger placement="top" overlay={tooltipCount}>
                        <button
                          className="bg-dark bg-hover-light text-hover-dark"
                          style={{
                            ...{
                              height: "30px",
                              width: "30px",
                              outline: "none",
                              borderRadius: "5px",
                              border: "0px",
                            },
                            ...(account?.subAccountStatus === 11
                              ? { opacity: 0.5 }
                              : {}),
                          }}
                          onClick={handleTransfer}
                          disabled={account?.subAccountStatus === 11}
                        >
                          <span className="svg-icon svg-icon-1 black-icon">
                            <i className="bi bi-cash text-secondary text-hover-dark"></i>
                          </span>
                        </button>
                      </OverlayTrigger>
                    </Authorize>
                    <Authorize hasAnyPermission={["MENU_SUB_ACCOUNTS|DELETE"]}>
                      <OverlayTrigger
                        placement="top"
                        overlay={tooltipThreeDots}
                      >
                        <button
                          className="bg-dark bg-hover-light text-hover-dark"
                          style={{
                            ...{
                              height: "30px",
                              width: "30px",
                              outline: "none",
                              borderRadius: "5px",
                              border: "0px",
                            },
                            ...(account?.subAccountStatus === 11
                              ? { opacity: 0.5 }
                              : {}),
                          }}
                          onClick={() => {
                            setAction("close");
                            handleClose();
                          }}
                          disabled={account?.subAccountStatus === 11}
                        >
                          <span className="svg-icon svg-icon-1">
                            <i className="bi bi-x-lg text-secondary text-hover-dark"></i>
                          </span>
                        </button>
                      </OverlayTrigger>
                    </Authorize>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="card shadow-sm d-flex  flex-grow-1 "
              style={{ width: "100%" }}
            >
              <div className="card-body p-5 flex-grow-1 p-0 m-0">
                <div className="d-flex justify-content-between mb-3 flex-grow-1">
                  <h4 className="p-0 m-0">Account Life Time</h4>
                </div>

                <div className="card shadow-sm flex-grow-1 ">
                  <div
                    className="card-body flex-grow-1 p-3"
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                  >
                    <div className="d-flex flex-row  justify-content-between">
                      <div className="d-flex flex-grow-1">
                        <div>
                          <h4 className="flex-grow-1 text-gray-800">
                            Total In
                          </h4>
                          {/* {/ <p className='text-nowrap'>Monthly Income 10%</p> /} */}
                        </div>
                      </div>
                      <div>
                        <h4 className="text-gray-800">
                          {account?.currency}{" "}
                          {account?.totalCredit.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </h4>
                      </div>
                    </div>

                    <div className="progress mt-2 mb-2">
                      <div
                        className="progress-bar bg-info"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuenow={86}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="card shadow-sm mt-5">
                  <div
                    className="card-body p-3"
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                  >
                    <div className="d-flex flex-row gap-10 justify-content-between">
                      <div className="d-flex  gap-5">
                        {/* {/ <div className=' d-flex align-items-center p-3' style={{ height: "35px", borderRadius: "10px", border: "1px solid grey", marginTop: "5px" }} ><i className="bi bi-currency-dollar"></i></div> /} */}
                        <div>
                          <h4 className="flex-grow-1 text-gray-800">
                            {" "}
                            Total Out
                          </h4>
                          {/* {/ <p>Monthly Income 5%</p> /} */}
                        </div>
                      </div>
                      <div>
                        <h4 className="text-gray-800">
                          {account?.currency}{" "}
                          {account?.totalDebit.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </h4>
                      </div>
                    </div>

                    <div className="progress mt-2 mb-2">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{
                          width: `${
                            ((account?.totalDebit?.valueOf() ?? 0) /
                              (account?.totalCredit?.valueOf() ?? 1)) *
                            100
                          }%`,
                        }}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="p-5">
            <div>
              <br />
              <div className="card shadow">
                <div className=" d-flex flex-row justify-content-between p-5">
                  <h5 className="m-0 p-0">Recent Transactions</h5>
                  <p
                    className=" m-0 cursor-pointer"
                    onClick={() => {
                      navigate("/subaccount-transactions", {
                        state: {
                          accountDetails: accData,
                          AccNumber: passedData,
                        },
                      });
                    }}
                    style={{ color: "#0000FF" }}
                  >
                    View more
                  </p>
                </div>
                <div className="p-5 d-flex flex-column gap-4">
                  {transactions?.slice(0, 10).map((transaction, index) => (
                    <div
                      className="card shadow p-0 d-flex flex-column gap-3 "
                      key={index}
                    >
                      <div className="card-body p-5 ">
                        <div className="d-flex justify-content-between">
                          <h5 className="card-title">
                            {transaction.txnTypeName}
                          </h5>
                          <h6
                            className={`card-text `}
                            style={{
                              color:
                                transaction.txnType === "CR"
                                  ? "#0000ff"
                                  : "#FF0000",
                            }}
                          >
                            {transaction.currency}{" "}
                            {transaction.amount.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </h6>
                        </div>
                        <div className="d-flex justify-content-between p-0">
                          <div className="d-flex p-0">
                            {/* <p className="text-muted p-0 m-0" style={{ fontSize: '12px' }}>
                              {new Date(transaction.dateTime).toLocaleDateString()}
                            </p>
                            <span className="text-muted">&nbsp;&#124;&nbsp;</span>
                            <p className="text-muted p-0 m-0" style={{ fontSize: '12px' }}>
                              {new Date(transaction.dateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
                            </p> */}
                            <p
                              className="text-muted p-0 m-0"
                              style={{ fontSize: "12px" }}
                            >
                              {transaction.dateTime}
                            </p>
                          </div>
                          <div className="d-flex gap-2">
                            <div>
                            <i
                                className={`bi ${
                                  transaction.txnType === "DR"
                                    ? "bi-arrow-up-square "
                                    : "bi-arrow-down-square "
                                }`}
                                style={{
                                  color:
                                    transaction.txnType === "CR"
                                      ? "#0000ff"
                                      : "#FF0000",
                                }}
                              ></i>
                            </div>

                            <p
                            className={`card-text`}
                            // style={{  }}
                            style={{
                              fontSize: "12px",
                              color:
                                transaction.txnType === "C"
                                  ? "#0000ff"
                                  : "#FF0000",
                            }}
                            >
                              {transaction.txnType === "DR"
                                ? "Debit"
                                : "Credit"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="card shadow-sm d-flex flex-grow-1"
          style={{ paddingTop: "15px", minWidth: "300px" }}
        >
          {/* <div>
            <div className="p-0 m-5 d-flex flex-column gap-10 ">
              <div className="card shadow d-flex justify-content-center align-items-center flex-grow-1">
                <img
                  src={Service}
                  alt=""
                  style={{ maxWidth: "300px", maxHeight: "220px" }}
                />
              </div>
              <div className="card shadow d-flex justify-content-center align-items-center flex-grow-1">
                <img
                  src={Offer}
                  alt=""
                  style={{ maxWidth: "300px", maxHeight: "220px" }}
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* PinInput Modal */}
      <div
        className="modal fade"
        tabIndex={-1}
        id="txnPin_modal"
        ref={modalRef}
          
      >
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          style={{ margin: "0 auto" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-row justify-content-between">
                <h1
                  className="modal-title text-dark"
                  style={{ paddingLeft: "100px" }}
                >
                  Enter Your PIN
                </h1>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                   <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center p-5">
                <div className="d-flex align-items-center justify-content-center">
                  <p className="text-center fs-5 mb-5">
                    Enter your PIN to confirm
                  </p>
                </div>
                <div className="d-flex justify-content-center flex-column mb-7">
                  <OTPInput
                    // {...field}
                    value={otp.join("")}
                    onChange={(value: string) => {
                      value
                        .split("")
                        .forEach((char, index) => handleChange(char, index));
                    }}
                    numInputs={4}
                    renderSeparator={<span>&nbsp;&nbsp;</span>}
                    renderInput={(props, index) => (
                      <input
                        {...props}
                        value={otp[index]}
                        ref={(el) => (inputRefs.current[index] = el)}
                        onChange={(e) => {
                          setError("");
                          const pin = handleChange(e.target.value, index);
                          if (pin.length === 4) {
                            setTxnPin(pin);
                          } else {
                            setTxnPin("");
                          }
                          setPinError(false);
                        }}
                        onKeyDown={(e) => {
                          setError("");
                          const pin = handleKeyDown(e, index);
                          handleEnterKeyPress(e);
                          setTxnPin("");
                        }}
                        type={show ? "password" : "text"}
                        autoFocus={true}
                        disabled={index !== activeInput}
                        onFocus={() => setActiveInput(index)}
                        className="text-center rounded mt-3 border border-1 border-dark"
                        style={{ height: "50px", width: "50px" }}
                      />
                    )}
                  />
                  <p
                    className="text-center text-decoration-underline mt-2 cursor-pointer mb-0"
                    onClick={() => setShow(!show)}
                  >
                    {show ? "Show PIN" : "Hide PIN"}
                  </p>
                  {error && (
                    <p className="text-center text-danger mt-3">{error}</p>
                  )}
                  {!txnPin && pinError && (
                    <p className="text-danger">Please enter Pin</p>
                  )}
                </div>

                <div className="d-flex gap-5">
                  <button
                    type="button"
                    className="btn rounded"
                    style={{
                      width: "150px",
                      color: "#ffff",
                    }}
                    onClick={() => {
                      if (pin.length === 4) {
                        if (action === "pause") {
                          if (account?.subAccountStatus === 10) {
                            pauseSubAccount();
                          } else {
                            activateSubAccount();
                          }
                        } else {
                          closeSubAccount();
                        }
                      } else {
                        setPinError(true);
                      }
                    }}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary rounded"
                    data-bs-dismiss="modal"
                    style={{
                      width: "150px",
                      color: "#246bfd",
                    }}
                    onClick={handlePaybillTxnPinClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <PauseModal passedData={passedData} accData={accData} /> */}
      <TransferModal
        passedData={passedData}
        accData={accData}
        accNumber={passedData?.subAccounts[0]?.subAccountNumber}
      />
      {/* <CloseModal passedData={passedData} accData={accData} /> */}
      <EditModal passedData={passedData} accData={accData} />
    </>
  );
}
