export interface IAppBasic {
  empName: string;
  appType: "Open" | "Set Limit" | "On Credit" | "Scheduled";
}
export interface IAppDate {
  date: string;
}
export interface IAppEmail {
  email: string;
}
export interface IAppPhone {
  phone: string;
}
export interface IAppAddress {
  address: string;
}
export interface IAppAddressDocId {
  addDocId: number;
}
export interface IAppAddressProof {
  addressProof: string;
}
export interface IAppAddressStatus {
  addDocStatus: number;
}
export interface IAppAddressDocExt {
  addDocExt: string;
}
export interface IAppAddressDocGuid {
  addDocGuid: string;
}
export interface IAppAddressRefId {
  addRefId: string;
}
export interface IAppBloodGroup {
  bloodGroup: string;
}
export interface IAppIdProof {
  idProof: string;
}
export interface IAppIdStatus {
  idDocStatus: number;
}
export interface IAppIdDocId {
  idproofDocId: number;
}
export interface IAppIdDocExt {
  idproofDocExt: string;
}
export interface IAppIdDocGuid {
  idproofDocGuid: string;
}
export interface IAppIdRefId {
  idproofRefId: string;
}
export interface IAppEmeContact {
  emeContact: string;
}
export interface IAppDoj {
  doj: string;
}
export interface IAppEmployeeID {
  employeeID: string;
}
export interface IAppDesignation {
  designation: string;
}
export interface IAppDepartment {
  department: string;
}
export interface IAppLocation {
  location: string;
}
export interface IAppAccNumber {
  accNumber: string;
}
export interface IAppAccName {
  accName: string;
}
export interface IAppAccIdentifier {
  accIdetifier: string;
}
export interface IAppAccIdentifier {
  accIdetifier: string;
}
export interface IAppMonthly {
  monthlySalary: string;
}
export interface IAppPensionDeductions {
  pensionDeductions: string;
}
export interface IAppTravelAllowance {
  travelAllowance: string;
}
export interface IAppMedicalDependents {
  medicalDependents: string;
}
export interface IAppOtherAllowance {
  otherAllowance: string;
}
export interface IAppAge {
  age: string;
}
export interface IAppAnnualBonus {
  annualBonus: string;
}

export interface IAppLimit {
  limit: string;
}

export interface ICreateAppData {
  appBasic: IAppBasic;
  empDate: IAppDate;
  empEmail: IAppEmail;
  empPhone: IAppPhone;
  empAddress: IAppAddress;
  empAddressDocId: IAppAddressDocId;
  empAddressProof: IAppAddressProof;
  addressDocStatus: IAppAddressStatus;
  empAddressDocExt: IAppAddressDocExt;
  empAddressDocGuid: IAppAddressDocGuid;
  empAddressRefId: IAppAddressRefId;
  empBloodGroup: IAppBloodGroup;
  appLimit: IAppLimit;
  idProof: IAppIdProof;
  idDocStatus: IAppIdStatus;
  empIDProofDocId: IAppIdDocId;
  empIDProofDocExt: IAppIdDocExt;
  empIDProofDocGuid: IAppIdDocGuid;
  empIDProofRefId: IAppIdRefId;
  emeContact: IAppEmeContact;
  doj: IAppDoj;
  employeeID: IAppEmployeeID;
  designation: IAppDesignation;
  department: IAppDepartment;
  location: IAppLocation;
  accNumber: IAppAccNumber;
  accName: IAppAccName;
  accIdentifier: IAppAccIdentifier;
  monthlySalary: IAppMonthly;
  pensionDeductions: IAppPensionDeductions;
  travelAllowance: IAppTravelAllowance;
  medicalDependents: IAppMedicalDependents;
  otherAllowance: IAppOtherAllowance;
  age: IAppAge;
  annualBonus: IAppAnnualBonus;
}

export const defaultCreateAppData: ICreateAppData = {
  appBasic: { empName: "", appType: "Open" },
  empDate: { date: "" },
  empEmail: { email: "" },
  empPhone: { phone: "" },
  appLimit: { limit: "" },
  empAddress: { address: "" },
  empAddressDocId: { addDocId: 0 },
  empAddressProof: { addressProof: "" },
  addressDocStatus: { addDocStatus: 1 },
  empAddressDocExt: { addDocExt: "" },
  empAddressDocGuid: { addDocGuid: "" },
  empAddressRefId: { addRefId: "" },
  empBloodGroup: { bloodGroup: "A+" },
  idProof: { idProof: "" },
  idDocStatus: { idDocStatus: 1 },
  empIDProofDocId: { idproofDocId: 0 },
  empIDProofDocExt: { idproofDocExt: "" },
  empIDProofDocGuid: { idproofDocGuid: "" },
  empIDProofRefId: { idproofRefId: "" },
  emeContact: { emeContact: "" },
  doj: { doj: "" },
  employeeID: { employeeID: "" },
  designation: { designation: "" },
  department: { department: "" },
  location: { location: "" },
  accNumber: { accNumber: "" },
  accName: { accName: "" },
  accIdentifier: { accIdetifier: "" },
  monthlySalary: { monthlySalary: "" },
  pensionDeductions: { pensionDeductions: "" },
  travelAllowance: { travelAllowance: "" },
  medicalDependents: { medicalDependents: "" },
  otherAllowance: { otherAllowance: "" },
  age: { age: "" },
  annualBonus: { annualBonus: "" },
};

export type StepProps = {
  data: ICreateAppData;
  updateData: (fieldsToUpdate: Partial<ICreateAppData>) => void;
  setAddressUpdate: (boolean: boolean) => void;
  setIdUpdate: (boolean: boolean) => void;
  hasError: boolean;
};