import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../core/Auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { docUpload, uploadSignature } from "./api";
import { useFormCompletion } from "./Stepper";
import Swal from "sweetalert2";

const Signature = () => {
  const [loading, setLoading] = useState(false);
  const { regUser } = useAuth();
  const [previewSrc, setPreviewSrc] = useState("");
  const [extension, setExtension] = useState("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const { markFormCompleted, resetCompletedForms } = useFormCompletion();

  const handlePreview = (documentPath: string, documentExtension: string) => {
    setPreviewSrc(documentPath);
    setExtension(documentExtension);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      signature: null,
    },
    validationSchema: regUser?.reapply
      ? null
      : Yup.object().shape({
          signature: Yup.mixed()
            .test("fileType", "Only images are allowed", (value) => {
              if (!value) return true;
              const file = value as File;
              const validExtensions = [
                ".jpg",
                ".jpeg",
                ".png",
                ".svg",
                ".gif",
                ".bmp",
                ".webp",
                ".tiff",
              ];
              const extension = file.name
                ? file.name.slice(file.name.lastIndexOf(".")).toLowerCase()
                : "";
              return validExtensions.includes(extension);
            })
            .required("Signature is required"),
        }),

        onSubmit: async (values, { setSubmitting, setFieldValue,resetForm }) => {
          setLoading(true);  // Start loading
          setSubmitting(true);  // Indicate form is being submitted
        
          try {
            const uploadSignaturePromise = uploadSignature(values?.signature, regUser?.token ?? "");
        
            const res = await uploadSignaturePromise;
        
            if (res?.data?.status?.statusCode === 0) {
              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
        
              markFormCompleted("signature");
        
              if (regUser?.noOfDirectors && Number(regUser.noOfDirectors) > 1) {
                navigate("/auth/secondary");
              } else {
                const result = await Swal.fire({
                  icon: "info",
                  title: "Application under review",
                  text: "Thank you for completing the application process. Your application is now under review. We usually take between 24 hours to 72 hours to revert with an update over SMS and Email.",
                  showCancelButton: false,
                  confirmButtonColor: "#007bff",
                  confirmButtonText: "Proceed",
                  allowEscapeKey: true,
                  allowEnterKey: true,
                });
        
                if (result.isConfirmed) navigate("/auth");
              }
        
              resetForm();
            } else {
              // Handle the case where statusCode is not 0
              setFieldValue("status", res?.data?.status?.messageDescription);
            }
          } catch (error) {
            console.error("Error:", error);
          } finally {
            setLoading(false);  // Stop loading
            setSubmitting(false);  // Indicate form submission is complete
          }
        }
        
  });
  //

  console.log(regUser);

  return (
    <div className="d-flex flex-column justify-content-center">
      <p className="fs-2 fw-bold text-center">Please upload your signature</p>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <div className="p-10">
          <div className="mb-10">
            <label className="form-label">
              Sign on a white paper with proper lighting and take a picture to
              upload.
            </label>
            <input
              type="file"
              ref={fileInputRef}
              className="form-control"
              onChange={(event) => {
                const file = event.currentTarget.files?.[0];
                formik.setFieldValue("signature", file); // Set file value in Formik
              }}
              accept="image/*"
            />
            {formik.touched.signature && formik.errors.signature && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.signature}</span>
                </div>
              </div>
            )}
            {regUser?.reapply &&
              !formik.values.signature &&
              regUser?.signatureDocument && (
                <div className="d-flex flex-end">
                  <div>
                    <img
                      src={regUser.signatureDocument.documentPath}
                      className="mt-2"
                      alt=""
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={() =>
                        handlePreview(
                          regUser?.signatureDocument?.documentPath,
                          regUser?.signatureDocument?.documentExtention
                        )
                      }
                      style={{ height: "40px", width: "40px" }}
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="p-10">
          <button type="submit" className="btn w-100 mt-15" disabled={formik.isSubmitting || loading}>
            {loading ? (
              <div className="spinner-border text-primary" role="status">
                <span className="" role="status" aria-hidden="true"></span>
              </div>
            ) : (
              <div>Proceed</div>
            )}
          </button>
        </div>
      </form>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        style={{ display: "none", marginLeft: "27%" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="">
            <div className="">
              {previewSrc && (
                <>
                  {extension === "pdf" ? (
                    <iframe
                      src={previewSrc + "#toolbar=0"}
                      title="PDF Preview"
                      style={{ width: "100%", height: "400px" }}
                    ></iframe>
                  ) : (
                    <img
                      src={previewSrc}
                      alt="Document Preview"
                      style={{
                        borderRadius: "10px",
                        maxHeight: "300px",
                        maxWidth: "300px",
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signature;
