import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  ChangeEvent,
} from "react";
import { Link, useParams } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import Avatar from "../../../../_metronic/assets/Icons/Ellipse.png";
import { Modal } from "bootstrap";
import { useFormik } from "formik";
import clsx from "clsx";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { PhoneNumberUtil } from "google-libphonenumber";
import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js";
import Webcam, { WebcamProps } from "react-webcam";
import XLogo from "../../../../_metronic/assets/Icons/xfin.png";
import { User } from "../../../pages/TeamBanking/TeamListing";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    console.log(error);
    return false;
  }
};

const img = {
  imgsrc: "",
};

const videoConstraints = {
  width: 220,
  height: 200,
  facingMode: "user",
};

type WebcamCaptureProps = {
  showModal: boolean;
  toggleModal: () => void;
  setProfile: (file: File) => void;
};

const base64ToBlob = (base64: string, mimeType: string) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
};

export const WebcamCapture: React.FC<WebcamCaptureProps> = ({
  showModal,
  toggleModal,
  setProfile,
}) => {
  const [image, setImage] = useState("");

  const webcamRef = useRef<Webcam | null>(null);

  const capture = useCallback(() => {
    if (webcamRef.current !== null) {
      const imageSrc = webcamRef.current.getScreenshot() || "";
      const base64Data = imageSrc.split(",")[1];
      const docExtension = imageSrc.split(",")[0]; // This extracts "data:image/jpeg;base64"
      const mimeType = docExtension.split(":")[1].split(";")[0];
      const format = mimeType.split("/")[1];
      const profile = {
        documentExtention: format,
        documentName: "profilePic",
        documentTypes: [2],
        documentUploadedByType: 1,
        documentStatus: 4,
        fileUploadType: 1,
        tenantId: "t1",
        referenceType: 2,
        referenceId: "t1",
        documentData: base64Data,
      };
      // profileData.documents.push(profile);

      const blob = base64ToBlob(base64Data, mimeType);
      const file = new File([blob], "profilePic." + format, { type: mimeType });
      setProfile(file);
      img.imgsrc = imageSrc;
      setImage(imageSrc);
    }
  }, []);

  return (
    <div className="webcam-container ">
      <div className="webcam-img d-flex justify-content-center align-items-center">
        {image === "" ? (
          <Webcam
            audio={false}
            height={500}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={750}
            videoConstraints={videoConstraints}
          />
        ) : (
          <img
            src={image}
            alt="Captured"
            style={{ width: "550px", height: "500px" }}
          />
        )}
      </div>

      <br />
      <div className="d-flex justify-content-center align-items-center">
        {image !== "" ? (
          <button
            type="button"
            className="btn"
            onClick={(e) => {
              e.preventDefault();

              setImage("");
            }}
          >
            Retake Image
          </button>
        ) : (
          <button
            onClick={(e) => {
              e.preventDefault();
              capture();
            }}
            type="button"
            className="btn"
          >
            Capture
          </button>
        )}
      </div>
    </div>
  );
};

export function InviteApplication() {
  const { "*": remainingPath } = useParams();
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const url = `${API_URL}/team/verify/${remainingPath}`;

  const navigate = useNavigate();

  const [user, setUser] = useState<User>({
    tenantId: "",
    businessGuid: "",
    email: "",
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    userStatus: 0,
    userGuid: "",
    limitType: 0,
    zarLimit: 0,
    usdLimit: 0,
    primaryUser: false,
    authority: [],
    roles: [],
    isdCode: 0,
    token: "",
    documents: [],
    idType: "",
    idNumber: "",
    mobileNumber: "",
    additionalProperties: {
      zarLimit: "0",
      authority: "",
      limitType: "",
      usdLimit: "0",
    },
    lastLogin: "",
    createdDate: "",
    createdBy: "",
    updatedDate: "",
    updatedBy: "",
    createdDateString: "",
  });

  useEffect(() => {
    // Example API call using the full path
    const fetchData = async () => {
      try {
        const body = JSON.stringify({});

        const options = {
          method: "POST",
          body,
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await fetch(url, options);
        const data = await response.json();
        // Handle the API response data
        console.log(data.message);
        if (data.status.statusCode === 0) {
          setUser(data.message);
          if (data.message.userStatus === 17)
            navigate("/new-password", {
              state: {
                userGuid: data.message?.userGuid,
                token: data.message?.token,
              },
            });
          if (data.message.userStatus === 18)
            navigate("/new-mpin", {
              state: {
                userGuid: data.message?.userGuid,
                token: data.message?.token,
              },
            });
          if (data.message.userStatus === 10) navigate("/auth");
        }
        if (data.status.statusCode === 1) {
          const msg = data.status.messageDescription;
          Swal.fire({
            icon: "warning",
            title: "Error",
            text: msg,
            allowEscapeKey: true,
            allowEnterKey: true,
            preConfirm(inputValue) {
              navigate("/auth");
            },
          });
        }
      } catch (error) {
        // Handle any errors
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [url]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState("");
  const [camOpen, setCamOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [verified, setVerified] = useState(false);
  const [isVerified, setIsVerified] = useState(true);
  const [otp, setOtp] = useState("");
  const [profile, setProfile] = useState<File | null>(null);
  const [idDoc, setIdDoc] = useState<File | null>(null);
  const [fileName, setFileName] = useState("");
  const [err, setError] = useState("");
  const [phoneErr, setPhoneError] = useState("");
  const [hasError, setHasError] = useState(false);

  const handleClick = () => {
    const modalElement = document.getElementById("cam_modal_1");
    const modal = new Modal(modalElement as Element);
    modal.show();
    setCamOpen(true);
  };

  useEffect(() => {
    if (camOpen) {
      toggleModal();
    }
  }, [camOpen]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    formik?.setFieldValue("profileImage", img.imgsrc);
    setHasError(false);
  }, [img.imgsrc, profile]);

  const handlePhoneChange = (value: string) => {
    try {
      const phoneNumber = parsePhoneNumberFromString(value);
      if (phoneNumber) {
        formik.setFieldValue("isdCode", `+${phoneNumber.countryCallingCode}`);
        formik.setFieldValue("phone", phoneNumber.nationalNumber);
      } else {
        formik.setFieldValue("isdCode", "");
        formik.setFieldValue("phone", "");
      }

      setIsVerified(true);
      setVerified(false);
      setOtp("");
      setPhoneError("");
      setError("");
    } catch (error) {
      setPhoneError("Invalid phone number");
      formik.setFieldValue("isdCode", "");
      formik.setFieldValue("phone", "");
    }
  };

  const handleCloseModal = () => {
    const modalElement = document.getElementById("cam_modal_1");
    const modal = Modal.getInstance(modalElement as Element);
    modal?.hide();
    setCamOpen(false);
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleOtpChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): string => {
    const value = event.target.value;

    // Validate input using regular expression
    if (/^\d{0,6}$/.test(value)) {
      setOtp(value);
      return value;
    } else {
      return otp;
    }
  };

  const validationSchema = Yup.object({
    profileImage: Yup.string().required("Profile image is required"),
    gender: Yup.string().required("Gender is required"),
    firstName: Yup.string()
    .min(2, "Minimum 2 characters required")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^(?=.*[a-zA-Z])[\sa-zA-Z0-9]*$/, 'Must contain at least one alphabetic character')
    .matches(/^[\sa-zA-Z0-9]*$/, 'Only alphanumeric characters and spaces are allowed')
    .test('no-only-spaces', 'Cannot contain only spaces', (value) => {
      if (typeof value === 'string') {
        return !/^\s+$/.test(value);
      }
      return false;
    })
    .trim()
  .required("Name is required"),
    email: Yup.string().max(70, "Maximum 70 characters allowed").required("Email is required").matches(/^[A-za-z0-9.@]+$/, "Only letters (a-z & A-Z), digits (0-9), and periods (.) are allowed"),
    dob: Yup.string().required("DOB is required"),
    uniqueId: Yup.string().max(6, "Maximum 6 characters allowed").required("Id is required"),
    idDocument: Yup.mixed().required("ID document is required"),
    phone: Yup.string().required("Phone number is required"),
  });

  const formData = new FormData();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      profileImage: "",
      gender: "",
      firstName: user?.firstName,
      email: user?.email,
      isdCode: "",
      phone: "",
      dob: "",
      idDocument: null,
      uniqueId: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      formData.append("firstName", values.firstName);
      formData.append("gender", values.gender);
      formData.append("isdCode", values.isdCode.replace("+", ""));
      formData.append("mobile", values.phone.replace("-", ""));
      formData.append("dob", values.dob);
      formData.append("email", values.email);
      formData.append("uniqueId", values.uniqueId);
      if (profile) {
        formData.append("profileImage", profile);
      }
      if (idDoc) {
        formData.append("idDocument", idDoc);
      }
      formData.append("userGuid", user?.userGuid);
      formData.append("tenantId", user?.tenantId);

      const url = `${API_URL}/team/updateUser`;
      const options = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        if (result.status.statusCode === 0) {
          navigate("/new-password", {
            state: {
              userGuid: result?.message?.userGuid,
              token: result?.message?.token,
            },
          });
        }
        if (result.status.statusCode === 1) {
          const msg = result.status.messageDescription;
          Swal.fire({
            icon: "warning",
            title: "Error",
            text: msg,
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        }
        console.log("Form submitted successfully:", result);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setIdDoc(file);
      setFileName(file.name);
      formik.setFieldValue("idDocument", file);

      const reader = new FileReader();
      reader.onload = () => {
        const dataURL = reader.result as string;
        const base64Data = dataURL.split(",")[1];

        setImage(dataURL);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveFile = () => {
    setIdDoc(null);
    setFileName("");
    formik.setFieldValue("idDocument", null);
    setImage("");
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the input value
    }
  };

  const verifyMobile = async (isdCode: string, mobile: string) => {
    const url = `${API_URL}/team/checkMobileAvailability`;
    try {
      const body = JSON.stringify({
        mobile: mobile,
        isdCode: isdCode,
        otpType: "SIGNUP",
        tenantId: user.tenantId,
      });

      const options = {
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(url, options);
      const data = await response.json();
      // Handle the API response data
      
      if (data.status.statusCode === 0) {

        toast(`Otp sent to ${formik.values.isdCode}-
          ${formik.values.phone}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          })
        setError("");
        setOtp('')
        setPhoneError("");
        setIsVerified(false);
      }
      if (data.status.statusCode === 1) {
        setPhoneError(data.status.messageDescription);
      }
    } catch (error) {
      // Handle any errors
      console.error("Error fetching data:", error);
    }
  };

  const verifyOtp = async (isdCode: string, mobile: string, otp: string) => {
    const url = `${API_URL}/team/verifyOtp`;
    try {
      const body = JSON.stringify({
        mobile: mobile,
        isdCode: isdCode,
        otp: otp,
        xfinRefId: user.userGuid,
        otpType: "SIGNUP",
        tenantId: user.tenantId,
      });

      const options = {
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.status.statusCode === 0) {
        // setUser(data.message);
        setVerified(true);
        // setIsVerified(false);
      }
      if (data.status.statusCode === 1) {
        setError(data.status.messageDescription);
      }
    } catch (error) {
      // Handle any errors
      console.error("Error fetching data:", error);
    }
  };

  const otpClassNames = clsx("form-control bg-transparent", getClassNames(err));

  function getClassNames(err: string) {
    return {
      "is-invalid": err && !verified,
      "is-valid": !err && verified,
    };
  }

  return (
    <>
      <style>
        {`
        .responsive-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
}

.responsive-card {
  width: 100%;
  max-width: 600px;
  height: auto;
  min-height: 300px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .responsive-card {
    padding: 1rem;
    min-height: 200px;
  }
}
        `}
      </style>
      <div className="responsive-container">
        <div className="responsive-card">
          <img alt="Logo" src={XLogo} className="h-50px mb-4" />
          <div className="w-75">
            <div style={{ textAlign: "center" }}>
              <h1>Complete Your Profile</h1>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center ">
              <div className="image-container d-flex  ">
                {profile ? (
                  <img
                    src={img.imgsrc}
                    alt="Your Image"
                    style={{
                      borderRadius: "50%",
                      height: "150px",
                      width: "150px",
                    }}
                    className="image"
                  />
                ) : (
                  <img
                    src={Avatar}
                    alt="Your Image"
                    style={{
                      borderRadius: "50%",
                      height: "150px",
                      width: "150px",
                    }}
                    className="image"
                  />
                )}
                <div
                  className="edit-icon  w-20px h-20px d-flex justify-content-center align-items-center p-4"
                  onClick={handleClick}
                  style={{
                    position: "relative",
                    top: "120px",
                    right: "40px",
                    backgroundColor: "#246BFD",
                    borderRadius: "5px",
                  }}
                >
                  <i className="fa fa-pencil text-light" aria-hidden="true"></i>
                </div>

                <div
                  className={`modal fade ${showModal ? "show" : ""}`}
                  tabIndex={-1}
                  id="cam_modal_1"
                  style={{ display: showModal ? "block" : "none" }}
                >
                  <div
                    className="modal-dialog d-flex justify-content-center align-items-center"
                    style={{ maxWidth: "800px" }}
                  >
                    <div className="modal-content" style={{ flexGrow: "1" }}>
                      <div className="modal-header">
                        <h5 className="modal-title">Web Cam</h5>
                        <button
                          type="button"
                          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={handleCloseModal}
                        >
                          <i className="ki-duotone ki-cross fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </button>
                      </div>
                      <div className="modal-body">
                        {camOpen && (
                          <WebcamCapture
                            showModal={showModal}
                            toggleModal={toggleModal}
                            setProfile={setProfile}
                          />
                        )}
                      </div>
                      <div className="modal-footer">
                        {/* <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={handleCloseModal}>
                        Close
                      </button> */}
                        <button
                          type="button"
                          className="btn"
                          data-bs-dismiss="modal"
                          onClick={handleCloseModal}
                        >
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {hasError && formik.errors.profileImage && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.profileImage}</span>
                  </div>
                </div>
              )}
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div
                className="mt-5 py-2 mb-5"
                style={{
                  overflow: "auto",
                  maxHeight: "280px",
                }}
              >
                <div className="d-flex mb-5 justify-content-center flex-column">
                  <div className="d-flex flex-row gap-10 justify-content-center">
                    <div className="d-flex gap-2">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="gender"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value="Male"
                        checked={formik.values.gender === "Male"}
                      />
                      <label htmlFor="gender">Male</label>
                    </div>
                    <div className="d-flex gap-2">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="gender"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value="Female"
                        checked={formik.values.gender === "Female"}
                      />
                      <label htmlFor="gender">Female</label>
                    </div>
                  </div>
                  {formik.touched.gender && formik.errors.gender && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <p role="alert" className="text-center text-danger">
                          {formik.errors.gender}
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="mb-5">
                <label className="required fw-bold fs-6 mb-2">
                          Enter name
                        </label>
                  <input
                    {...formik.getFieldProps("name")}
                    type="text"
                    className={clsx(
                      "form-control bg-transparent",
                      {
                        "is-invalid":
                          formik.touched.firstName && formik.errors.firstName,
                      },
                      {
                        "is-valid":
                          formik.touched.firstName && !formik.errors.firstName,
                      }
                    )}
                    placeholder="Full Name"
                    name="firstName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    maxLength={50}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.firstName}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="mb-5">
                <label className="required fw-bold fs-6 mb-2">
                          Enter email
                        </label>
                  <input
                    {...formik.getFieldProps("email")}
                    type="email"
                    className={clsx(
                      "form-control bg-transparent",
                      {
                        "is-invalid":
                          formik.touched.email && formik.errors.email,
                      },
                      {
                        "is-valid":
                          formik.touched.email && !formik.errors.email,
                      }
                    )}
                    placeholder="Enter email"
                    name="email"
                    value={formik.values.email}
                    readOnly
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.email}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="mb-5">
                <label className="required fw-bold fs-6 mb-2">
                          Enter phone number
                        </label>
                  <PhoneInput
                    // {...formik.getFieldProps("phone")}
                    inputClassName={"form-control bg-transparent"}
                    // value={image}
                    inputStyle={{
                      padding: "21px",
                      paddingLeft: "10px",
                      width: "100%",
                      borderColor:
                        formik.touched.phone && formik.errors.phone
                          ? "red"
                          : "",
                    }}
                    countrySelectorStyleProps={{
                      buttonStyle: {
                        padding: "21px 5px",
                      },
                    }}
                    forceDialCode
                    onChange={(e) => {
                      handlePhoneChange(e);
                    }}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.phone}</span>
                      </div>
                    </div>
                  )}
                  {phoneErr ? <p className="text-danger">{phoneErr}</p> : ""}
                </div>

                {isVerified ? (
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-sm fs-5 px-10 text-nowrap"
                      type="button"
                      style={{
                        height: "44px",
                        maxHeight: "44px",
                      }}
                      onClick={() => {
                        if (
                          isPhoneValid(
                            formik.values.isdCode + " " + formik.values.phone
                          )
                        ) {
                          const isdCode = formik.values.isdCode;
                          const phone = formik.values.phone;
                          verifyMobile(isdCode, phone);
                        } else {
                          setPhoneError("Enter a valid number");
                          console.log(
                            formik.values.isdCode + " " + formik.values.phone
                          );
                        }
                      }}
                      // disabled={profileData.documents.length === 0}
                    >
                      Get OTP
                    </button>
                  </div>
                ) : (
                  <>
                  <label className="required fw-bold fs-6 mb-2">
                          Enter OTP
                        </label>
                    <div className="position-relative d-flex align-item-center border border-1 rounded border-secondary mb-5">
                      <input
                        type="text"
                        className={otpClassNames}
                        placeholder="Enter OTP"
                        value={otp}
                        style={{ border: "none" }}
                        onChange={(e) => {
                          setError("");
                          if (handleOtpChange(e).length === 6) {
                            const isdCode = formik.values.isdCode;
                            const phone = formik.values.phone;
                            verifyOtp(isdCode, phone, handleOtpChange(e));
                          }
                        }}
                        disabled={verified}
                      />
                      <p
                        className="m-0 fs-7 text-gray-600 text-nowrap p-3 cursor-pointer text-hover-primary"
                        onClick={() => {
                            verifyMobile(
                              formik.values.isdCode,
                              formik.values.phone
                            );
                        }}
                      >
                        Resend OTP
                      </p>
                    </div>
                    {err ? <p className="text-danger">{err}</p> : ""}
                  </>
                )}
                {verified ? (
                  <>
                    {" "}
                    <div className="mb-5">
                    <label className="required fw-bold fs-6 mb-2">
                          Select DOB
                        </label>
                      <input
                        {...formik.getFieldProps("dob")}
                        type="date"
                        name="dob"
                        placeholder="Enter DOB"
                        max={
                          new Date(
                            new Date().getFullYear() - 15,
                            new Date().getMonth(),
                            new Date().getDate()
                          )
                            .toISOString()
                            .split("T")[0]
                        }
                        min={
                          new Date(
                            new Date().getFullYear() - 100,
                            new Date().getMonth(),
                            new Date().getDate()
                          )
                            .toISOString()
                            .split("T")[0]
                        }
                        className={clsx(
                          "form-control bg-transparent",
                          {
                            "is-invalid":
                              formik.touched.dob && formik.errors.dob,
                          },
                          {
                            "is-valid":
                              formik.touched.dob && !formik.errors.dob,
                          }
                        )}
                        onChange={(e)=>{
                          const selectedDate = new Date(e.target.value);
                          if (
                            selectedDate >=
                              new Date(
                                new Date().getFullYear() - 100,
                                new Date().getMonth(),
                                new Date().getDate()
                              ) &&
                            selectedDate <=
                              new Date(
                                new Date().getFullYear() - 15,
                                new Date().getMonth(),
                                new Date().getDate()
                              )
                          ) {formik.handleChange(e)}}}
                        
                      />
                      {formik.touched.dob && formik.errors.dob && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.dob}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mb-5">
                    <label className="required fw-bold fs-6 mb-2">
                          Upload ID
                        </label>
                      <div className="position-relative form-control p-3 d-flex">
                      
                        {fileName ? (
                          <div className="d-flex">
                            <p className="m-0 fs-7">{fileName}</p>
                            <i
                              className="bi bi-x fs-3 ms-2 cursor-pointer"
                              onClick={handleRemoveFile}
                            ></i>
                          </div>
                        ) : (
                          <span className="text-gray-500 ms-2">Upload ID</span>
                        )}

                        <input
                          // {...formik.getFieldProps("idDocument")}
                          name="idDocument"
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          accept=".jpeg,.jpg,.png,.pdf,.txt,.docx"
                          onChange={(e) => {
                            handleFileChange(e);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {fileName ? null : (
                          <div
                            className="position-absolute translate-middle-y  end-0 me-3 "
                            style={{ marginTop: "11px" }}
                          >
                            <i
                              className="bi bi-cloud-arrow-up-fill fs-2 cursor-pointer"
                              id="uploadIcon"
                              onClick={handleUploadClick}
                            ></i>
                          </div>
                        )}
                      </div>
                      <p className="mb-0 fs-7">Only .jpeg,.jpg,.png,.pdf,.txt,.docx files are accepted</p>
                      {formik.touched.idDocument && formik.errors.idDocument && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.idDocument}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mb-5">
                    <label className="required fw-bold fs-6 mb-2">
                          Enter unique ID
                        </label>
                      <input
                        {...formik.getFieldProps("uniqueId")}
                        type="text"
                        className={clsx(
                          "form-control bg-transparent",
                          {
                            "is-invalid":
                              formik.touched.uniqueId && formik.errors.uniqueId,
                          },
                          {
                            "is-valid":
                              formik.touched.uniqueId &&
                              !formik.errors.uniqueId,
                          }
                        )}
                        placeholder="Enter Unique ID"
                        name="uniqueId"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.uniqueId}
                        maxLength={6}
                      />
                      {formik.touched.uniqueId && formik.errors.uniqueId && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.uniqueId}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : null}
              </div>
              {verified ? (
                <div className="d-flex flex-row justify-content-center">
                  <button
                    className="btn border-0 fs-3 rounded w-100"
                    type="submit"
                    style={{
                      color: "#ffff",
                      backgroundColor: "#246BFD",
                      fontWeight: "bolder",
                    }}
                    onClick={() => {
                      if (!profile) setHasError(true);
                    }}
                    // disabled={profileData.documents.length === 0}
                  >
                    Continue{" "}
                    {/* <KTIcon iconName="arrow-right" className="fs-3 ms-1 me-0" /> */}
                  </button>

                  {/* <Link to="javascript:void(0)">
                    <button
                      onClick={() => window.history.back()}
                      className="p-5 border-0 fs-4"
                      style={{
                        borderRadius: "50px",
                        color: "#246BFD",
                        backgroundColor: "#E9F0FF",
                        width: "180px",
                        fontWeight: "bolder",
                      }}
                    >
                      Back
                    </button>
                  </Link> */}
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
      <ToastContainer limit={1}/>
    </>
  );
}
