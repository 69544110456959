import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../core/Auth";
import { secondaryApplicant } from "./api";
import { useNavigate } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
import { PhoneInputs } from "../PhoneInput";
import Swal from "sweetalert2";
import { log } from "console";
import { useFormCompletion } from "./Stepper";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { isPhoneValid } from "../Login";

const SecondaryApplicant = () => {
  const deviceId = uuidv4();
  const [previewSrc, setPreviewSrc] = useState("");
  const [extension, setExtension] = useState("");
  const { regUser, setRegUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [formIndex, setFormIndex] = useState(1);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { resetCompletedForms } = useFormCompletion();

  const navigate = useNavigate();

  const maxSize = 3 * 1024 * 1024;

  const validationSchema = Yup.object({
    firstName: Yup.string() .matches(/^[a-zA-Z0-9 ]+$/, "Only letters and numbers are allowed").required("First name required"),
    lastName: Yup.string() .matches(/^[a-zA-Z0-9 ]+$/, "Only letters and numbers are allowed").required("Last name required"),
    dateOfBirth: Yup.date().required("Date of birth required"),
    emailId: Yup.string()
      .required("Email ID required")
      .email("Wrong email format")
      .min(8, "Minimum 8 characters")
      .max(50, "Maximum 50 characters")
      .matches(/^[a-z0-9.@]+$/, "Only letters (a-z), digits (0-9), and periods (.) are allowed"),
    idNumber: Yup.string() .matches(/^[a-zA-Z0-9 ]+$/, "Only letters and numbers are allowed").required("ID number required"),
    nationality: Yup.string().required("Nationality is required"),
    idProofType: Yup.string().required("Id proof type required"),
    secondaryMobileNumber: Yup.string()
      .required("Phone number is required")
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number cannot be longer than 15 digits")
      .test("is-valid-phone", "Phone number is invalid", function (value) {
        if ( value) {
          return isPhoneValid(value); // Validate phone only if Phone is true and value exists
        }
        return true; // If Phone is false, or no value, bypass the phone validation
      }),
    // .matches(/^[0-9]+$/, 'Phone number must only contain digits'),
    file: Yup.mixed()
    .nullable()
    .test("fileType", "Upload PDF or images", function (value) {
      const reapply = regUser?.reapply;
      if (reapply) {
        return true;
      }
      if (!value) return true; // Allow null or undefined

      const file = value as File;
      const validExtensions = [
        ".pdf",
        ".jpg",
        ".jpeg",
        ".png",
        ".svg",
        ".gif",
        ".bmp",
        ".webp",
        ".tiff",
      ];
      const extension = file.name
        ? file.name.slice(file.name.lastIndexOf(".")).toLowerCase()
        : "";
      return validExtensions.includes(extension);
    })
    .test("fileSize", "File size must be less than or equal to 3 MB", function (value) {
      const reapply = regUser?.reapply;
      if (reapply) {
        return true;
      }
      if (!value) return true; // Allow null or undefined

      const file = value as File;
      return file.size <= maxSize;
    }),
  });

  const handlePreview = (documentPath: string, documentExtension: string) => {
    setPreviewSrc(documentPath);
    setExtension(documentExtension);
  };

  const formik = useFormik({
    initialValues: {
      firstName: regUser?.secondaryDirectors?.[formIndex - 1]?.firstName || "",
      lastName: regUser?.secondaryDirectors?.[formIndex - 1]?.lastName || "",
      dateOfBirth:
        regUser?.secondaryDirectors?.[formIndex - 1]?.dob?.split("T")[0] || "",
      emailId: regUser?.secondaryDirectors?.[formIndex - 1]?.email || "",
      secondaryIsdCode:
        regUser?.secondaryDirectors?.[formIndex - 1]?.isdCode || "",
      secondaryMobileNumber:
        (regUser?.secondaryDirectors?.[formIndex - 1]?.isdCode
          ? regUser.secondaryDirectors[formIndex - 1].isdCode.toString()
          : "") +
        (regUser?.secondaryDirectors?.[formIndex - 1]?.mobileNumber
          ? regUser.secondaryDirectors[formIndex - 1].mobileNumber
          : ""),
      orderId: formIndex + 1,
      nationality:
        regUser?.secondaryDirectors?.[formIndex - 1]?.nationality || "",
      idProofType: regUser?.secondaryDirectors?.[formIndex - 1]?.idType || "",
      file: null,
      idNumber: regUser?.secondaryDirectors?.[formIndex - 1]?.idNumber || "",
      status: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values, { setFieldValue,setSubmitting, resetForm }) => {
      setLoading(true);
      setSubmitting(true);  // Start form submission
    
      try {
        const res = await secondaryApplicant(
          values?.file,
          values?.firstName,
          values?.lastName,
          values?.dateOfBirth,
          values?.emailId,
          values?.nationality,
          values?.idProofType,
          values?.idNumber,
          regUser?.token ?? "",
          values?.secondaryMobileNumber.split(" ")[0],
          values?.secondaryMobileNumber.split(" ")[1].replace(/[^0-9]/g, ""),
          values?.orderId,
          regUser?.reapply ?? false
        );
    
        if (res?.data?.status?.statusCode === 0) {
          setRegUser((prevState) => ({
            ...prevState,
            ...res?.data?.message,
          }));
          
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
    
          if (formIndex + 1 < Number(regUser?.noOfDirectors)) {
            setFormIndex(formIndex + 1);
          } else {
            resetCompletedForms();
            Swal.fire({
              icon: "info",
              title: "Application under review",
              text: "Thank you for completing the application process. Your application is now under review. We usually take between 24 hours to 72 hours to revert with an update over SMS and Email.",
              showCancelButton: false,
              confirmButtonColor: "#007bff",
              confirmButtonText: "Proceed",
              allowEscapeKey: true,
              allowEnterKey: true,
            }).then((result) => {
              if (result.isConfirmed) navigate("/auth");
            });
    
            navigate("/auth");
          }
    
          resetForm();  // Reset the form only after all logic is executed
        } else {
          setFieldValue("status", res?.data?.status?.messageDescription);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);  // Stop loading
        setSubmitting(false);  // Stop form submission
      }
    }
    
  });

  return (
    <div
      className="card shadow-sm p-5 "
      style={{ maxHeight: "600px", overflow: "auto" }}
    >
      <p className="fs-2 fw-bold text-center">
        Please enter the secondary applicant’s details. (Director{" "}
        {formIndex + 1})
      </p>

      <div className="p-10">
        {formik?.values?.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              {formik?.values?.status}
            </div>
          </div>
        )}
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-5">
            <label className="form-label">First Name</label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.firstName && formik.errors.firstName
                  ? "is-invalid"
                  : ""
              }`}
              placeholder="First Name"
              id="firstName"
              name="firstName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              maxLength={50}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="invalid-feedback">{formik.errors.firstName}</div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">Last Name</label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.lastName && formik.errors.lastName
                  ? "is-invalid"
                  : ""
              }`}
              placeholder="Last Name"
              id="lastName"
              name="lastName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              maxLength={50}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="invalid-feedback">{formik.errors.lastName}</div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">Date Of Birth</label>
            <input
              type="date"
              className={`form-control ${
                formik.touched.dateOfBirth && formik.errors.dateOfBirth
                  ? "is-invalid"
                  : ""
              }`}
              placeholder="Date Of Birth"
              id="dateOfBirth"
              name="dateOfBirth"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dateOfBirth}
              max={new Date(new Date().setFullYear(new Date().getFullYear() - 15)).toISOString().split("T")[0]}
            />
            {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
              <div className="invalid-feedback">
                {formik.errors.dateOfBirth}
              </div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">
              Email Id (To be used as registered ID)
            </label>
            <input
              type="email"
              className={`form-control ${
                formik.touched.emailId && formik.errors.emailId
                  ? "is-invalid"
                  : ""
              }`}
              placeholder="Email Id"
              id="emailId"
              name="emailId"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.emailId}
              maxLength={50}
            />
            {formik.touched.emailId && formik.errors.emailId ? (
              <div className="invalid-feedback">{formik.errors.emailId}</div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">Mobile</label>
            {/* <PhoneInputs value={(formik.values?.secondaryMobileNumber)} onChange={(value) => { formik.setFieldValue("secondaryIsdCode", value?.countryData?.dialCode); formik.setFieldValue("secondaryMobileNumber", value?.phoneNumber) }} /> */}
            <PhoneInput
              inputStyle={{
                width: "100%",
                height: "43.59px",
              }}
              countrySelectorStyleProps={{
                buttonStyle: {
                  height: "43.59px",
                },
              }}
              // hideDropdown={true}
              // inputProps={{
              //   readOnly: true,
              // }}
              forceDialCode={true}
              value={formik.values.secondaryMobileNumber}
              onChange={(value) => {
                formik.setFieldValue("secondaryMobileNumber", value);
              }}
              defaultCountry="in"
            />
            {formik.touched.secondaryMobileNumber &&
              formik.errors.secondaryMobileNumber && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">
                      {formik.errors.secondaryMobileNumber}
                    </span>
                  </div>
                </div>
              )}
          </div>
          <div className="mb-5">
            <label className="form-label">Nationality</label>
            <div>
              <select
                className={`form-select ${
                  formik.touched.nationality && formik.errors.nationality
                    ? "is-invalid"
                    : ""
                }`}
                name="nationality"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nationality}
                aria-label="Select example"
              >
                <option value="">Select</option>
                <option value="India">India</option>
                <option value="South Africa">South Africa</option>
                <option value="Uganda">Uganda</option>
              </select>
              {formik.touched.nationality && formik.errors.nationality ? (
                <div className="invalid-feedback">
                  {formik.errors.nationality}
                </div>
              ) : null}
            </div>
          </div>
          <div className="mb-5">
            <label className="form-label">Id Proof Type</label>
            <div>
              <select
                className={`form-select ${
                  formik.touched.idProofType && formik.errors.idProofType
                    ? "is-invalid"
                    : ""
                }`}
                name="idProofType"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.idProofType}
                aria-label="Select example"
              >
                <option value="">Select</option>
                <option value="NATIONAL_ID">NID</option>
                <option value="NATIONAL_ID_NO_PHOTO">Passport</option>
              </select>
              {formik.touched.idProofType && formik.errors.idProofType ? (
                <div className="invalid-feedback">
                  {formik.errors.idProofType}
                </div>
              ) : null}
            </div>
          </div>
          <div className="mb-5">
            <label className="form-label">Upload Id Proof</label>
            <input
              type="file"
              ref={fileInputRef}
              className="form-control"
              onChange={(event) => {
                const file = event.currentTarget.files?.[0];
                formik.setFieldValue("file", file); // Set file value in Formik
              }}
              accept="image/*,.pdf"
            />
            {formik.touched.file && formik.errors.file && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.file}</span>
                </div>
              </div>
            )}
            {regUser?.reapply &&
              !formik.values.file &&
              regUser?.secondaryDirectors &&
              Array.isArray(regUser.secondaryDirectors) &&
              regUser.secondaryDirectors[formIndex - 1] &&
              regUser.secondaryDirectors[formIndex - 1]?.documents &&
              Array.isArray(
                regUser.secondaryDirectors[formIndex - 1].documents
              ) &&
              regUser.secondaryDirectors[formIndex - 1].documents.length > 0 &&
              regUser.secondaryDirectors[formIndex - 1].documents[0]
                ?.documentPath && (
                <div className="d-flex flex-end">
                  <div>
                    <img
                      src={
                        regUser?.secondaryDirectors[formIndex - 1]?.documents[0]
                          ?.documentExtention === "pdf"
                          ? toAbsoluteUrl("/media/subicons/pdf.png")
                          : regUser.secondaryDirectors[formIndex - 1]
                              ?.documents[0]?.documentPath
                      }
                      className="mt-2"
                      alt=""
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={() =>
                        handlePreview(
                          regUser?.secondaryDirectors[formIndex - 1]
                            ?.documents[0]?.documentPath,
                          regUser?.secondaryDirectors[formIndex - 1]
                            ?.documents[0]?.documentExtention
                        )
                      }
                      style={{ height: "40px", width: "40px" }}
                    />
                  </div>
                </div>
              )}
          </div>
          <div className="mb-5">
            <label className="form-label">ID Number</label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.idNumber && formik.errors.idNumber
                  ? "is-invalid"
                  : ""
              }`}
              placeholder="ID Number"
              id="idNumber"
              name="idNumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.idNumber}
              maxLength={20}
            />
            {formik.touched.idNumber && formik.errors.idNumber ? (
              <div className="invalid-feedback">{formik.errors.idNumber}</div>
            ) : null}
          </div>
          <div className="mt-20">
            <button type="submit" className="btn w-100"  disabled={formik.isSubmitting || loading}>
              {loading ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Next"
              )}
            </button>
          </div>
        </form>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          style={{ display: "none", marginLeft: "27%" }}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="">
              <div className="">
                {previewSrc && (
                  <>
                    {extension === "pdf" ? (
                      <iframe
                        src={previewSrc + "#toolbar=0"}
                        title="PDF Preview"
                        style={{ width: "100%", height: "400px" }}
                      ></iframe>
                    ) : (
                      <img
                        src={previewSrc}
                        alt="Document Preview"
                        style={{
                          borderRadius: "10px",
                          maxHeight: "300px",
                          maxWidth: "300px",
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondaryApplicant;
