
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-datepicker/dist/react-datepicker.css";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useRef, useState } from "react";
import { findBusinessCategory, getUserDetails, updateUser } from "./Api";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { BusinessFormValues, CategoryModal, Payload } from "./Interface";
import dayjs from "dayjs";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import Swal from "sweetalert2";
import { Modal } from "bootstrap";
import { apiCall } from "../dashboard/DashboardWrapper";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { ImgUpdate } from "../../../_metronic/layout/components/header/Navbar";

export const ApiCalling = {
  api: (): void => {},
};

const EditProfile = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  console.log(token);
  

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const [userDetails, setUserDetails] = useState<BusinessFormValues>();
  const [businessCategory, setBusinessCategory] = useState<CategoryModal[]>();
  const [change, setChange] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const imageModalRef = useRef<HTMLDivElement | null>(null);
  const initialValues = {
    businessName: userDetails?.businessName || "",
    firstName: userDetails?.firstName || "",
    yearOfIncorporation: userDetails?.yearOfIncorporation || "",
    email: userDetails?.email || "",
    country: userDetails?.isdCode.toString() || "",
    businessCategory: userDetails?.businessCategory || "",
    address: userDetails?.address || "",
    vatNumber: userDetails?.vatNumber || "",
    nidNumber: userDetails?.nidNumber || "",
  };

  const validationSchema = Yup.object({
    businessName: Yup.string().required("Business Name is required"),
    firstName: Yup.string().required("Business Owner Name is required"),
    yearOfIncorporation: Yup.string().required(
      "Incorporation Date is required"
    ),
    country: Yup.string().required("Country is required"),
    businessCategory: Yup.string().required("Company is required"),
    address: Yup.string().required("Business address is required"),
  });

  const onSubmit = (values: Payload) => {
    if (values.yearOfIncorporation) {
      values.yearOfIncorporation = values.yearOfIncorporation;
    }

    const formattedDate = dayjs(values.yearOfIncorporation).toISOString();

    // Your submission logic here...
    const payload: Payload = {
      ...values,
      yearOfIncorporation: formattedDate,
    };
    updateUser(API_URL, token, payload).then((response) => {
      if (response?.statusCode === 0) {
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Your Profile has been updated",
          showCancelButton: false, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "Ok",
        });
      }
    });
  };

  const handleViewClick = async () => {
    const modalElement = document.getElementById("image_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleButtonClick = () => {
    // Trigger the file input click event
    fileInputRef?.current?.click();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      // Handle the selected file
      const selectedFile = e.target.files?.[0];

      if (selectedFile) {
        // Read the selected file as a base64-encoded string
        const fileData = await readFileAsBase64(selectedFile);

        // Call the API to update the profile with the file data
        await updateProfile({
          documentExtention: selectedFile.name.split(".").pop(), // Get the file extension
          documentName: selectedFile.name.split(".")[0],
          documentTypes: [2],
          documentUploadedByType: 1,
          documentStatus: 4,
          fileUploadType: 1,
          documentData: fileData,
        });

        console.log("Selected File:", selectedFile);
      } else {
        console.error("No file selected");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const enhancedFetchData = async () => {
      const data = await getUserDetails(API_URL, token);
      setUserDetails(data);
      const businessCategory = await findBusinessCategory(API_URL, token);
      setBusinessCategory(businessCategory);
    };

    ApiCalling.api = enhancedFetchData;

    enhancedFetchData();
  }, []);

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result as string;
        const startIndex = base64String.indexOf(",") + 1; // Find the index of the comma and move one position forward
        resolve(base64String.substring(startIndex));
      };

      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  };

  const updateProfile = async (profileData: any) => {
    try {
      const url = `${API_URL}/updateProfileImage`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({ documents: [profileData] });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        ApiCalling.api();
        ImgUpdate.api()
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Your Profile Image has been updated",
          showCancelButton: false, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (<>
 <h4 className="mb-4">Edit Profile</h4>
   <div
      className={`d-flex gap-3 ${
        window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
      }`}
    >
      
      <div className="card p-5 pt-0 d-flex flex-column flex-grow-1">
        <div className="card-header p-0 align-items-center">
         
        </div>
        <div className="">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: typeof initialValues) => {
              onSubmit(values);
            }}
          >
            {({ setFieldValue, handleChange, values }) => (
              <Form>
                {/* Logo */}
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fs-4 w-25 ms-2 m-0">
                    Profile
                  </label>
                  <div className="col-lg-7 mt-4 p-0">
                    <div
                      className="image-input image-input-outline overlay overflow-hidden shadow"
                      data-kt-image-input="true"
                    >
                      <div className="overlay-wrapper">
                        <div
                          className="image-input-wrapper w-125px h-125px"
                          style={{
                            backgroundImage: `url(${
                              userDetails?.documents?.find((doc) =>
                                doc.documentTypes.includes(2)
                              )?.documentPath || ""
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        ></div>
                      </div>
                      <div className="overlay-layer bg-dark bg-opacity-10 align-items-end">
                        <div className="d-flex flex-grow-1 flex-center py-5 justify-content-evenly">
                          <div
                            className="btn btn-icon bg-light border border-dark"
                            style={{ height: "30px", width: "30px" }}
                            onClick={handleButtonClick}
                          >
                            <i className="bi bi-camera fs-5 text-dark"></i>
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              accept=".jpg, .jpeg, .png"
                              onChange={handleFileChange}
                            />
                          </div>

                          <div
                            className="btn btn-icon bg-light border border-dark"
                            style={{ height: "30px", width: "30px" }}
                            onClick={handleViewClick}
                          >
                            <i className="bi bi-eye fs-5 text-dark"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Business Name */}
                <div className="d-flex flex-row my-5 mx-2 align-items-center">
                  <label
                    htmlFor="businessName"
                    className="form-label w-25 fs-4 m-0"
                  >
                    Business Name
                  </label>
                  <div className="d-flex flex-column w-75">
                    <Field
                      type="text"
                      name="businessName"
                      className="form-control"
                      style={{ width: "37vw" }}
                    />
                    <ErrorMessage
                      name="businessName"
                      component="div"
                      className="text-danger mt-2"
                    />
                  </div>
                </div>
                {/* Business Owner Name */}
                <div className="d-flex flex-row my-5 mx-2 align-items-center">
                  <label
                    htmlFor="firstName"
                    className="form-label w-25 fs-4 m-0"
                  >
                    Business Owner Name
                  </label>
                  <div className="d-flex flex-column w-75">
                    <Field
                      type="text"
                      name="firstName"
                      className="form-control"
                      style={{ width: "37vw" }}
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="text-danger mt-2"
                    />
                  </div>
                </div>
                <div className="d-flex flex-row my-5 mx-2 mt-3 align-items-center">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label w-25 fs-4 m-0"
                  >
                    Incorporation Date
                  </label>
                  <div className="d-flex flex-column w-75">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={dayjs(initialValues?.yearOfIncorporation)}
                        onChange={(date) => {
                          setFieldValue(
                            "yearOfIncorporation",
                            dayjs(date) || null
                          );
                        }}
                        slotProps={{
                          textField: {
                            size: "small",
                            style: {
                              width: "37vw",
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                    {/* <Field
                      className="form-control"
                      type="date"
                      name="yearOfIncorporation"
                      style={{ width: "37vw" }}
                      value={initialValues?.yearOfIncorporation?.split("T")[0]}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(event: any) => {
                       
                        console.log(event.target.value);
                        console.log(
                          initialValues?.yearOfIncorporation?.split("T")[0]
                        );
                        setFieldValue(
                          "yearOfIncorporation",
                          event.target.value
                        );
                      }}
                    /> */}
                  </div>
                </div>
                {/* Registered Email ID */}
                <div className="d-flex flex-row my-5 mx-2 align-items-center">
                  <label htmlFor="email" className="form-label w-25 fs-4 m-0">
                    Registered Email ID
                  </label>
                  <div className="d-flex flex-column w-75">
                    <Field
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="name@example.com"
                      style={{ width: "37vw" }}
                      readOnly
                    />
                  </div>
                </div>

                {/* Select Country */}
                <div className="d-flex flex-row my-5 mx-2 align-items-center">
                  <label htmlFor="country" className="form-label w-25 fs-4 m-0">
                    Select Country
                  </label>
                  <div className="d-flex flex-column w-75">
                    <Field
                      as="select"
                      name="country"
                      className="form-select"
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setFieldValue("country", e.target.value);
                      }}
                      style={{ width: "37vw" }}
                      disabled
                    >
                      <option value="">Select Country</option>
                      <option value="91">India</option>
                      <option value="962">Jordan</option>
                      <option value="234">Nigeria</option>
                      <option value="27">South Africa</option>
                      <option value="211">South Sudan</option>
                      <option value="249">Sudan</option>
                    </Field>
                    <ErrorMessage
                      name="country"
                      component="div"
                      className="text-danger mt-2"
                    />
                  </div>
                </div>

                <div className="d-flex flex-row my-5 mx-2 align-items-center">
                  <label className="form-label w-25 fs-4 m-0">
                    Mobile Number
                  </label>
                  <div className="d-flex flex-column w-75">
                    <PhoneInput
                      defaultCountry="in"
                      forceDialCode={true}
                      value={`${userDetails?.isdCode}${userDetails?.mobile}`}
                      inputStyle={{ width: "34vw", padding: "0px" }}
                      disableDialCodePrefill={false}
                      disabled
                    />
                  </div>
                </div>

                {/* Company */}
                <div className="d-flex flex-row my-5 mx-2 align-items-center">
                  <label
                    htmlFor="businessCategory"
                    className="form-label w-25 fs-4 m-0"
                  >
                    Company
                  </label>
                  <div className="d-flex flex-column w-75">
                    <Field
                      as="select"
                      name="businessCategory"
                      className="form-select"
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setFieldValue("businessCategory", e.target.value);
                      }}
                      style={{ width: "37vw" }}
                    >
                      <option value="">Select Category</option>
                      {businessCategory?.map((category) => (
                        <option value={category.key}>{category.value}</option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="businessCategory"
                      component="div"
                      className="text-danger mt-2"
                    />
                  </div>
                </div>

                {/* Business Address */}
                <div className="d-flex flex-row my-5 mx-2 align-items-center">
                  <label htmlFor="address" className="form-label w-25 fs-4 m-0">
                    Business Address
                  </label>
                  <div className="d-flex flex-column w-75">
                    <Field
                      type="text"
                      name="address"
                      className="form-control"
                      style={{ width: "37vw" }}
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="text-danger mt-2"
                    />
                  </div>
                </div>

                {/* Submit Button */}
                <div className="d-flex justify-content-center m-5">
                  <button
                    type="submit"
                    className="btn rounded"
                    style={{ width: "350px" }}
                  >
                    Update
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <div
        className="modal"
        tabIndex={-1}
        id="image_modal"
        ref={imageModalRef}
          
      >
        <div
          className="modal-dialog modal-dialog-centered w-400px"
          style={{ margin: "0 auto" }}
        >
          <div className="modal-content p-5">
            <div className="d-flex flex-row justify-content-between">
              <label className="form-label fs-3">Profile Image</label>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </div>
            </div>
            <div className="modal-body p-2">
              <div
                className="image-input image-input-outline"
                data-kt-image-input="true"
              >
                <div
                  className="image-input-wrapper w-350px h-250px"
                  style={{
                    backgroundImage: `url(${
                      userDetails?.documents?.find((doc) =>
                        doc.documentTypes.includes(2)
                      )?.documentPath || ""
                    })`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>

  
  );
};
export default EditProfile;
