import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../core/Auth';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { docUpload } from './api';
import { useFormCompletion } from './Stepper';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';

interface BusinessDoc {
  documentTypes: number[];
  documentPath: string;
  documentExtension: string;
}

const Documents = () => {
  const [loading, setLoading] = useState(false);
  const [previewSrc, setPreviewSrc] = useState('');
  const [extension,setExtension]=useState('')
  const { regUser } = useAuth();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const { markFormCompleted } = useFormCompletion();
  
  const maxSize = 3 * 1024 * 1024;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      license: null,
      moa: null,
      tax: null,
    },
    validationSchema: regUser?.reapply
    ? null
    : Yup.object().shape({
        license: Yup.mixed()
          .test('fileType', 'Only PDF and images are allowed', (value) => {
            if (!value) return true;
            const file = value as File;
            const validExtensions = [
              '.pdf',
              '.jpg',
              '.jpeg',
              '.png',
              '.svg',
              '.gif',
              '.bmp',
              '.webp',
              '.tiff',
            ];
            const extension = file.name
              ? file.name.slice(file.name.lastIndexOf('.')).toLowerCase()
              : '';
            return validExtensions.includes(extension);
          })
          .test('fileSize', 'File size must be less than or equal to 3 MB', (value) => {
            if (!value) return true;
            const file = value as File;
            return file.size <= maxSize;
          })
          .required('File is required'),
  
        moa: Yup.mixed()
          .test('fileType', 'Only PDF and images are allowed', (value) => {
            if (!value) return true;
            const file = value as File;
            const validExtensions = [
              '.pdf',
              '.jpg',
              '.jpeg',
              '.png',
              '.svg',
              '.gif',
              '.bmp',
              '.webp',
              '.tiff',
            ];
            const extension = file.name
              ? file.name.slice(file.name.lastIndexOf('.')).toLowerCase()
              : '';
            return validExtensions.includes(extension);
          })
          .test('fileSize', 'File size must be less than or equal to 3 MB', (value) => {
            if (!value) return true;
            const file = value as File;
            return file.size <= maxSize;
          })
          .required('File is required'),
  
        tax: Yup.mixed()
          .test('fileType', 'Only PDF and images are allowed', (value) => {
            if (!value) return true;
            const file = value as File;
            const validExtensions = [
              '.pdf',
              '.jpg',
              '.jpeg',
              '.png',
              '.svg',
              '.gif',
              '.bmp',
              '.webp',
              '.tiff',
            ];
            const extension = file.name
              ? file.name.slice(file.name.lastIndexOf('.')).toLowerCase()
              : '';
            return validExtensions.includes(extension);
          })
          .test('fileSize', 'File size must be less than or equal to 3 MB', (value) => {
            if (!value) return true;
            const file = value as File;
            return file.size <= maxSize;
          })
          .required('File is required'),
      }),

      onSubmit: async (values, { setStatus, resetForm, setSubmitting }) => {
        setLoading(true);
        setSubmitting(true);
      
        try {
          if (!regUser?.reapply && formik.values) {
            const data = await docUpload(
              values?.license,
              values?.moa,
              values?.tax,
              regUser?.token ?? ''
            );
      
            if (data?.data?.status?.statusCode === 0) {
              markFormCompleted('documents');
              navigate('/auth/applicant');
              // Optionally reset the form or clear input if needed
              // if (fileInputRef.current) {
              //   fileInputRef.current.value = '';
              // }
            }
          } else if (
            regUser?.reapply &&
            formik?.values?.license &&
            formik?.values?.tax &&
            formik?.values?.moa
          ) {
            const data = await docUpload(
              values?.license,
              values?.moa,
              values?.tax,
              regUser?.token ?? ''
            );
      
            if (data?.data?.status?.statusCode === 0) {
              if (fileInputRef.current) {
                fileInputRef.current.value = '';
              }
              markFormCompleted('documents');
              navigate('/auth/applicant');
              resetForm();
            }
          } else {
            markFormCompleted('documents');
            navigate('/auth/applicant');
            resetForm();
          }
        } catch (error) {
          console.error('Error:', error);
        } finally {
          setLoading(false);
          setSubmitting(false);
        }
      }
      
  });

  const handlePreview = (documentPath: string, documentExtension: string) => {
    setPreviewSrc(documentPath);
    setExtension(documentExtension);
  };

  return (
    <div className="d-flex flex-column justify-content-center">
      <p className="fs-2 fw-bold text-center">Please upload the following documents</p>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <div className="p-10">
          <div className="mb-10">
            <label className="form-label">Trade Licence</label>
            <input
              type="file"
              ref={fileInputRef}
              className="form-control"
              onChange={(event) => {
                const file = event.currentTarget.files?.[0];
                formik.setFieldValue('license', file); // Set file value in Formik
              }}
              accept="image/*,.pdf"
            />
            {formik.touched.license && formik.errors.license && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.license}</span>
                </div>
              </div>
            )}
            {regUser?.reapply &&
              !formik.values.license &&
              regUser?.businessDocs.map((item, index) =>
                item.documentTypes[0] === 16 ? (
                  <div key={index} className="d-flex flex-end">
                    <div>
                      <img
                        src={
                          item?.documentExtention === 'pdf'
                            ? toAbsoluteUrl('/media/subicons/pdf.png')
                            : item?.documentPath
                        }
                        onClick={() =>
                          handlePreview(item.documentPath, item?.documentExtention)
                        }
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        className="mt-2"
                        alt=""
                        style={{
                          height: '40px',
                          width: '40px',
                          cursor: 'pointer',
                          borderRadius: '5%',
                        }}
                      />
                    </div>
                  </div>
                ) : null
              )}
          </div>

          <div className="mb-10">
            <label className="form-label">Tax Documents</label>
            <input
              type="file"
              ref={fileInputRef}
              className="form-control"
              onChange={(event) => {
                const file = event.currentTarget.files?.[0];
                formik.setFieldValue('tax', file); // Set file value in Formik
              }}
              accept="image/*,.pdf"
            />
            {formik.touched.tax && formik.errors.tax && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.tax}</span>
                </div>
              </div>
            )}
            {regUser?.reapply &&
              !formik.values.tax &&
              regUser?.businessDocs.map((item, index) =>
                item.documentTypes[0] === 19 ? (
                  <div key={index} className="d-flex flex-end">
                    <div>
                      <img
                        src={
                          item?.documentExtention === 'pdf'
                            ? toAbsoluteUrl('/media/subicons/pdf.png')
                            : item?.documentPath
                        }
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() =>
                          handlePreview(item.documentPath, item?.documentExtention)
                        }
                        className="mt-2"
                        alt=""
                        style={{
                          height: '40px',
                          width: '40px',
                          cursor: 'pointer',
                          borderRadius: '5%',
                        }}
                      />
                    </div>
                  </div>
                ) : null
              )}
          </div>

          <div>
            <label className="form-label">AOA/MOA</label>
            <input
              type="file"
              ref={fileInputRef}
              className="form-control"
              onChange={(event) => {
                const file = event.currentTarget.files?.[0];
                formik.setFieldValue('moa', file); // Set file value in Formik
              }}
              accept="image/*,.pdf"
            />
            {formik.touched.moa && formik.errors.moa && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.moa}</span>
                </div>
              </div>
            )}
            {regUser?.reapply &&
              !formik.values.moa &&
              regUser?.businessDocs.map((item, index) =>
                item.documentTypes[0] === 18 ? (
                  <div key={index} className="d-flex flex-end">
                    <div>
                      <img
                        src={
                          item?.documentExtention === 'pdf'
                            ? toAbsoluteUrl('/media/subicons/pdf.png')
                            : item?.documentPath
                        }
                        onClick={() =>
                          handlePreview(item.documentPath, item?.documentExtention)
                        }
                        className="mt-2"
                        alt=""
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        style={{
                          height: '40px',
                          width: '40px',
                          cursor: 'pointer',
                          borderRadius: '5%',
                        }}
                      />
                    </div>
                  </div>
                ) : null
              )}
          </div>
        </div>
        <div className="p-10">
          <button type="submit" className="btn w-100" disabled={formik.isSubmitting || loading}>
            {loading ? (
              <div className="spinner-border text-primary" role="status">
                <span role="status" aria-hidden="true"></span>
              </div>
            ) : (
              <div>Proceed</div>
            )}
          </button>
        </div>
      </form>

      {/* Modal for Preview */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        style={{ display: "none" ,marginLeft:"27%"}}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="" >
            <div className="">
              {previewSrc && (
                <>
                  {extension==="pdf" ? (
                    <iframe
                      src={previewSrc+"#toolbar=0"}
                      title="PDF Preview"
                      style={{ width: '100%', height: '400px' }}
                       
                    ></iframe>
                  ) : (
                    <img
                      src={previewSrc}
                      alt="Document Preview"
                      style={{ borderRadius: "10px" }}

                    />
                  )}
                </>
              )}
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
