/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";
import { defaultCreateAppData, ICreateAppData } from "./IAppModels";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { KTIcon } from "../../../../_metronic/helpers";
import { Step1 } from "./steps/Step1";
import { Step2 } from "./steps/Step2";
import { Step3, Step3Ref, dataOutcome } from "./steps/Step3";
import { Step4, Step4Ref } from "./steps/Step4";
import { Step3_5 } from "./steps/Step5";
import Swal from "sweetalert2";
import { ApiData } from "../bulkPaymentDashboard";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
import { User } from "../../TransferMoney/TransferModal";

type Props = {
  show: boolean;
  handleClose: () => void;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const CreateAppModal = ({ show, handleClose }: Props) => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const baseCurrency = sessionStorage.getItem("currency");

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const userDetails = sessionStorage.getItem("userDetails");
  const userDet = userDetails ? JSON.parse(userDetails) : null;

  const [button, setButton] = useState("Submit");
  const [authority, setAuthority] = useState(0);

  const [txnPin, setTxnPin] = useState("");
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [data, setData] = useState<ICreateAppData>(defaultCreateAppData);
  const [hasError, setHasError] = useState(false);
  let pindata = "";
  let isToastVisible = false;
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const updateData = (fieldsToUpdate: Partial<ICreateAppData>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  useEffect(() => {
    if (userDet?.userDetails?.authority?.includes("2")) {
      setButton("Initiate");
    }
    if (
      userDet?.userDetails?.authority?.length === 1 &&
      userDet?.userDetails?.authority?.includes("3")
    ) {
      setAuthority(3);
    }
  }, []);

  const checkAppBasic = (): boolean => {
    if (!data.name.name) {
      return false;
    }

    if (!data.description.description) {
      return false;
    }

    if (!data.account.account) {
      return false;
    }

    if (!data.date.date && data.schedule.schedule === "Later") {
      return false;
    }

    if (
      isNaN(new Date(data.date.date).getTime()) &&
      data.schedule.schedule === "Later"
    ) {
      return false;
    }

    if (
      new Date(data.date.date).getTime() < new Date().getTime() &&
      data.schedule.schedule === "Later"
    ) {
      return false;
    }

    return true;
  };

  const checkAppPin = (): boolean => {
    if (txnPin.length === 4) {
      return true;
    }

    return false;
  };

  const TransactionPin = async () => {
    let apiUrlDAta = "";
    if (data.category.category === "Employee Payment") {
      apiUrlDAta = "processEmployeePaymentFile";
    } else {
      apiUrlDAta = "processVendorPaymentFile";
    }

    try {
      const url = `${API_URL}/bulk/${apiUrlDAta}`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        fileUploadId: dataOutcome?.fileUploadId,
        transactionPIN: txnPin,
        ...(button === "Initiate" && {
          currency: "ZAR",
          amount: dataOutcome.amount,
          approverGuid: data.approverGuid.approverGuid,
          beneficiaryName:
            apiUrlDAta === "processEmployeePaymentFile"
              ? "Employee Payment" + "-" + dataOutcome?.fileUploadId
              : "Vendor Payment" + "-" + dataOutcome?.fileUploadId,
          approverName: data.approverName.approverName,
          initiatedTxn: true,
        }),
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let res = await response.json();

      if (res.status.statusCode === 0) {
        updateData(resetData);
        handleClose();
        const hasJobScheduledTime = !!res?.message?.jobScheduledTime;
        if (button === "Initiate") {
          Swal.fire({
            icon: "success",
            title: "Confirmation",
            html: "Initiation successful <br> Pending for approval",
            allowEscapeKey: true,
            allowEnterKey: true,
          });
        } else {
          Swal.fire({
            icon: "success",
            title: hasJobScheduledTime
              ? "Bulk Payment Scheduled!"
              : "Transfer Processing!",
            text: hasJobScheduledTime
              ? "Your payment has been scheduled!"
              : "Payment transfer successfully processing!",
            // allowEscapeKey: true,
            // allowEnterKey: true,
          });
        }
        setTimeout(() => {
          ApiData.api();
        }, 1000);
      }

      if (res.status.statusCode === 1) {
        handleRemovePinInChild(res.status.messageDescription);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    if (stepper?.current?.getCurrentStepIndex() === 2) {
      updateData(resetData);
    }
    if (stepper?.current?.getCurrentStepIndex() === 3) {
      handleRemoveFileInChild();
    }
    if (stepper?.current?.getCurrentStepIndex() === 4) {
      handleRemovePinInChild("");
    }

    stepper.current.goPrev();
  };

  const nextStep = () => {
    setHasError(false);
    if (!stepper.current) {
      return;
    }

    // if (stepper.current.getCurrentStepIndex() === 1) {
    //   if (!checkAppBasic()) {
    //     setHasError(true)
    //     return
    //   }
    // }

    if (stepper.current.getCurrentStepIndex() === 2) {
      if (!checkAppBasic()) {
        setHasError(true);
        return;
      }
    }

    if (stepper.current.getCurrentStepIndex() === 3) {
      if (!dataOutcome.fileExist) {
        if (!dataOutcome.fileExist && !isToastVisible) {
          const toast = document.createElement("div");
          toast.classList.add(
            "toast",
            "show",
            "position-absolute",
            "top-0",
            "start-50",
            "translate-middle-x",
            "bg-danger",
            "text-light"
          );
          toast.setAttribute("role", "alert");
          toast.setAttribute("aria-live", "assertive");
          toast.setAttribute("aria-atomic", "true");
          toast.style.zIndex = "2000";
          toast.style.marginTop = "140px";
          toast.style.marginLeft = "140px";

          toast.innerHTML = `
              <div class="toast-header bg-danger text-light border-0">
                  <i class="ki-duotone ki-abstract-39 fs-2 text-primary me-3"><span class="path1"></span><span class="path2"></span></i>
                  <strong class="me-auto">Error</strong>
                  
                  <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
              </div>
              <div class="toast-body">
                 File is required
              </div>
          `;

          document.body.appendChild(toast);

          // Set isToastVisible to true to prevent showing the toast again
          isToastVisible = true;

          // Remove the toast after 3 seconds
          setTimeout(() => {
            toast.remove();
            // Reset isToastVisible after the toast is removed
            isToastVisible = false;
          }, 3000);
        }

        return;
      }
    }

    if (button === "Initiate") {
      if (stepper.current.getCurrentStepIndex() === 4) {
        if (!data.approverName.approverName) {
          setHasError(true);
          return false;
        }
      }
    }

    stepper.current.goNext();
  };

  const resetData = {
    category: { category: "Employee Payment" },
    name: { name: "" },
    description: { description: "" },
    schedule: { schedule: "Now" },
    account: { account: "" },
    date: { date: "" },
    approverName: { approverName: "" },
    approverGuid: { approverGuid: "" },
    submit: { submit: () => {} },
  };

  const submit = () => {
    if (!stepper.current) {
      return;
    }
    if (button === "Initiate") {
      if (stepper?.current?.getCurrentStepIndex() === 5) {
        if (!checkAppPin()) {
          setHasError(true);
          return;
        }
      }
    } else {
      if (stepper?.current?.getCurrentStepIndex() === 4) {
        if (!checkAppPin()) {
          setHasError(true);
          return;
        }
      }
    }

    if (txnPin.length === 4 && dataOutcome.fileExist) {
      TransactionPin();
    } else if (!dataOutcome.fileExist && !isToastVisible) {
      const toast = document.createElement("div");
      toast.classList.add(
        "toast",
        "show",
        "position-absolute",
        "top-0",
        "start-50",
        "translate-middle-x",
        "bg-danger",
        "text-light"
      );
      toast.setAttribute("role", "alert");
      toast.setAttribute("aria-live", "assertive");
      toast.setAttribute("aria-atomic", "true");
      toast.style.zIndex = "2000";
      toast.style.marginTop = "140px";
      toast.style.marginLeft = "140px";

      toast.innerHTML = `
          <div class="toast-header bg-danger text-light border-0">
              <i class="ki-duotone ki-abstract-39 fs-2 text-primary me-3"><span class="path1"></span><span class="path2"></span></i>
              <strong class="me-auto">Error</strong>
              
              <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
          <div class="toast-body">
             File is required
          </div>
      `;

      document.body.appendChild(toast);

      // Set isToastVisible to true to prevent showing the toast again
      isToastVisible = true;

      // Remove the toast after 3 seconds
      setTimeout(() => {
        toast.remove();
        // Reset isToastVisible after the toast is removed
        isToastVisible = false;
      }, 3000);
    }
  };

  const step4Ref = useRef<Step4Ref>(null);

  const handleRemovePinInChild = (msg: string) => {
    console.log("handle pin called");

    if (step4Ref.current) {
      step4Ref.current.handlePinRemove(msg);
    }
  };

  const step3Ref = useRef<Step3Ref>(null);

  const handleRemoveFileInChild = () => {
    console.log("handle remove called");

    if (step3Ref.current) {
      step3Ref.current.handleFileRemove();
    }
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-800px"
      show={show}
      onHide={()=>{
        handleClose()
        updateData(resetData);
      }}
      onEntered={loadStepper}

    >
      <div className="modal-header">
        <h2>Create Bulk Payments</h2>

        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
          onClick={() => {
            handleClose();
            updateData(resetData);
          }}
        >
          <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
        </div>
      </div>

      <div
        className="modal-body py-lg-10 px-lg-10"
        style={{
          display: authority === 3 ? "flex" : "",
          minHeight: "300px",
          alignItems: authority === 3 ? "center" : "normal",
          justifyContent: authority === 3 ? "center" : "normal",
        }}
      >
        {authority === 3 ? (
          <div>
            <p className="fw-bold text-center text-danger">
              You don't have access to this feature.
            </p>

            <p className="fw-bold text-center text-danger">
              Please contact admin.
            </p>
          </div>
        ) : (
          <div
            ref={stepperRef}
            className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
            id="kt_modal_create_app_stepper"
          >
            <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
              <div className="stepper-nav ps-lg-10">
                <div
                  className="stepper-item current"
                  data-kt-stepper-element="nav"
                >
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">1</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">Choose a category</h3>
                    </div>
                  </div>
                  <div className="stepper-line h-40px"></div>
                </div>
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">2</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">Enter details</h3>
                    </div>
                  </div>
                  <div className="stepper-line h-40px"></div>
                </div>
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">3</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">Choose a method</h3>
                    </div>
                  </div>
                  <div className="stepper-line h-40px"></div>
                </div>
                {button === "Initiate" && (
                  <div className="stepper-item" data-kt-stepper-element="nav">
                    <div className="stepper-wrapper">
                      <div className="stepper-icon w-40px h-40px">
                        <i className="stepper-check fas fa-check"></i>
                        <span className="stepper-number">4</span>
                      </div>
                      <div className="stepper-label">
                        <h3 className="stepper-title">Choose approver</h3>
                      </div>
                    </div>
                    <div className="stepper-line h-40px"></div>
                  </div>
                )}
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">
                        {button === "Initiate" ? "5" : "4"}
                      </span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">Enter PIN</h3>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>

            <div className="flex-row-fluid py-lg-5 px-lg-15">
              <form noValidate id="kt_modal_create_app_form">
                <Step1
                  data={data}
                  updateData={updateData}
                  hasError={hasError}
                  txnPin={txnPin}
                  setTxnPin={setTxnPin}
                />
                <Step2
                  data={data}
                  updateData={updateData}
                  hasError={hasError}
                  txnPin={txnPin}
                  setTxnPin={setTxnPin}
                />
                <Step3
                  data={data}
                  updateData={updateData}
                  hasError={hasError}
                  txnPin={txnPin}
                  setTxnPin={setTxnPin}
                  ref={step3Ref}
                />
                {button === "Initiate" && (
                  <Step3_5
                    data={data}
                    updateData={updateData}
                    hasError={hasError}
                    txnPin={txnPin}
                    setTxnPin={setTxnPin}
                  />
                )}
                <Step4
                  data={data}
                  updateData={updateData}
                  hasError={hasError}
                  txnPin={txnPin}
                  setTxnPin={setTxnPin}
                  ref={step4Ref}
                />

                {/*begin::Actions */}
                <div className="d-flex flex-stack pt-10">
                  <div className="me-2">
                    <button
                      type="button"
                      className="btn btn-lg btn-light-primary me-3"
                      data-kt-stepper-action="previous"
                      onClick={prevStep}
                    >
                      <KTIcon iconName="arrow-left" className="fs-3 me-1" />{" "}
                      Previous
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-lg btn"
                      data-kt-stepper-action="submit"
                      onClick={submit}
                    >
                      {button}{" "}
                      <KTIcon
                        iconName="arrow-right"
                        className="fs-3 ms-2 me-0"
                      />
                    </button>

                    <button
                      type="button"
                      className="btn btn-lg btn"
                      data-kt-stepper-action="next"
                      onClick={nextStep}
                    >
                      Next Step{" "}
                      <KTIcon
                        iconName="arrow-right"
                        className="fs-3 ms-1 me-0"
                      />
                    </button>
                  </div>
                </div>
                {/*end::Actions */}
              </form>
            </div>
          </div>
        )}
      </div>
    </Modal>,
    modalsRoot
  );
};

export { CreateAppModal };
