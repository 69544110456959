import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../core/Auth'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import Sucess from "../../../../../_metronic/assets/Icons/success.png"
import { getAllData } from './api'

const Approved = () => {

    const { regUser } = useAuth()
    const navigate = useNavigate()
    console.log(regUser);



    return (
        <div className='d-flex flex-column justify-content-center'>
            <div className='d-flex flex-row justify-content-center'>
                <img style={{ height: "150px", width: "150px", objectFit: "contain" }} src={Sucess} alt="" />
            </div>
            <p className='fs-2 fw-bold text-center'>Congratulations! </p>
            <div className='mt-10'>
                <div className='p-10'>
                    <p className='fs-4'>Your application has been approved. Please proceed with the enrolment steps and access your account dashboard.</p>
                </div>

            </div>

            <div className='d-flex justify-content-center mt-10 p-10'>
                <button className="btn w-100" onClick={() => { navigate('/auth/primary-acc') }} >
                    Complete Enrollment
                </button>
            </div>
        </div>

    )
}

export default Approved
