/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTIcon } from "../../../../_metronic/helpers";
import { StepProps } from "./SubAccountAppModel";

export const SubAccountStepperModal = ({
  data,
  updateData,
  hasError,
  setHasError
}: StepProps) => {
  return (
    <div className="current" data-kt-stepper-element="content">
      <div className="w-100">
        <div className="fv-row">
          {/* begin::Label */}
          {/* <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
            <span className='required'>Select Category</span>

            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Select your app category'
            ></i>
          </label> */}
          {/* end::Label */}
          <div>
            <div className="mt-10 mb-10 p-5">
              <label className="form-label">
                Enter Name of the Sub Account
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                maxLength={20}
                value={data.appBasic.appName}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const isValid = /^[a-zA-Z0-9\s]+$/.test(inputValue);
                  if (isValid || inputValue === "") {
                    updateData({
                      appBasic: {
                        appName: inputValue,
                        appType: data.appBasic.appType,
                      },
                    });
                  }
                }}
                title="Only letters are allowed"
              />
              {!data.appBasic.appName && hasError && (
                <div className="fv-plugins-message-container">
                  <div
                    data-field="appname"
                    data-validator="notEmpty"
                    className="fv-help-block"
                  >
                    Name of the Sub Account is required
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/*end::Form Group */}
      </div>
    </div>
  );
};
