import { useState, ChangeEvent, FC, useEffect } from "react";
import { TxnReceipt } from "./TxnReceipt";
import {
  AccountStmnt,
  ScheduleReceipt,
  TxnData,
} from "../../interface/Interface";
import { Modal } from "bootstrap";
import { getTransactionDetail, getAccountStmnt } from "./TxnApi";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Download from "../../../_metronic/assets/all-serviceIcons/download.svg";
import { downloadAccountStmnt } from "./TxnApi";
import { useRef } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-datepicker/dist/react-datepicker.css";
import dayjs, { Dayjs } from "dayjs";
import { ScheduleTxnReceipt } from "./ScheduleReceipt";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { spawn } from "child_process";

interface DateSelectionModalProps {
  accountNumber: string;
  currency: string;
}
export const accountStatement = {
  data: [] as AccountStmnt[],
  accountNumber: "",
  currency: "",
  stmntDuration: "",
  fromDate: "",
  toDate: "",
};

interface Accounts {
  accountNumber: string;
  currency: string;
  accountType: string;
  balance: number;
}
interface Schedule {
  functionCode: string;
  secondaryFundType: string;
  amount: number;
  txnId: string;
  txnStatus: number;
  accountNumber: string;
  currency: string;
  payeeAccountNumber: string;
  accountType: number;
  txnScheduleDate: string;
  receipt: ScheduleReceipt;
}

const Transaction: FC = () => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const [dateRange, setDateRange] = useState(false);
  const [stmntDuration, setStmntDuration] = useState("6");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [data, setData] = useState<AccountStmnt[]>([]);
  const [scheduleData, setScheduleData] = useState<Schedule[]>([]);
  const [filteredTransactions, setFilteredTransactions] = useState(data);
  const [scheduleFilteredTransactions, setScheduleFilteredTransactions] =
    useState(scheduleData);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTab, setSelectedTab] = useState<string>("All");
  const [search, setSearch] = useState(false);
  const [selectedData, setSelectedData] = useState<TxnData>({
    amount: 0,
    txnId: "",
    txnStatus: 0,
    accountNumber: "",
    currency: "",
    txnTypeName: "",
    dateTime: "",
    txnType: 0,
    category: "",
    note: "",
    scheduled: "",
    hours: "",
    receipt: [],
    businessGuid: "",
    senderName: "",
    senderCustomerId: "",
    benefName: "",
    benefCustomerId: "",
  });

  const [selectedScheduledData, setSelectedScheduledData] =
    useState<ScheduleReceipt>({
      Amount: "",
      "Payee Name": "",
      "Bank Account": "",
      "Schedule Date": "",
      Status: "",
    });

  const [accounts, setAccounts] = useState<Accounts[]>([]);

  const handleFromDateChange = (date: string | Date | Dayjs | null) => {
    let formattedDate = "";
    if (date) {
      if (dayjs.isDayjs(date)) {
        formattedDate = date.format("YYYY-MM-DD");
      } else {
        formattedDate = dayjs(date).format("YYYY-MM-DD");
      }
      setFromDate(formattedDate);
    } else {
      setFromDate("");
    }

    // If toDate is already set, validate the dates
    if (toDate) {
      validateDates(formattedDate, toDate);
    }
  };

  const handleToDateChange = (date: string | Date | Dayjs | null) => {
    let formattedDate = "";

    if (date) {
      if (dayjs.isDayjs(date)) {
        formattedDate = date.format("YYYY-MM-DD");
      } else {
        formattedDate = dayjs(date).format("YYYY-MM-DD");
      }
      setToDate(formattedDate);
    } else {
      setToDate("");
    }

    // If fromDate is already set, validate the dates
    if (fromDate) {
      validateDates(fromDate, formattedDate);
    }
  };

  const validateDates = (startDate: string, endDate: string) => {
    if (stmntDuration === "5") {
      if (!startDate || !endDate) {
        setValidationError("Start date and End date should not be empty.");
        return false;
      } else if (startDate > endDate) {
        setValidationError("Start date should not be greater than End date.");
        return false;
      } else if (endDate > dayjs(new Date()).format("YYYY-MM-DD")) {
        setValidationError("End date should not be greater than Today.");
        return false;
      }
    }
    setValidationError(null);
    return true;
  };

  const handleGenerateClick = () => {
    // Assuming statementDuration is a state variable
    if (stmntDuration === "5") {
      // Check for fromDate and toDate conditions
      if (fromDate && toDate && !validationError) {
        enhancedFetchDataAndSetData();
        transaction();
        handleDeleteCloseModal();
      } else {
        // Handle error or show a message if fromDate and toDate conditions are not met
        validateDates(fromDate, toDate);
        console.error("Invalid fromDate or toDate");
      }
    } else if (stmntDuration) {
      enhancedFetchDataAndSetData();
      transaction();
      handleDeleteCloseModal();
    } else {
      // Handle the case where statementDuration is falsy (undefined, null, etc.)
      console.error("Statement duration is not set");
    }
  };

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);
      modalRef.current.addEventListener(
        "hidden.bs.modal",
        handleDeleteCloseModal
      );
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleDeleteCloseModal
        );
        modal.dispose();
      };
    }
  }, []);
  const enhancedFetchDataAndSetData = async () => {
    try {
      accountStatement.accountNumber = accountDetails?.accountNumber;
      accountStatement.currency = accountDetails?.currency;
      accountStatement.stmntDuration = stmntDuration;
      accountStatement.fromDate = fromDate;
      accountStatement.toDate = toDate;
    } catch (error) {
      // Handle errors if needed
      console.error("Error enhancedFetching or downloading data:", error);
    }
  };

  useEffect(() => {
    enhancedFetchDataAndSetData();
  }, [stmntDuration]);

  const handleDeleteCloseModal = () => {
    const modalElement = modalRef.current;
    // setDateRange(false);
    // setStmntDuration("6");
    setValidationError(null);
    // setFromDate("");
    // setToDate("");
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
  };

  const location = useLocation();
  const accountDetails: Accounts = (
    location.state as { accountDetails: Accounts }
  )?.accountDetails;

  const [selectedAccount, setSelectedAccount] = useState<Accounts | null>(
    location.state
      ? (location.state as { accountDetails: Accounts }).accountDetails
      : accounts?.[0]
  );

  useEffect(() => {
    // Filter data based on both selected tab and search query
    const filteredData = data?.filter((transaction) => {
      // Filter based on selected tab
      let isTabMatched = false;
      switch (selectedTab) {
        case "All":
          isTabMatched = true; // Always match for "All" tab
          break;
        case "Credit":
          isTabMatched = transaction.txnType === "C";
          break;
        case "Debit":
          isTabMatched = transaction.txnType === "D";
          break;
        default:
          isTabMatched = true; // Default to true for unknown tabs
      }

      // Filter based on search query
      const isSearchMatched =
        transaction.txnTypeName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        transaction.dateTime
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        transaction.currency
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        transaction.txnId.toLowerCase().includes(searchQuery.toLowerCase());

      // Return true if both conditions are matched
      return isTabMatched && isSearchMatched;
    });

    // Update filtered transactions
    setFilteredTransactions(filteredData);
  }, [data, searchQuery, selectedTab]);

  useEffect(() => {
    setScheduleFilteredTransactions(
      scheduleData?.filter(
        (transaction) =>
          transaction?.accountNumber
            ?.toLowerCase()
            ?.includes(searchQuery?.toLowerCase()) ||
          transaction?.amount
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchQuery?.toLowerCase()) ||
          transaction?.currency
            ?.toLowerCase()
            ?.includes(searchQuery?.toLowerCase()) ||
          transaction?.txnScheduleDate
            ?.toLowerCase()
            ?.includes(searchQuery?.toLowerCase())
      )
    );
  }, [scheduleData, searchQuery]);

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  // useEffect(() => {
  //   const enhancedFetchData = async () => {
  //     const result = await getAccountStmnt(
  //       API_URL,
  //       token,
  //       accountDetails?.accountNumber,
  //       accountDetails?.currency,
  //       "2"
  //     );
  //     setData(result);
  //   };

  //   enhancedFetchData();
  // }, []);

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    setCurrentPage(1);

    // Update filteredTransactions based on the selected tab
    if (tab === "All") {
      setFilteredTransactions(data);
    } else if (tab === "Credit") {
      const filteredData = data.filter(
        (transaction) => transaction.txnType === "C"
      );
      setFilteredTransactions(filteredData);
    } else {
      const filteredData = data.filter(
        (transaction) => transaction.txnType === "D"
      );
      setFilteredTransactions(filteredData);
    }
  };

  const getScheduleData = async (selectedAccount: string) => {
    try {
      const url = `${API_URL}/scheduler/viewScheduledTxns`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
      
          searchCriteriaList: [
            {
              propertyName: "payerAccountNumber",
              propertyValue: selectedAccount
            }
          ]
        })
    

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setScheduleData(data?.results);
    } catch (error) {
      console.log(error);
    }
  };


  const handleDownloadRange = () => {
    const modalElement = document.getElementById("date_range");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handleDownloadClick = async (txnId: string) => {
    try {
      if (txnId) {
        const transaction = await getTransactionDetail(
          API_URL,
          token,
          selectedAccount
            ? selectedAccount.accountNumber
            : accounts[0].accountNumber,
          txnId
        );

        setSelectedData(transaction);
      }
    } catch (error) {
      console.error("Failed to enhancedFetch transaction details:", error);
    }
  };

  const handleScheduleDownloadClick = (txn: ScheduleReceipt) => {
    setSelectedScheduledData(txn);
  };

  const handleConfirmation = (item: any) => {
    Swal.fire({
      title: "Are you sure?",
      html: "Do you want to cancel the scheduled payment?<br><b>Note:</b> Once canceled, it cannot be revoked",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#007bff",
      allowEscapeKey: true,
      allowEnterKey: true, // Set the confirm button color to btn
    }).then((result) => {
      if (result.isConfirmed) {
        scheduleCancell(item.txnId);
      }
    });
  };
  const scheduleCancell = async (txnId: string) => {
    try {
      const url = `${API_URL}/scheduler/cancelScheduledTxn`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        jobId: txnId,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Schedule transaction is cancelled",
          showCancelButton: false, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      getScheduleData( selectedAccount
        ? selectedAccount.accountNumber
        : accounts[0]?.accountNumber);
    } catch (error) {
      console.log(error);
    }
  };

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredTransactions?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(filteredTransactions?.length / itemsPerPage);

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${currentPage === pageNumbers ? "active" : ""
              }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${currentPage === pageNumbers ? "active" : ""
              }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  // Calculate pagination for scheduleTransactions
  const indexOfLastItemSch = currentPage * itemsPerPage;
  const indexOfFirstItemSch = indexOfLastItem - itemsPerPage;
  const currentSchData = scheduleFilteredTransactions?.slice(
    indexOfFirstItemSch,
    indexOfLastItemSch
  );

  const renderSchPageNumbers = () => {
    const pageNumbers = Math.ceil(
      scheduleFilteredTransactions?.length / itemsPerPage
    );

    if (pageNumbers <= 5) {
      return Array.from({ length: pageNumbers }).map((_, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          style={{ zIndex: 0 }}
        >
          <a
            href="#"
            className="page-link"
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </a>
        </li>
      ));
    } else {
      const visiblePages = [];
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${currentPage === pageNumbers ? "active" : ""
              }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      } else if (currentPage >= pageNumbers - 2) {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = pageNumbers - 2; i <= pageNumbers; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
      } else {
        visiblePages.push(
          <li
            key={1}
            className={`page-item ${currentPage === 1 ? "active" : ""}`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(1)}
            >
              1
            </a>
          </li>
        );
        visiblePages.push(
          <li key="ellipsis1" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          visiblePages.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <a
                href="#"
                className="page-link"
                onClick={() => handlePageChange(i)}
              >
                {i}
              </a>
            </li>
          );
        }
        visiblePages.push(
          <li key="ellipsis2" className="page-item">
            <a href="#" className="page-link">
              ...
            </a>
          </li>
        );
        visiblePages.push(
          <li
            key={pageNumbers}
            className={`page-item ${currentPage === pageNumbers ? "active" : ""
              }`}
          >
            <a
              href="#"
              className="page-link"
              onClick={() => handlePageChange(pageNumbers)}
            >
              {pageNumbers}
            </a>
          </li>
        );
      }
      return visiblePages;
    }
  };

  const handleDownload = async () => {
    const response = await downloadAccountStmnt(
      API_URL,
      token,
      selectedAccount
        ? selectedAccount.accountNumber
        : accounts[0].accountNumber,
      selectedAccount && selectedAccount.currency === "R"
        ? "SSP"
        : selectedAccount?.currency ?? accounts[0].currency,
      stmntDuration,
      fromDate,
      toDate
    );

    const blobData = new Blob(
      [
        new Uint8Array(
          atob(response.fileData)
            .split("")
            .map((char) => char.charCodeAt(0))
        ),
      ],
      {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }
    );
    const url = window.URL.createObjectURL(blobData);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", response.fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const transaction = async () => {
    try {
      const url = `${API_URL}/txnStmnt/getAccountStmnt`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        accountNumber: selectedAccount
          ? selectedAccount?.accountNumber
          : accounts[0]?.accountNumber,
        currency:
          selectedAccount && selectedAccount?.currency === "R"
            ? "SSP"
            : selectedAccount?.currency ?? accounts[0]?.currency,
        stmntType: "A",
        stmntDuration: stmntDuration,
        fromDate: fromDate,
        toDate: toDate,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setData(data?.results);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    transaction();
  }, [accounts, selectedAccount]);

  const getAccounts = async () => {
    try {
      const url = `${API_URL}/transaction/getAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setAccounts(data.results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAccounts();
  }, []);

  const tooltipView = <Tooltip id="tooltip-add-money">View</Tooltip>;
  const tooltipSearch = <Tooltip id="tooltip-add-money">Search</Tooltip>;
  const tooltipFilter = <Tooltip id="tooltip-add-money">Filter</Tooltip>;
  const tooltipDownload = <Tooltip id="tooltip-add-money">Download</Tooltip>;
  const tooltipCancel = <Tooltip id="tooltip-add-money">Cancel</Tooltip>;

  return (
    <>
      <h4 className="mb-5">Transactions</h4>
      <div className="py-2 card p-5">
        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            <ul className="nav nav-tabs nav-line-tabs ms-4 fs-6 d-flex flex-nowrap">
              <li className="nav-item" key={1}>
                <a
                  className={`nav-link text-active-gray-800 ${selectedTab === "All" ? "active" : ""
                    }`}
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_1"
                  onClick={() => handleTabClick("All")}
                >
                  All
                </a>
              </li>
              <li className="nav-item" key={2}>
                <a
                  className={`nav-link text-active-gray-800 ${selectedTab === "Credit" ? "active" : ""
                    }`}
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_2"
                  onClick={() => handleTabClick("Credit")}
                >
                  Credit
                </a>
              </li>
              <li className="nav-item" key={3}>
                <a
                  className={`nav-link text-active-gray-800 text-nowrap ${selectedTab === "Debit" ? "active" : ""
                    }`}
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_3"
                  onClick={() => handleTabClick("Debit")}
                >
                  Debit
                </a>
              </li>
            
              <li className="nav-item" key={4}>
                <a
                  className={`nav-link text-active-gray-800 text-nowrap ${selectedTab === "Schedule" ? "active" : ""
                    }`}
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_4"
                  onClick={() =>{ handleTabClick("Schedule"); getScheduleData(accounts[0]?.accountNumber)}}
                >
                  Scheduled
                </a>
              </li>
            </ul>
          </div>

          <div className="d-flex align-items-center gap-10 me-5 p-3">
            <div>
              {" "}
              <select
                className="form-select text-gray cursor-pointer"
                value={selectedAccount ? selectedAccount.accountNumber : ""}
                onChange={(e) => {
                  const selectedAccountId = e.target.value;
                  const selectedAccount = accounts.find(
                    (account) => account.accountNumber === selectedAccountId
                  );
                  setSelectedAccount(
                    selectedAccount ? selectedAccount : accounts?.[0]
                  );
                  if (selectedTab === "Schedule") {
                    const accountNumber = selectedAccount?.accountNumber?.toString();
                    if (accountNumber) {
                      getScheduleData(accountNumber);
                    } 
                  }
                  
                }}

              >
                {accounts?.map((account) => (
                  <option
                    key={account.accountNumber}
                    value={account.accountNumber}
                  >
                    {account.accountNumber}
                    {"  "}({account.currency})
                  </option>
                ))}
              </select>
            </div>
            <div
              className={`d-flex align-items-center position-relative my-1 ${search ? "" : "me-6"
                }`}
            >
              <OverlayTrigger placement="top" overlay={tooltipSearch}>
                <i
                  className={`ki-outline ki-magnifier fs-1 position-absolute ${search ? "ms-5" : ""
                    }  cursor-pointer`}
                  onClick={() => setSearch(!search)}
                >

                </i>
              </OverlayTrigger>
              {search && (
                <input
                  value={searchQuery}
                  onChange={handleSearchChange}
                  type="text"
                  data-kt-docs-table-filter="search"
                  className={`form-control p-3 border-secondary form-control-solid w-${search ? "250px" : "40px"
                    } ps-15`}
                  placeholder="Search transaction"
                />
              )}
            </div>
            <div
              className="mt-1 cursor-pointer "
              onClick={() => handleDownloadRange()}
            >
              <OverlayTrigger placement="top" overlay={tooltipFilter}>
                <i className="bi bi-funnel fs-2 text-hover-dark"></i>
              </OverlayTrigger>
            </div>
            <div>
              <OverlayTrigger placement="top" overlay={tooltipDownload}>
                <i
                  className=" bi bi-download fs-2 text-hover-dark cursor-pointer"
                  onClick={() => {
                    handleDownload();
                  }}
                />
              </OverlayTrigger>
            </div>
          </div>
        </div>

        <div
          className="tab-content"
          id="myTabContent"
          style={{
            overflow: "scroll",
          }}
        >
          <div
            className="tab-pane fade show active"
            id="kt_tab_pane_1"
            role="tabpanel"
          >
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 ">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Description</th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Transaction ID
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Transaction Date
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">
                        Transaction Time
                      </th>
                      <th className="min-w-100px p-5 text-nowrap">Amount</th>
                      <th className="min-w-100px p-5 text-nowrap">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData.length > 0 ? (
                      currentData?.map((item, index) => (
                        <tr
                          key={index}
                          onMouseOver={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "#f1f1f2";
                            // target.style.transform = "translate3d(6px, -6px, 0)";
                            // target.style.boxShadow =
                            //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                          }}
                          onMouseOut={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "white";
                            // target.style.transform = "none";
                            // target.style.boxShadow = "none";
                          }}
                          id="kt_receipt_modal_toggle"
                          onClick={() => handleDownloadClick(item.txnId)}
                        >
                          <td
                            className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline"
                            style={{ cursor: "pointer" }}
                          >
                            {item.txnTypeName}
                          </td>
                          <td className="p-5 text-gray-600 mw-100px">
                            {item.txnId}
                          </td>
                          <td className="p-5 text-gray-600 mw-100px">
                            {item.dateTime.split(" | ")[0]}
                          </td>
                          <td className="p-5 text-gray-600 mw-100px">
                            {item.dateTime.split(" | ")[1]}
                          </td>
                          <td
                            className={`p-5 text-nowrap mw-125px`}
                            style={{
                              color:
                                item.txnType === "C" ? "#0000ff" : "#FF0000",
                            }}
                          >
                            {item?.txnStatus === 1 ? (
                              <>
                                {item.currency}{" "}
                                {item.amount.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}{" "}
                                <i
                                  className={`bi bi-arrow-${item.txnType == "C"
                                      ? "down-square"
                                      : "up-square"
                                    }`}
                                  style={{
                                    color:
                                      item.txnType === "C"
                                        ? "#0000ff"
                                        : "#FF0000",
                                  }}
                                ></i>
                              </>
                            ) : (
                              <span style={{color:"#FF0000"}}>
                                 Failed
                              </span>
                             
                            )}
                          </td>
                          <td className="p-5 text-gray-600 p-2 mw-100px">
                            <OverlayTrigger
                              placement="top"
                              overlay={tooltipView}
                            >
                              <i
                                className="bi bi-eye p-5 cursor-pointer"
                                style={{ color: "#0000ff" }}
                              ></i>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="d-flex justify-content-between">
                  <div className="p-5">
                    <ul className="pagination">
                      <li
                        className={`page-item double-arrow ${currentPage === 1 ? "disabled" : ""
                          }`}
                        key={0}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(1)}
                        >
                          <i className="previous"></i>
                          <i className="previous"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item previous ${currentPage === 1 ? "disabled" : ""
                          }`}
                        key={1}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="previous"></i>
                        </a>
                      </li>
                      {renderPageNumbers()}
                      <li
                        className={`page-item next ${currentPage ===
                            Math.ceil(filteredTransactions?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                          }`}
                        key={2}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="next"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item double-arrow ${currentPage ===
                            Math.ceil(filteredTransactions?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                          }`}
                        key={3}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() =>
                            handlePageChange(
                              Math.ceil(
                                filteredTransactions?.length / itemsPerPage
                              )
                            )
                          }
                        >
                          <i className="next"></i>
                          <i className="next"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="p-5 d-flex align-items-center">
                    <label
                      htmlFor="itemsPerPage"
                      style={{ fontWeight: "bold" }}
                    >
                      Total: {filteredTransactions?.length}&nbsp;&nbsp;
                    </label>
                    <select
                      id="itemsPerPage"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                      style={{ padding: "1px 2px 1px 2px" }}
                    >
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={75}>75</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
              </div>
            </>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Description</th>
                      <th className="min-w-100px p-5">Transaction ID</th>
                      <th className="min-w-100px p-5">Transaction Date</th>
                      <th className="min-w-100px p-5">Transaction Time</th>
                      <th className="min-w-100px p-5 text-nowrap">Amount</th>
                      <th className="min-w-100px p-5 text-nowrap">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData.length > 0 ? (
                      currentData.map((item, index) => {
                        if (item.txnType === "C") {
                          return (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                                // target.style.transform =
                                //   "translate3d(6px, -6px, 0)";
                                // target.style.boxShadow =
                                //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                                // target.style.transform = "none";
                                // target.style.boxShadow = "none";
                              }}
                              id="kt_receipt_modal_toggle"
                              onClick={() => handleDownloadClick(item.txnId)}
                            >
                              <td
                                className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline"
                                style={{ cursor: "pointer" }}
                              >
                                {item.txnTypeName}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.txnId}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.dateTime.split(" | ")[0]}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.dateTime.split(" | ")[1]}
                              </td>
                              <td
                                className={`p-5 text-nowrap`}
                                style={{
                                  color:
                                    item.txnType === "C"
                                      ? "#0000ff"
                                      : "#FF0000",
                                }}
                              >
                                {item.currency}{" "}
                                {item.amount.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}{" "}
                                <i
                                  className={`bi bi-arrow-${item.txnType == "C"
                                      ? "down-square"
                                      : "up-square"
                                    }`}
                                  style={{
                                    color:
                                      item.txnType === "C"
                                        ? "#0000ff"
                                        : "#FF0000",
                                  }}
                                ></i>
                              </td>
                              <td className="p-5 text-gray-600 p-2">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltipView}
                                >
                                  <i
                                    className="bi bi-eye p-5 cursor-pointer"
                                    style={{ color: "#0000ff" }}
                                  ></i>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="d-flex justify-content-between">
                  <div className="p-5">
                    <ul className="pagination">
                      <li
                        className={`page-item double-arrow ${currentPage === 1 ? "disabled" : ""
                          }`}
                        key={0}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(1)}
                        >
                          <i className="previous"></i>
                          <i className="previous"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item previous ${currentPage === 1 ? "disabled" : ""
                          }`}
                        key={1}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="previous"></i>
                        </a>
                      </li>
                      {renderPageNumbers()}
                      <li
                        className={`page-item next ${currentPage ===
                            Math.ceil(filteredTransactions?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                          }`}
                        key={2}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="next"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item double-arrow ${currentPage ===
                            Math.ceil(filteredTransactions?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                          }`}
                        key={3}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() =>
                            handlePageChange(
                              Math.ceil(
                                filteredTransactions?.length / itemsPerPage
                              )
                            )
                          }
                        >
                          <i className="next"></i>
                          <i className="next"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="p-5 d-flex align-items-center">
                    <label
                      htmlFor="itemsPerPage"
                      style={{ fontWeight: "bold" }}
                    >
                      Total: {filteredTransactions?.length}&nbsp;&nbsp;
                    </label>
                    <select
                      id="itemsPerPage"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                      style={{ padding: "1px 2px 1px 2px" }}
                    >
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={75}>75</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
              </div>
            </>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Description</th>
                      <th className="min-w-100px p-5">Transaction ID</th>
                      <th className="min-w-100px p-5">Transaction Date</th>
                      <th className="min-w-100px p-5">Transaction Time</th>
                      <th className="min-w-100px p-5 text-nowrap">Amount</th>
                      <th className="min-w-100px p-5 text-nowrap">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData && currentData.length > 0 ? (
                      currentData.map((item, index) => {
                        if (item.txnType === "D") {
                          return (
                            <tr
                              key={index}
                              onMouseOver={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "#f1f1f2";
                                // target.style.transform =
                                //   "translate3d(6px, -6px, 0)";
                                // target.style.boxShadow =
                                //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                              }}
                              onMouseOut={(e) => {
                                const target = e.currentTarget as HTMLElement;
                                target.style.backgroundColor = "white";
                                // target.style.transform = "none";
                                // target.style.boxShadow = "none";
                              }}
                              id="kt_receipt_modal_toggle"
                              onClick={() => handleDownloadClick(item.txnId)}
                            >
                              <td
                                className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline"
                                style={{ cursor: "pointer" }}
                              >
                                {item.txnTypeName}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.txnId}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.dateTime.split(" | ")[0]}
                              </td>
                              <td className="p-5 text-gray-600">
                                {item.dateTime.split(" | ")[1]}
                              </td>
                              <td
                                className={`p-5 text-nowrap`}
                                style={{
                                  color:
                                    item.txnType === "D"
                                      ? "#FF0000"
                                      : "#0000ff",
                                }}
                              >
                                {item.currency}{" "}
                                {item.amount.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}{" "}
                                <i
                                  className={`bi bi-arrow-${item.txnType == "D"
                                      ? "up-square"
                                      : "down-square"
                                    }`}
                                  style={{
                                    color:
                                      item.txnType === "D"
                                        ? "#FF0000"
                                        : "#0000ff",
                                  }}
                                ></i>
                              </td>
                              <td className="p-5 text-gray-600 p-2">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltipView}
                                >
                                  <i
                                    className="bi bi-eye p-5 cursor-pointer"
                                    style={{ color: "#0000ff" }}
                                  ></i>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="d-flex justify-content-between">
                  <div className="p-5">
                    <ul className="pagination">
                      <li
                        className={`page-item double-arrow ${currentPage === 1 ? "disabled" : ""
                          }`}
                        key={0}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(1)}
                        >
                          <i className="previous"></i>
                          <i className="previous"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item previous ${currentPage === 1 ? "disabled" : ""
                          }`}
                        key={1}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="previous"></i>
                        </a>
                      </li>
                      {renderPageNumbers()}
                      <li
                        className={`page-item next ${currentPage ===
                            Math.ceil(filteredTransactions?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                          }`}
                        key={2}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="next"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item double-arrow ${currentPage ===
                            Math.ceil(filteredTransactions?.length / itemsPerPage)
                            ? "disabled"
                            : ""
                          }`}
                        key={3}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() =>
                            handlePageChange(
                              Math.ceil(
                                filteredTransactions?.length / itemsPerPage
                              )
                            )
                          }
                        >
                          <i className="next"></i>
                          <i className="next"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="p-5 d-flex align-items-center">
                    <label
                      htmlFor="itemsPerPage"
                      style={{ fontWeight: "bold" }}
                    >
                      Total: {filteredTransactions?.length}&nbsp;&nbsp;
                    </label>
                    <select
                      id="itemsPerPage"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                      style={{ padding: "1px 2px 1px 2px" }}
                    >
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={75}>75</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
              </div>
            </>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
            <>
              <div className="card p-2">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6 ">
                      <th className="min-w-100px p-5">Payee Name</th>
                      <th className="min-w-100px p-5">Transaction Date</th>
                      <th className="min-w-100px p-5">Transaction Time</th>
                      <th className="min-w-100px p-5 text-nowrap">Amount</th>
                      <th className="min-w-100px p-5">Status</th>
                      <th className="min-w-100px p-5 text-nowrap">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentSchData && currentSchData.length > 0 ? (
                      currentSchData.map((item, index) => (
                        <tr
                          key={index}
                          onMouseOver={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "#f1f1f2";
                            // target.style.transform =
                            //   "translate3d(6px, -6px, 0)";
                            // target.style.boxShadow =
                            //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                          }}
                          onMouseOut={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "white";
                            // target.style.transform = "none";
                            // target.style.boxShadow = "none";
                          }}
                          onClick={() =>
                            setSelectedScheduledData(item?.receipt)
                          }
                        >
                          <td
                            className="p-5 text-primary text-hover-dark fw-hover-bold fs-6 text-decoration-underline"
                            style={{ cursor: "pointer" }}
                            id="kt_schedule_receipt_modal_toggle"
                          >
                            {item.payeeAccountNumber}
                          </td>
                          {/* <td className="p-5 text-gray-600">{item.txnId}</td> */}
                          <td
                            className="p-5 text-gray-600"
                            id="kt_schedule_receipt_modal_toggle"
                          >
                            {item.txnScheduleDate.split(" ")[0]}
                          </td>
                          <td
                            className="p-5 text-gray-600"
                            id="kt_schedule_receipt_modal_toggle"
                          >
                            {item.txnScheduleDate.split(" ")[1]}
                          </td>
                          <td
                            className="p-5 text-gray-600"
                            id="kt_schedule_receipt_modal_toggle"
                          >
                            {item.currency ? item.currency : "ZAR "}{" "}
                            {item.amount.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td
                            className="p-5 text-gray-600"
                            id="kt_schedule_receipt_modal_toggle"
                          >
                            {item.txnStatus === 3
                              ? "CANCELLED"
                              : item.txnStatus === 8
                                ? "SCHEDULED"
                                : item.txnStatus === 15
                                  ? "COMPLETED"
                                  : item.txnStatus === 19
                                    ? "FAILED"
                                    : ""}
                          </td>
                          <td className="p-5 text-gray-600 p-2">
                            <OverlayTrigger
                              placement="top"
                              overlay={tooltipView}
                            >
                              <i
                                className="bi bi-eye p-5 cursor-pointer"
                                style={{ color: "#0000ff" }}
                                id="kt_schedule_receipt_modal_toggle"
                              ></i>
                            </OverlayTrigger>
                            {item.txnStatus === 8 && (
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltipCancel}
                              >
                                <i
                                  className="bi bi-x-circle fs-2 p-5 cursor-pointer"
                                  style={{ color: "#FF0000" }}
                                  onClick={() => handleConfirmation(item)}
                                ></i>
                              </OverlayTrigger>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="d-flex justify-content-between">
                  <div className="p-5">
                    <ul className="pagination">
                      <li
                        className={`page-item double-arrow ${currentPage === 1 ? "disabled" : ""
                          }`}
                        key={0}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(1)}
                        >
                          <i className="previous"></i>
                          <i className="previous"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item previous ${currentPage === 1 ? "disabled" : ""
                          }`}
                        key={1}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="previous"></i>
                        </a>
                      </li>
                      {renderSchPageNumbers()}
                      <li
                        className={`page-item next ${currentPage ===
                            Math.ceil(
                              scheduleFilteredTransactions?.length / itemsPerPage
                            )
                            ? "disabled"
                            : ""
                          }`}
                        key={2}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="next"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item double-arrow ${currentPage ===
                            Math.ceil(
                              scheduleFilteredTransactions?.length / itemsPerPage
                            )
                            ? "disabled"
                            : ""
                          }`}
                        key={3}
                      >
                        <a
                          href="#"
                          className="page-link"
                          onClick={() =>
                            handlePageChange(
                              Math.ceil(
                                scheduleFilteredTransactions?.length /
                                itemsPerPage
                              )
                            )
                          }
                        >
                          <i className="next"></i>
                          <i className="next"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="p-5 d-flex align-items-center">
                    <label
                      htmlFor="itemsPerPage"
                      style={{ fontWeight: "bold" }}
                    >
                      Total: {scheduleFilteredTransactions?.length}&nbsp;&nbsp;
                    </label>
                    <select
                      id="itemsPerPage"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                      style={{ padding: "1px 2px 1px 2px", cursor: "pointer" }}
                    >
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={75}>75</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id="date_range" ref={modalRef}>
        <div
          className="modal-dialog w-400px"
          style={{ margin: "0 auto", marginTop: "10%" }}
        >
          <div className="modal-content">
            <div className="modal-body p-10">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex flex-column gap-10 w-100">
                  <div className="d-flex flex-column gap-5 ">
                    <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                      <label className="fw-bold" htmlFor="Radio1">
                        This Week
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        id="Radio1"
                        value={stmntDuration}
                        onChange={() => {
                          setDateRange(false);
                          setStmntDuration("1");
                          setValidationError("");
                          setToDate("");
                          setFromDate("");
                        }}
                        checked={stmntDuration === "1"}
                      />
                    </div>
                    <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                      <label className="fw-bold" htmlFor="Radio2">
                        Last Week
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        id="Radio2"
                        value={stmntDuration}
                        onChange={() => {
                          setDateRange(false);
                          setStmntDuration("2");
                          setValidationError("");
                          setToDate("");
                          setFromDate("");
                        }}
                        checked={stmntDuration === "2"}
                      />
                    </div>
                    <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                      <label className="fw-bold" htmlFor="Radio2">
                        Last Month
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        id="Radio2"
                        value={stmntDuration}
                        onChange={() => {
                          setDateRange(false);
                          setStmntDuration("7");
                          setValidationError("");
                          setToDate("");
                          setFromDate("");
                        }}
                        checked={stmntDuration === "7"}
                      />
                    </div>
                    <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                      <label className="fw-bold" htmlFor="Radio3">
                        Last 3 Month
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        id="Radio3"
                        onChange={() => {
                          setDateRange(false);
                          setStmntDuration("3");
                          setValidationError("");
                          setToDate("");
                          setFromDate("");
                        }}
                        checked={stmntDuration === "3"}
                      />
                    </div>
                    <div className="form-check d-flex justify-content-between p-4 rounded shadow-sm">
                      <label className="fw-bold" htmlFor="Radio4">
                        Last 6 Month
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        id="Radio4"
                        onChange={() => {
                          setDateRange(false);
                          setStmntDuration("4");
                          setValidationError("");
                          setToDate("");
                          setFromDate("");
                        }}
                        checked={stmntDuration === "4"}
                      />
                    </div>
                    <div
                      className={`form-check d-flex justify-content-between ${dateRange ? "mb-0" : "mb-2"
                        } p-4 rounded shadow-sm`}
                    >
                      <label className="fw-bold" htmlFor="Radio5">
                        Date Range
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="listGroupRadio"
                        id="Radio5"
                        onChange={() => {
                          setDateRange(true);
                          setStmntDuration("5");
                        }}
                        checked={stmntDuration === "5"}
                      />
                    </div>

                    {dateRange && (
                      <>
                        {/* <li className='list-group-item'> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              label="Start Date"
                              disableFuture
                              slotProps={{
                                textField: {
                                  className: "mb-2",
                                  size: "small",
                                  style: {
                                    width: "35vw",
                                  },
                                },
                              }}
                              value={fromDate ? dayjs(fromDate) : null}
                              onChange={(date) => {
                                handleFromDateChange(date);
                                // validateDates();
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              label="End Date"
                              disableFuture
                              slotProps={{
                                textField: {
                                  size: "small",
                                  style: {
                                    width: "35vw",
                                  },
                                },
                              }}
                              value={toDate ? dayjs(toDate) : null}
                              onChange={(date) => {
                                handleToDateChange(date);
                                // validateDates();
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        {/* </li> */}
                        {validationError && (
                          <p className="text-danger">{validationError}</p>
                        )}
                      </>
                    )}
                    {/* </ul> */}
                  </div>
                </div>

                <div className="d-flex flex-row gap-5 mt-5">
                  <button
                    type="button"
                    className={`rounded ${validationError ? "disabled-btn" : "btn"
                      }`}
                    style={{
                      width: "150px",
                      color: "#ffff",
                    }}
                    onClick={() => {
                      setCurrentPage(1);
                      if (stmntDuration) handleGenerateClick();
                    }}
                  >
                    View
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary rounded"
                    data-bs-dismiss="modal"
                    style={{
                      width: "150px",
                      color: "#246bfd",
                    }}
                    onClick={() => {
                      handleDeleteCloseModal();
                      setStmntDuration("4");
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TxnReceipt data={selectedData} />
      <ScheduleTxnReceipt data={selectedScheduledData} />
    </>
  );
};

export default Transaction;
