import "quill/dist/quill.snow.css";
import "react-quill/dist/quill.snow.css";
import { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import { useLocation, useNavigate } from "react-router-dom";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { formatTimestamp } from "./ServiceTicket";
import { Modal } from "bootstrap";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";

interface Attachment {
  documentName: string;
  documentPath: string;
  cloneDocument: boolean;
}

interface Conversation {
  type: string;
  date: string;
  authorType: string;
  authorEmail?: string;
  authorName?: string;
  message?: string;
  attachments?: Attachment[];
}

interface Ticket {
  ticketId: string;
  shortID: string;
  createdAt: string;
  status: string;
  priority: number;
  subject: string;
  unreadMessage: boolean;
  lastAdminMessageId: number;
  conversations: Conversation[];
}

export const Api = {
  api: (ticketId: string): void => {},
};

export const ReplyPage = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const cardBodyRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<ReactQuill>(null);
  const imageModalRef = useRef<HTMLDivElement | null>(null);
  const replyImageModalRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [ticket, setTicket] = useState<Ticket>({
    ticketId: "",
    shortID: "",
    createdAt: "",
    status: "",
    priority: 0,
    subject: "",
    unreadMessage: false,
    lastAdminMessageId: 0,
    conversations: [],
  });
  const [selectedImage, setSelectedImage] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [previewUrl, setPreviewUrl] = useState<string>("");
  const [previewImage, setPreviewImage] = useState<string>("");
  const [imageLoading, setImageLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const ticketId = location.state as { data: string };
  const clearPreviewData = () => {
    setPreviewUrl("");
    setPreviewImage("");
  };

  const markAsRead = async (ticketId: string, messageId: number) => {
    try {
      const url = `${API_URL}/helpDesk/markLastReadMessageId`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        ticketId: ticketId,
        lastAdminMessageId: messageId,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getTicketById = async (ticketId: string) => {
    try {
      const url = `${API_URL}/helpDesk/getTicketById`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        ticketId: ticketId,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setTicket(data.message);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    if (ticket?.unreadMessage && ticket?.lastAdminMessageId !== 0)
      markAsRead(ticket?.ticketId, ticket?.lastAdminMessageId);
  }, [ticket]);

  Api.api = getTicketById;

  useEffect(() => {
    if (ticketId) getTicketById(ticketId?.data);
  }, [ticketId]);

  useEffect(() => {
    if (cardBodyRef.current) {
      const cardBody = cardBodyRef.current;
      // Check if there's content that might cause scrolling
      if (cardBody.scrollHeight > cardBody.clientHeight) {
        // Set the scroll position to the bottom/end
        cardBody.scrollTop = cardBody.scrollHeight - cardBody.clientHeight;
      }
    }
  }, [ticket]);

  const handleImageUpload = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*,.pdf,.xlsx,.csv");
    input.setAttribute("multiple", "true");
    input.click();
  
    input.onchange = async () => {
      const files = input.files;
      if (files) {
        const newFilesArray = Array.from(files).filter(file => 
          file.type.startsWith('image/') || 
          file.type === 'application/pdf' ||
          file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          file.type === 'application/vnd.ms-excel' ||
          file.type === 'text/csv'
        );
        
        setSelectedFiles((prevFiles) => {
          // Combine previous files with new files
          const combinedFiles = [...prevFiles, ...newFilesArray];
          // Limit to 10 files, keeping the most recent ones
          const limitedFiles = combinedFiles.slice(-10);
          return limitedFiles;
        });
  
        setSelectedImage((prevImages) => {
          // Combine previous image names with new image names
          const combinedImages = [...prevImages, ...newFilesArray.map((file) => file.name)];
          // Limit to 10 image names, keeping the most recent ones
          const limitedImages = combinedImages.slice(-10);
          return limitedImages;
        });
      }
    };
  };

  const handleClick = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth", // Optional: smooth scrolling effect
    });
  };

  const removeFile = (indexToRemove: number) => {
    setSelectedFiles((prevFiles) => {
      // Remove the file at the specified index
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(indexToRemove, 1);
      return updatedFiles;
    });
    setSelectedImage((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(indexToRemove, 1);
      return updatedFiles;
    });
  };

  const handleViewClick = async (index: number) => {
    clearPreviewData();
    setFileLoading(true);
    previewFile(selectedFiles[index]);
    setTimeout(() => {
      const modalElement = document.getElementById("image_modal");
      const modal = new Modal(modalElement as Element);
      modal.show();
    }, 300);
  };

  const handleImageViewClick = async (path: string) => {
    clearPreviewData();
    setImageLoading(true);
    setPreviewImage(path);
    setTimeout(() => {
      const modalElement = document.getElementById("reply_image_modal");
      const modal = new Modal(modalElement as Element);
      modal.show();
    }, 300);
    setImageLoading(false);
  };

  const previewFile = (file: File) => {
    const reader = new FileReader();
    reader.onloadstart = () => {
      setFileLoading(true);
    };
    reader.onloadend = () => {
      setPreviewUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const createFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: "",
    },
    validationSchema: Yup.object().shape({
      message: Yup.string().required("message is required"),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      const formData = new FormData();
      formData.append("channel", "WEB");
      selectedFiles.forEach((file, index) => {
        formData.append(`files`, file);
      });
      formData.append("message", values?.message);
      formData.append("ticketId", ticketId?.data);

      const body = JSON.stringify({
        message: values?.message,
        ticketId: ticketId?.data,
      });

      setSubmitting(true);
      const headersforFile = {
        Authorization: `Bearer ${token}`,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      // Create the POST request options
      const requestOptions = {
        method: "POST",
        headers: selectedFiles?.length > 0 ? headersforFile : headers,
        body: selectedFiles?.length > 0 ? formData : body,
      };

      let apiUrlDAta = "";
      if (selectedFiles?.length > 0) {
        apiUrlDAta = "helpDesk/replyTicketWithAttachments";
      } else {
        apiUrlDAta = "helpDesk/replyTicket";
      }
      setLoading(true);
      // Send the POST request
      fetch(`${API_URL}/${apiUrlDAta}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result?.status?.statusCode === 0) {
            setLoading(false);
            getTicketById(ticketId?.data);
            createFormik.resetForm();
            // createFormik?.setFieldValue("message", "");
            setSelectedFiles([]);
            setSelectedImage([]);
            // getTickets();
            Swal.fire({
              icon: "success",
              title: "Message sent",
              text: result?.status?.messageDescription,
              allowEscapeKey: true,
              allowEnterKey: true,
            });
          } else {
            setLoading(false);
            Swal.fire({
              icon: "warning",
              title: "Failed",
              text: result?.status?.messageDescription,
              allowEscapeKey: true,
              allowEnterKey: true,
            });
          }
        })
        .catch((error) => console.error("error", error));

      createFormik.resetForm();
      // if (fileInputRef.current) {
      //   fileInputRef.current.value = "";
      // }
    },
  });

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center mb-4">
        <h4 className="">Service Ticket</h4>
      </div>

      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div
          className="card d-flex flex-column flex-grow-1"
          style={{
            height: "580px",
          }}
        >
          <div className="card-header align-items-center">
            <h2 className="fw-semibold ">{ticket?.subject}</h2>
          </div>
          <div
            ref={cardBodyRef}
            className="card-body p-5"
            style={{
              overflow: "auto",
            }}
          >
            {ticket && ticket?.conversations?.length > 0 ? (
              <>
                <div className="rounded p-4">
                  {ticket?.conversations?.map((conv, index) => (
                    <div
                      className={`d-flex justify-content-${
                        conv?.authorType === "agent" ? "start" : "end"
                      }`}
                    >
                      <div
                        className={`d-flex flex-column mw-50 justify-content-${
                          conv?.authorType === "agent" ? "start" : "end"
                        }`}
                      >
                        <p
                          className={`d-flex m-0 text-gray-600 justify-content-${
                            conv?.authorType === "agent" ? "start" : "end"
                          } fs-8`}
                        >
                          {conv?.authorType === "agent" ? conv?.authorName : ""}{" "}
                          {formatTimestamp(conv?.date)}
                        </p>
                        <div
                          className={`card border d-flex flex-column mb-4 px-4 py-2 justify-content-center ${
                            conv?.authorType === "agent"
                              ? "bg-light"
                              : "bg-success"
                          }`}
                          style={{
                            borderRadius: `${
                              conv?.authorType === "agent" ? "0px" : "10px"
                            } 10px ${
                              conv?.authorType === "agent" ? "10px" : "0px"
                            } 10px `,
                          }}
                        >
                          <div
                            style={{
                              whiteSpace: "pre-line",
                              color: `${
                                conv?.authorType === "agent" ? "black" : "white"
                              }`,
                            }}
                          >
                            {conv?.message}
                          </div>
                          {/* <p className="p-0 m-0">{conv?.message}</p> */}
                        </div>
                        <div className={``}>
                          {conv?.attachments?.map((docs) => (
                            <p
                              className={`d-flex mb-2 text-primary cursor-pointer justify-content-${
                                conv?.authorType === "agent" ? "start" : "end"
                              }`}
                              onClick={() => {
                                handleImageViewClick(docs?.documentPath);
                              }}
                            >
                              {docs?.documentName}
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <p>No ticket found</p>
            )}
          </div>
          <div className="card-footer p-5">
            {" "}
            <form
              id="kt_inbox_reply_form"
              onSubmit={createFormik?.handleSubmit}
            >
              <div className="d-flex flex-row ms-10 gap-3 cursor-pointer flex-wrap mb-3">
                {selectedImage &&
                  selectedImage?.map((name, index) => (
                    <div
                      key={index}
                      className="d-flex flex-row align-items-center bg-secondary px-2 py-1"
                      style={{
                        zIndex: 1,
                        borderRadius: "5px",
                      }}
                    >
                      <p className="m-0" onClick={() => handleViewClick(index)}>
                        {name}
                      </p>
                      <i
                        className="bi bi-x m-0 text-dark fs-4"
                        onClick={() => removeFile(index)}
                      ></i>
                    </div>
                  ))}
              </div>
              <div className="d-flex flex-row align-items-center gap-3">
                <span
                  className="btn btn-icon btn-sm btn-clean btn-active-light-primary me-2"
                  id="kt_inbox_reply_attachments_select"
                  data-kt-inbox-form="dropzone_upload"
                  onClick={() => {
                    if (selectedFiles?.length >= 10) {
                      Swal.fire({
                        icon: "warning",
                        text: "You can upload only upto 10 files at a time",
                        allowEscapeKey: true,
                        allowEnterKey: true,
                      });
                    } else {
                      handleImageUpload();
                    }
                  }}
                >
                  <i className="ki-duotone ki-paper-clip fs-2 m-0 p-2"></i>
                </span>
                <textarea
                  rows={1}
                  {...createFormik.getFieldProps("message")}
                  draggable={false}
                  name="message"
                  className="form-control"
                  style={{ resize: "none" }}
                  onChange={createFormik.handleChange}
                  placeholder="Write your message here..."
                />
                <div className="d-flex align-items-center me-3">
                  <button className="btn" type="submit">
                    Send
                  </button>
                </div>
                {/* <span
                  className="btn btn-icon btn-sm btn-clean btn-active-light-primary me-2"
               
                >
                  <i className="bi bi-send"></i>
                </span> */}
              </div>
              <p className="mt-2 fs-7">Upload only .pdf,.xlsx and all image types</p>
              {createFormik.touched.message && createFormik.errors.message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block px-2">
                    <span role="alert">{createFormik.errors.message}</span>
                  </div>
                </div>
              )}

              {/* <div className="d-flex py-5 ps-8 pe-5 border-top border-secondary align-items-center">
                <div className="d-flex align-items-center me-3">
                  <button className="btn" type="submit">
                    Send
                  </button>
                </div>

                <span
                  className="btn btn-icon btn-sm btn-clean btn-active-light-primary me-2"
                  id="kt_inbox_reply_attachments_select"
                  data-kt-inbox-form="dropzone_upload"
                  onClick={() => {
                    if (selectedFiles?.length >= 10) {
                      Swal.fire({
                        icon: "warning",
                        text: "You can upload only upto 10 files at a time",
                         allowEscapeKey: true,
          allowEnterKey: true,
                      });
                    } else {
                      handleImageUpload();
                    }
                  }}
                >
                  <i className="ki-duotone ki-paper-clip fs-2 m-0 p-2"></i>
                </span>

                <div className="d-flex align-items-center me-3"></div>
              </div> */}
            </form>
          </div>
        </div>

        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              zIndex: 9999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span>Loading...</span>
          </div>
        )}
      </div>

      <div className="modal" tabIndex={-1} id="image_modal" ref={imageModalRef}>
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content"
            style={{
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <div className="modal-body justify-content-center d-flex">
              {previewUrl && (
                <div className="image-preview">
                  {previewUrl.startsWith("data:application/pdf") ? (
                    // Render PDF viewer
                    <embed
                      src={`${previewUrl}#toolbar=0`}
                      type="application/pdf"
                      width="600px"
                      height="600px"
                    />
                  ) : previewUrl.startsWith(
                      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) ? (
                    // Render Excel viewer
                    <iframe
                      src={`${previewUrl}`}
                      width="600px"
                      height="600px"
                    />
                  ) : (
                    // Render image
                    <img
                      className="image"
                      src={previewUrl}
                      style={{
                        maxHeight: "800px",
                        maxWidth: "800px",
                        borderRadius: "10px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal"
        tabIndex={-1}
        id="reply_image_modal"
        ref={replyImageModalRef}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content"
            style={{
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            {/* <div className="d-flex flex-row justify-content-end"> */}
            {/* <label className="form-label fs-3">Profile Image</label> */}
            {/* <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                 <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div> */}
            <div className="modal-body justify-content-center d-flex">
              <div
                className="image-input image-input-outline"
                data-kt-image-input="true"
              >
                {previewImage && (
                  <div className="image-preview">
                    {previewImage.endsWith(".pdf") ? (
                      // Render PDF viewer
                      <embed
                        src={`${previewImage}#toolbar=0`}
                        type="application/pdf"
                        width="600px"
                        height="600px"
                      />
                    ) : previewImage.endsWith(".xlsx") ? (
                      // Render Excel viewer
                      <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${previewImage}`}
                        width="600px"
                        height="600px"
                      />
                    ) : (
                      // Render image
                      <img
                        className="image"
                        src={previewImage}
                        style={{
                          maxHeight: "800px",
                          maxWidth: "800px",
                          borderRadius: "10px",
                          objectFit: "contain",
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReplyPage;
