/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { StepProps } from "./EditPayrollAppModel";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
import { Bank } from "../PayrollStepper/PayrollStepperModal2";

export const SteppperModal2 = ({ data, updateData, hasError }: StepProps) => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  interface DesignationItem {
    key: string;
    value: string;
  }

  const [department, setDepartment] = useState<DesignationItem[]>([]);
  const [designation, setDesignation] = useState<DesignationItem[]>([]);
  const [bank, setBank] = useState([]);

  const viewDepartment = async () => {
    try {
      const url = `${API_URL}/payrollSetting/department/view`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setDepartment(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const viewDesignation = async () => {
    try {
      const url = `${API_URL}/payrollSetting/designation/view`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setDesignation(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const getBank = async () => {
    try {
      const url = `${API_URL}/invSetting/getBanks`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setBank(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBank();
    viewDepartment();
    viewDesignation();
  }, []);

  return (
    <>
      <div
        className="pb-5 card shadow-sm p-10"
        data-kt-stepper-element="content"
        style={{ maxHeight: "300px", overflow: "auto" }}
      >
        <div className="w-100">
          <div className="">
            <label className="form-label">Employee Id</label>
            <input
              type="text"
              className="form-control "
              placeholder="Employee ID"
              value={data.employeeID.employeeID}
              style={{ padding: "6.5px" }}
              // onChange={(e) =>
              //   updateData({
              //     employeeID: {
              //       employeeID: e.target.value,

              //     },
              //   })
              // }
            />
            {!data.employeeID.employeeID && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Employee Id is required
                </div>
              </div>
            )}
          </div>
          
          <div className="mt-8">
            <label className="form-label">Designation</label>
            <select
              className="form-select"
              data-control="select2"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                updateData({
                  designation: {
                    designation: e.target.value,
                  },
                });
              }}
              data-placeholder="Select an option"
              style={{ padding: "6.5px" }}
            >
              <option disabled value="">
                Select Designation
              </option>
              {designation?.map((item, index) => (
                <option
                  key={index}
                  value={item.key}
                  selected={data.designation.designation === item.value}
                >
                  {item["value"]}
                </option>
              ))}
            </select>

            {!data.designation.designation && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Designation is required
                </div>
              </div>
            )}
          </div>
          <div className="mt-8">
            <label className="form-label">Department</label>
            <select
              className="form-select"
              data-control="select1"
              data-placeholder="Select an option"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                updateData({
                  department: {
                    department: e.target.value,
                  },
                });
              }}
              style={{ padding: "6.5px" }}
            >
              <option disabled value="">
                Select Department
              </option>
              {department?.map((item, index) => (
                <option
                  key={index}
                  value={item.key}
                  selected={data.department.department === item.value}
                >
                  {item["value"]}
                </option>
              ))}
            </select>
            {!data.department.department && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Department is required
                </div>
              </div>
            )}
          </div>

          <div className="mt-8">
            <label className="form-label">Location</label>
            <input
              type="text"
              className="form-control "
              placeholder="Location"
              value={data.location.location}
              style={{ padding: "6.5px" }}
              onChange={(e) =>
                updateData({
                  location: {
                    location: e.target.value,
                  },
                })
              }
              maxLength={30}
            />
            {!data.location.location && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  location is required
                </div>
              </div>
            )}
          </div>
          <div className="mt-8">
            <label className="form-label">IBAN / Account Number</label>
            <input
              type="text"
              className="form-control"
              placeholder="IBAN / Account Number"
              value={data.accNumber.accNumber}
              style={{ padding: "6.5px" }}
              onChange={(e) => {
                const input = e.target.value;
                if (/^\d*$/.test(input)) {
                  updateData({
                    accNumber: {
                      accNumber: input,
                    },
                  });
                }
              }}
              maxLength={20}
            />
             {(!data.accNumber.accNumber || data.accNumber.accNumber.length < 10) && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="accNumber"
                  data-validator="minLength"
                  className="fv-help-block"
                >
                  {!data.accNumber.accNumber
                    ? "IBAN / Account Number is required"
                    : "IBAN / Account Number must be at least 10 digits long"}
                </div>
              </div>
            )}
          </div>
          <div className="mt-8">
            <label className="form-label">Bank Name</label>
            <select
              className="form-select"
              data-control="select2"
              value={data.accName.accName} // Use value prop to control the selected value
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const selectedValue = e.target.value;
                updateData({
                  accName: {
                    accName: selectedValue,
                  },
                });
              }}
              data-placeholder="Select an option"
              style={{ padding: "6.5px" }}
            >
              <option value="" style={{ color: "red" }}>
                Bank Name
              </option>
              {bank &&
                bank.map((item: Bank, index: number) => (
                  <option key={index} value={item.key}>
                    {item.value}
                  </option>
                ))}
            </select>
            {!data.accName.accName && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Bank Name is required
                </div>
              </div>
            )}
          </div>

          <div className="mt-8">
            <label className="form-label">SWIFT/BIC</label>
            <input
              type="text"
              className="form-control "
              placeholder="Bank Identifier Code"
              value={data.accIdentifier.accIdetifier}
              style={{ padding: "6.5px" }}
              onChange={(e) =>
                updateData({
                  accIdentifier: {
                    accIdetifier: e.target.value,
                  },
                })
              }
              maxLength={20}
            />
            {!data.accIdentifier.accIdetifier && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  SWIFT/BIC is required
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
