import { useState, useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage, FormikProps } from "formik";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import { Modal } from "bootstrap";
import { KTIcon } from "../../../_metronic/helpers";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { ApiData } from "./Table";
import { enhancedFetch } from "../../modules/auth/core/_requests";

interface BankResult {
  key: string;
  value: string;
}

interface PaymentDetails {
  modeOfPay: string;
  amount: string;
  bankName: string;
  txnRefNo: string;
  paymentDate: string;
}

interface InvPreviewData {
  tenantId: string;
  referenceId: string;
  invoiceGuid: string;
  invoiceNumber: string;
  businessName: string;
  businessAddress: string;
  customerGuid: string;
  customerName: string;
  invoiceDate: string;
  dueDate: string;
  subTotal: number;
  discount: number;
  tax: number;
  shipping: number;
  total: number;
  termsAndConditions: string;
  notes: string;
  invStatus: number;
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
  items: [
    {
      tenantId: string;
      referenceId: string;
      invoiceGuid: string;
      itemName: string;
      qty: number;
      rate: number;
      total: number;
    }
  ];
}

export function RecordPayModal(data: { data?: string | "" }) {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;
  const baseCurrency = sessionStorage.getItem("currency");

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const modalRefff = useRef<HTMLDivElement | null>(null);
  const [paymentMode, setPaymentMode] = useState("");
  const [banks, setBanks] = useState<BankResult[]>([]);
  const [invData, setData] = useState<InvPreviewData | null>(null);

  useEffect(() => {
    if (modalRefff.current) {
      const modal = new Modal(modalRefff.current);
      modalRefff.current.addEventListener(
        "hidden.bs.modal",
        handleDeleteCloseModal
      );
      return () => {
        modalRefff.current?.removeEventListener(
          "hidden.bs.modal",
          handleDeleteCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleRecordPaySuccess = () => {
    const modalElement = document.getElementById("recordPay_success");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleDeleteCloseModal = () => {
    const modalElement = modalRefff.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
    // handleDeleteConfirmation()
  };

  const getInvBanks = async () => {
    try {
      const url = `${API_URL}/invSetting/getBanks`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      // console.log(data);

      setBanks(data.results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getInvBanks();
  }, []);

  const LoadInvoice = async () => {
    try {
      const url = `${API_URL}/invoice/load`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        invoiceGuid: data?.data,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let dataa = await response.json();
      setData(dataa.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data.data) {
      LoadInvoice();
    }
  }, [data.data]);

  const recordPay = async (details: PaymentDetails) => {
    try {
      const url = `${API_URL}/invoice/recordPayment`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        invoiceGuid: data.data,
        modeOfPay: +details.modeOfPay,
        amount: +details.amount,
        bankName: details.bankName,
        txnRefNo: details.txnRefNo,
        paymentDate: details.paymentDate,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let res = await response.json();
      if (res.status.statusCode === 0) {
        handleDeleteCloseModal();
        ApiData.api();
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Your invoice information has been updated",
          showCancelButton: false, // Hide the cancel button
          confirmButtonColor: "#007bff", // Set the button color to btn
          confirmButtonText: "OK",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        tabIndex={-1}
        id="recordpay_modal"
        ref={modalRefff}
      >
        <div
          className="modal-dialog w-400px"
          style={{ margin: "0 auto", marginTop: "5%" }}
        >
          <div className="modal-content">
            <div className="modal-head d-flex align-items-center justify-content-between p-4 pb-0">
              <div className="fs-3 fw-bold px-5">Invoice Info</div>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                onClick={() => {
                  handleDeleteCloseModal();
                }}
              >
                <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
              </div>
            </div>
            <div className="modal-body pt-0">
              <div className="mb-5">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    amount: invData?.total.toString(),
                    paymentDate: "",
                    bankName: "",
                    modeOfPay: "",
                    txnRefNo: "",
                  }}
                  validationSchema={Yup.object({
                    amount: Yup.number()
                      .required("Amount is required")
                      .positive("Input value must be positive")
                      .max(99999999, "Input value must be less than 99999999"),
                    paymentDate: Yup.date()
                      .required("Payment date is required")
                      .nullable(),
                    // bankName: Yup.string().required("Bank Name is required"),
                    modeOfPay: Yup.string().required(
                      "Mode of Payment is required"
                    ),
                    // txnRefNo: Yup.string().required(
                    //   "Transaction Reference Number is required"
                    // ),
                  })}
                  onSubmit={(values, { resetForm }) => {
                    // Submit logic here...

                    recordPay(values as PaymentDetails);
                    resetForm();
                  }}
                >
                  {({
                    values,
                    setFieldValue,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm,
                  }) => (
                    <div className="p-3">
                      <Form>
                        <div className="mb-3">
                          <label className="form-label">Amount</label>
                          <Field
                            type="text"
                            id="amount"
                            name="amount"
                            className="form-control text-end"
                            placeholder="Amount"
                            value={invData?.total}
                          />
                          <span
                            className="position-absolute fw-bold"
                            style={{ marginTop: "-30px", marginLeft: "10px" }}
                          >
                            {baseCurrency}
                          </span>
                          <ErrorMessage
                            name="amount"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="d-flex flex-column mb-5">
                          <label className="form-label">Select Date</label>
                          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              // label="Select Date"
                              value={values.paymentDate}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  style: {
                                    width: "23.2vw",
                                  },
                                },
                              }}
                              onChange={(date) =>
                                setFieldValue(
                                  "paymentDate",
                                  dayjs(date).format("YYYY-MM-DD")
                                )
                              }
                            />
                          </LocalizationProvider> */}

                          <input
                            type="date"
                            value={values.paymentDate}
                            onChange={(e) => setFieldValue("paymentDate", e.target.value)}
                           className="form-control"
                          />


                          <ErrorMessage
                            name="paymentDate"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="mb-5">
                          <label className="form-label">Mode of Pay</label>
                          <Field
                            as="select"
                            id="modeOfPay"
                            name="modeOfPay"
                            className="form-select"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="">Select</option>
                            <option value="1">Cash</option>
                            <option value="2">Cheque</option>
                            <option value="3">Bank Transfer</option>
                          </Field>
                          <ErrorMessage
                            name="modeOfPay"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        {values.modeOfPay && (
                          <>
                            {(values.modeOfPay === "2" ||
                              values.modeOfPay === "3") && (
                                <>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Select Bank
                                    </label>
                                    <Field
                                      as="select"
                                      id="bankName"
                                      name="bankName"
                                      className="form-select"
                                    >
                                      <option value="">Select</option>
                                      {banks.map((bank, index) => (
                                        <option key={index} value={bank?.value}>
                                          {bank?.value}
                                        </option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      name="bankName"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      {values.modeOfPay === "2"
                                        ? "Cheque Number"
                                        : "Transaction Refrence Number"}
                                    </label>
                                    <Field
                                      type="text"
                                      id="txnRefNo"
                                      name="txnRefNo"
                                      className="form-control"
                                    />
                                    <ErrorMessage
                                      name="txnRefNo"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                </>
                              )}
                          </>
                        )}
                        <div className="d-flex align-items-center justify-content-center">
                          <button type="submit" className="btn ">
                            Submit
                          </button>
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
