import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import { v4 as uuidv4 } from "uuid";
import { v5 as uuidv5 } from "uuid";
import { useAuth } from "../../core/Auth";
import { business } from "./api";
import { useNavigate } from "react-router-dom";
import { useFormCompletion } from "./Stepper";

const getLocation = async () => {
  return new Promise<GeolocationCoordinates>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position.coords);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

const BusinessDetails = () => {
  const deviceId = uuidv4();
  const [loading, setLoading] = useState(false);
  const { regUser, setRegUser } = useAuth();
  const [uuidLocation, setUuidLocation] = useState<string | null>(null);
  const navigate = useNavigate();
  const { markFormCompleted } = useFormCompletion();

  const getLocationAndGenerateUuid = async () => {
    try {
      const coords = await getLocation();
      const locationString = `${coords.latitude},${coords.longitude}`;
      const namespace = "1b671a64-40d5-491e-99b0-da01ff1f3341"; // Example UUID namespace
      const uuid = uuidv5(locationString, namespace);
      setUuidLocation(uuid);
    } catch (error) {
      console.error("Error getting location:", error);
    }
  };

  useEffect(() => {
    getLocationAndGenerateUuid();
    sessionStorage.setItem("deviceId", deviceId);
  }, []);

  const validationSchema = Yup.object({
    registeredBusinessName: Yup.string()
    .matches(/^[a-zA-Z0-9 ]+$/, "Only letters and numbers are allowed")
    .required("Business name required"),
    businessRegistrationDate: Yup.date().required(
      "Business registration date required"
    ),
    brandName: Yup.string()
     .matches(/^[a-zA-Z0-9 ]+$/, "Only letters and numbers are allowed"),
    businessRegistrationNumber: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, "Business registration number can only contain letters and numbers")
    .required("Business registration number is required"),
  
    cityOfBusiness: Yup.string() .matches(/^[a-zA-Z0-9 ]+$/, "Only letters and numbers are allowed").required("City of business required"),
  });

  const formik = useFormik({
    initialValues: {
      registeredBusinessName: regUser?.businessName ?? "",
      brandName: regUser?.brandName ?? "",
      businessRegistrationDate:
        regUser?.yearOfIncorporation?.split("T")[0] ?? "",
      businessRegistrationNumber: regUser?.vatNumber ?? "",
      cityOfBusiness: regUser?.city ?? "",
      status: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus,setFieldValue, resetForm, setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        const res = await business(
          true,
          regUser?.mobile ?? "",
          regUser?.isdCode ?? 91,
          values?.brandName,
          values?.registeredBusinessName,
          values?.businessRegistrationDate,
          values?.businessRegistrationNumber,
          values?.cityOfBusiness,
          deviceId,
          "WEB",
          uuidLocation ?? "",
          "103.171.98.61",
          regUser?.token ?? ""
        );

        if (res?.data?.status?.statusCode === 0) {
          setLoading(false);
          navigate("/auth/documents");
          markFormCompleted("business");
          setRegUser((prevState) => ({
            ...prevState,
            ...res?.data?.message,
          }));

          //   resetForm();
        } else {
          setFieldValue("status", res?.data?.status?.messageDescription);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    console.log(formik.values);
  });
  //

  return (
    <div className="d-flex flex-column justify-content-center">
      <p className="fs-2 fw-bold text-center">
        Please provide us with your business details
      </p>
      <div className="p-10">
        {formik?.values?.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              {formik?.values?.status}
            </div>
          </div>
        )}
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-5">
            <label className="form-label">Registered Business name</label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.registeredBusinessName &&
                formik.errors.registeredBusinessName
                  ? "is-invalid"
                  : ""
              }`}
              placeholder="Business Name"
              id="registeredBusinessName"
              name="registeredBusinessName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.registeredBusinessName}
              maxLength={50}
            />
            {formik.touched.registeredBusinessName &&
            formik.errors.registeredBusinessName ? (
              <div className="invalid-feedback">
                {formik.errors.registeredBusinessName}
              </div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">Brand Name</label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.brandName && formik.errors.brandName
                  ? "is-invalid"
                  : ""
              }`}
              placeholder="Brand Name (If any)"
              id="brandName"
              name="brandName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.brandName}
              maxLength={50}
            />
             {formik.touched.brandName &&
            formik.errors.brandName ? (
              <div className="invalid-feedback">
                {formik.errors.brandName}
              </div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">Business Registration date</label>
            <input
              type="date"
              className={`form-control ${
                formik.touched.businessRegistrationDate &&
                formik.errors.businessRegistrationDate
                  ? "is-invalid"
                  : ""
              }`}
              placeholder="Business Registration date"
              id="businessRegistrationDate"
              name="businessRegistrationDate"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.businessRegistrationDate}
              max={new Date().toISOString().split("T")[0]} 
            />
            {formik.touched.businessRegistrationDate &&
            formik.errors.businessRegistrationDate ? (
              <div className="invalid-feedback">
                {formik.errors.businessRegistrationDate}
              </div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">Business registration number</label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.businessRegistrationNumber &&
                formik.errors.businessRegistrationNumber
                  ? "is-invalid"
                  : ""
              }`}
              placeholder="Business registration number"
              id="businessRegistrationNumber"
              name="businessRegistrationNumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.businessRegistrationNumber}
              maxLength={20}
            />
            {formik.touched.businessRegistrationNumber &&
            formik.errors.businessRegistrationNumber ? (
              <div className="invalid-feedback">
                {formik.errors.businessRegistrationNumber}
              </div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">City of Business</label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.cityOfBusiness && formik.errors.cityOfBusiness
                  ? "is-invalid"
                  : ""
              }`}
              placeholder="City of Business"
              id="cityOfBusiness"
              name="cityOfBusiness"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.cityOfBusiness}
              maxLength={30}
            />
            {formik.touched.cityOfBusiness && formik.errors.cityOfBusiness ? (
              <div className="invalid-feedback">
                {formik.errors.cityOfBusiness}
              </div>
            ) : null}
          </div>
          <div className="mt-20">
            <button type="submit" className="btn w-100" disabled={formik.isSubmitting || loading}>
              {loading ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <div>Next</div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BusinessDetails;
