import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../core/Auth";
import { business, primaryApplicant } from "./api";
import { useNavigate } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
import { useFormCompletion } from "./Stepper";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";

const PrimaryApplicant = () => {
  const deviceId = uuidv4();
  const [previewSrc, setPreviewSrc] = useState("");
  const [extension, setExtension] = useState("");
  const { regUser, setRegUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const navigate = useNavigate();
  const { markFormCompleted } = useFormCompletion();

  const maxSize = 3 * 1024 * 1024;
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(/^[a-zA-Z0-9 ]+$/, "Only letters and numbers are allowed")
      .required("First name required"),
    lastName: Yup.string()
      .matches(/^[a-zA-Z0-9 ]+$/, "Only letters and numbers are allowed")
      .required("Last name required"),
    dateOfBirth: Yup.date().required("Date of birth required"),
    emailId: Yup.string()
      .required("Email ID required")
      .email("Wrong email format")
      .min(8, "Minimum 8 characters")
      .max(50, "Maximum 50 characters")
      .matches(
        /^[a-z0-9.@]+$/,
        "Only letters (a-z), digits (0-9), and periods (.) are allowed"
      ),
    idNumber: Yup.string()
      .matches(/^[a-zA-Z0-9 ]+$/, "Only letters and numbers are allowed")
      .required("ID number required"),
    nationality: Yup.string()
      .matches(/^[a-zA-Z0-9 ]+$/, "Only letters and numbers are allowed")
      .required("Nationality is required"),
    idProofType: Yup.string().required("Id proof type required"),
    file: Yup.mixed()
      .nullable()
      .test("fileType", "Upload PDF or images", function (value) {
        const reapply = regUser?.reapply;
        if (reapply) {
          return true;
        }
        if (!value) return true; // Allow null or undefined

        const file = value as File;
        const validExtensions = [
          ".pdf",
          ".jpg",
          ".jpeg",
          ".png",
          ".svg",
          ".gif",
          ".bmp",
          ".webp",
          ".tiff",
        ];
        const extension = file.name
          ? file.name.slice(file.name.lastIndexOf(".")).toLowerCase()
          : "";
        return validExtensions.includes(extension);
      })
      .test(
        "fileSize",
        "File size must be less than or equal to 3 MB",
        function (value) {
          const reapply = regUser?.reapply;
          if (reapply) {
            return true;
          }
          if (!value) return true; // Allow null or undefined

          const file = value as File;
          return file.size <= maxSize;
        }
      ),
  });

  const handlePreview = (documentPath: string, documentExtension: string) => {
    setPreviewSrc(documentPath);
    setExtension(documentExtension);
  };

  const formik = useFormik({
    initialValues: {
      firstName: regUser?.firstName ?? "",
      lastName: regUser?.lastName ?? "",
      dateOfBirth: regUser?.dob?.split("T")[0] ?? "",
      emailId: regUser?.email ?? "",
      mobile: regUser?.isdCode?.toString().concat(regUser?.mobile),
      nationality: regUser?.nationality ?? "",
      idProofType: regUser?.idType ?? "",
      file: null,
      idNumber: regUser?.idNumber ?? "",
      status: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setFieldValue, resetForm, setSubmitting }) => {
      setLoading(true); // Start loading
      setSubmitting(true); // Indicate form is being submitted

      try {
        const res = await primaryApplicant(
          values?.file,
          values?.firstName,
          values?.lastName,
          values?.dateOfBirth,
          values?.emailId,
          values?.nationality,
          values?.idProofType,
          values?.idNumber,
          regUser?.token ?? "",
          regUser?.reapply ?? false
        );

        if (res?.data?.status?.statusCode === 0) {
          setRegUser((prevState) => ({
            ...prevState,
            ...res?.data?.message,
          }));

          markFormCompleted("primary-applicant");
          navigate("/auth/smileId");
          resetForm();
        } else {
          setFieldValue("status", res?.data?.status?.messageDescription);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false); // Stop loading
        setSubmitting(false); // Indicate form submission is complete
      }
    },
  });

  console.log(regUser);

  return (
    <div
      className="card shadow-sm p-5 "
      style={{ maxHeight: "600px", overflow: "auto" }}
    >
      <p className="fs-2 fw-bold text-center">
        Please enter the primary applicant’s details. (Director 1)
      </p>

      <div className="p-10">
        {formik?.values?.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              {formik?.values?.status}
            </div>
          </div>
        )}
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-5">
            <label className="form-label">First Name</label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.firstName && formik.errors.firstName
                  ? "is-invalid"
                  : ""
              }`}
              placeholder="First Name"
              maxLength={50}
              id="firstName"
              name="firstName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="invalid-feedback">{formik.errors.firstName}</div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">Last Name</label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.lastName && formik.errors.lastName
                  ? "is-invalid"
                  : ""
              }`}
              placeholder="Last Name"
              id="lastName"
              name="lastName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              maxLength={50}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="invalid-feedback">{formik.errors.lastName}</div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">Date Of Birth</label>
            <input
              type="date"
              className={`form-control ${
                formik.touched.dateOfBirth && formik.errors.dateOfBirth
                  ? "is-invalid"
                  : ""
              }`}
              placeholder="Date Of Birth"
              id="dateOfBirth"
              name="dateOfBirth"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dateOfBirth}
              max={
                new Date(new Date().setFullYear(new Date().getFullYear() - 15))
                  .toISOString()
                  .split("T")[0]
              }
            />
            {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
              <div className="invalid-feedback">
                {formik.errors.dateOfBirth}
              </div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">
              Email Id (To be used as registered ID)
            </label>
            <input
              type="email"
              className={`form-control ${
                formik.touched.emailId && formik.errors.emailId
                  ? "is-invalid"
                  : ""
              }`}
              placeholder="Email Id"
              id="emailId"
              name="emailId"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.emailId}
              maxLength={50}
            />
            {formik.touched.emailId && formik.errors.emailId ? (
              <div className="invalid-feedback">{formik.errors.emailId}</div>
            ) : null}
          </div>
          <div className="mb-5">
            <label className="form-label">Mobile</label>
            <PhoneInput
              inputStyle={{
                width: "100%",
                height: "43.59px",
              }}
              countrySelectorStyleProps={{
                buttonStyle: {
                  height: "43.59px",
                },
              }}
              hideDropdown={true}
              inputProps={{
                readOnly: true,
              }}
              value={formik.values.mobile}
              onChange={(value) => formik.setFieldValue("mobile", value)}
              defaultCountry="in"
            />
          </div>
          <div className="mb-5">
            <label className="form-label">Nationality</label>
            <div>
              <select
                className={`form-select ${
                  formik.touched.nationality && formik.errors.nationality
                    ? "is-invalid"
                    : ""
                }`}
                name="nationality"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nationality}
                aria-label="Select example"
              >
                <option value="">Select</option>
                <option value="India">India</option>
                <option value="South Africa">South Africa</option>
                <option value="Uganda">Uganda</option>
              </select>
              {formik.touched.nationality && formik.errors.nationality ? (
                <div className="invalid-feedback">
                  {formik.errors.nationality}
                </div>
              ) : null}
            </div>
          </div>
          <div className="mb-5">
            <label className="form-label">Id Proof Type</label>
            <div>
              <select
                className={`form-select ${
                  formik.touched.idProofType && formik.errors.idProofType
                    ? "is-invalid"
                    : ""
                }`}
                name="idProofType"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.idProofType}
                aria-label="Select example"
              >
                <option value="">Select</option>
                <option value="NATIONAL_ID">NID</option>
                <option value="NATIONAL_ID_NO_PHOTO">Passport</option>
              </select>
              {formik.touched.idProofType && formik.errors.idProofType ? (
                <div className="invalid-feedback">
                  {formik.errors.idProofType}
                </div>
              ) : null}
            </div>
          </div>
          <div className="mb-5">
            <label className="form-label">Upload Id Proof</label>
            <input
              type="file"
              ref={fileInputRef}
              className="form-control"
              onChange={(event) => {
                const file = event.currentTarget.files?.[0];
                formik.setFieldValue("file", file); // Set file value in Formik
              }}
              accept="image/*,.pdf"
            />
            {formik.touched.file && formik.errors.file && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.file}</span>
                </div>
              </div>
            )}
            {regUser?.reapply &&
              !formik.values.file &&
              regUser?.idProofDocument && (
                <div className="d-flex flex-end">
                  <div>
                    <img
                      src={
                        regUser.idProofDocument.documentExtention === "pdf"
                          ? toAbsoluteUrl("/media/subicons/pdf.png")
                          : regUser.idProofDocument.documentPath
                      }
                      className="mt-2"
                      alt=""
                      style={{ height: "40px", width: "40px" }}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={() =>
                        handlePreview(
                          regUser.idProofDocument.documentPath,
                          regUser.idProofDocument.documentExtention
                        )
                      }
                    />
                  </div>
                </div>
              )}
          </div>
          <div className="mb-5">
            <label className="form-label">ID Number</label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.idNumber && formik.errors.idNumber
                  ? "is-invalid"
                  : ""
              }`}
              placeholder="ID Number"
              id="idNumber"
              name="idNumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.idNumber}
              maxLength={20}
            />
            {formik.touched.idNumber && formik.errors.idNumber ? (
              <div className="invalid-feedback">{formik.errors.idNumber}</div>
            ) : null}
          </div>
          <div className="mt-20">
            <button
              type="submit"
              className="btn w-100"
              disabled={formik.isSubmitting || loading}
            >
              {formik.isSubmitting ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <div>Next</div>
              )}
            </button>
          </div>
        </form>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          style={{ display: "none", marginLeft: "27%" }}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="">
              <div className="">
                {previewSrc && (
                  <>
                    {extension === "pdf" ? (
                      <iframe
                        src={previewSrc + "#toolbar=0"}
                        title="PDF Preview"
                        style={{ width: "100%", height: "400px" }}
                      ></iframe>
                    ) : (
                      <img
                        src={previewSrc}
                        alt="Document Preview"
                        style={{
                          borderRadius: "10px",
                          maxHeight: "300px",
                          maxWidth: "300px",
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrimaryApplicant;
