import { useState, useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import { KTSVG } from "../../../../_metronic/helpers";
import Swal from "sweetalert2";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
import Authorize from "../../../modules/auth/components/Authorize";

export default function PayrollSetttings() {
  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const modalRef = useRef<HTMLDivElement | null>(null);
  const desmodalRef = useRef<HTMLDivElement | null>(null);
  const codemodalRef = useRef<HTMLDivElement | null>(null);

  const handleDeleteCloseModal = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
    setDepartmentName("");
    setDepartmentError(false);
  };

  const handleDesCloseModal = () => {
    const modalElement = desmodalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
    setDesignationError(false);
    setDesignationName("");
  };

  const handleCodeCloseModal = () => {
    const modalElement = codemodalRef.current;
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      modal?.hide();
    }
    setSequence("");
    setprefix("");
    setEmpError(false);
  };

  useEffect(() => {
    if (modalRef.current) {
      const modal = new Modal(modalRef.current);
      modalRef.current.addEventListener(
        "hidden.bs.modal",
        handleDeleteCloseModal
      );
      return () => {
        modalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleDeleteCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  useEffect(() => {
    if (desmodalRef.current) {
      const modal = new Modal(desmodalRef.current);
      desmodalRef.current.addEventListener(
        "hidden.bs.modal",
        handleDeleteCloseModal
      );
      return () => {
        desmodalRef.current?.removeEventListener(
          "hidden.bs.modal",
          handleDeleteCloseModal
        );
        modal.dispose();
      };
    }
  }, []);

  const handleAddDepartment = () => {
    const modalElement = document.getElementById("add_department");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };
  const handleEditDate = () => {
    const modalElement = document.getElementById("edit_date");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };
  const handleAddDesignation = () => {
    const modalElement = document.getElementById("add_designation");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };
  const handleAddEmpSequence = () => {
    const modalElement = document.getElementById("add_sequence");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleDepartmentDelete = async (key: string) => {
    try {
      const url = `${API_URL}/payrollSetting/department/delete`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({
        departmentGuid: key,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.status.statusCode === 0) {
        Swal.fire({
          icon: "success",
          text: "Department deleted",
          confirmButtonColor: "#007bff",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      const updatedDepartment = department.filter((item) => item.key !== key);
      setDepartment(updatedDepartment);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDepartmentEdit = async () => {
    try {
      const url = `${API_URL}/payrollSetting/department/update`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        departmentGuid: dptKey,
        name: editDepartment,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.status.statusCode === 0) {
        Swal.fire({
          icon: "success",
          text: "Department Updated",
          confirmButtonColor: "#007bff",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      } else {
        const errorMessage = data?.status.messageDescription;
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      getDepartment();
    } catch (error) {
      console.log(error);
    }
  };
  const handleDesignationEdit = async () => {
    try {
      const url = `${API_URL}/payrollSetting/designation/update`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        designationGuid: desKey,
        name: editDesignation,
        confirmButtonColor: "#007bff",
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.status.statusCode === 0) {
        Swal.fire({
          icon: "success",
          text: "Designation Updated",
          confirmButtonColor: "#007bff",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      } else {
        const errorMessage = data?.status.messageDescription;
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      getDesignation();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDesignationDelete = async (key: string) => {
    try {
      const url = `${API_URL}/payrollSetting/designation/delete`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        designationGuid: key,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.status.statusCode === 0) {
        Swal.fire({
          icon: "success",
          text: "Designation deleted",
          confirmButtonColor: "#007bff",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      const updatedDepartment = designation.filter((item) => item.key !== key);
      setDesignation(updatedDepartment);
    } catch (error) {
      console.log(error);
    }
  };

  interface DesignationItem {
    key: string;
    value: string;
  }

  interface EmployeeData {
    tenantId: string;
    prefix: string;
    sequencePattern: string;
    createdBy: string;
    createdDate: string;
    seqStatus: number;
  }
  const [department, setDepartment] = useState<DesignationItem[]>([]);

  const [designation, setDesignation] = useState<DesignationItem[]>([]);
  const [employeeData, setEmployeeData] = useState<EmployeeData[]>([]);

  const [departmentName, setDepartmentName] = useState("");
  const [departmentError, setDepartmentError] = useState(false);

  const [designationName, setDesignationName] = useState("");
  const [designationError, setDesignationError] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState<DesignationItem[]>([]);
  const [designationFilter, setDesignationFilter] = useState<DesignationItem[]>(
    []
  );
  const [empSeqFilter, setEmpSeqFilter] = useState<EmployeeData[]>([]);

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [EdithoveredIndex, setEditHoveredIndex] = useState<number | null>(null);

  const [prefix, setprefix] = useState("");
  const [sequnece, setSequence] = useState("");
  const [empError, setEmpError] = useState(false);

  const [editDepartment, setEditDepartment] = useState("");
  const [dptKey, setDptKey] = useState("");

  const [editDesignation, setEditDesignation] = useState("");
  const [desKey, setDesKey] = useState("");

  const [selectedOption, setSelectedOption] = useState("");

  const tooltipEdit = <Tooltip id="tooltip-add-money">Edit</Tooltip>;
  const tooltipDelete = <Tooltip id="tooltip-add-money">Delete</Tooltip>;

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };
  const handleUpdateButtonClick = async () => {
    try {
      const url = `${API_URL}/payrollSetting/managePayslipGenerationDate`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        payslipGenerationDate: selectedOption,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.status.statusCode === 0) {
        Swal.fire({
          icon: "success",
          text: "Payroll Generation Date Updated",
          confirmButtonColor: "#007bff",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handlePayrollGeneration = async () => {
  //     try {
  //         const url =
  //             `${API_URL}/payrollSetting/managePayslipGenerationDate`;
  //             const headers = {
  //                 'Content-Type': 'application/json',
  //                 'Authorization': `Bearer ${token}`
  //               };
  //         const body = JSON.stringify({
  //             payslipGenerationDate: selectedOption,
  //         });

  //         const options = {
  //             method: "POST",
  //             headers,
  //             body,
  //         };

  //         let response = await enhancedFetch(url, options);
  //         let data = await response.json();
  //         if (data.status.statusCode === 1) {
  //             setDepartmentError(true);
  //         }
  //     } catch (error) {
  //         console.log(error);
  //     }
  // };

  const handleEditDpt = (item: any) => {
    setEditDepartment(item.value);
    setDptKey(item.key);
    const modalElement = document.getElementById("edit_department");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };
  const handleEditDes = (item: any) => {
    setEditDesignation(item.value);
    setDesKey(item.key);
    const modalElement = document.getElementById("edit_designation");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  useEffect(() => {
    setFilteredData(
      department?.filter((dpt) =>
        dpt.value.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [department, searchQuery]);

  useEffect(() => {
    setDesignationFilter(
      designation?.filter((dpt) =>
        dpt.value.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [designation, searchQuery]);

  useEffect(() => {
    setEmpSeqFilter(
      employeeData?.filter(
        (dpt) =>
          dpt.createdBy.toLowerCase().includes(searchQuery.toLowerCase()) ||
          dpt.tenantId.toLowerCase().includes(searchQuery.toLowerCase()) ||
          dpt.seqStatus.toString().includes(searchQuery.toLowerCase()) ||
          dpt.prefix.toLowerCase().includes(searchQuery.toLowerCase()) ||
          dpt.sequencePattern.toString().includes(searchQuery.toLowerCase())
      )
    );
  }, [employeeData, searchQuery]);

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const handleDepartmentSubmit = () => {
    if (!departmentName) {
      setDepartmentError(true);
      return;
    }
    if (departmentName) {
      AddDepartment();
      handleDeleteCloseModal();
    }
  };

  const handleDesignationSubmit = () => {
    if (!designationName) {
      setDesignationError(true);
      return;
    }
    if (designationName) {
      AddDesignation();
      handleDesCloseModal();
    }
  };
  const handleEmpSeqSubmit = () => {
    if (!sequnece || !prefix) {
      setEmpError(true);
      return;
    }
    if (sequnece && prefix) {
      AddEmpSequence();
      handleCodeCloseModal();
    }
  };

  const AddDepartment = async () => {
    try {
      const url = `${API_URL}/payrollSetting/department/create`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        name: departmentName,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      // if (data.status.statusCode === 1) {
      //     setDepartmentError(true);
      // }
      if (data?.status.statusCode === 0) {
        Swal.fire({
          icon: "success",
          text: "Department Added",
          confirmButtonColor: "#007bff",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      } else {
        const errorMessage = data?.status.messageDescription;
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      getDepartment();
    } catch (error) {
      console.log(error);
    }
  };

  const AddDesignation = async () => {
    try {
      const url = `${API_URL}/payrollSetting/designation/create`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        name: designationName,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.status.statusCode === 0) {
        Swal.fire({
          icon: "success",
          text: "Designation Added",
          confirmButtonColor: "#007bff",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      } else {
        const errorMessage = data?.status.messageDescription;
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      getDesignation();
    } catch (error) {
      console.log(error);
    }
  };

  const AddEmpSequence = async () => {
    try {
      const url = `${API_URL}/payrollSetting/addEmployeeCodeSequence`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        empCodePrefix: prefix,
        empCodeSequencePattern: sequnece,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data?.status.statusCode === 0) {
        Swal.fire({
          icon: "success",
          text: "Sequence Added",
          confirmButtonColor: "#007bff",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      } else {
        const errorMessage = data?.status.messageDescription;
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      getEmpCodeSequence();
    } catch (error) {
      console.log(error);
    }
  };

  const getDepartment = async () => {
    try {
      const url = `${API_URL}/payrollSetting/department/view`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setDepartment(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const getDesignation = async () => {
    try {
      const url = `${API_URL}/payrollSetting/designation/view`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setDesignation(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const getEmpCodeSequence = async () => {
    try {
      const url = `${API_URL}/payrollSetting/loadPayrollSetting`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setEmployeeData(data.message.sequenceHistory);
      setSelectedOption(data.message.payslipGenerationDate);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDepartment();
    getDesignation();
    getEmpCodeSequence();
  }, []);

  return (
    <>
      <h4 className="mb-4">Settings</h4>
      <div className="card shadow p-2">
        <div className="d-flex justify-content-between mt-2">
          <ul className="nav nav-tabs nav-line-tabs ms-5 fs-6 d-flex flex-nowrap">
            <li className="nav-item" key={1}>
              <a
                className="nav-link active text-active-gray-800"
                data-bs-toggle="tab"
                href="#kt_tab_pane_1"
              >
                Department
              </a>
            </li>
            <li className="nav-item" key={2}>
              <a
                className="nav-link text-active-gray-800"
                data-bs-toggle="tab"
                href="#kt_tab_pane_2"
              >
                Designation
              </a>
            </li>
            <li className="nav-item" key={3}>
              <a
                className="nav-link text-nowrap text-active-gray-800"
                data-bs-toggle="tab"
                href="#kt_tab_pane_3"
              >
                Employee Id Sequence
              </a>
            </li>
            <li className="nav-item" key={4}>
              <a
                className="nav-link text-nowrap text-active-gray-800"
                data-bs-toggle="tab"
                href="#kt_tab_pane_4"
              >
                Payroll Generation Date
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content " id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="kt_tab_pane_1"
            role="tabpanel"
          >
            <>
              <div className="card">
                <div className="d-flex align-items-center justify-content-between p-5">
                  <div className="d-flex align-items-center position-relative my-1">
                    <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <input
                      value={searchQuery}
                      onChange={handleSearchChange}
                      type="text"
                      data-kt-docs-table-filter="search"
                      className="form-control p-3 border-secondary form-control-solid w-250px ps-15"
                      placeholder="Search department"
                    />
                  </div>
                  <Authorize hasAnyPermission={["MENU_PAYROLL|ADD"]}>
                    <button
                      className="btn fs-7 p-3"
                      onClick={() => {
                        handleAddDepartment();
                      }}
                    >
                      Add Department
                    </button>
                  </Authorize>
                </div>

                <div
                  className="modal fade"
                  tabIndex={-1}
                  id="add_department"
                  ref={modalRef}
                    
                >
                  <div
                    className="modal-dialog w-500px"
                    style={{ margin: "0 auto", marginTop: "15%" }}
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Add Department</h5>
                        <div
                          className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                          onClick={handleDeleteCloseModal}
                        >
                          <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="mt-5 mb-5">
                          <div className="p-5 ">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Department name"
                              value={departmentName}
                              onChange={(e) => {
                                setDepartmentName(e.target.value);
                              }}
                            />
                            {!departmentName && departmentError && (
                              <div className="text-danger">
                                Department cannot be empty
                              </div>
                            )}
                          </div>

                          <div className="d-flex flex-row justify-content-around p-5">
                            <button
                              className="btn"
                              style={{ width: "100%" }}
                              onClick={handleDepartmentSubmit}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-5">
                  <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                    <thead className="bg-gray-200">
                      <tr className="text-start text-dark-600 fw-bold fs-6">
                        <th className="min-w-100px p-5 text-start">
                          Department
                        </th>
                        <Authorize
                          hasAnyPermission={[
                            "MENU_PAYROLL|EDIT",
                            "MENU_PAYROLL|DELETE",
                          ]}
                        >
                          <th className="min-w-100px p-5 text-end">Action</th>
                        </Authorize>
                      </tr>
                    </thead>

                    <tbody>
                      {filteredData?.map((item, index) => (
                        <tr
                          key={index}
                          onMouseOver={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "#f1f1f2";
                            // target.style.transform =
                            //   "translate3d(6px, -6px, 0)";
                            // target.style.boxShadow =
                            //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                          }}
                          onMouseOut={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "white";
                            // target.style.transform = "none";
                            // target.style.boxShadow = "none";
                          }}
                        >
                          <td className="p-5 text-gray-600 text-start">
                            {item.value}
                          </td>
                          <Authorize
                            hasAnyPermission={[
                              "MENU_PAYROLL|EDIT",
                              "MENU_PAYROLL|DELETE",
                            ]}
                          >
                            <td className="p-5 text-end">
                              <Authorize
                                hasAnyPermission={["MENU_PAYROLL|EDIT"]}
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltipEdit}
                                >
                                  <i
                                    className={`bi bi-pencil-fill p-2 ${
                                      EdithoveredIndex === index
                                        ? "text-primary"
                                        : "text-dark"
                                    } cursor-pointer`}
                                    onClick={() => {
                                      handleEditDpt(item);
                                    }}
                                    onMouseEnter={() =>
                                      setEditHoveredIndex(index)
                                    }
                                    onMouseLeave={() =>
                                      setEditHoveredIndex(null)
                                    }
                                  ></i>
                                </OverlayTrigger>
                              </Authorize>
                              <Authorize
                                hasAnyPermission={["MENU_PAYROLL|DELETE"]}
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltipDelete}
                                >
                                  <i
                                    className={`bi bi-trash3-fill p-2 ${
                                      hoveredIndex === index
                                        ? "text-danger"
                                        : "text-dark"
                                    } cursor-pointer`}
                                    onClick={() =>
                                      handleDepartmentDelete(item.key)
                                    }
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                  ></i>
                                </OverlayTrigger>
                              </Authorize>
                            </td>
                          </Authorize>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
            <>
              <div className="card">
                <div className="d-flex align-items-center justify-content-between p-5">
                  <div className="d-flex align-items-center position-relative my-1">
                    <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <input
                      value={searchQuery}
                      onChange={handleSearchChange}
                      type="text"
                      data-kt-docs-table-filter="search"
                      className="form-control p-3 border-secondary form-control-solid w-250px ps-15"
                      placeholder="Search designation"
                    />
                  </div>
                  <Authorize hasAnyPermission={["MENU_PAYROLL|ADD"]}>
                    <button
                      className="btn fs-7 p-3"
                      onClick={() => {
                        handleAddDesignation();
                      }}
                    >
                      Add Designation
                    </button>
                  </Authorize>
                </div>

                <div
                  className="modal fade"
                  tabIndex={-1}
                  id="add_designation"
                  ref={desmodalRef}
                    
                >
                  <div
                    className="modal-dialog w-500px"
                    style={{ margin: "0 auto", marginTop: "15%" }}
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Add Designation</h5>
                        <div
                          className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                          onClick={handleDesCloseModal}
                        >
                           <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="mt-5 mb-5">
                          <div className="p-5 ">
                            <input
                              type="text"
                              className="form-control"
                              value={designationName}
                              placeholder="Enter Designation name"
                              onChange={(e) => {
                                setDesignationName(e.target.value);
                              }}
                            />
                            {!designationName && designationError && (
                              <div className="text-danger">
                                Designation cannot be empty
                              </div>
                            )}
                          </div>
                          <div className="d-flex flex-row justify-content-around p-5">
                            <button
                              className="btn"
                              style={{ width: "100%" }}
                              onClick={handleDesignationSubmit}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-5">
                  <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                    <thead className="bg-gray-200">
                      <tr className="text-start text-dark-600 fw-bold fs-6">
                        <th className="min-w-100px p-5 text-start">
                          Designation
                        </th>
                        <Authorize
                          hasAnyPermission={[
                            "MENU_PAYROLL|EDIT",
                            "MENU_PAYROLL|DELETE",
                          ]}
                        >
                          <th className="min-w-100px p-5 text-end">Action</th>
                        </Authorize>
                      </tr>
                    </thead>

                    <tbody>
                      {designationFilter.map((item, index) => (
                        <tr
                          key={index}
                          onMouseOver={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "#f1f1f2";
                            // target.style.transform =
                            //   "translate3d(6px, -6px, 0)";
                            // target.style.boxShadow =
                            //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                          }}
                          onMouseOut={(e) => {
                            const target = e.currentTarget as HTMLElement;
                            target.style.backgroundColor = "white";
                            // target.style.transform = "none";
                            // target.style.boxShadow = "none";
                          }}
                        >
                          <td className="p-5 text-start">{item.value}</td>
                          <Authorize
                            hasAnyPermission={[
                              "MENU_PAYROLL|EDIT",
                              "MENU_PAYROLL|DELETE",
                            ]}
                          >
                            <td className="p-5 text-end">
                              <Authorize
                                hasAnyPermission={["MENU_PAYROLL|EDIT"]}
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltipEdit}
                                >
                                  <i
                                    className={`bi bi-pencil-fill p-2 ${
                                      EdithoveredIndex === index
                                        ? "text-primary"
                                        : "text-dark"
                                    } cursor-pointer`}
                                    onClick={() => {
                                      handleEditDes(item);
                                    }}
                                    onMouseEnter={() =>
                                      setEditHoveredIndex(index)
                                    }
                                    onMouseLeave={() =>
                                      setEditHoveredIndex(null)
                                    }
                                  ></i>
                                </OverlayTrigger>
                              </Authorize>
                              <Authorize
                                hasAnyPermission={["MENU_PAYROLL|DELETE"]}
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltipDelete}
                                >
                                  <i
                                    className={`bi bi-trash3-fill p-2 ${
                                      hoveredIndex === index
                                        ? "text-danger"
                                        : "text-dark"
                                    } cursor-pointer`}
                                    onClick={() =>
                                      handleDesignationDelete(item.key)
                                    }
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                  ></i>
                                </OverlayTrigger>
                              </Authorize>
                            </td>
                          </Authorize>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
            <div className="card">
              <div className="d-flex align-items-center justify-content-between p-5">
                <div className="d-flex align-items-center position-relative my-1">
                  <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <input
                    value={searchQuery}
                    onChange={handleSearchChange}
                    type="text"
                    data-kt-docs-table-filter="search"
                    className="form-control p-3 border-secondary form-control-solid w-250px ps-15"
                    placeholder="Search Employee Id"
                  />
                </div>
                <Authorize hasAnyPermission={["MENU_PAYROLL|ADD"]}>
                  <button
                    className="btn fs-7 p-3"
                    onClick={() => {
                      handleAddEmpSequence();
                    }}
                  >
                    Add Employee Sequence
                  </button>
                </Authorize>
              </div>
              <div
                className="modal fade"
                ref={codemodalRef}
                tabIndex={-1}
                id="add_sequence"
                  
              >
                <div
                  className="modal-dialog w-500px"
                  style={{ margin: "0 auto", marginTop: "15%" }}
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        Add Employee Code Sequence
                      </h5>
                      <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                        onClick={handleCodeCloseModal}
                      >
                        <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="mt-5 mb-5">
                        <div className="p-5 ">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Prefix"
                            value={prefix}
                            onChange={(e) => setprefix(e.target.value)}
                          />
                          {!prefix && empError && (
                            <div className="text-danger">
                              Prefix cannot be empty
                            </div>
                          )}
                          <input
                            type="text"
                            className="form-control mt-5"
                            placeholder="Sequence"
                            value={sequnece}
                            onChange={(e) => setSequence(e.target.value)}
                          />
                          {!sequnece && empError && (
                            <div className="text-danger">
                              Sequence cannot be empty
                            </div>
                          )}
                        </div>
                        <div className="d-flex flex-row justify-content-around p-5">
                          <button
                            className="btn"
                            style={{ width: "100%" }}
                            onClick={handleEmpSeqSubmit}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-5">
                <table className="table align-middle border rounded table-row-dashed fs-6 g-5">
                  <thead className="bg-gray-200">
                    <tr className="text-start text-dark-600 fw-bold fs-6">
                      <th className="min-w-100px p-5 text-start">Prefix</th>
                      <th className="min-w-100px p-5 text-center">
                        Created Date
                      </th>
                      <th className="min-w-100px p-5 text-end">
                        Sequence Pattern
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {empSeqFilter?.map((item, index) => (
                      <tr
                        key={index}
                        onMouseOver={(e) => {
                          const target = e.currentTarget as HTMLElement;
                          target.style.backgroundColor = "#f1f1f2";
                          // target.style.transform =
                          //   "translate3d(6px, -6px, 0)";
                          // target.style.boxShadow =
                          //   "#e8e9eb -1px 1px, #e8e9eb -2px 2px, #e8e9eb -3px 3px, #e8e9eb -4px 4px, #e8e9eb -5px 5px, #e8e9eb -6px 6px";
                        }}
                        onMouseOut={(e) => {
                          const target = e.currentTarget as HTMLElement;
                          target.style.backgroundColor = "white";
                          // target.style.transform = "none";
                          // target.style.boxShadow = "none";
                        }}
                      >
                        <td className="p-5 text-start">{item.prefix}</td>
                        <td className="p-5 text-center">
                          <p
                            className="text-muted m-0"
                            style={{ fontSize: "12px" }}
                          >
                            {new Date(item.createdDate).toLocaleString(
                              "en-US",
                              {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            )}
                          </p>
                        </td>
                        <td className="p-5 text-end">
                          <h4
                            className={`card-text ${
                              item.seqStatus === 1
                                ? "text-primary"
                                : "text-danger"
                            }`}
                          >
                            {item.sequencePattern}
                          </h4>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
            <Authorize hasAnyPermission={["MENU_PAYROLL|ADD"]}>
              <div className="text-end me-5">
                <button
                  className="btn fs-7 p-3 "
                  onClick={() => {
                    handleEditDate();
                  }}
                >
                  Edit Generation Date
                </button>
              </div>
            </Authorize>

            <div className="card  p-5">
              {/* <div className="p-5">
                                <label className="form-label">Payroll Generation Date</label>
                                <select
                                    className="form-select"
                                    data-control="select2"
                                    data-placeholder="Select an option"
                                    value={selectedOption}
                                    onChange={handleSelectChange}
                                >
                                    <option>Select a Date</option>
                                    {Array.from({ length: 31 }, (_, index) => (
                                        <option key={index + 1} value={index + 1}>
                                            {" "}
                                            {index + 1}
                                        </option>
                                    ))}
                                </select>
                            </div> */}

              <div className="card shadow p-5 d-flex flex-row gap-2">
                <span className="fw-bolder fs-2x">{selectedOption}</span> of
                every month
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" tabIndex={-1} id="edit_department">
          <div
            className="modal-dialog w-500px"
            style={{ margin: "0 auto", marginTop: "15%" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Department</h5>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                </div>
              </div>
              <div className="modal-body">
                <div className="mt-5 mb-5">
                  <div className="p-5 ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Department name"
                      value={editDepartment}
                      onChange={(e) => {
                        setEditDepartment(e.target.value);
                      }}
                    />
                    {!editDepartment && departmentError && (
                      <div className="text-danger">
                        Department cannot be empty
                      </div>
                    )}
                  </div>

                  <div className="d-flex flex-row justify-content-around p-5">
                    <button
                      className="btn"
                      style={{ width: "100%" }}
                      onClick={handleDepartmentEdit}
                      data-bs-dismiss="modal"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          tabIndex={-1}
          id="edit_date"
            
        >
          <div
            className="modal-dialog w-500px"
            style={{ margin: "0 auto", marginTop: "15%" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Payroll Generation Date</h5>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                   <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                </div>
              </div>
              <div className="modal-body">
                <div className="p-5">
                  <label className="form-label">Payroll Generation Date</label>
                  <select
                    className="form-select mb-10"
                    data-control="select2"
                    data-placeholder="Select an option"
                    value={selectedOption}
                    onChange={handleSelectChange}
                  >
                    <option>Select a Date</option>
                    {Array.from({ length: 31 }, (_, index) => (
                      <option key={index + 1} value={index + 1}>
                        {" "}
                        {index + 1}
                      </option>
                    ))}
                  </select>
                  <div className="d-flex flex-row justify-content-around ">
                    <button
                      className="btn"
                      style={{ width: "100%" }}
                      data-bs-dismiss="modal"
                      onClick={handleUpdateButtonClick}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" tabIndex={-1} id="edit_designation">
          <div
            className="modal-dialog w-500px"
            style={{ margin: "0 auto", marginTop: "15%" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Designation</h5>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
                </div>
              </div>
              <div className="modal-body">
                <div className="mt-5 mb-5">
                  <div className="p-5 ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Department name"
                      value={editDesignation}
                      onChange={(e) => {
                        setEditDesignation(e.target.value);
                      }}
                    />
                    {!editDesignation && designationError && (
                      <div className="text-danger">
                        Designation cannot be empty
                      </div>
                    )}
                  </div>

                  <div className="d-flex flex-row justify-content-around p-5">
                    <button
                      className="btn"
                      style={{ width: "100%" }}
                      onClick={handleDesignationEdit}
                      data-bs-dismiss="modal"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
