/* eslint-disable jsx-a11y/anchor-is-valid */
import { StepProps } from "../IAppModels";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useEffect, useState } from "react";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import { enhancedFetch } from "../../../../modules/auth/core/_requests";
interface Accounts {
  accountNumber: string;
  currency: string;
  accountType: string;
  balance: number;
}

const Step2 = ({ data, hasError, updateData }: StepProps) => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  dayjs.extend(utc);
  dayjs.extend(timezone);

  // Set the default timezone to IST
  dayjs.tz.setDefault("Asia/Kolkata");

  const [accounts, setAccounts] = useState<Accounts[]>([]);

  const getAccounts = async () => {
    try {
      const url = `${API_URL}/transaction/getAccounts`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({});

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      setAccounts(data.results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAccounts();
  }, []);
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>): string => {
    const input = e.target.value;
    
    // Allow alphanumeric characters and spaces
    const processed = input.replace(/[^A-Za-z0-9\s]/g, "");
    
    // Check if the string contains at least one letter
    if (/[A-Za-z]/.test(processed) || processed === '') {
      // If it contains a letter or is empty, return the processed string
      return processed;
    } else {
      // If it's only numbers and/or spaces, return the previous value (removing the last character)
      return input.slice(0, -1);
    }
  };
  const getCurrentDateTime = () => {
    const now = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  return (
    <div className="pb-5" data-kt-stepper-element="content">
      <div className="w-100">
        <div className="mb-5">
          <label className="form-label">Enter name of bulk payment</label>
          <input
            type="text"
            className="form-control"
            placeholder="Name of bulk payment"
            minLength={2}
            maxLength={50}
            value={data.name.name}
            onChange={(e) =>
              updateData({
                name: {
                  name: handleInput(e),
                },
              })
            }
          />
          {!data.name.name && hasError && (
            <div className="fv-plugins-message-container">
              <div
                data-field="appname"
                data-validator="notEmpty"
                className="fv-help-block"
              >
                Name of the Bulk Payment is required
              </div>
            </div>
          )}
        </div>
        <div className="mb-5">
          <label className="form-label">Enter description</label>
          <input
            type="text"
            className="form-control"
            placeholder="Description"
            minLength={2}
            maxLength={50}
            autoComplete="off"
            value={data.description.description}
            onChange={(e) =>
              updateData({
                description: {
                  description:handleInput(e),
                },
              })
            }
          />
          {!data.description.description && hasError && (
            <div className="fv-plugins-message-container">
              <div
                data-field="appname"
                data-validator="notEmpty"
                className="fv-help-block"
              >
                Description of the Bulk Payment is required
              </div>
            </div>
          )}
        </div>
        <label className="form-label ">
          Would you like to Pay now or later ?
        </label>
        <div className="mb-5 d-flex flex-row  gap-5">
          <div className="form-check form-check-custom form-check-solid form-check-sm">
            <input
              className="form-check-input"
              name="schedule"
              type="radio"
              value="2"
              // id="sameBankRadio"
              checked={data.schedule.schedule === "Now"}
              onChange={() =>
                updateData({
                  schedule: {
                    schedule: "Now",
                  },
                })
              }
            />
            <label className="form-check-label" htmlFor="sameBankRadio">
              Now
            </label>
          </div>
          <div className="form-check form-check-custom form-check-solid form-check-sm">
            <input
              className="form-check-input"
              name="schedule"
              type="radio"
              value="1"
              // id="sameBankRadio"
              checked={data.schedule.schedule === "Later"}
              onChange={() =>
                updateData({
                  schedule: {
                    schedule: "Later",
                  },
                })
              }
            />
            <label className="form-check-label" htmlFor="sameBankRadio">
              Later
            </label>
          </div>
        </div>
        {data.schedule.schedule === "Later" && (
          <div className="mb-5">
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  disablePast
                  slotProps={{
                    textField: {
                      size: "small",
                      placeholder: "Select a date",
                      className: "form-control ",
                      style: {
                        // width: "100vw",
                        maxWidth: "705px",
                      },
                    },
                    inputAdornment: {
                      style: { padding: "22.5px 0px" },
                    },
                  }}
                  onChange={(selectedDate: Date | null) => {
                    if (selectedDate) {
                      // Use dayjs to format the date with the correct timezone
                      const formattedDate = dayjs(selectedDate).format();

                      // Use the formattedDate to update your data
                      updateData({
                        date: {
                          date: formattedDate,
                        },
                      });
                    }
                  }}
                />
              </DemoContainer>
            </LocalizationProvider> */}

            <input
              className="form-control"
              type="date"
              style={{ position: "relative" }}
              min={
                new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
                  .toISOString()
                  .split("T")[0]
              }
              onChange={(event) => {
                const selectedDate = event.target.value;
                console.log(selectedDate);

                const date = selectedDate?.split("T")[0];
                const time = selectedDate?.split("T")[1];
                const formattedDate = date + " " + time + ":00";
                console.log(formattedDate);

                updateData({
                  date: {
                    date: event.target.value,
                  },
                });
              }}
            />

            {!data.date.date && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Date is required
                </div>
              </div>
            )}

            {/* {data.date.date && hasError && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  Date should be greater than current date
                </div>
              </div>
            )} */}
            {new Date(data.date.date).getTime() < new Date().getTime() &&
              hasError && (
                <div className="fv-plugins-message-container">
                  <div
                    data-field="appname"
                    data-validator="notEmpty"
                    className="fv-help-block"
                  >
                    Date should be greater than current date
                  </div>
                </div>
              )}
          </div>
        )}

        <div className="mb-5">
          <label className="form-label">Choose A/C</label>

          <select
            className="form-select"
            data-control="select2"
            data-placeholder="Select an option"
            value={data.account.account}
            onChange={(e) =>
              updateData({
                account: {
                  account: e.target.value,
                },
              })
            }
          >
            <option selected>Select</option>
            {accounts
              ?.filter((item) => item.currency !== "USD")
              .map((item, key) => (
                <option value={item.accountNumber} key={key}>
                  {item.accountNumber}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  {item.balance.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  &nbsp;{item.currency}
                </option>
              ))}
          </select>
          {!data.account.account && hasError && (
            <div className="fv-plugins-message-container">
              <div
                data-field="appname"
                data-validator="notEmpty"
                className="fv-help-block"
              >
                Account is required
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { Step2 };
