import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import clsx from "clsx";
import { useLayout } from "../../core";
import { DrawerComponent } from "../../../assets/ts/components";
import { WithChildren } from "../../../helpers";
import ZohoSalesIQ from "../../../../app/routing/ZohoSalesiq";
import FirebaseNotification from "../../../../firebase/FirebaseNotification";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const Content = ({ children }: WithChildren) => {
  const { config, classes } = useLayout();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);

  const appContentContainer = config.app?.content?.container;
  return (
    <div
      id="kt_app_content"
      className={clsx(
        "app-content flex-column-fluid",
        classes.content.join(" "),
        config?.app?.content?.class
      )}
    >
      {appContentContainer ? (
        <div
          id="kt_app_content_container"
          className={clsx("app-container", classes.contentContainer.join(" "), {
            "container-xxl": appContentContainer === "fixed",
            "container-fluid": appContentContainer === "fluid",
          })}
        >
          {!window.location.href.split("/").includes("dashboard") && (
            <div className="d-flex flex-row flex-end">
              <div
                className="btn mb-5 d-flex flex-row"
                style={{
                  padding: "5px",
                  width: "80px",
                  position: "absolute",
                  top: "90px",
                }}
                onClick={() => navigate(-1)}
              >
                <i className="ki-duotone ki-arrow-left fs-2">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>{" "}
                Back
              </div>
            </div>
          )}
          <FirebaseNotification />
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
      {/* <TawkMessengerReact
        propertyId="66e299b950c10f7a00a87a1b"
        widgetId="1i7iigvmn" /> */}
      <ZohoSalesIQ />
      <br />
      <br />
    </div>
  );
};

export { Content };
