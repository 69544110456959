import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-datepicker/dist/react-datepicker.css";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { DatePicker } from "@mui/x-date-pickers";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { findBusinessCategory, getUserDetails, updateUser } from "./Api";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { BusinessFormValues, CategoryModal, Payload } from "./Interface";
import dayjs from "dayjs";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import Swal from "sweetalert2";
import { Modal } from "bootstrap";
import { apiCall } from "../dashboard/DashboardWrapper";
import { enhancedFetch } from "../../modules/auth/core/_requests";
import { ImgUpdate } from "../../../_metronic/layout/components/header/Navbar";

export const ApiCalling = {
  api: (): void => {},
};

const CompanyDetails = () => {
  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const [img, setImg] = useState<string | undefined>("");
  const [doc, setDoc] = useState<string | undefined>("");

  const API_URL = process.env.REACT_APP_WEB_API_URL;
  const [userDetails, setUserDetails] = useState<BusinessFormValues>();
  const [businessCategory, setBusinessCategory] = useState<CategoryModal[]>();
  const [change, setChange] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const imageModalRef = useRef<HTMLDivElement | null>(null);
  const initialValues = {
    businessName: userDetails?.businessName || "",
    firstName: userDetails?.firstName || "",
    yearOfIncorporation: userDetails?.yearOfIncorporation || "",
    email: userDetails?.email || "",
    country: userDetails?.isdCode?.toString() || "",
    businessCategory: userDetails?.businessCategory || "",
    address: userDetails?.address || "",
    vatNumber: userDetails?.vatNumber || "",
    nidNumber: userDetails?.nidNumber || "",
  };

  const validationSchema = Yup.object({
    businessName: Yup.string().required("Business Name is required"),
    firstName: Yup.string().required("Business Owner Name is required"),
    yearOfIncorporation: Yup.string().required(
      "Incorporation Date is required"
    ),
    country: Yup.string().required("Country is required"),
    businessCategory: Yup.string().required("Company is required"),
    address: Yup.string().required("Business address is required"),
  });

  const onSubmit = (values: Payload) => {
    if (values.yearOfIncorporation) {
      values.yearOfIncorporation = values.yearOfIncorporation;
    }

    const formattedDate = dayjs(values.yearOfIncorporation).toISOString();

    // Your submission logic here...
    const payload: Payload = {
      ...values,
      yearOfIncorporation: formattedDate,
    };
    updateUser(API_URL, token, payload).then((response) => {
      if (response?.statusCode === 0) {
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Your Profile has been updated",
          showCancelButton: false, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    });
  };

  const handleViewClick = async () => {
    const modalElement = document.getElementById("image_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleDocViewClick = async () => {
    const modalElement = document.getElementById("pdf_modal");
    const modal = new Modal(modalElement as Element);
    modal.show();
  };

  const handleButtonClick = () => {
    // Trigger the file input click event
    fileInputRef?.current?.click();
  };
  async function convertFileToBase64(
    file: File | undefined
  ): Promise<string | undefined> {
    if (!file) {
      return undefined;
    }

    return new Promise<string | undefined>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (typeof reader.result === "string") {
          const base64Data = reader.result.split(",")[1];
          resolve(base64Data);
        } else {
          resolve(undefined);
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }

  const handleFileChange = async (
    event: ChangeEvent<HTMLInputElement>,
    documentType: number
  ) => {
    const file = event.target.files?.[0];
    const businessGuid = businessData.businessGuid;

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
    }

    const data = {
      tenantId: "tenantId",
      businessGuid: businessData.businessGuid,
      documents: [
        {
          documentExtention: file?.type.split("/")[1],
          documentName: file?.name.split(".")[0],
          documentTypes: [documentType],
          documentUploadedByType: 1,
          documentStatus: 4,
          fileUploadType: 1,
          documentData: await convertFileToBase64(file),
        },
      ],
    };

    try {
      // await dispatch(editBusinessDocument(data as any) as any);
      // dispatch(
      //   fetchprofileOverview({
      //     tenantId,
      //     businessGuid,
      //     userGuid,
      //   } as any) as any
      // );
    } catch (error) {
      console.error("Error editing user image:", error);
      // Handle the error as needed
    }
  };

  useEffect(() => {
    const enhancedFetchData = async () => {
      const data = await getUserDetails(API_URL, token);
      setUserDetails(data);
      const businessCategory = await findBusinessCategory(API_URL, token);
      setBusinessCategory(businessCategory);
    };

    ApiCalling.api = enhancedFetchData;

    enhancedFetchData();
  }, []);

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result as string;
        const startIndex = base64String.indexOf(",") + 1; // Find the index of the comma and move one position forward
        resolve(base64String.substring(startIndex));
      };

      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  };

  const updateProfile = async (profileData: any) => {
    try {
      const url = `${API_URL}/updateProfileImage`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({ documents: [profileData] });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();
      if (data.status.statusCode === 0) {
        ApiCalling.api();
        ImgUpdate.api();
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: "Your Profile Image has been updated",
          showCancelButton: false, // Show the cancel button
          confirmButtonColor: "#007bff", // Set the confirm button color to btn
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const businessData = {
    tenantId: "eazy_bank",
    businessGuid: "1da885f910dc40a4a56faa19a4f91e85",
    businessName: "Amar Traders",
    bankCustomerId: "258439",
    address: "Kudlu, Bangalore",
    contactPerson: "Dennis",
    contactNumber: "91-9590314009",
    email: "sambit@yopmail.com",
    county: "Bangalore",
    state: "Karnataka",
    country: "IND",
    primaryCurrency: "ZAR",
    businessStatus: 10,
    yearOfIncorporation: "1998-07-31T18:30:00.000+00:00",
    businessCategory: "BUSINESS_CUSTOMER",
    vatNumber: "1234567",
    createdDate: "2024-01-31T12:23:04.000+00:00",
    createdBy: "dennis",
    updatedDate: "2024-06-12T07:52:26.000+00:00",
    updatedBy: "ed4ca90a568b469e87c31a11b87f30d9",
    documents: [
      {
        tenantId: "Eazy_bank",
        documentId: 610,
        documentGuid: "997a6a0d-d670-416b-b135-220004e37a33",
        documentExtention: "jpeg",
        documentName: "logoimage",
        documentPath:
          "https://dev-test.xfin.digital/document/document/downloadDocument/997a6a0d-d670-416b-b135-220004e37a33",
        fileUploadType: 1,
        documentUploadedByType: 1,
        documentStatus: 1,
        referenceType: "BUSINESS",
        referenceId: "1da885f910dc40a4a56faa19a4f91e85",
        documentTypes: [16],
        cloneDocument: false,
      },
      {
        tenantId: "eazy_bank",
        documentId: 3135,
        documentGuid: "dbbc5824-c6b4-4083-baff-6858f66a5c23",
        documentExtention: "jpeg",
        documentName: "TypeScript Cheat Sheet (LIGHT)",
        documentPath:
          "https://dev-test.xfin.digital/document/document/downloadDocument/dbbc5824-c6b4-4083-baff-6858f66a5c23",
        fileUploadType: 1,
        documentUploadedByType: 1,
        documentStatus: 1,
        referenceType: "BUSINESS",
        referenceId: "1da885f910dc40a4a56faa19a4f91e85",
        documentTypes: [19],
        cloneDocument: false,
      },
      {
        tenantId: "Eazy_bank",
        documentId: 615,
        documentGuid: "9c42f94f-7b61-4eb7-b0de-3e4a0491dc63",
        documentExtention: "jpeg",
        documentName: "liscence",
        documentPath:
          "https://dev-test.xfin.digital/document/document/downloadDocument/9c42f94f-7b61-4eb7-b0de-3e4a0491dc63",
        fileUploadType: 1,
        documentUploadedByType: 1,
        documentStatus: 1,
        referenceType: "BUSINESS",
        referenceId: "1da885f910dc40a4a56faa19a4f91e85",
        documentTypes: [18],
        cloneDocument: false,
      },
    ],
    profiles: [
      {
        profileGuid: "2",
        profileName: "Full Kyc",
      },
    ],
    city: "Bangalore",
  };
  return (
    <>
      {" "}
      <h4 className="mb-5">Company Details</h4>
      <div
        className={`d-flex gap-3 ${
          window.innerWidth < 11 * 96 ? "flex-wrap" : "flex-nowrap"
        }`}
      >
        <div className="card shadow-sm p-5">
          <div className="d-flex flex-row ms-5 mt-5">
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Company Name</div>
              <div className="fs-7">{userDetails?.businessName}</div>
            </div>
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Registered Email</div>
              <div>{userDetails?.email}</div>
            </div>
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Registered Phone Number</div>
              <div>
                {userDetails?.isdCode}-{userDetails?.mobile}
              </div>
            </div>
          </div>
          <br />
          <div className="d-flex flex-row ms-5 mt-5">
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Registered Address</div>
              <div className="fs-7">{userDetails?.address}</div>
            </div>
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">License</div>
              <div className="d-flex flex-row">
                <div
                  className="text-primary cursor-pointer"
                  onClick={() => {
                    if (
                      businessData?.documents?.find(
                        (document) => document?.documentTypes[0] === 16
                      )?.documentExtention === "pdf"
                    ) {
                      setDoc(
                        businessData?.documents?.find(
                          (document) => document?.documentTypes[0] === 16
                        )?.documentPath
                      );
                      handleDocViewClick();
                    } else {
                      setImg(
                        businessData?.documents?.find(
                          (document) => document?.documentTypes[0] === 16
                        )?.documentPath
                      );
                      handleViewClick();
                    }
                  }}
                >
                  {
                    businessData?.documents?.find(
                      (document) => document?.documentTypes[0] === 16
                    )?.documentName
                  }
                </div>{" "}
                <i
                  className="bi bi-pencil-square fs-8 cursor-pointer text-dark mt-2 ms-2"
                  // onChange={(e) => handleFileChange(e, 18)}
                ></i>
              </div>
            </div>
            <div style={{ width: "400px" }}>
              <div className="fw-bold fs-5">Tax Document</div>
              <div className="d-flex flex-row">
                <div
                  className="text-primary cursor-pointer"
                  onClick={() => {
                    if (
                      businessData?.documents?.find(
                        (document) => document?.documentTypes[0] === 19
                      )?.documentExtention === "pdf"
                    ) {
                      setDoc(
                        businessData?.documents?.find(
                          (document) => document?.documentTypes[0] === 19
                        )?.documentPath
                      );
                      handleDocViewClick();
                    } else {
                      setImg(
                        businessData?.documents?.find(
                          (document) => document?.documentTypes[0] === 19
                        )?.documentPath
                      );
                      handleViewClick();
                    }
                  }}
                >
                  {
                    businessData?.documents?.find(
                      (document) => document?.documentTypes[0] === 19
                    )?.documentName
                  }
                </div>{" "}
                <i
                  className="bi bi-pencil-square fs-8 cursor-pointer text-dark mt-2 ms-2"
                  // onChange={(e) => handleFileChange(e, 18)}
                ></i>
              </div>
            </div>
          </div>
          <br />
          <div className="d-flex flex-row ms-5 my-5" style={{ width: "250px" }}>
            <div>
              <div className="fw-bold fs-5">AOA/MOA</div>
              <div className="d-flex flex-row">
                <div
                  className="text-primary cursor-pointer"
                  onClick={() => {
                    if (
                      businessData?.documents?.find(
                        (document) => document?.documentTypes[0] === 18
                      )?.documentExtention === "pdf"
                    ) {
                      setDoc(
                        businessData?.documents?.find(
                          (document) => document?.documentTypes[0] === 18
                        )?.documentPath
                      );
                      handleDocViewClick();
                    } else {
                      setImg(
                        businessData?.documents?.find(
                          (document) => document?.documentTypes[0] === 18
                        )?.documentPath
                      );
                      handleViewClick();
                    }
                  }}
                >
                  {
                    businessData?.documents?.find(
                      (document) => document?.documentTypes[0] === 18
                    )?.documentName
                  }
                </div>{" "}
                <i
                  className="bi bi-pencil-square fs-8 cursor-pointer text-dark mt-2 ms-2"
                  // onChange={(e) => handleFileChange(e, 18)}
                ></i>
              </div>
            </div>
          </div>
        </div>

        <div className="modal" tabIndex={-1} id="image_modal">
          <div className="modal-dialog modal-dialog-centered">
            <div
              className="modal-content"
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <div className="modal-body justify-content-center d-flex">
                <div
                  className="image-input image-input-outline"
                  data-kt-image-input="true"
                >
                  <img
                    className="image"
                    src={img}
                    style={{
                      borderRadius: "10px",
                      objectFit: "contain",
                      maxWidth: "500px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="pdf_modal"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered mw-700px">
            <div
              className="modal-content"
              style={{ maxHeight: "700px", overflow: "auto" }}
            >
              <div className="modal-header" id="kt_modal_add_user_header">
                <h2 className="fw-bold">PDF Viewer</h2>

                <div
                  className="btn btn-icon btn-sm btn-active-icon-primary"
                  data-kt-users-modal-action="close"
                  data-bs-dismiss="modal"
                >
                  <i className="ki-duotone ki-cross fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </div>
              </div>

              <div className="modal-body px-15">
                <embed
                  src={`${doc}#toolbar=0`}
                  type="application/pdf"
                  width="100%"
                  height="800px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CompanyDetails;
