/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";
import { defaultCreateAppData, ICreateAppData } from "./IAppModels";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { KTIcon } from "../../../../_metronic/helpers";
import { Step1 } from "./steps/Step1";
import { Step2, dataOutcome } from "./steps/Step2";
import { getQrCodes } from "../QrApi";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { ApiData } from "../QRDashboard";
import PinInput from "react-pin-input";
import withReactContent from "sweetalert2-react-content";
import { enhancedFetch } from "../../../modules/auth/core/_requests";
interface CustomSweetAlertOptions extends SweetAlertOptions {
  onOpen?: () => void;
}

type Props = {
  show: boolean;
  handleClose: () => void;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const CreateAppModal = ({ show, handleClose }: Props) => {
  const API_URL = process.env.REACT_APP_WEB_API_URL;

  const tokenDataString = sessionStorage.getItem("kt-auth-react-v");
  const tokenData = tokenDataString ? JSON.parse(tokenDataString) : null;
  const token = tokenData ? tokenData.token : null;

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  let pindata: string;
  const [data, setData] = useState<ICreateAppData>(defaultCreateAppData);
  const [hasError, setHasError] = useState(false);

  const [isFileUploading, setIsFileUploading] = useState(false);
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  document.addEventListener('keydown', function(event) {
    if (event.key === "Escape" || event.key === "Esc") {
      Swal.clickConfirm(); 
      updateData(resetData);
      handleClose();
    }
  });

  const updateData = (fieldsToUpdate: Partial<ICreateAppData>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const showPinInputModal = async () => {
    const MySwal = withReactContent(Swal);

    const handlePinChange = (value: string) => {
      if (value.length <= 4) {
        pindata = value;
      }
    };

    // Customize the Swal modal
    await MySwal.fire({
      title: "Enter Your Pin",
      customClass: {
        input: "pin-input-class",
      },
      confirmButtonColor: "#007bff",
      html: (
        <>
          <p>Enter your PIN to confirm</p>
          <PinInput
            length={4}
            inputStyle={{ borderRadius: "5px", marginLeft: "10px" }}
            // ref={pinInputRef}
            onChange={handlePinChange}
            focus={true}
            autoSelect={false}
            secret
          />
        </>
      ),
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      allowEscapeKey: true,
      allowEnterKey: true,
      preConfirm: () => {
        if (pindata.length === 4) {
          return;
        } else {
          Swal.resetValidationMessage();
          Swal.showValidationMessage("Please enter a 4-digit PIN");
          return false;
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        fileUpload();
      }
    });
  };

  const checkAppBasic = (): boolean => {
    if (data.category.category === "Manual") {
      if (!data.name.name) {
        return false;
      }
      if (!data.description.description) {
        return false;
      }
    }
    if (data.category.category === "Batch") {
      if (!dataOutcome.fileExist) {
        return false;
      }
    }

    return true;
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }
    if (stepper.current.getCurrentStepIndex() === 2) {
      updateData({
        name: { name: "" },
        description: { description: "" },
        fileData: { fileData: "" },
      });
    }
    stepper.current.goPrev();
  };

  const nextStep = () => {
    setHasError(false);
    if (!stepper.current) {
      return;
    }

    if (stepper.current.getCurrentStepIndex() === 2) {
      if (!checkAppBasic()) {
        setHasError(true);
        return;
      }
    }

    stepper.current.goNext();
  };

  const submit = () => {
    setHasError(false);

    if (!stepper.current) {
      return;
    }

    if (stepper.current.getCurrentStepIndex() === 2) {
      if (!checkAppBasic()) {
        setHasError(true);
        return;
      }
    }

    if (data.category.category === "Manual") createQrCodes();
    if (data.category.category === "Batch" && dataOutcome.fileUploadId) {
      showPinInputModal();
      handleClose();
    }
  };

  const createQrCodes = async () => {
    try {
      const url = `${API_URL}/payment/getQRCode`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        qrCodeModels: [
          {
            qrCodeName: data.name.name,
            usage: "multiple_use",
            qrCodeDescription: data.description.description,
          },
        ],
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let qrData = await response.json();

      if (qrData.status.statusCode === 0) {
        updateData({
          name: {
            name: "",
          },
          description: {
            description: "",
          },
        });
        setTimeout(() => {
          ApiData.api();
        }, 5000);

        handleClose();
        const msg = isFileUploading
          ? "File upload successful !! We will notify once the file is processed"
          : "Your QR Code has been generated";
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          html: `<p>${msg}</p><img src="${qrData?.message?.txnPGResponseMessage}" alt="QR Code" style="width: 100%; height: 100%; object-fit: cover;">`,
          showCancelButton: true,
          confirmButtonColor: "#007bff",
          confirmButtonText: "Download QR",
          cancelButtonColor: "#9fa6b2",
          cancelButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        }).then((result) => {
          if (result.isConfirmed) {
            const downloadLink = document.createElement("a");
            downloadLink.href = qrData?.message?.txnPGResponseMessage || "";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          }
        });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const fileUpload = async () => {
    try {
      const url = `${API_URL}/bulk/processQRCodeFile`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = JSON.stringify({
        fileUploadId: dataOutcome?.fileUploadId,
        transactionPIN: pindata,
      });

      const options = {
        method: "POST",
        headers,
        body,
      };

      let response = await enhancedFetch(url, options);
      let data = await response.json();

      if (data.status.statusCode === 0) {
        updateData({
          name: { name: "" },
          description: { description: "" },
          fileData: { fileData: "" },
        });
        setTimeout(() => {
          ApiData.api();
        }, 5000);
        handleClose();
        pindata = "";
        const msg = isFileUploading
          ? "File upload successful !! We will notify once the file is processed"
          : "Your QR Code has been generated";
        Swal.fire({
          icon: "success",
          title: "Confirmation",
          text: msg,
          showCancelButton: false,
          confirmButtonColor: "#007bff",
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
      if (data.status.statusCode === 1) {
        const msg = data?.status?.messageDescription;
        Swal.fire({
          icon: "error",
          title: "Error",
          text: msg,
          showCancelButton: false,
          confirmButtonColor: "#007bff",
          confirmButtonText: "Ok",
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetData = {
    category: { category: "Manual" },
    name: { name: "" },
    description: { description: "" },
    submit: { submit: () => {} },
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-800px"
      show={show}
      onHide={() => {
        handleClose();
        updateData(resetData);
      }}
      onEntered={loadStepper}
     
    >
      <div className="modal-header">
        <h2>Create Qr Code</h2>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2 text-hover-primary"
          onClick={() => {
            handleClose();
            updateData(resetData);
          }}
        >
        <i className="fas fa-times fs-2 text-light text-hover-primary"></i>
        </div>
      </div>

      <div className="modal-body py-lg-10 px-lg-10">
        <div
          ref={stepperRef}
          className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
          id="kt_modal_create_app_stepper"
        >
          <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
            <div className="stepper-nav ps-lg-10">
              <div
                className="stepper-item current"
                data-kt-stepper-element="nav"
              >
                <div className="stepper-wrapper">
                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">1</span>
                  </div>
                  <div className="stepper-label">
                    <h3 className="stepper-title">Select Type of QR</h3>
                  </div>
                </div>
                <div className="stepper-line h-40px"></div>
              </div>
              <div className="stepper-item" data-kt-stepper-element="nav">
                <div className="stepper-wrapper">
                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">2</span>
                  </div>
                  <div className="stepper-label">
                    <h3 className="stepper-title">Enter details/Upload File</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-row-fluid px-lg-15">
            <form noValidate id="kt_modal_create_app_form">
              <Step1 data={data} updateData={updateData} hasError={hasError} />
              <Step2 data={data} updateData={updateData} hasError={hasError} />

              <div className="d-flex flex-stack pt-10">
                <div className="me-2">
                  <button
                    type="button"
                    className="btn btn-lg btn-light-primary me-3"
                    data-kt-stepper-action="previous"
                    onClick={prevStep}
                  >
                    <KTIcon iconName="arrow-left" className="fs-3 me-1" />{" "}
                    Previous
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-lg btn"
                    data-kt-stepper-action="next"
                    onClick={nextStep}
                  >
                    Next Step{" "}
                    <KTIcon iconName="arrow-right" className="fs-3 ms-1 me-0" />
                  </button>

                  <button
                    type="button"
                    className="btn btn-lg btn"
                    data-kt-stepper-action="submit"
                    onClick={submit}
                  >
                    Submit{" "}
                    <KTIcon iconName="arrow-right" className="fs-3 ms-2 me-0" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  );
};

export { CreateAppModal };
